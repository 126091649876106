import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import NoResultsFound from "components/ui/NoResultsFound";
import { useQueryClient } from "@tanstack/react-query";
import {
  ColumnOrderState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  SourceProps,
  returnValueAsAnArray,
  getImageSrc,
  getValueByAccessor,
  TableColumn,
} from "../../../views/library/helpers";
import { errorHandler } from "utils/helpers";
import {
  FaFilter,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
} from "react-icons/fa";
import { useDataLibraryAPI } from "api/useDataLibraryAPI";
import DataSearchInput from "./SearchInput";
import { useNavigate } from "react-router-dom";
import { indicationToColor } from "theme/helper";
import { CoreContext } from "views/library/core";
import {
  handleDragEnd,
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleFilterClear,
  handleRowClick,
  handleFilterInputKeyDown,
  handleFilterIconClick,
  handleFilterInputChange
} from './helper';
import useGuideTour from 'hooks/guideTour/useGuideTour';
import { CustomOverlay } from "./CustomOverlay";
import CustomLoader from "./CustomLoader";

const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
};

export interface AgnosticDataDisplayProps {
  source: SourceProps;
}

export interface ColumnFiltersProp {
  [key: string]: string;
}

const AgnosticDataDisplay = ({ source }: AgnosticDataDisplayProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { key, table } = source;
  const { columns } = table;

  const { searchState, setSearchState, results, setResults } = useContext(CoreContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const itemsPerPage = 20;
  const page = useRef<number>(1);
  const sort = useRef<string | null>(null);
  const order = useRef<"desc" | "asc" | null>(null);
  const fetchedAll = useRef<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    table.columns.map((col) => col.accessor)
  );
  const [searchValue, setSearchValue] = useState(searchState.searchValue);
  const [sorting, setSorting] = useState(searchState.sorting);
  const [columnsFilters, setColumnsFilters] = useState(searchState.columnsFilters);
  const [filters, setFilters] = useState(searchState.filters);
  const inputRefs = useRef(new Map());
  const { fetchTableData, fetchProprietaryTableData } = useDataLibraryAPI();

  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false; // Set to false when the component is unmounted
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  useEffect(() => {
    setSearchState({ searchValue, sorting, filters, columnsFilters });
  }, [searchValue, sorting, filters, setSearchState, columnsFilters]);

  const handleScroll = async (e: any) => {
    console.log(e, 'scrolling now******* core')

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottom = scrollHeight - scrollTop <= clientHeight * 1.5;

    if (bottom && !isFetching && page.current && !fetchedAll.current) {
      setFetching(true);
      page.current += 1;
      try {
        const controller = new AbortController();
        setAbortController(controller);

        const data = await queryClient.fetchQuery({
          queryKey: [`${key}-table`, { query: searchValue, page: page.current, itemsPerPage, source: key, order: order.current, sort: sort.current, filters }],
          queryFn: ({ queryKey }) => fetchTableData({ queryKey, signal: controller.signal }),
          staleTime: 2 * 1000 * 60,
          retry: false,
        });
        setResults((prevData) => prevData ? [...prevData, ...data.data] : data.data);
        fetchedAll.current = !data.hasMore;
      } catch (error: any) {
        if (error.name !== 'CanceledError') {
          setError(errorHandler(error).message);
        } else {
          isMounted.current && fetchData();
        }
      } finally {
        setFetching(false);
      }
    }
  };

  // Table Instance
  const columnHelper = createColumnHelper<TableColumn>();
  const tableColumns = useMemo(() =>
    table.columns.map((col: TableColumn) => columnHelper.accessor(row => row.accessor, {
      id: col.accessor,
      header: col.header,
      enableSorting: !!col.sortable ? true : false,
      enableColumnFilter: !!col.filterable ? true : false,
    })),
    [table.columns]
  );

  const tableInstance = useReactTable({
    columns: tableColumns,
    data: results ?? [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnOrder,
      columnVisibility: {
        id: false
      }
    },
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
  });

  // Table Search
  const clearSearch = () => {
    // Cancel ongoing request
    if (abortController) abortController.abort();

    setSearchValue('')
    page.current = 1
    sort.current = null
    order.current = null
  }

  const submitSearchForm = (inputStr: string) => {
    if (loading || isFetching) return
    clearSearch()
    setFilters({})
    setColumnsFilters({ showFilter: null, filters: {} })
    setSearchValue(inputStr)
  };

  // Sorting
  const fetchData = async () => {
    setLoading(true);
    try {
      const controller = new AbortController();
      setAbortController(controller);

      const data = await queryClient.fetchQuery({
        queryKey: [`${key}-table`, { query: searchValue, page: page.current, itemsPerPage, source: key, order: order.current, sort: sort.current, filters }],
        queryFn: ({ queryKey }) => fetchTableData({ queryKey, signal: controller.signal }),
        staleTime: 2 * 1000 * 60,
        retry: false,
      });
      console.log()
      setResults(data.data);
      fetchedAll.current = !data.hasMore;
      if (data.data.length === 0)
        fetchedAll.current = !data.hasMore;
    } catch (error: any) {
      if (error.name !== 'CanceledError') {
        setError(errorHandler(error).message);
      } else {
        isMounted.current && fetchData();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchValue, key, itemsPerPage, filters]);

  useEffect(() => {
    if (sorting.length === 0) return;

    const tempOrder = sorting[0]?.desc ? "desc" : "asc";
    const tempSort = sorting[0]?.id ?? "id";
    order.current = tempOrder;
    sort.current = tempSort;

    page.current = 1;
    fetchData();
  }, [sorting]);

  const { isHighlighted } = useGuideTour()
  let sortHighlighted = isHighlighted('core_table-column-sort')
  let filterHighlighted = isHighlighted('core_table-column-filter')
  let rowHighlighted = isHighlighted('core_table-row')
  let sortInfoDisplayed = false
  let filterInfoDisplayed = false

  return (
    <>
      <DataSearchInput
        submitSearchForm={submitSearchForm}
        clearSearch={clearSearch}
        searchVal={searchValue}
      />
      <TableContainer height="full" style={{ position: 'relative' }} zIndex={sortHighlighted || filterHighlighted || rowHighlighted ? 1300 : 1}>
        <CustomScrollBar style={tableStyle} onScrollCapture={handleScroll} zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}>
          
          
          <Table variant="simple" size="sm" >
            <Thead
              position={"sticky"}
              zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}
              top={0}
              bg={"gray.100"}
              boxShadow={'none'}
            >
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} position={'relative'}>
                  {headerGroup.headers.map((header, index) => {
                    const canSort = header.column.getCanSort();
                    const isSorted = header.column.getIsSorted();
                    const canFilter = header.column.getCanFilter();
                    const showFilter = (columnsFilters.showFilter === header.id) || !!columnsFilters.filters[header.id];

                    let SortInfo = !sortInfoDisplayed
                    let FilterInfo = !filterInfoDisplayed

                    if (canSort) {
                      sortInfoDisplayed = true
                    }
                    if (canFilter) {
                      filterInfoDisplayed = true
                    }

                    return (
                      <Th
                        key={header.id}
                        boxShadow={'none'}
                        py={2}
                        px={1}
                        textAlign={'left'}
                        border={'none'}
                        borderLeftRadius={index === 0 ? "md" : 'none'}
                        borderRightRadius={index === headerGroup.headers.length - 1 ? "md" : 'none'}
                        borderRight={index !== headerGroup.headers.length - 1 ? '1px solid' : 'none'}
                        borderRightColor={'gray.200'}
                        draggable
                        onDragStart={(e) => handleDragStart(e, header.id)}
                        onDrop={(e) => handleDrop(e, header.id, columnOrder, setColumnOrder)}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDragEnd={handleDragEnd}
                      >
                        {(sortHighlighted || filterHighlighted || rowHighlighted) && <CustomOverlay />}
                        <Flex alignItems={'center'} justifyContent={'space-between'} color={'gray.500'}>
                          {canSort ? (
                            <Box
                              p={1}
                              id={'core_table-column-sort'}
                              zIndex={sortHighlighted && SortInfo ? 1400 : 1}
                              bg={sortHighlighted && SortInfo ? 'background' : 'transparent'}
                              height={'100%'}
                              onClick={header.column.getToggleSortingHandler()}
                              borderRadius={'4px'}
                            >
                              <Flex alignItems={'center'}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {isSorted === 'asc' ? (
                                  <FaSortUp style={{ marginLeft: '2px' }} />
                                ) : isSorted === 'desc' ? (
                                  <FaSortDown style={{ marginLeft: '2px' }} />
                                ) : (
                                  <FaSort style={{ marginLeft: '2px' }} />
                                )}
                              </Flex>
                            </Box>
                          ) : (
                            <Box>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </Box>
                          )}
                          {canFilter && (
                            <InputGroup
                              width={'fit-content'}
                              justifyContent={'flex-end'}
                              id={'core_table-column-filter'}
                              zIndex={FilterInfo && filterHighlighted ? 1400 : 1}
                              bg={FilterInfo && filterHighlighted ? 'background' : 'transparent'}
                              borderRadius={'4px'}
                            >
                              <Input
                                ref={(el) => inputRefs.current.set(header.id, el)}
                                disabled={!!filters[header.id]}
                                value={columnsFilters.filters[header.id] || ''}
                                transition="ease-in-out .4s"
                                width={showFilter ? '100%' : '0'}
                                minWidth={showFilter ? '70px' : '0'}
                                maxWidth={'170px'}
                                pl={showFilter ? 1 : 0}
                                border={showFilter ? '1px solid' : 'none'}
                                borderColor={showFilter ? 'inherit' : 'transparent'}
                                backgroundColor={!!filters[header.id] ? 'green.100' : 'inherit'}
                                pr={'20px'}
                                py={0}
                                h={6}
                                borderRadius={4}
                                size="xs"
                                color={'gray.700'}
                                outline={'none'}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleFilterInputKeyDown(e, header.id, clearSearch, setFilters)}
                                onChange={(e) => handleFilterInputChange(e, header.id, setColumnsFilters)}
                                placeholder={`filter ${header.column.columnDef.header}`}
                                _focus={{
                                  boxShadow: 'none'
                                }}
                              />
                              <InputRightElement height={'100%'} width={'12px'} right={'5px'}>
                                {columnsFilters.filters[header.id] ? (
                                  <FaTimes
                                    fontSize="12px"
                                    onClick={() => handleFilterClear(header.id, setColumnsFilters, setFilters)}
                                    cursor={'pointer'}
                                  />
                                ) : (
                                  <FaFilter
                                    fontSize="12px"
                                    onClick={() => handleFilterIconClick(header.id, setColumnsFilters, inputRefs)}
                                    cursor={'pointer'}
                                  />
                                )}
                              </InputRightElement>
                            </InputGroup>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody position={'relative'}>
              {loading ? (
                <Tr>
                  <Td border={'none'} colSpan={columns.length} py={10}>
                    <CustomLoader sourceName={key} />
                  </Td>
                </Tr>
              ) : error ? (
                <Tr>
                  <Td colSpan={columns.length} py={10}>
                    <Flex direction={'column'} alignItems={'center'}>
                      <MainPanelError errorMessage={errorHandler(error).message} />
                    </Flex>
                  </Td>
                </Tr>
              ) : !results?.length ? (
                <Tr>
                  <Td colSpan={columns.length} py={10}>
                    <Flex direction={'column'} alignItems={'center'}>
                      <NoResultsFound sourceName={key.toLowerCase()} />
                    </Flex>
                  </Td>
                </Tr>
              ) :
                <>
                  {(sortHighlighted || filterHighlighted) && <Tr><Td><CustomOverlay /></Td></Tr>}
                  {tableInstance.getRowModel().rows.map(row => (
                    <Tr
                      key={"table-row" + row.id}
                      id='core_table-row'
                      zIndex={rowHighlighted ? 1350 : 1}
                      boxShadow={'none'}
                      bg={rowHighlighted ? 'background' : 'transparent'}
                      py={1}
                      transition="background 0.3s ease"
                      _hover={{ cursor: "pointer", bg: "gray.100" }}
                      onClick={() => handleRowClick(row.original, source, navigate)}
                    >
                      {row.getVisibleCells().map(cell => {
                        const column = columns.find(col => col.accessor === cell.column.id);
                        if (column?.displayFormat === "IMAGE") {
                          return (
                            <Td borderColor={"gray.100"} w={"20%"} key={"table-cell" + cell.id} >
                              <Image
                                src={getImageSrc(row.original, key)}
                                boxSize="100%"
                                maxHeight={"120px"}
                                maxWidth={"120px"}
                                objectFit={"cover"}
                                fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
                                alt={cell.column.id}
                                boxShadow={"md"}
                                borderRadius={"6px"}
                                mx={"auto"}
                              />
                            </Td>
                          );
                        } else if (column?.displayFormat === "TAGS" ||
                          column?.displayFormat === "LIST") {
                          const isTag = column?.displayFormat === "TAGS";
                          const tags = returnValueAsAnArray(getValueByAccessor(row.original, cell.column.id)) || [];
                          return (
                            <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"25%"} p={1.5} isTruncated >
                              <Flex wrap={"wrap"} gap={1}>
                                {tags.slice(0, 5).map((tag: any, index) => {
                                  const displayedText = cell.column.id === 'authors' ? `${tag.first_name} ${tag.last_name}` : tag
                                  return (
                                    <Tooltip
                                      key={"tag-" + index}
                                      label={displayedText}
                                      bg={"gray.900"}
                                      color={"gray.100"}
                                      hasArrow
                                      py={2}
                                      px={3}
                                      m={2}
                                      maxW={["300px", "360px", "440px"]}
                                      borderRadius={"6px"}
                                      fontSize={"12px"}
                                      boxShadow={"none"}
                                      arrowSize={8}
                                    >
                                      <Tag color={"gray.700"}
                                        bg={
                                          isTag
                                            ? indicationToColor(tag)
                                            : "gray.200"
                                        } fontSize={["10px", "12px", "12px"]}>
                                        {displayedText.length < 21 ? displayedText : `${displayedText.slice(0, 20)}..`}
                                      </Tag>
                                    </Tooltip>
                                  )
                                })}
                              </Flex>
                              {tags.length >= 6 &&
                                <Tag fontStyle={'italic'} bg={'transparent'} color={"gray.500"} fontSize={["10px", "12px", "12px"]}>{tags.length - 5} more {cell.column.id}..</Tag>
                              }
                            </Td>
                          );
                        } else if (column?.displayFormat === "BOOL") {
                          const value = getValueByAccessor(row.original, cell.column.id);
                          const NotDefined = value === null || value === undefined;
                          const bgColor = NotDefined ? 'gray.300' : value === false ? 'red.200' : 'green.200';
                          const color = NotDefined ? 'gray.700' : value === false ? 'red.500' : 'green.600';
                          const displayText = cell.column.id === 'is_open_access'
                            ? NotDefined ? 'Not defined' : value === false ? 'Access restricted' : 'Open access'
                            : NotDefined ? 'Not defined' : value === false ? 'False' : 'True';
                          return (
                            <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"25%"} p={1.5} isTruncated>
                              <Tag fontSize={["10px", "12px", "12px"]} bg={bgColor} color={color}>
                                {displayText}
                              </Tag>
                            </Td>
                          );
                        } else {
                          return (
                            <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"350px"} px={1.5} isTruncated>
                              <Tooltip
                                label={getValueByAccessor(row.original, cell.column.id)}
                                bg={"gray.900"}
                                color={"gray.100"}
                                hasArrow
                                py={2}
                                px={3}
                                m={2}
                                maxW={["200px", "220px", "240px"]}
                                borderRadius={"6px"}
                                fontSize={"12px"}
                                boxShadow={"none"}
                                arrowSize={8}
                              >
                                <Text
                                  textTransform={"capitalize"}
                                  whiteSpace={"nowrap"}
                                  overflow={"hidden"}
                                  textOverflow={"ellipsis"}
                                  fontSize={["12px", "14px", "14px"]}
                                  fontWeight={'400'}
                                >
                                  {getValueByAccessor(row.original, cell.column.id)}
                                </Text>
                              </Tooltip>
                            </Td>
                          );
                        }
                      })}
                    </Tr>
                  )
                  )}
                  {isFetching && (
                    <Tr>
                      <Td colSpan={columns.length}>
                        <Loading />
                      </Td>
                    </Tr>
                  )}
                </>
              }
            </Tbody>
          </Table>
          
        </CustomScrollBar>
      </TableContainer>
    </>
  );
};

export default AgnosticDataDisplay;