import { Icon, Link, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link as RouterLink } from "react-router-dom";

interface LinkToViewProps {
  name: string;
  icon: IconType;
  link: string;
}

function LinkToView({ name, icon, link }: LinkToViewProps) {
  return (
    <Link
      as={RouterLink}
      to={link}
      display={"flex"}
      alignItems={"center"}
      gap={2}
      color={"gray.500"}
      borderRadius={"20px"}
      borderColor={"gray.300"}
      borderWidth={1}
      py={1.5}
      px={3}
      transition={"all .2s ease"}
      _hover={{
        textDecoration: "none",
        bg: "lightBackground",
        color: "blue.400",
        borderColor: "blue.400",
        transition: "all .2s ease",
      }}
    >
      <Icon as={icon} boxSize={4} />
      <Text fontSize={"14px"}>{name}</Text>
    </Link>
  );
}

export default LinkToView;
