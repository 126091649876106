import { Flex } from "@chakra-ui/react";
import Header from "./Header";
import Steps from "./Steps";

export default function Content() {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const bg = colorMode === "light" ? "secondary.700" : "secondary.300";

  return (
    <Flex direction={"column"} px={"auto"} py={"220px"} bg={bg}>
      <Header />
      <Steps />
    </Flex>
  );
}
