import { Box, Flex, Icon } from "@chakra-ui/react";
import { MdOutlineMarkEmailRead } from "react-icons/md";

interface SuccessMessageProps {
  type?: string;
}

function SuccessMessage({ type }: SuccessMessageProps) {
  return (
    <Flex
      gap={4}
      py={4}
      px={6}
      color="white"
      bg={"highlight.primary"}
      borderRadius={"6px"}
      fontWeight={"500"}
      fontSize={[null, null, null, "14px", "15px", "16px"]}
    >
      <Icon as={MdOutlineMarkEmailRead} boxSize={"30px"} />
      <Box>
        We've received your{" "}
        {type === "subscription" ? "subscription request" : "message"}.
        <br />
        We'll be in touch shortly.
      </Box>
    </Flex>
  );
}

export default SuccessMessage;
