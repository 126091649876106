import { TourGuideSteps } from "models/guide";

export const tourGuideSteps: TourGuideSteps = {
  WELCOMING: [
    {
      id: "data",
      title: "Data library",
      content: "Discover all the data sources we provide and manage your own.",
      icon: "BsDatabaseFill",
    },
    {
      id: "assistant",
      title: "Assistant",
      content: "Your scientific research assistant",
      icon: "BsChatRightQuoteFill",
    },
    {
      id: "user_menu",
      title: "User Menu",
      content:
        "Here you can access your account settings, API documentation of our application and others.",
    },
    {
      id: "feedback",
      title: "Feedback submission",
      content:
        "We value your input! Please share your feedback, suggestions, or any ideas to help us improve.",
      icon: "BiSolidMessageAltDetail",
      guideTourPlacement: "LEFT",
    },
    {
      id: "help_tour",
      title: "Page Instructions",
      content:
        "Click the help button to explore and learn about different sections on select pages.",
      guideTourPlacement: "LEFT",
      icon: "MdHelp",
    },
  ],
  PAGE_INSTRUCTIONS: {
    core_table: [
      {
        id: "core_table-search",
        title: "Table Search",
        content:
          "Use the search input to find specific data within the entire table.",
        icon: "IoSearchSharp",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "table_table-search",
        title: "Table Search",
        content:
          "Use the search input to find specific data within the entire table.",
        icon: "IoSearchSharp",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "core_table-column-filter",
        title: "Column Filter",
        content:
          "Filter the table data by typing criteria in the column filter input and pressing enter.",
        icon: "FaFilter",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "proprietary_table-column-filter",
        title: "Column Filter",
        content:
          "Filter the table data by typing criteria in the column filter input and pressing enter.",
        icon: "FaFilter",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "core_table-column-sort",
        title: "Column Sort",
        content:
          "Click on the column header to sort the data in ascending or descending order.",
        icon: "TbSortAscendingLetters",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "proprietary_table-column-sort",
        title: "Column Sort",
        content:
          "Click on the column header to sort the data in ascending or descending order.",
        icon: "TbSortAscendingLetters",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "core_table-row",
        title: "Row Detail",
        content: "By clicking on row, more details will be revealed.",
        icon: "PiMouseFill",
        guideTourPlacement: "TOP",
      },

      {
        id: "proprietary_table-row",
        title: "Row Detail",
        content: "By clicking on row, more details will be revealed.",
        icon: "PiMouseFill",
        guideTourPlacement: "TOP",
      },
    ],
    proprietary_table: [
      {
        id: "table_table-search",
        title: "Table Search",
        content:
          "Use the search input to find specific data within the entire table.",
        icon: "IoSearchSharp",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "proprietary_table-column-filter",
        title: "Column Filter",
        content:
          "Filter the table data by typing criteria in the column filter input and pressing enter.",
        icon: "FaFilter",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "proprietary_table-column-sort",
        title: "Column Sort",
        content:
          "Click on the column header to sort the data in ascending or descending order.",
        icon: "TbSortAscendingLetters",
        guideTourPlacement: "RIGHT",
      },
      {
        id: "proprietary_table-row",
        title: "Row Detail",
        content: "By clicking on row, more details will be revealed.",
        icon: "PiMouseFill",
        guideTourPlacement: "TOP",
      },
    ],
    chat_conversation: [
      {
        id: "copy",
        title: "Copy Bot Answer",
        content: "Copy the generated answer to clipboard",
        icon: "HiClipboard",
        guideTourPlacement: "LEFT",
      },
      {
        id: "positive_feedback",
        title: "Like",
        content: "Leave a thumb up if you are satisfied with the content",
        icon: "TbThumbUpFilled",
        guideTourPlacement: "LEFT",
      },
      {
        id: "negative_feedback",
        title: "Dislike and feedback",
        content:
          "Express your disapproval or dissatisfaction with the content by providing a feedback.",
        icon: "TbThumbDownFilled",
        guideTourPlacement: "LEFT",
      },
      {
        id: "chat_sources",
        title: "Sources",
        content:
          "View and explore various sources related to the content (only available if the answer has related sources).",
      },
      {
        id: "chat_molecules",
        title: "Molecules",
        content:
          "Discover different molecules associated with the topic (only available if the answer has related molecules).",
      },
      {
        id: "chat_sources_list",
        title: "List",
        content: "Switch to list view for a detailed display of information.",
        icon: "PiListFill",
      },
      {
        id: "chat_sources_network",
        title: "Network",
        content: "Visualize the network of related entities.",
        icon: "BiSolidNetworkChart",
      },
    ],
  },
};
