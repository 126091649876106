import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";
import { LuHome } from "react-icons/lu";

interface SuccessSendMessageProps {
  text: string;
  isWelcome?: boolean;
  onClose?: () => void;
}

export default function SuccessSendMessage({
  text,
  isWelcome,
  onClose,
}: SuccessSendMessageProps) {
  return (
    <Flex
      direction={"column"}
      gap={6}
      justify={"center"}
      align={"center"}
      w={"full"}
      h={"100vh"}
      maxW={"80%"}
      mx={"auto"}
      alignSelf={"center"}
      justifySelf={"center"}
      bg={"transparent"}
    >
      <Flex direction={"column"} gap={2} justify={"center"} align={"center"}>
        <Icon as={BiMailSend} boxSize={10} color={"highlight.primary"} />
        <Text color={"gray.700"} fontWeight={"500"}>
          Thank you!
        </Text>
      </Flex>

      <Text fontSize={"14px"} color={"gray.500"} textAlign={"center"}>
        {text}
      </Text>

      {isWelcome && (
        <Button
          bg={"gray.700"}
          color={"gray.50"}
          border={"none"}
          _hover={{ opacity: 0.9 }}
          borderRadius={"24px"}
          fontSize={"14px"}
          type="submit"
          w={"fit-content"}
          px={"60px"}
          mt={"10px"}
          py={2}
          leftIcon={<LuHome />}
          onClick={onClose}
        >
          Go to Homepage
        </Button>
      )}
    </Flex>
  );
}
