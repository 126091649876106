import { chakra } from "@chakra-ui/react";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

export const CustomScrollBarLighterBg = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.300",
    },
    // remove black border on focus and element is visibly indicated to the user
    // apply style for main scrollbar element & his children
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
      {
        border: "none",
        outline: "none",
      },
  },
});

export const CustomScrollBar = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
      {
        border: "none",
        outline: "none",
      },
  },
});

export const CustomThickScrollBar = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-track": {
      width: "8px",
    },
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
      {
        border: "none",
        outline: "none",
      },
  },
});
