import { useContext } from "react";
import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import Sources from "./Sources";
import Molecules from "./Molecules";
import { BotMessageContext } from "./BotMessage";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

interface SourceProp {
  title: string;
  id: string;
}

interface ReferencesProps {
  sources: SourceProp[];
  compounds: string[] | undefined;
}

function References({ sources, compounds }: ReferencesProps) {
  const hasSources = sources && sources?.length > 0;
  const hasCompounds = compounds && compounds?.length > 0;
  const { openAccordionIndex, resetOpenAccordionIndex } =
    useContext(BotMessageContext);

  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const { isHighlighted } = useGuideTour()
  let sourcesTabHighlighted = isHighlighted('chat_sources')
  let moleculesTabHighlighted = isHighlighted('chat_molecules')
  const applyIsGuideOpenStyles = isGuideOpen && currentMode === 'PAGE_INSTRUCTIONS'

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      defaultIndex={0}
      index={openAccordionIndex !== null ? 0 : undefined}
    >
      <TabList
        bg={'transparent'}
        color={"gray.500"}
        borderBottomWidth={1}
        borderColor={applyIsGuideOpenStyles ? "transparent" : "gray.200"}
      >
        {hasSources && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={sourcesTabHighlighted ? "background" : "inherit"}
            _selected={{ color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary" }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={'5px'}
          >
            <Box id="chat_sources" px={3}>SOURCES</Box>
          </Tab>
        )}
        {hasCompounds && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={moleculesTabHighlighted ? "background" : "inherit"}
            _selected={{ color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary" }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={'5px'}
          >
            <Box id="chat_molecules" px={3}>MOLECULES</Box>
          </Tab>
        )}
      </TabList>
      <TabIndicator
        mt={"-2px"}
        h="2px"
        bg={applyIsGuideOpenStyles ? "inherit" : "highlight.primary"}
        borderRadius="1px"
      />
      <TabPanels>
        {hasSources && (
          <TabPanel p={1}>
            <Sources sources={sources} />
          </TabPanel>
        )}
        {hasCompounds && (
          <TabPanel p={1}>
            <Molecules compounds={compounds} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default References;
