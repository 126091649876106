import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdOutlineHideSource } from "react-icons/md";

function ChatHistoryError() {
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      direction={"column"}
      gap={3}
      justify={"center"}
      align={"center"}
      color={"orange.500"}
    >
      <Icon as={MdOutlineHideSource} boxSize={8} />
      <Text fontSize={"12px"} fontWeight={"500"} textAlign={"center"}>
        Something went wrong.
        <br />
        Try again
      </Text>
    </Flex>
  );
}

export default ChatHistoryError;
