import {
  Box,
  Text,
  SimpleGrid,
  Card,
  Heading,
  CardBody,
  Icon,
  Circle,
  Flex,
  useTheme,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { coreSources } from "../helpers";

export default function SourcesList() {
  // Theme
  const { colors } = useTheme();
  const navigate = useNavigate();

  function openSource(path: string) {
    navigate(`/data/core/${path.toLowerCase()}`);
  }

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgCard = isLight
    ? "background"
    : `lightBackground`;

  return (
    <SimpleGrid columns={2} spacing={6} p={"1px"}>
      {coreSources.map((source, index) => (
        <Box key={"core-source-" + index}>
          <Card
            bg={bgCard}
            h={"120px"}
            _hover={{
              bg: hexToRgba(colors.secondary[100], 0.15),
              cursor: "pointer",
            }}
            onClick={() => openSource(source.path)}
          >
            <CardBody>
              <Flex gap={6} align={"center"}>
                <Circle
                  size="50px"
                  bg={source.color}
                  color="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={source.icon} />
                </Circle>
                <Box>
                  <Heading size="sm" textTransform={"capitalize"}>
                    {source.key}
                  </Heading>
                  <Text
                    fontSize={"sm"}
                    maxH="3em"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {source.description}
                  </Text>
                  {source.source && (
                    <Text fontSize={"xs"} color={"neutral.800"} mt={2}>
                      By {source.source}
                    </Text>
                  )}
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </Box>
      ))}
    </SimpleGrid>
  );
}
