import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import LoginBg from "assets/man-walking.jpg";

export default function AuthLayout() {
  const { colors } = useTheme();
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from || null;
  const { user } = useSelector(selectCurrentAuthData);

  useEffect(() => {
    let redirectTo="/chat";
    if (from?.pathname?.length>0 && from?.search?.length == 0){
      redirectTo=from.pathname;
    }
    else if (from?.pathname?.length>0 && from?.search?.length > 0){
      redirectTo=from.pathname+from.search;
    }
        
    if (user) navigate(redirectTo, { replace: true });
  }, [from, navigate, user]);

  return user ? null : (
    <Grid h="100vh" templateColumns="repeat(7, 1fr)" position={"relative"}>
      <GridItem colSpan={2} />
      <GridItem colSpan={5} bg={"primary.700"}>
        <Box
          backgroundImage={LoginBg}
          bgSize={"cover"}
          bgPosition={"top"}
          bgRepeat={"no-repeat"}
          h={"100vh"}
          position={"relative"}
        >
          {/* Color overlay */}
          <Box
            bgGradient={`linear(to-br, ${hexToRgba(
              colors.primary[700],
              0.1
            )}, ${hexToRgba(colors.primary[700], 0.65)}, ${hexToRgba(
              colors.primary[700],
              0.9
            )}, ${hexToRgba(colors.primary[700], 1)})`}
            p={"4"}
            position={"absolute"}
            top={"0"}
            left={"0"}
            right={"0"}
            bottom={"0"}
            color={"whiteAlpha.900"}
          >
            {/* Header Content */}
            <Flex
              h={"100%"}
              w={"100%"}
              direction={"column"}
              justify={"center"}
              ml={"38%"}
            >
              {/* Logo */}
              <Flex mb={6}>
                <Center>
                  <Image
                    src={
                      "https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
                    }
                    h={"80px"}
                    mr={4}
                    opacity={0.6}
                  />
                  <Heading color={"whiteAlpha.900"}>MoleculeLake</Heading>
                </Center>
              </Flex>
              {/* Our mission  */}
              <Stack gap={4} maxW={"450px"}>
                <Text
                  fontSize={[null, null, null, "md", "lg", "xl"]}
                  color={"whiteAlpha.900"}
                >
                  <Highlight
                    query="mission"
                    styles={{
                      px: "2",
                      py: "1",
                      bg: "whiteAlpha.800",
                      opacity: "0.6",
                      color: "primary.800",
                      rounded: "full",
                      fontWeight: "bold",
                    }}
                  >
                    Our mission is to provide world access and acceleration to
                    drug development.
                  </Highlight>
                </Text>
                <Text fontSize="sm" color={"whiteAlpha.800"}>
                  We care about your privacy! We are excited to have you test
                  our platform!
                </Text>
              </Stack>
            </Flex>
          </Box>
        </Box>
      </GridItem>

      {/* Login */}
      <Box
        position="absolute"
        transform={"translateY(-50%) translateX(-50%)"}
        left="calc(2/7 * 100%)"
        top={"50%"}
      >
        <Outlet />
      </Box>
    </Grid>
  );
}
