import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { KeyboardEvent } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

interface PasswordInputProps {
  value: string;
  error: string;
  onChange: (input: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  showPassword: boolean;
  onChangeVisibility: () => void;
}

function PasswordInput({
  value,
  error,
  onChange,
  onKeyDown,
  showPassword,
  onChangeVisibility,
}: PasswordInputProps) {
  return (
    <FormControl w="100%">
      <FormLabel
        htmlFor="password"
        color={"gray.500"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Password
      </FormLabel>

      <InputGroup>
        <Input
          type={showPassword ? "text" : "password"}
          color={"gray.500"}
          fontSize={"14px"}
          name="password"
          id="password"
          placeholder="Your new password"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          width="100%"
        />
        <InputRightElement>
          <Icon
            as={showPassword ? LuEyeOff : LuEye}
            name={"password"}
            color={"secondary.600"}
            cursor={"pointer"}
            onClick={onChangeVisibility}
          />
        </InputRightElement>
      </InputGroup>
      <Text
        mt={0.5}
        minH={4}
        fontSize={"12px"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={"1.3"}
        flexWrap={"wrap"}
        color={"red.600"}
      >
        {error}
      </Text>
    </FormControl>
  );
}

export default PasswordInput;
