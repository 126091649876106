import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Tag,
  TagLabel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableCaption,
  Button,
} from "@chakra-ui/react";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { FaRegUserCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
import { formatCurrency } from "utils/helpers";
import { TbDownload } from "react-icons/tb";
import { useState } from "react";

interface InvoicesProps {
  invoices: any;
}

export default function InvoiceTemplate({ invoices }: InvoicesProps) {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // State
  const [isDownloading, setIsDownloading] = useState(false);

  const getBillDate = (dt: number) => {
    return format(new Date(dt * 1000), "MMM d, yy");
  };

  const downloadInvoice = (invoiceUrl: string, invoiceNo: string) => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = invoiceUrl;
    link.setAttribute("download", `invoice_${invoiceNo}.pdf`);
    document.body.appendChild(link);
    // Trigger the download programmatically
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <Accordion allowMultiple>
      {invoices?.map((invoice: any, index: number) => (
        <AccordionItem key={`${invoice.number}-${index}`}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton _expanded={{ bg: "gray.100" }}>
                  <Box as="span" flex="1" textAlign="left">
                    <Flex align={"center"}>
                      <Flex direction={"row"} gap={1}>
                        <Text>
                          {getBillDate(invoice.lines.data[0].period.start)} -{" "}
                          {getBillDate(invoice.lines.data[0].period.end)}
                        </Text>
                        {index === 0 && (
                          <Tag
                            ml={4}
                            size="md"
                            variant="solid"
                            bg="highlight.primary"
                          >
                            <TagLabel>Active</TagLabel>
                          </Tag>
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex justify="space-between" align={"center"}>
                  <Flex direction={"row"} align="center">
                    <FaRegUserCircle fontSize={"36px"} />
                    <Text ml={2}>
                      {user?.given_name} {user?.family_name}
                    </Text>
                    <Text px={2} color={"gray.400"}>
                      <FaCircle fontSize={"4px"} />
                    </Text>
                    <Text color={"gray.500"}>{user?.email}</Text>
                  </Flex>

                  <Flex align="center" mt={2}>
                    Billed to
                    <br />
                    {invoice.customer_address?.city && (
                      <>{invoice.customer_address.city}</>
                    )}
                    {invoice.customer_address?.city &&
                      invoice.customer_address?.state && <>,</>}
                    {invoice.customer_address?.state && (
                      <>{invoice.customer_address.state},</>
                    )}
                    {invoice.customer_address?.state &&
                      invoice.customer_address?.postal_code && <>,</>}
                    {invoice.customer_address?.postal_code && (
                      <>{invoice.customer_address.postal_code},</>
                    )}
                    {invoice.customer_address?.country &&
                      invoice.customer_address?.postal_code && <>,</>}
                    {invoice.customer_address?.country && (
                      <>{invoice.customer_address.country}</>
                    )}
                  </Flex>
                </Flex>

                <Flex align={"center"} mt={4}>
                  <Text pr={2}>Currency:</Text>
                  <Text color={"gray.500"}>
                    {invoice.currency?.toUpperCase()}
                  </Text>
                </Flex>

                <TableContainer my={6}>
                  <Table size="sm">
                    <TableCaption>
                      <Button
                        size={"xs"}
                        mt={3}
                        py={2}
                        px={4}
                        borderRadius={"22px"}
                        bg={"gray.500"}
                        color={"background"}
                        h={"fit-content"}
                        w={"fit-content"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        isLoading={isDownloading}
                        loadingText={"Downloading..."}
                        rightIcon={<TbDownload size={"16px"} />}
                        letterSpacing={".02rem"}
                        _active={{ bg: "gray.500" }}
                        _hover={{ bg: "gray.500" }}
                        _focus={{ bg: "gray.500" }}
                        _focusWithin={{ bg: "gray.500" }}
                        onClick={() =>
                          downloadInvoice(invoice.invoice_pdf, invoice.number)
                        }
                      >
                        Download PDF
                      </Button>
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Description</Th>
                        <Th>Unit Price</Th>
                        <Th>Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {getBillDate(invoice.lines.data[0].period.start)} -{" "}
                          {getBillDate(invoice.lines.data[0].period.end)}
                        </Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>{invoice.lines.data[0].description}</Td>
                        <Td color={"gray.500"}>
                          {formatCurrency(
                            invoice.lines.data[0].plan.amount / 100
                          )}
                        </Td>
                        <Td color={"gray.500"}>
                          {formatCurrency(invoice.lines.data[0].amount / 100)}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <br />
                          Subtotal: {formatCurrency(
                            invoice.subtotal / 100
                          )}{" "}
                          {invoice.lines.data[0].discount_amounts?.length >
                            0 && <br />}
                          {invoice.lines.data[0].discount_amounts?.length >
                            0 && (
                            <>
                              {`${invoice.discount.coupon.name} (${invoice.discount.coupon.percent_off} off)`}
                              : -
                              {formatCurrency(
                                invoice.lines.data[0].discount_amounts[0]
                                  .amount / 100
                              )}
                            </>
                          )}
                          <br /> Total:{" "}
                          <b>{formatCurrency(invoice.total / 100)}</b>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
}
