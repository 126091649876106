import { Box } from "@chakra-ui/react";

export default function BetaTag() {
  return (
    <Box
      color={"secondary.500"}
      fontSize={{ lg: "8px", xl: "9px" }}
      fontWeight={"bold"}
      letterSpacing={"-.015rem"}
      textTransform={"uppercase"}
      lineHeight={"1"}
      p={0}
    >
      βeta Version
    </Box>
  );
}
