const HOST_URL = window.location.protocol + "//" + window.location.hostname;
const MOLECULELAKE_DATA_URL = window.location.protocol + "//" + 'https://mdps.vitafluence.com'; 
export const environment = {
  // BACKEND_API:
  BACKEND_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : `${HOST_URL}`,
      MOLECULELAKE_DATA_API:
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8080"
        : `${MOLECULELAKE_DATA_URL}`,
 
  STRIPE_PUBLISHABLE_KEY:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "pk_test_51PTJHaP9ouUg1VcitkTtGSPV1R2tix2Ek4QI9AKV6xPK4D7zSU5lVmFmrNRuRmW6N2vy3yFFLVJjy07OuhnUUyn800wdAghs2I"
      : "pk_live_51PYm9qLCHMm6EaV2uTnsWXxwSIPqSwxraPKYRUy4tQ1THs6bruUjfhUfqRjgpgv4MRZMBwd0rZC7LWTKrfz1JbC900eMOcnO1R",
};
 
export const FeedbackParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "feedback_template",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};
 
export const SubscriptionParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "template_hby6us9",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};
 
export const ContactParams = {
  SERVICE_ID: "service_l267a3e",
  TEMPLATE_ID: "contact_template",
  PUBLIC_KEY: "HgGHRjPm2WVGXxgBq",
};
 
export const WelcomeParams = {
  SERVICE_ID: "service_ek8skf4",
  TEMPLATE_ID: "access_request_template",
  PUBLIC_KEY: "nHsKxYi6JPjbkFA1z",
};
