import { Flex, Heading, Text } from "@chakra-ui/react";

function Header() {
  return (
    <Flex
      direction={"column"}
      textAlign={"center"}
      w={"fit-content"}
      maxW={{ base: "95%", sm: "420px", md: "510px" }}
      mx={"auto"}
      mb={"50px"}
    >
      <Flex direction={"column"} gap={{ base: 2, md: 1 }}>
        <Text
          fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px" }}
          fontWeight={{ base: "400", lg: "500" }}
          color={"whiteAlpha.800"}
          letterSpacing={{ base: 1, lg: 3 }}
          lineHeight={1}
        >
          - Pipeline
        </Text>
        <Heading
          fontSize={{ base: "24px", md: "28px", lg: "32px" }}
          lineHeight={1}
          color={"white"}
          fontFamily={"Poppins, sans-serif"}
        >
          Our Process
        </Heading>
      </Flex>

      <Text
        pt={6}
        color={"primary.100"}
        opacity={0.85}
        fontFamily={"Poppins, sans-serif"}
        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
      >
        With our proven data enrichment pipeline, we make it easy to explore the
        full scientific corpus and accelerate drug discovery.
      </Text>
    </Flex>
  );
}

export default Header;
