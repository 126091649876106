import { Button } from "@chakra-ui/react";

interface SendButtonProps {
  sending: boolean;
}

function SendButton({ sending }: SendButtonProps) {
  return (
    <Button
      px={10}
      py={5}
      letterSpacing={".03rem"}
      transition={"0.25s ease all"}
      bg={"highlight.primary"}
      fontSize={{ base: "sm", sm: "sm", lg: "md" }}
      color={"white"}
      _hover={{ opacity: 0.8 }}
      _active={{ opacity: 0.8 }}
      borderRadius={{ base: "8px", md: "12px" }}
      w={{ base: "100%", md: "fit-content" }}
      type="submit"
      isLoading={sending}
      loadingText="sending..."
      _loading={{ color: "white" }}
    >
      Send
    </Button>
  );
}

export default SendButton;
