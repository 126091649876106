import { useMemo, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Flex,
  Tooltip,
  Heading,
  HStack,
  Spacer,
} from "@chakra-ui/react";

import { useQueries, useQuery } from "@tanstack/react-query";
import { useCompoundsAPI } from "api/useCompoundsAPI";
import AssaysTable from "components/compounds/AssaysTable";
import CompoundsTable from "components/compounds/CompoundsTable";
import PapersReferencesChart from "components/compounds/PapersReferencesChart";
import PharmaAccordion from "components/compounds/PharmaAccordion";
import ChemPropsTable from "components/compounds/assets/ChemPropsTable";
import ExternalSourcesList from "components/compounds/assets/ExternalSourcesList";
import PropsCard from "components/compounds/assets/PropsCard";
import SmilesWithTag from "components/compounds/assets/SmilesWithTag";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import { SubtitleSM } from "components/ui/Subtitle";
import { CompoundProps } from "models/compounds/CompoundProps";
import { errorHandler } from "utils/helpers";
import { capitalise } from "views/compounds/helpers";

interface MoleculeItemProps {
  isOpen: boolean;
  onClose: () => void;
  details: CompoundProps | undefined;
}

function MoleculeItem({ isOpen, onClose, details }: MoleculeItemProps) {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const { fetchCompoundsById, fetchPapersReferencedIn } = useCompoundsAPI();

  // * Fetch similar compounds
  const similarCompoundsQueries = useQueries({
    queries: details?.similar_compounds
      ? details.similar_compounds.map((cmp: any) => {
          return {
            queryKey: ["compound", cmp.compound_ikey],
            queryFn: fetchCompoundsById,
            staleTime: 40 * 1000 * 60,
            gcTime: 30 * 1000 * 60,
            retry: false,
          };
        })
      : [],
  });

  const similarCompounds = useMemo(() => {
    return similarCompoundsQueries
      ? similarCompoundsQueries
          .filter((query) => query.data !== undefined)
          .map((query) => query.data as CompoundProps)
      : [];
  }, [similarCompoundsQueries]);

  // * Fetch papers referenced in
  const {
    isLoading: isLoadingPapers,
    error: papersError,
    data: papersData,
  } = useQuery({
    queryKey: [
      "compound",
      "paper",
      encodeURIComponent(details?.name as string),
    ],
    queryFn: fetchPapersReferencedIn,
    staleTime: 40 * 1000 * 60,
    gcTime: 30 * 1000 * 60,
    enabled: !!details,
  });

  const hasPapersError = useMemo(
    () => !isLoadingPapers && !!papersError,
    [isLoadingPapers, papersError]
  );
  const hasPapersData = useMemo(
    () => !isLoadingPapers && !hasPapersError && papersData?.length > 0,
    [hasPapersError, papersData, isLoadingPapers]
  );

  return (
    <>
      {!!details && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          initialFocusRef={modalContentRef}
          finalFocusRef={modalContentRef} // scroll to top when modal is open
        >
          <ModalOverlay backdropFilter="blur(3px)" />
          <ModalContent
            ref={modalContentRef}
            alignSelf={"center"}
            overflowY={"auto"}
            minW={{ base: "90%", lg: "900px" }}
            maxW={{ base: "90%", lg: "900px" }}
            h={"650px"}
            my={"auto"}
            bg={"white"}
          >
            <ModalBody py={4} bg={"gray.100"}>
              <VStack spacing={4} align="stretch">
                {/* Name & Aliases */}
                <Flex direction={"column"} gap={2} w={"100%"}>
                  {/* Header */}
                  <Flex
                    gap={5}
                    align={"center"}
                    justify={"space-between"}
                    w={"100%"}
                  >
                    {/* compound name */}
                    <Tooltip
                      label={capitalise(details?.name)}
                      bg={"gray.900"}
                      color={"gray.100"}
                      hasArrow
                      py={2}
                      px={3}
                      m={2}
                      maxW={[null, null, null, "400px", "500px", "700px"]}
                      borderRadius={"6px"}
                      fontSize={"14px"}
                      boxShadow={"none"}
                      arrowSize={8}
                    >
                      <Heading
                        size={[null, null, null, "md", "lg", "lg"]}
                        color={"secondary.700"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        w={"100%"}
                        maxW={"700px"}
                      >
                        {capitalise(details?.name)}
                      </Heading>
                    </Tooltip>
                  </Flex>

                  {/* Aliases */}
                  {details?.aliases && details?.aliases?.length > 0 && (
                    <HStack spacing={2} mb={4} align={"flex-start"}>
                      <Text color={"gray.500"} fontSize={"14px"}>
                        <Text as={"span"} fontWeight={"500"}>
                          {details?.aliases.length < 2 ? "Alias:" : "Aliases:"}
                        </Text>{" "}
                        {details?.aliases?.join(" · ")}
                      </Text>
                    </HStack>
                  )}
                </Flex>

                {/* Chemical Properties */}
                <PropsCard>
                  {/* Title */}
                  <Flex align={"flex-end"}>
                    <SubtitleSM>Chemical Properties</SubtitleSM>
                  </Flex>

                  <Flex direction="row" gap={5} mb={5} align={"center"}>
                    {/* SMILES image */}
                    <SmilesWithTag
                      canonic_url={
                        details?.chemical_props?.isomeric_smiles_structure
                      }
                      isometric_url={
                        details?.chemical_props?.canonical_smiles_structure
                      }
                    />

                    {/* General Info */}
                    <ChemPropsTable
                      chemProperties={details?.chemical_props}
                      origin={details?.citeline_data?.origin_compound?.join(
                        ", "
                      )}
                    />
                  </Flex>
                </PropsCard>

                {/* Pharmacological Properties */}
                <PropsCard>
                  <Flex align={"baseline"}>
                    {/* Title */}
                    <Flex align={"flex-end"} mb={4} gap={1}>
                      <SubtitleSM>Pharmacological Properties</SubtitleSM>
                    </Flex>
                    <Spacer />
                  </Flex>

                  {/* Pharmacological Properties */}
                  <PharmaAccordion compound={details} inChat />
                </PropsCard>

                {/* Similar compounds */}
                {!!similarCompounds.length && (
                  <PropsCard>
                    <Flex align={"flex-end"} mb={4} gap={1}>
                      <SubtitleSM>Similar Compounds</SubtitleSM>
                    </Flex>

                    <CompoundsTable
                      items={similarCompounds}
                      similarityScores={details?.similar_compounds?.map(
                        (similar: any) => similar.similarity_score
                      )}
                    />
                  </PropsCard>
                )}

                {/* Assays */}
                {/* Assay Hits */}
                {details?.assays_hits && details?.assays_hits?.length > 0 && (
                  <PropsCard>
                    {/* Title */}
                    <Flex align={"flex-end"} mb={4} gap={1}>
                      <SubtitleSM>Activity Data</SubtitleSM>
                    </Flex>

                    {/* Assay Hits */}
                    <AssaysTable assays={details?.assays_hits} isHit={true} />
                  </PropsCard>
                )}

                {/* Screening collection */}
                <PropsCard>
                  {/* Title */}
                  <Flex align={"flex-end"} mb={4} gap={1}>
                    <SubtitleSM>Primary Screening Data</SubtitleSM>
                  </Flex>

                  {/* Assay Hits */}
                  <AssaysTable assays={details?.screening_collection} />
                </PropsCard>

                {/* External Resources */}
                <PropsCard>
                  {/* Title */}
                  <Flex align={"flex-end"} mb={4} gap={1}>
                    <SubtitleSM>External Sources</SubtitleSM>
                  </Flex>

                  {/* External Resources List */}
                  <ExternalSourcesList
                    resources={details?.external_resources}
                  />
                </PropsCard>

                {/* References */}
                <PropsCard>
                  {/* Title */}
                  <Flex align={"flex-end"} mb={4} gap={1}>
                    <SubtitleSM>References</SubtitleSM>
                  </Flex>

                  {isLoadingPapers ? (
                    <Loading message="Loading papers data.." />
                  ) : (
                    <>
                      {/* has errors */}
                      {hasPapersError && (
                        <MainPanelError
                          errorMessage={
                            papersError && errorHandler(papersError)?.message
                          }
                        />
                      )}

                      {/* has valid data */}
                      {!hasPapersError && hasPapersData && (
                        <PapersReferencesChart papersData={papersData} />
                      )}

                      {/* has no data */}
                      {!hasPapersError && !hasPapersData && (
                        <Text fontSize={"14px"}>
                          No research papers related to this compound.
                        </Text>
                      )}
                    </>
                  )}
                </PropsCard>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default MoleculeItem;
