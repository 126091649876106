import { Text } from "@chakra-ui/react";

function NoDataAvailable() {
  return (
    <Text
      w={"fit-content"}
      color={"gray.600"}
      fontWeight={"500"}
      fontSize={"14px"}
      textAlign={"center"}
      py={4}
      px={8}
      mt={4}
      mx={"auto"}
      borderColor={"gray.200"}
      borderWidth={1}
      borderRadius={"16px"}
    >
      No data available
    </Text>
  );
}

export default NoDataAvailable;
