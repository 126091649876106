import { Flex, Text } from "@chakra-ui/react";
import { BaseButtonProps } from "./sources/SourcesTabPanel";

interface ReadMoreButtonProps extends BaseButtonProps {}

function ReadMoreButton({ onClick, label }: ReadMoreButtonProps) {
  return (
    <Flex
      color={"secondary.600"}
      align={"center"}
      justify={"center"}
      cursor={"pointer"}
      mt={2}
      mx={"auto"}
      w={"fit-content"}
      transition={"all .3s ease"}
      onClick={onClick}
      _hover={{ color: "secondary.400", transition: "all .3s ease" }}
    >
      <Text fontSize={"14px"} fontWeight={"500"} lineHeight={"1.2"}>
        {label}
      </Text>
    </Flex>
  );
}

export default ReadMoreButton;
