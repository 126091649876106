import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  SimpleGrid,
  useToast,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import PlanOffers from "./PlanOffers";
import { getSubscriptionPlans } from "services/plans.services";
import MainPanelError from "components/ui/MainPanelError";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SwitchBillPlanProps {
  isAnnual: boolean;
  switchPlan: () => void;
}

export default function PricingModal({ isOpen, onClose }: PricingModalProps) {
  const { user } = useSelector(selectCurrentAuthData);
  const isYearly = (user && user.user_wallet.is_yearly === true) ? true : false;

  // Hooks
  const toast = useToast();

  // States
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnnual, setIsAnnual] = useState(isYearly);

  // Handlers
  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  useEffect(() => {
    const getPlans = async () => {
      setIsLoading(true);
      await getSubscriptionPlans()
        .then((res) => {
          setPlans(res);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };

    getPlans();
  }, [toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ lg: "95%", xl: "1100px" }}
        maxW={{ lg: "95%", xl: "1100px" }}
        h={"100%"}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
        >
          <Text fontSize={"16px"} fontWeight={"500"}>
            Change Your Plan
          </Text>
          <SwitchBillPlan isAnnual={isAnnual} switchPlan={handleToggle} />
        </ModalHeader>

        <ModalBody
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={6}
        >
          {isLoading ? (
            <Loading message="Loading plans..." />
          ) : (
            <>
              {plans.length > 0 ? (
                <SimpleGrid columns={3} spacingX={{ lg: 4, xl: 3 }}>
                  {plans?.map((plans: any) => (
                    <PlanOffers
                      isAnnual={isAnnual}
                      key={plans.plan_name}
                      data={plans}
                      isLoading={isLoading}
                    />
                  ))}
                </SimpleGrid>
              ) : (
                <Flex h={"100%"} w={"100%"}>
                  <MainPanelError errorMessage={errorHandler(null).message} />
                </Flex>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function SwitchBillPlan({ isAnnual, switchPlan }: SwitchBillPlanProps) {
  return (
    <Flex align="center" gap={2}>
      <Text
        fontWeight={"500"}
        fontSize={{ lg: "12px", xl: "14px" }}
        color={!isAnnual ? "secondary.500" : "gary.400"}
      >
        Bill Monthly
      </Text>

      <Switch
        id="billing-switch"
        isChecked={isAnnual}
        onChange={switchPlan}
        size={"sm"}
      />
      <Text
        fontWeight={"500"}
        fontSize={{ lg: "12px", xl: "14px" }}
        color={isAnnual ? "secondary.500" : "gary.400"}
      >
        Bill Annually
      </Text>
    </Flex>
  );
}
