import { useState } from "react";
import { Box, Divider, Flex, Spacer, useMediaQuery } from "@chakra-ui/react";

import PrivacyPolicyModal from "components/home/PrivacyPolicyModal";
import VitafluenceContent from "components/home/Footer/VitafluenceContent";
import ContactUs from "components/home/Footer/ContactUs";
import AboutUs from "components/home/Footer/AboutUs";
import CopyrightAndSocialMedia from "components/home/Footer/CopyrightAndSocialMedia";
import ResponsivenessInfo from "components/home/ResponsivenessInfo";
import DisclaimersModal from "components/home/DisclaimersModal";

interface FooterProps {
  id: string;
}

export default function Footer({ id }: FooterProps) {
  // State
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showDisclaimersModal, setShowDisclaimersModal] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Box id={id}>
      {/* privacy policy modal content */}
      <PrivacyPolicyModal
        isOpen={showPrivacy}
        onClose={() => setShowPrivacy(false)}
      />

      <DisclaimersModal
        isOpen={showDisclaimersModal}
        onClose={() => setShowDisclaimersModal(false)}
      />

      {/* Responsiveness Info modal content */}
      <ResponsivenessInfo
        isOpen={showInfo && isMobileView}
        onClose={() => setShowInfo(false)}
      />

      {/* Footer content */}
      <Flex
        direction={"column"}
        gap={8}
        mx={"auto"}
        p={{ base: 6, md: 0 }}
        w={{ base: "95%", "2xl": "100%" }}
        maxW={{ base: "100%", lg: "1100px", xl: "1360px", "2xl": "1360px" }}
        my={["40px", "40px", "60px", "60px", "100px", "100px"]}
      >
        <Flex
          gap={[6, 6, 2, 4, 8, 10]}
          direction={{ base: "column", md: "row" }}
        >
          <VitafluenceContent />
          <Spacer display={{ base: "none", md: "inline-block" }} />
          <Flex
            gap={[2, 2, 1, 4, 8, 10]}
            align={{ base: "center", md: "flex-start" }}
            justify={{ base: "space-between", md: "flex-start" }}
            flexWrap={"wrap"}
          >
            <ContactUs />
            <AboutUs
              setShowPrivacy={setShowPrivacy}
              setShowDisclaimers={setShowDisclaimersModal}
              setShowInfo={setShowInfo}
            />
          </Flex>
        </Flex>

        <Divider bg={"black"} opacity={0.1} />
        <CopyrightAndSocialMedia />
      </Flex>
    </Box>
  );
}
