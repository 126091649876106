import { axiosPrivate } from 'api/axios';
import { environment } from "../environments";

export const getSubscriptionPlans = async () => {
  const response = await axiosPrivate.get(
    `${environment.BACKEND_API}/api/get_subscription_plans`
  );
  return response.data;
};

