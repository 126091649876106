import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { KeyboardEvent } from "react";

interface EmailInputProps {
  value: string;
  onChange: (input: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  error: string;
}

function EmailInput({ value, onChange, error, onKeyDown }: EmailInputProps) {
  return (
    <FormControl id="email" w="100%">
      <FormLabel
        htmlFor="email"
        color={"gray.500"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Email
      </FormLabel>

      <Input
        color={"gray.500"}
        fontSize={"14px"}
        type="email"
        name="email"
        placeholder="Your contact email"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        width="100%"
        disabled // NOTE: needs incognito/backend assistance to be enabled
      />
      <Text
        mt={0.5}
        minH={4}
        fontSize={"12px"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={"1.3"}
        flexWrap={"wrap"}
        color={"red.600"}
      >
        {error}
      </Text>
    </FormControl>
  );
}

export default EmailInput;
