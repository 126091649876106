import { ReactElement } from "react";
import { IconButton, PlacementWithLogical, Tooltip } from "@chakra-ui/react";

interface ActionButtonProps {
  label: string;
  icon: ReactElement;
  isDisabled?: boolean;
  inResearch?: boolean;
  placement?: PlacementWithLogical;
  onClick?: () => void;
}

export default function ActionButton({
  label,
  icon,
  onClick,
  isDisabled,
  placement,
  inResearch,
}: ActionButtonProps) {
  return (
    <Tooltip
      label={label}
      placement={placement ?? "bottom"}
      bg={"gray.900"}
      color={"gray.100"}
      hasArrow
      py={2}
      px={3}
      m={2}
      maxW={[null, null, null, "200px", "220px", "240px"]}
      borderRadius={"6px"}
      fontSize={"12px"}
      boxShadow={"none"}
      arrowSize={8}
    >
      <IconButton
        icon={icon}
        aria-label={label}
        bg={"transparent"}
        fontSize={inResearch ? "sm" : [null, null, null, "sm", "sm", "md"]}
        color={"gray.500"}
        borderRadius={"50%"}
        boxShadow={inResearch ? "none" : "md"}
        isDisabled={isDisabled}
        _hover={{ bg: "gray.100", boxShadow: "lg" }}
        _active={{ bg: "gray.100" }}
        onClick={onClick}
      />
    </Tooltip>
  );
}
