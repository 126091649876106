import { axiosPrivate } from "api/axios";
import { environment } from "../environments";

interface TeamMessageProps {
  team_id : string;
  user_id : string;
  message : string;  
}

// ----------------- Team APIs Section -----------------
export const getTeam = async () => {
  const response = await axiosPrivate.get(
    `${environment.BACKEND_API}/api/teams`
  );
  return response.data;
};

export const createTeam = async (payload: { name: string }) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/api/teams`,
    payload
  );
  return response.data;
};

export const removeTeamMember = async (operation: string, memberId:string="") => {  
  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/teams?operation=${operation}&memberId=${memberId}`
  );
  return response.data;
};

// ----------------- Team Invites APIs Section -----------------
export const inviteMember = async (payload: { email: string }) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/api/team_invites`,
    payload
  );
  return response.data;
};

export const acceptTeamInvite = async (payload: { email: string, invite_token: string }) => {
  const response = await axiosPrivate.put(
    `${environment.BACKEND_API}/api/team_invites`,
    payload
  );
  return response.data;
};

export const deleteTeamInvite = async (email: string) => {
  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/team_invites?email=${email}`    
  );
  return response.data;
};

// ----------------- Team Messages APIs Section -----------------
export const fetchTeamMessages = async (teamId:string | undefined, pageNumber:number) => {
  const response = await axiosPrivate.get(`${environment.BACKEND_API}/api/team_messages?teamId=${teamId}&pageNumber=${pageNumber}`);
  return response.data;
};