import { Flex, SkeletonText } from "@chakra-ui/react";

export default function SessionsSkeleton() {
  return (
    <Flex direction={"column"} px={2} gap={"30px"}>
      <Flex direction={"column"} gap={2}>
        <SkeletonItem width={"25%"} height={2} date />
        {Array.from({ length: 3 }, (_, index) => (
          <SkeletonItem key={index} />
        ))}
      </Flex>

      <Flex direction={"column"} gap={2}>
        <SkeletonItem width={"35%"} height={2} date />
        {Array.from({ length: 2 }, (_, index) => (
          <SkeletonItem key={index} />
        ))}
      </Flex>

      <Flex direction={"column"} gap={2}>
        <SkeletonItem width={"50%"} height={2} date />
        {Array.from({ length: 5 }, (_, index) => (
          <SkeletonItem key={index} />
        ))}
      </Flex>
    </Flex>
  );
}

interface SkeletonItemProps {
  width?: string;
  height?: number;
  date?: boolean;
}

function SkeletonItem({ width, height, date }: SkeletonItemProps) {
  return (
    <SkeletonText
      w={width ?? "100%"}
      noOfLines={1}
      skeletonHeight={height ?? 4}
      borderRadius={4}
      startColor={date ? "primary.100" : "gray.100"}
      endColor={date ? "primary.200" : "gray.300"}
    />
  );
}
