
interface IconProps {
    width: string;
    height: string;
    fill: string;
}

export const CustomLock: React.FC<IconProps> = ({ width, height, fill }) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 256 512"
    >
        <path
            fill={fill}
            d="M128.235 64c-56.61 0-102.673 46.06-102.673 102.673v95.804C13.768 281.153 6.93 303.257 6.93 326.932C6.93 393.682 61.248 448 128 448c66.755 0 121.068-54.317 121.068-121.068c0-23.36-6.657-45.191-18.163-63.71v-96.55C230.906 110.06 184.846 64 128.235 64m0 41.117c33.937 0 61.552 27.613 61.552 61.556v56.182c-18.1-10.786-39.23-16.993-61.785-16.993c-22.363 0-43.325 6.103-61.322 16.719v-55.908c0-33.943 27.617-61.556 61.555-61.556M128 246.981c44.084 0 79.947 35.865 79.947 79.951c0 44.082-35.864 79.954-79.947 79.954c-44.084 0-79.953-35.872-79.953-79.954c0-44.086 35.869-79.95 79.953-79.95m.395 46.388c-18.734 0-33.922 15.188-33.922 33.93c0 18.73 15.188 33.917 33.922 33.917c18.736 0 33.922-15.186 33.922-33.917c0-18.742-15.186-33.93-33.922-33.93"
            color="currentColor"
        ></path>
    </svg>)
}

export const CustomUnLock: React.FC<IconProps> = ({ width, height, fill }) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 256 512"
    >
        <path
            fill={fill}
            d="M128.233 64c-56.46 0-102.67 46.212-102.67 102.672v14.023H66.68v-14.023c0-34.239 27.315-61.554 61.553-61.554s61.553 27.315 61.553 61.554v56.252c-18.113-10.823-39.251-17.06-61.786-17.06c-66.62 0-121.068 54.448-121.068 121.068S61.38 448 128 448s121.068-54.447 121.068-121.068c0-23.29-6.661-45.088-18.164-63.596v-96.664c0-56.46-46.21-102.672-102.67-102.672zM128 246.982c44.398 0 79.95 35.552 79.95 79.95c0 44.399-35.552 79.95-79.95 79.95s-79.95-35.551-79.95-79.95c0-44.398 35.552-79.95 79.95-79.95zm.395 46.393c-18.735 0-33.922 15.187-33.922 33.922s15.187 33.922 33.922 33.922c18.734 0 33.922-15.187 33.922-33.922s-15.188-33.922-33.922-33.922z"
            color="currentColor"
        ></path>
    </svg>)
}
