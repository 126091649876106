import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import Loading from "components/ui/Loading";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurrentAuthData, updateUserAttributes } from "redux/features/auth/authSlice";
import { acceptTeamInvite } from "services/teams.services";
import { PostContext } from "..";

export default function AcceptInvite() {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(selectCurrentAuthData);
  const [loaded, setLoaded] = useState(false);
  const { getTeamDetails } = useContext(PostContext);

  const token = new URLSearchParams(useLocation().search).get("token") || ""

  const triggerInviteAcceptance = async () => {
    await acceptTeamInvite({ email: user?.email || "", invite_token: token }).then(response => {
      const attributes = {
        can_access_teams: true,
      };
      dispatch(updateUserAttributes(attributes));
      toast({
        position: "top-right",
        duration: 5000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Invite successfully accepted!
          </Box>
        ),
      });
      getTeamDetails();      
      navigate("/team");      
    }).catch(error => {      
      toast({
        description: error.response.data.message
          ? error.response.data.message
          : "An error occured while verifying your invite",
        status: (error.response.status === 400) ? "error" : "info",
        position: "top-right",
      });
      setTimeout(() => {
        navigate("/chat");
      }, 500);
    })
  }

  useEffect(() => {
    if (loaded === false) {
      setLoaded(true);
      triggerInviteAcceptance();
    }
  }, []);

  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Box textAlign="center">
        <Text fontWeight={"bold"} textAlign={"center"} fontSize={"x-large"} >Invite Verification</Text>
        <Loading pt={5} small={false} />
        <Text pt={5} fontWeight={"600"} color={"gray.600"} fontSize={"14px"}>
          Please wait as we verify your invitation ...
        </Text>
      </Box>
    </Flex>
  );
}