import { Flex, Heading, Text } from "@chakra-ui/react";

function ContactUs() {
  return (
    <Flex direction={"column"} gap={3} mt={{ base: 2, lg: 6 }}>
      <Heading
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        color={"black"}
        opacity={0.85}
      >
        Contact Us
      </Heading>

      <Text
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        color={"black"}
        opacity={0.75}
        w={["180px", "190px", "210px", "85%", "80%", "75%"]}
      >
        Westerbachstrasse 23, VitaFluence.ai GmbH,
        <br />
        Kronberg i. Taunus, Germany
      </Text>
    </Flex>
  );
}

export default ContactUs;
