import { createSlice } from "@reduxjs/toolkit";
import { SavedStateProps } from "models/research/SavedProps";
import { createSelector } from "reselect";

const initialState = {
  loading: false,
  error: "",
  savedFolders: [],
  showNotification: false,
};

const savedSlice = createSlice({
  name: "saved",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = "";
    },
    setFail: (state, action) => {
      state.error = action.payload;
      state.savedFolders = [];
      state.loading = false;
    },
    setSuccess: (state, action) => {
      state.savedFolders = action.payload;
      state.error = "";
      state.loading = false;
    },
    setNotification: (state, action) => {
      state.showNotification = action.payload;
    },
  },
});

export const { setSuccess, setLoading, setFail, setNotification } =
  savedSlice.actions;

export default savedSlice.reducer;

const selectSavedState = (state: { saved: SavedStateProps }) => state.saved;

export const selectCurrentSavedData = createSelector(
  [selectSavedState],
  (saved) => ({
    loading: saved.loading,
    error: saved.error,
    savedFolders: saved.savedFolders,
    showNotification: saved.showNotification,
  })
);
