import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import SmilesWithTag from "components/compounds/assets/SmilesWithTag";
import { CompoundProps } from "models/compounds/CompoundProps";

import ChemPropsTable from "components/compounds/assets/ChemPropsTable";

import CompoundsTable from "components/compounds/CompoundsTable";
import PropsCard from "components/compounds/assets/PropsCard";
import PharmaAccordion from "components/compounds/PharmaAccordion";
import PhaseChart from "components/compounds/assets/PhaseChart";
import AssaysTable from "components/compounds/AssaysTable";
import ExternalSourcesList from "components/compounds/assets/ExternalSourcesList";

import { SubtitleSM } from "components/ui/Subtitle";
import { chemprops_helper } from "data/helpers/chemicalprops";

// Helpers
import HelperModal from "components/compounds/HelperModal";
import { pharmaprops_helper } from "data/helpers/pharmacologicalprops";

import { similarsummaryprops_helper } from "data/helpers/summaryprops";
import { screeningdataprops_helper } from "data/helpers/screeningdata";
import {
  externalsources_helper,
  papers_helper,
} from "data/helpers/externalsources";
import PapersReferencesChart from "components/compounds/PapersReferencesChart";

import { BsBookmarkCheck } from "react-icons/bs";
import MainPanelError from "components/ui/MainPanelError";
import ActionButton from "components/buttons/ActionButton";
import { maxHeight } from "utils/responsive";
import { activitydataprops_helper } from "data/helpers/activitydata";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useCompoundsAPI } from "api/useCompoundsAPI";
import { errorHandler } from "utils/helpers";
import { capitalise } from "views/compounds/helpers";
import { CoreContext } from "views/library/core";
import FoldersModal from "components/research/modal/FoldersModal";
import Ontologies from "components/compounds/Ontologies";
import { moaprops_helper } from "data/helpers/moadata";

export default function MoleculeItem() {
  // Hooks
  const { sourceItemId: id } = useParams();
  const { setSourceItemName } = useContext(CoreContext);

  // States
  const [showSaveModal, setShowSaveModal] = useState(false);

  // APIs
  const { fetchCompoundsById, fetchPapersReferencedIn } = useCompoundsAPI();

  // API
  // * Fetch compound
  const {
    isLoading: compoundIsLoading,
    error: compoundError,
    data: compound,
  } = useQuery({
    queryKey: ["compound", id],
    queryFn: fetchCompoundsById,
    staleTime: 40 * 1000 * 60,
    gcTime: 30 * 1000 * 60,
    retry: 1,
  });

  // * Update recently visited compounds
  useEffect(() => {
    if (compound) {
      const newCachedData = JSON.parse(
        localStorage.getItem("recentlyViewedCompounds") || "[]"
      ).filter((item: CompoundProps) => item?.compound_id !== id);
      newCachedData.unshift({
        compound_id: compound?.compound_id,
        name: compound?.name,
        generic_name: compound?.generic_name,
      });
      const mostRecentItems = newCachedData.slice(0, 10);
      localStorage.setItem(
        "recentlyViewedCompounds",
        JSON.stringify(mostRecentItems)
      );

      setSourceItemName(compound?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compound]);

  // * Fetch similar compounds
  const similarCompoundsQueries = useQueries({
    queries: compound?.similar_compounds
      ? compound.similar_compounds.map((cmp: any) => {
          return {
            queryKey: ["compound", cmp.compound_ikey],
            queryFn: fetchCompoundsById,
            staleTime: 40 * 1000 * 60,
            gcTime: 30 * 1000 * 60,
            retry: false,
          };
        })
      : [],
  });

  const similarCompounds = useMemo(() => {
    return similarCompoundsQueries
      ? similarCompoundsQueries
          .filter((query) => query.data !== undefined)
          .map((query) => query.data as CompoundProps)
      : [];
  }, [similarCompoundsQueries]);

  // * Fetch papers referenced in
  const {
    isLoading: papersReferencedInIsLoading,
    error: referencedInError,
    data: papersReferencedIn,
  } = useQuery({
    queryKey: ["compound", "paper", compound?.name],
    queryFn: fetchPapersReferencedIn,
    staleTime: 40 * 1000 * 60,
    gcTime: 30 * 1000 * 60,
    enabled: !!compound,
    retry: 1,
  });

  // Responsiveness: ~992px, ~1280px, ~1536px
  const CompoundItemHeight = useBreakpointValue(maxHeight);

  // if error
  if (compoundError || referencedInError) {
    let err = compoundError ? compoundError : referencedInError;
    return (
      <Flex h={CompoundItemHeight} w={"100%"}>
        <MainPanelError errorMessage={err && errorHandler(err).message} />
      </Flex>
    );
  }

  // if Loading
  if (compoundIsLoading)
    return (
      <Flex
        direction={"column"}
        h={CompoundItemHeight}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading compound data.." />
      </Flex>
    );

  return (
    <Box>
      {/* if data fetched successfully */}
      {compound ? (
        <VStack spacing={4} align="stretch" marginBottom={100}>
          {/* Name & Aliases */}
          <Flex direction={"column"} gap={2} w={"100%"}>
            {/* Header */}
            <Flex gap={5} align={"center"} justify={"space-between"} w={"100%"}>
              {/* compound name */}
              <Tooltip
                label={capitalise(compound?.name || "")}
                bg={"gray.900"}
                color={"gray.100"}
                hasArrow
                py={2}
                px={3}
                m={2}
                maxW={[null, null, null, "400px", "500px", "700px"]}
                borderRadius={"6px"}
                fontSize={"14px"}
                boxShadow={"none"}
                arrowSize={8}
              >
                <Heading
                  size={[null, null, null, "md", "lg", "lg"]}
                  color={"secondary.700"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  w={"100%"}
                  maxW={"700px"}
                >
                  {capitalise(compound?.name || "")}
                </Heading>
              </Tooltip>

              {/* Save button */}
              <ActionButton
                label={"Save compound"}
                placement={"left"}
                icon={<BsBookmarkCheck />}
                onClick={() => setShowSaveModal(true)}
              />
            </Flex>

            {/* Aliases */}
            {compound?.aliases && compound?.aliases?.length > 0 && (
              <HStack spacing={2} mb={4} align={"flex-start"}>
                <Text color={"gray.500"} fontSize={"14px"}>
                  <Text as={"span"} fontWeight={"500"}>
                    {compound?.aliases.length < 2 ? "Alias:" : "Aliases:"}
                  </Text>{" "}
                  {compound?.aliases?.join(" · ")}
                </Text>
              </HStack>
            )}
          </Flex>

          {/* Chemical Properties */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"}>
              <SubtitleSM>Chemical Properties</SubtitleSM>
              <HelperModal
                title={"Chemical Properties"}
                data={chemprops_helper}
              />
            </Flex>

            <Flex direction="row" gap={5} mb={5} align={"center"}>
              {/* SMILES image */}
              <SmilesWithTag
                canonic_url={
                  compound?.chemical_props?.isomeric_smiles_structure
                }
                isometric_url={
                  compound?.chemical_props?.canonical_smiles_structure
                }
              />

              {/* General Info */}
              <ChemPropsTable
                chemProperties={compound?.chemical_props}
                origin={compound?.citeline_data?.origin_compound?.join(", ")}
              />
            </Flex>

            {/* RDKit calculated  */}
            {/* TODO: add when data is available or when possible to calculate with RDKit */}
            {/* {false && (
              <Grid
                templateColumns="repeat(11, 1fr)"
                as={Card}
                height={20}
                variant="outline"
              >
                <ChemPropTag name="MW" value={5.4} tooltip="Molecular Weight" />
                <ChemPropTag
                  name="HBA"
                  value={2}
                  tooltip="Hydrogen Bond Acceptors"
                />
                <ChemPropTag
                  name="HBD"
                  value={3}
                  tooltip="Hydrogen Bond Donors"
                />
                <ChemPropTag name="RB" value={2} tooltip="Rotable Bonds" />
                <ChemPropTag name="RC" value={2} tooltip="Ring Cound" />
                <ChemPropTag name="LOGP" value={2} tooltip="cLogP" />
                <ChemPropTag
                  name="ARC"
                  value={2}
                  tooltip="Aromatic Ring Count"
                />
                <ChemPropTag name="TPSA" value={3} tooltip="TPSA" />
                <ChemPropTag name="CSP3" value={2} tooltip="Fraction CSP3" />
                <ChemPropTag name="CC" value={2} tooltip="Chiral centers" />
                <ChemPropTag name="LR" value={2} tooltip="Largest ring" />
              </Grid>
            )} */}
          </PropsCard>

          {/* Pharmacological Properties */}
          <PropsCard>
            <Flex align={"baseline"}>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Pharmacological Properties</SubtitleSM>
                <HelperModal
                  title={"Phamacological Properties"}
                  data={pharmaprops_helper}
                />
              </Flex>
              <Spacer />

              {/* Phase */}
              <PhaseChart
                selected={
                  compound?.citeline_data?.phase ??
                  compound?.integrity_data?.phase ??
                  compound?.gvk_data?.phase ??
                  ""
                }
              />
            </Flex>

            {/* Pharmacological Properties */}
            <PharmaAccordion compound={compound} />
          </PropsCard>

          {/* Similar compounds */}
          {!!similarCompounds.length && (
            <PropsCard>
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Similar Compounds</SubtitleSM>
                <HelperModal
                  title={"Compounds Summary Data"}
                  data={similarsummaryprops_helper}
                />
              </Flex>

              <CompoundsTable
                items={similarCompounds}
                similarityScores={compound?.similar_compounds?.map(
                  (similar: any) => similar.similarity_score
                )}
              />
            </PropsCard>
          )}

          {/* Assays */}
          {/* Assay Hits */}
          {compound?.assays_hits && compound?.assays_hits?.length > 0 && (
            <PropsCard>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Activity Data</SubtitleSM>
                <HelperModal
                  title="Activity Data"
                  data={activitydataprops_helper}
                />
              </Flex>

              {/* Assay Hits */}
              <AssaysTable assays={compound?.assays_hits} isHit={true} />
            </PropsCard>
          )}

          {/* Screening collection */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Primary Screening Data</SubtitleSM>
              <HelperModal
                title="Primary Screening Data"
                data={screeningdataprops_helper}
              />
            </Flex>

            {/* Assay Hits */}
            <AssaysTable assays={compound?.screening_collection} />
          </PropsCard>

            {/* Ontologies collection */}
            <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Ontology</SubtitleSM>
              <HelperModal
                title="Ontology"
                data={moaprops_helper}
              />
            </Flex>

            {/* External Resources List */}
            <Ontologies ontologies={compound?.moa} />
          </PropsCard>

          {/* External Resources */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>External Sources</SubtitleSM>
              <HelperModal
                title="External Sources"
                data={externalsources_helper}
              />
            </Flex>

            {/* External Resources List */}
            <ExternalSourcesList resources={compound?.external_resources} />
          </PropsCard>

          {/* References */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>References</SubtitleSM>
              <HelperModal title="References" data={papers_helper} />
            </Flex>

            {papersReferencedInIsLoading ? (
              <Loading message="Loading papers data.." />
            ) : papersReferencedIn.length > 0 ? (
              <PapersReferencesChart papersData={papersReferencedIn} />
            ) : (
              <Text>No research papers related to this compound.</Text>
            )}
          </PropsCard>
        </VStack>
      ) : (
        <Text textAlign={"center"}>No data for selected compound</Text>
      )}

      {/* Modal */}
      {id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "COMPOUND",
              content: {
                elementId: id,
                aliases: compound?.aliases,
                chemical_props: compound?.chemical_props,
                generic_name: compound?.generic_name,
              },
            },
            successMessage: `Compound is successfully saved.`,
          }}
        />
      )}
    </Box>
  );
}
