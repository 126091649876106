import { useState } from "react";
import {
  FormControl,
  Input,
  Button,
  useToast,
  useTheme,
  Box,
} from "@chakra-ui/react";
import { axiosClient } from "api/axios";
import { hexToRgba } from "utils/helpers";

export default function ResendNewPassword({ username }: { username: string }) {
  // Hooks
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);

  // Theme
  const { colors } = useTheme();
  // form submit
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await axiosClient.post(
        "/api/resend_new_password",
        JSON.stringify({ username }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      toast({
        position: "bottom-right",
        duration: 1000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            New password resent successfully
          </Box>
        ),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error: any) {
      setSubmitting(false);
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    }
  };

  return (
    <>
      <FormControl id="username">
        <Input
          disabled
          type="text"
          name="username"
          _placeholder={{
            fontSize: "14px",
            color: hexToRgba(colors.black, 0.55),
          }}
          value={username}
          borderColor={"neutral.700"}
          color={"black"}
          w={"100%"}
          _focus={{
            outline: "none",
            boxShadow: "none",
            borderColor: hexToRgba(colors.black, 0.25),
            bg: hexToRgba(colors.black, 0.03),
          }}
          _hover={{
            outline: "none",
            boxShadow: "none",
            borderColor: hexToRgba(colors.black, 0.25),
          }}
        />
      </FormControl>
      <Button
        bg={"secondary.500"}
        size="md"
        onClick={handleSubmit}
        w="100%"
        isLoading={submitting}
        loadingText="Processing"
        color={"primary.100"}
        type="submit"
        _hover={{ bg: "secondary.500" }}
      >
        Resend New Password
      </Button>
    </>
  );
}
