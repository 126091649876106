import { SiMoleculer } from "react-icons/si";
import { PiNewspaperFill } from "react-icons/pi";
import { ImLab } from "react-icons/im";
import { IconType } from "react-icons";
import { CompoundProps } from "models/compounds/CompoundProps";
import { SavedFolderProps } from "models/research/SavedProps";

export interface SearchResults {
  data: any[];
  currentPage: number;
  hasMore: boolean;
}

export interface TableColumn {
  header: string;
  accessor: string;
  displayFormat?: "IMAGE" | "TAGS" | "BOOL" | "LIST" | "STRING";
  filterable?: boolean;
  sortable?: boolean;
}

export interface Table {
  columns: TableColumn[];
}

export interface FetchResponse {
  newData: any[];
  currentPage: number;
  hasMore: boolean;
}

export interface SourceProps {
  key: "MOLECULES" | "LITERATURE" | "ASSAYS";
  path: string;
  description: string;
  icon: IconType;
  source: string;
  color: string;
  table: Table;
}

export const LibFeatures = ["Core", "Proprietary", "Tools", "Research"];
export interface ProprietarySourceProps {
  key: 'PROPRIETARY';
  category: 'LITERATURE'
  path: string;
  description: string;
  icon: IconType;
  color: string;
  source: string;
  table: Table;
}

export const coreSources: SourceProps[] = [
  {
    key: "MOLECULES",
    path: "molecules",
    description: "Molecules Database comprises over 12000 life molecules.",
    icon: SiMoleculer,
    source: "ReframeDB",
    color: "secondary.400",
    table: {
      columns: [
        { header: "Molecule", accessor: "smiles", displayFormat: "IMAGE" },
        {
          header: "Id",
          accessor: "compound_id",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Name",
          accessor: "name",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Aliases",
          accessor: "aliases",
          displayFormat: "LIST",
          filterable: true,
        },
      ],
    },
  },
  {
    key: "ASSAYS",
    path: "assays",
    description: "Assays Database comprises the assays conducted by ReframeDB.",
    icon: ImLab,
    source: "ReframeDB",
    color: "secondary.300",
    table: {
      columns: [
        {
          header: "Id",
          accessor: "assay_id",
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Name",
          accessor: "name",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Indication",
          accessor: "overview.indication",
          filterable: true,
          sortable: true,
          displayFormat: "TAGS",
        },
        {
          header: "Assay Type",
          accessor: "overview.assay_type",
          displayFormat: "LIST",
          filterable: true,
        },
      ],
    },
  },
  {
    key: "LITERATURE",
    path: "literature",
    description:
      "Collection of over 1M research papers from the scientific communities work on drugs & molecules.",
    icon: PiNewspaperFill,
    source: "Pubmed, NIH, Nature, ACS",
    color: "primary.300",
    table: {
      columns: [
        {
          header: "Title",
          accessor: "title",
          filterable: true,
          sortable: true,
        },
        { header: "Year", accessor: "year_published", filterable: true },
        {
          header: "Authors",
          accessor: "authors",
          displayFormat: "TAGS",
          filterable: true,
        },
        {
          header: "Type",
          accessor: "paper_type",
          displayFormat: "TAGS",
          filterable: true,
        },
        { header: "Access", accessor: "is_open_access", displayFormat: "BOOL" },
        { header: "_id", accessor: "id" },
      ],
    },
  },
];

export const getCoreSource = (path: string | undefined) => {
  return coreSources.find((cs) => cs.path === path);
};

export const getLibraryFolder = (
  folderId: string | undefined,
  folders: SavedFolderProps[]
) => {
  return folders.find((f) => f.id === folderId);
};

export const getImageSrc = (
  row: any,
  key: 'MOLECULES' | 'LITERATURE' | 'ASSAYS' | 'PROPRIETARY'
) => {
  // Get image source for molecules
  if (key === "MOLECULES") {
    const chemProps = (row as CompoundProps).chemical_props;
    const smiles = chemProps?.isomeric_smiles;
    const smilesStructure = chemProps?.isomeric_smiles_structure;

    if (!!smiles && window.RDKit) {
      const mol = window.RDKit.get_mol(smiles || "invalid");
      const svg = btoa(window.RDKit.get_mol(smiles ?? "")?.get_svg() ?? "");

      mol?.delete();

      return `data:image/svg+xml;base64,${svg}`;
    }
    if (smilesStructure) {
      return `data:image/png+svg;base64,${smilesStructure}`;
    }
  }

  return "ex.png";
};

export function getValueByAccessor(row: any, accessor: string) {
  return accessor.split(".").reduce((acc, part) => acc && acc[part], row);
}

export function returnValueAsAnArray(value: string | string[]) {
  if (typeof value === "string") {
    return value.split(",").map((str) => str.trim());
  }
  return value;
}


export const proprietarySources: ProprietarySourceProps[] = [
  {
    key: "PROPRIETARY",
    category: "LITERATURE",
    path: "literature",
    description:
      "Upload your papers securely. Choose private access or share with your team. The papers are then embedded into the chat assistant for easy retrieval and use",
    icon: PiNewspaperFill,
    source: "Access: Private or Team",
    color: "secondary.400",
    table: {
      columns: [
        { header: "Name", accessor: "title", filterable: true, sortable: true },
        { header: "Year", accessor: "year_published", filterable: true },
        { header: "Authors", accessor: "authors", displayFormat: "TAGS", filterable: true },
        { header: "Type", accessor: "paper_type", displayFormat: "TAGS", filterable: true },
        { header: "Embeding", accessor: "is_processing", displayFormat: "BOOL" },
        { header: "Access", accessor: "team_access", displayFormat: "BOOL" },
        { header: "_id", accessor: "id" },
      ],
    },
  }
];

export const getProprietarySource = (path: string | undefined) => {
  return proprietarySources.find(cs => cs.path === path);
}
