import {
  FormControl,
  FormErrorMessage,
  Textarea,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface MessageFormControlProps {
  message: string;
  isInvalid: boolean;
  onMessageChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function MessageFormControl({
  message,
  isInvalid,
  onMessageChange,
}: MessageFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Textarea
        isRequired
        name="message"
        value={message}
        onChange={onMessageChange}
        placeholder="Write message here..."
        resize={"none"}
        h={"140px"}
        _placeholder={{
          color: "black",
          opacity: 0.75,
          fontSize: { base: "13px", md: "15px" },
        }}
        fontSize={{ base: "14px", md: "16px" }}
        borderRadius={"8px"}
        color={"black"}
        bg={hexToRgba(colors.white, 0.5)}
        border={"none"}
        _hover={{ bg: hexToRgba(colors.white, 0.7) }}
        _focusVisible={{ bg: hexToRgba(colors.white, 0.7) }}
      />
      <FormErrorMessage color={"whiteAlpha.700"}>
        Message is required. Should be longer than 3 characters.
      </FormErrorMessage>
    </FormControl>
  );
}

export default MessageFormControl;
