import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { MdHelpOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  onGuideClose,
  selectCurrentGuideData,
  setGuideOpen,
} from "redux/features/guide/guideSlice";

const HelpTourButton = () => {
  // Hooks
  const dispatch = useDispatch();
  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  const { getRoute, isHelpTourAvailable, isHighlighted } = useGuideTour();

  // Handler
  const handleClick = () => {
    if (isGuideOpen) dispatch(onGuideClose());
    isHelpTourAvailable() && dispatch(setGuideOpen({ route: getRoute() }));
  };

  let highlighted = isHighlighted("help_tour");

  return (
    <Flex
      id="help_tour"
      cursor={"pointer"}
      zIndex={highlighted ? 1350 : 999}
      bg={"secondary.600"}
      color={"background"}
      align={"center"}
      position={"absolute"}
      top={"calc(50% + 82px)"}
      right={0}
      transition={"all 0.3s ease"}
      borderRadius={"8px 0 0 8px"}
      _hover={{ opacity: 0.9 }}
      onClick={handleClick}
      h={"36px"}
      w={"36px"}
      transform={"translateY(-50%)"}
      overflow={"hidden"}
      m={0}
      p={0}
      gap={2}
    >
      <Tooltip
        label={"Help guide"}
        placement={"left"}
        bg={"gray.900"}
        color={"gray.100"}
        hasArrow
        py={2}
        px={3}
        m={2}
        maxW={[null, null, null, "200px", "220px", "240px"]}
        borderRadius={"6px"}
        fontSize={"12px"}
        boxShadow={"none"}
        arrowSize={8}
      >
        <Flex align={"center"} justify={"center"} h={"36px"} w={"36px"}>
          <Icon as={MdHelpOutline} boxSize={"18px"} />
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default HelpTourButton;
