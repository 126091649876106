import { AxiosError } from "axios";
import {
  containsUppercase,
  hasLowercase,
  containsNumber,
  containsSpecialCharacter,
} from "views/auth/helpers";

interface FreqObj {
  [key: string]: number;
}

interface StylesObject {
  [key: string]: string;
}

// count item occurence
function FrequencyObject(arr: string[]) {
  return arr.reduce((acc: FreqObj, item: string) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {});
}

// echarts-format data
export function transformDataForChart(arr: string[] | []) {
  return Object.keys(FrequencyObject(arr)).map((key) => ({
    name: key,
    value: +FrequencyObject(arr)[key],
  }));
}

export function stylesToString(stylesObj: StylesObject) {
  let styleString = "";

  for (const property in stylesObj) {
    if (stylesObj.hasOwnProperty(property)) {
      styleString += `${property}: ${stylesObj[property]}; `;
    }
  }

  return styleString.trim();
}

export function savedAt() {
  const now = new Date();
  return now.toISOString().split("T")[0];
}

export function hexToRgba(hexCode: string, opacity = 1): string {
  // fallback color
  if (!hexCode) return "black";

  // Remove the # symbol from the hex code
  hexCode = hexCode.replace("#", "");

  // If the hex code is short, expand it to the full 6-digit format
  if (hexCode.length === 3) {
    hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  // Parse the hex code into RGB values
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the opacity value
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const errorHandler = (Error: any) => {
  let errorMessage = null;

  if (Error instanceof AxiosError) {
    // Handle AxiosError specifically
    if (Error.response && Error.response.data) {
      // NOTE: to delete this if-else check after implementing it on the server
      if (Error.response.data.message?.toLowerCase() === "session not found")
        errorMessage = "Conversation not found";
      else errorMessage = Error.response.data.message;
      // errorMessage = Error.response.data.message; // NOTE: to unmute
    } else {
      errorMessage = Error.message;
    }
  } else {
    // Handle plain Error
    errorMessage = Error?.message;
  }
  return { message: errorMessage ?? "An unknown error occurred" };
};

export const validateFullName = (name: string) => {
  if (name) {
    const re = /^[a-zA-Z\-’']+( [a-zA-Z\-’']+)+$/;
    return re.test(name);
  } else {
    return false;
  }
};

export const validateEmail = (email: string) => {
  if (email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  } else {
    return false;
  }
};

export const validatePass = (pass: string) => {
  if (pass.length < 8) {
    return "Password should be 8 characters long.";
  } else if (!containsUppercase(pass)) {
    return "Password should contain at least one uppercase letter.";
  } else if (!hasLowercase(pass)) {
    return "Password should contain at least one lowercase letter.";
  } else if (!containsNumber(pass)) {
    return "Password should contain at least one number.";
  } else if (!containsSpecialCharacter(pass)) {
    return "Password should contain at least one special character.";
  } else {
    return "";
  }
};

// Get firstname and lastname from fullname
export const getName = (name: string, fullname: string) => {
  // name is either firstname or lastname
  let arr = fullname?.split(" ");

  if (name === "first") {
    return titleCase(arr[0]);
  } else {
    if (arr.length === 3) {
      // Combine last name and surname
      return titleCase(arr[1] + " " + titleCase(arr[2]));
    } else {
      return titleCase(arr[1]);
    }
  }
};

export const titleCase = (str: string) => {
  return str?.toLowerCase()?.replace(/\b\S/g, function (t) {
    return t?.toUpperCase();
  });
};

export const formatCurrency = (amount: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(amount);
};
