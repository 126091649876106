import { ReactNode } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from "@chakra-ui/react";
import { HiOutlineExclamation } from "react-icons/hi";

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: ReactNode;
  header: string;
}

export default function DeleteModal({
  isOpen,
  onClose,
  onConfirm,
  header,
  children,
}: DeleteModalProps) {
  // Theme

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        textAlign={"center"}
        alignItems={"center"}
        w={"fit-content"}
        minW={"412px"}
        minH={"224px"}
        borderRadius={"6px"}
        bg={"background"}
      >
        <ModalHeader
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          gap={"2px"}
          color={"gray.600"}
        >
          <Icon
            as={HiOutlineExclamation}
            color={"red.500"}
            boxSize={"32px"}
            borderRadius={"50%"}
          />
          {header}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter gap={2}>
          <Button
            bg={"gray.100"}
            color={"gray.600"}
            _hover={{
              color: "gray.700",
              bg: "gray.200",
            }}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            bg="red.500"
            color={"gray.50"}
            border={"none"}
            _hover={{ bg: "red.600" }}
            borderRadius={"100px"}
            onClick={onConfirm}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
