import { Box, Flex, Text } from '@chakra-ui/react';
import { PiMouse } from "react-icons/pi";


interface CustomNodeTooltipProps {
    name: string;
    publishedYear: number;
    isOriginPaper: boolean;
}

function CustomNodeTooltip(props: CustomNodeTooltipProps) {
    const { name, publishedYear, isOriginPaper } = props
    return (
        <Flex
            style={{
                color: "#718096",
                width: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "6px",
                padding: "6px",
            }}
        >
            {/* IsOrigin paper */}
            {isOriginPaper && (
                <Box
                    style={{
                        width: 'fit-content',
                        padding: '1px 6px',
                        marginBottom: 3,
                        borderRadius: 6,
                        fontWeight: "bold",
                        fontFamily: "Arial",
                        color: "#e53e3e",
                        fontSize: "12px",
                        backgroundColor: '#fed7d7'
                    }}
                >
                    Source paper
                </Box>
            )}
            {/* Name */}
            {name &&
                <Text style={{
                    minWidth: "240px",
                    maxWidth: "300px",
                    fontSize: "14px",
                    whiteSpace: "normal",
                    lineHeight: "1.35",
                }}>{name}</Text>}


            {/* Published year */}
            {!!publishedYear &&
                <Flex style={{
                    margin: "4px 0",
                    fontSize: "12px",
                    gap: '6px'
                }}>
                    <Text style={{ fontWeight: "bold", color: "#2d3748" }}>Published:</Text>
                    <Text>{publishedYear}</Text>
                </Flex>}

            {/* Right click info */}
            <Flex
                style={{
                    color: "#a0aec0",
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: 2,
                    alignItems: "center",
                    fontSize: "11px",
                    marginTop: "8px",
                    fontStyle: 'italic',
                    textDecoration: 'underline'
                }}
            >
                <Text>Right click for more details</Text>
                <PiMouse style={{ transform: 'skewX(-10deg)' }} fontSize={"15px"} strokeWidth={"1.5px"} />
            </Flex>
        </Flex>)
}

export default CustomNodeTooltip