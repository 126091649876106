import { Flex, Icon, Text, Tooltip, useClipboard } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query';
import { useLiteraturesAPI } from 'api/useLiteraturesAPI';
import ActionButton from 'components/buttons/ActionButton';
import Abstract from 'components/papers/paperDetails/Abstract';
import Authors from 'components/papers/paperDetails/Authors';
import Citation from 'components/papers/paperDetails/Citation';
import Doi from 'components/papers/paperDetails/Doi';
import LensId from 'components/papers/paperDetails/LensId';
import TypeAndYear from 'components/papers/paperDetails/TypeAndYear';
import FoldersModal from 'components/research/modal/FoldersModal';
import Loading from 'components/ui/Loading';
import MainPanelError from 'components/ui/MainPanelError';
import { ExternalId } from 'models/papers/PaperProps'
import { useContext, useEffect, useState } from 'react';
import { BiSolidCopy } from 'react-icons/bi';
import { BsBookmarkCheck } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { errorHandler } from 'utils/helpers';
import { getPaperColor } from 'views/compounds/helpers';
import { ProprietaryContext } from 'views/library/proprietary';

const LiteraturesItem = () => {
    // Hooks
    const { sourceItemId: id } = useParams();
    const { setSourceItemName } = useContext(ProprietaryContext);
    const { fetchPapersById } = useLiteraturesAPI()

    // * Fetch paper details
    const {
        isLoading,
        error,
        data,
    } = useQuery({
        queryKey: ["proprietary-literature", id],
        queryFn: fetchPapersById,
        enabled: !!id,
        staleTime: 40 * 1000 * 60,
        gcTime: 10 * 1000 * 60,
    });

    console.log(data, 'my paper')
    // State
    const [hasCopied, setHasCopied] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const { onCopy } = useClipboard(data?.title || "");

    // Handlers
    function handleCopy() {
        onCopy();
        setHasCopied(true);
    }

    const doi = data?.external_ids?.find(
        (id: ExternalId) => id?.type?.toLowerCase() === "doi"
    )?.value;

    useEffect(() => {
        if (data) {
            setSourceItemName(data.title)
        }
        setHasCopied(false);
    }, [data]);


    return (
        <>
            {error ? (<Flex w={"100%"}>
                <MainPanelError errorMessage={errorHandler(error).message} />
            </Flex>) :
                isLoading ? (<Flex
                    h={"100%"}
                    w={"100%"}
                    align={"center"}
                    justify={"center"}
                    color={"gray.500"}
                    direction={"column"}
                >
                    <Loading message={`Loading literature data...`} />
                </Flex>) :
                    (<Flex direction={"column"} justify={"space-between"} h={"100%"}>
                        
                        <Flex direction={"column"} gap={3}>
                            {/* paper type & publication year  */}
                            {data && <TypeAndYear data={data} />}

                            {/* title */}
                            <Tooltip
                                label={data?.title}
                                placement={"left"}
                                hasArrow
                                display={data?.title && data?.title.length < 121 ? "none" : "block"}
                                bg={"gray.900"}
                                color={"gray.100"}
                                py={2}
                                px={3}
                                m={2}
                                maxW={[null, null, null, "200px", "220px", "240px"]}
                                borderRadius={"6px"}
                                fontSize={"12px"}
                                boxShadow={"none"}
                                arrowSize={8}
                            >
                                <Text
                                    fontSize={[null, null, null, "12px", "14px", "16px"]}
                                    fontWeight={"500"}
                                    color={getPaperColor(data?.paper_type)}
                                    lineHeight={"1.35"}
                                    display={"inline"}
                                    mb={1.5}
                                >
                                    {data?.title && data?.title.length < 121
                                        ? data?.title
                                        : data?.title?.slice(0, 120) + ".."}

                                    <Icon
                                        ml={2}
                                        display={"inline"}
                                        color={hasCopied ? "highlight.primary" : "gray.500"}
                                        boxSize={"14px"}
                                        onClick={handleCopy}
                                        cursor={"pointer"}
                                        as={hasCopied ? FaCheck : BiSolidCopy}
                                    />
                                </Text>
                            </Tooltip>

                            <Abstract data={data?.abstract ?? ""} />
                            <Authors data={data?.authors ?? []} />
                            <Citation data={data?.scholarly_citations_count} />
                            <LensId data={data?.lens_id ?? ""} />
                            <Doi data={doi ?? ""} />
                        </Flex>

                        {/* action buttons */}
                        <Flex w={"100%"} borderTopColor={"gray.200"} borderTopWidth={1} mt={5}>
                            <Flex alignItems="center" w={"100%"} gap={2} pt={1}>
                                <ActionButton
                                    label={"Save"}
                                    icon={<BsBookmarkCheck />}
                                    onClick={() => setShowSaveModal(true)}
                                />
                            </Flex>
                        </Flex>

                        {/* Modal */}
                        {data?.id && (
                            <FoldersModal
                                isOpen={showSaveModal}
                                onClose={() => setShowSaveModal(false)}
                                payload={{
                                    saveElementPayload: {
                                        elementType: "LITERATURE",
                                        content: {
                                            elementId: data?.id,
                                            is_private: true,
                                        },
                                    },
                                    successMessage: `Literature is successfully saved.`,
                                }}
                            />
                        )}
                    </Flex>)}
        </>)
}

export default LiteraturesItem