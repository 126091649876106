import { Avatar } from "@chakra-ui/react";

export interface AvatarProps{
  memberName:string
}

function OtherUserAvatar(avatar:AvatarProps) {  
  return (
    <Avatar name={avatar.memberName} size='sm' />      
  );
}

export default OtherUserAvatar;