import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Toast,
  Tooltip,
  Tr,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import NoResultsFound from "components/ui/NoResultsFound";
import { useQueryClient } from "@tanstack/react-query";
import {
  ColumnOrderState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  SourceProps,
  returnValueAsAnArray,
  getImageSrc,
  getValueByAccessor,
  TableColumn,
  ProprietarySourceProps,
} from "../../../views/library/helpers";
import { errorHandler, hexToRgba } from "utils/helpers";
import {
  FaFilter,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
} from "react-icons/fa";
import { useDataLibraryAPI } from "api/useDataLibraryAPI";
import DataSearchInput from "./SearchInput";
import { useNavigate } from "react-router-dom";
import { indicationToColor } from "theme/helper";
import { ProprietaryContext } from "views/library/proprietary";
import {
  handleDragEnd,
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleFilterClear,
  handleRowClick,
  handleFilterInputKeyDown,
  handleFilterIconClick,
  handleFilterInputChange
} from './helper';
import useGuideTour from 'hooks/guideTour/useGuideTour';
import { CustomOverlay } from "./CustomOverlay";
import CustomLoader from "./CustomLoader";
import NewSourceModal from "./AddNewSourcesModal";
import { AiOutlineFileAdd } from "react-icons/ai";

const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
  borderBottom: 0,
};

export interface AgnosticDataDisplayProps {
  source: ProprietarySourceProps;
}

export interface ColumnFiltersProp {
  [key: string]: string;
}

const AgnosticDataDisplay = ({ source }: AgnosticDataDisplayProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { key, category, table } = source;
  const { columns } = table;
  const { searchState, setSearchState } = useContext(ProprietaryContext)
  const itemsPerPage = 20;
  const page = useRef<number>(1);
  const sort = useRef<string | null>(null);
  const order = useRef<"desc" | "asc" | null>(null);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    table.columns.map((col) => col.accessor)
  );
  const [searchValue, setSearchValue] = useState(searchState.searchValue);
  const [sorting, setSorting] = useState(searchState.sorting);
  const [columnsFilters, setColumnsFilters] = useState(searchState.columnsFilters);
  const [filters, setFilters] = useState(searchState.filters);
  const inputRefs = useRef(new Map());
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const isMounted = useRef(true);
  const [showNewSourceModal, setShowNewSourceModal] = useState(false)
  const { colors } = useTheme();
  const toast = useToast()
  const { fetchProprietaryTableData, fetchTableData } = useDataLibraryAPI();
  const fetchedAll = useRef<boolean>(false);
  const [isFetching, setFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string>("");
  const [results, setResults] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsLoaded, setresultsLoaded] = useState<boolean>(false);


  useEffect(() => {
    console.log(isMounted.current)
    return () => {
      isMounted.current = false; // Set to false when the component is unmounted
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  useEffect(() => {
    setSearchState({ searchValue, sorting, filters, columnsFilters });
  }, [searchValue, sorting, filters, setSearchState, columnsFilters]);

  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottom = scrollHeight - scrollTop <= clientHeight * 1.5;

    if (bottom && !isFetching && page.current && !fetchedAll.current) {
      setFetching(true);
      page.current += 1;
      try {
        const controller = new AbortController();
        setAbortController(controller);

        const data = await queryClient.fetchQuery({
          queryKey: [`${key}-table`, { query: searchValue, page: page.current, itemsPerPage, source: category, order: order.current, sort: sort.current, filters }],
          queryFn: ({ queryKey }) => fetchProprietaryTableData({ queryKey, signal: controller.signal }),
          staleTime: 2 * 1000 * 60,
          retry: false,
        });

        setResults((prevData) => prevData ? [...prevData, ...data.data] : data.data);
        fetchedAll.current = !data.hasMore;
      } catch (error: any) {
          if (error.name !== 'CanceledError') {
            setError(errorHandler(error).message);
          } else {
            isMounted.current && fetchData();
          }
        } finally {
          setFetching(false);
        }
      }
  }

  const columnHelper = createColumnHelper<TableColumn>();
  const tableColumns = useMemo(() =>
    table.columns.map((col: TableColumn) => columnHelper.accessor(row => row.accessor, {
      id: col.accessor,
      header: col.header,
      enableSorting: !!col.sortable ? true : false,
      enableColumnFilter: !!col.filterable ? true : false,
    })),
    [table.columns]
  );

  const tableInstance = useReactTable({
    columns: tableColumns,
    data: results ?? [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnOrder,
      columnVisibility: {
        id: false
      }
    },
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
  });

  // Table Search
  const clearSearch = () => {
    // Cancel ongoing request
    if (abortController) abortController.abort();

    setSearchValue('')
    page.current = 1
    sort.current = null
    order.current = null
  }

  const submitSearchForm = (inputStr: string) => {
    if (loading || isFetching) return
    clearSearch()
    setFilters({})
    setColumnsFilters({ showFilter: null, filters: {} })
    setSearchValue(inputStr)
    setIsSearching(false)
  };

  const { isHighlighted } = useGuideTour()
  let sortHighlighted = isHighlighted('prorietary_table-column-sort')
  let filterHighlighted = isHighlighted('prorietary_table-column-filter')
  let rowHighlighted = isHighlighted('prorietary_table-row')
  let sortInfoDisplayed = false
  let filterInfoDisplayed = false

  const handleAddNewSource = () => {
    setShowNewSourceModal(true)
  }


  const closeModal = () =>{
    setShowNewSourceModal(false)
    fetchData()
 }

  // Sorting
  const fetchData = async () => {
    setLoading(true)
    try {
      const controller = new AbortController();
      setAbortController(controller);

      const data = await queryClient.fetchQuery({
        queryKey: [`${key}-${category}-table`, { query: searchValue, page: page.current, itemsPerPage, source: category, order: order.current, sort: sort.current, filters }],
        queryFn: ({ queryKey }) => fetchProprietaryTableData({ queryKey, signal: controller.signal }),
        staleTime: 2 * 1000 * 60,
        retry: false,
      });
      console.log()
      setResults(data.data);
      setresultsLoaded(true)
      fetchedAll.current = !data.hasMore;
      setLoading(false);
      setresultsLoaded(true)

      if (data.data.length === 0)
        fetchedAll.current = !data.hasMore;
    } catch (error: any) {
        if (error.name !== 'CanceledError' && error?.response?.status !== 404) {
          setError(errorHandler(error).message);
        } else {
          isMounted.current && fetchData();
        }

        if(error?.response?.status === 404){
          setResults([]);
          setError('')
        }
      } finally {
        setLoading(false);
      }
  }


  useEffect(() => {
    fetchData();
  }, [searchValue, key, category, itemsPerPage, filters]);

  useEffect(() => {
    if (sorting.length === 0) return;

    const tempOrder = sorting[0]?.desc ? "desc" : "asc";
    const tempSort = sorting[0]?.id ?? "id";
    order.current = tempOrder;
    sort.current = tempSort;

    page.current = 1;
    fetchData();
    console.log(isMounted.current, 'mounted*******')
  }, [sorting]);

  return (
    <>
    {/* {results?.length === 0 && !loading && !isFetching?
      <Flex direction={'column'} alignItems={'center'}>
        <Icon as={ImFilesEmpty} mr={2} boxSize={30} color={'gray.400'} />
        <Text color={'gray.400'} mt={10}>You have not uploaded any proprietary papers yet!</Text>
        <Button
          maxW={"60%"}
          // h={"sm"}
          textAlign={'center'}
          size="md"
          mt={'20px'}
          bg={'highlight.primary'}
          color={"neutral.100"}
          letterSpacing={".03rem"}
          fontSize={"16px"}
          borderRadius={"8px"}
          p={4}
          _hover={{ opacity: 0.9 }}
          _active={{ opacity: 0.9 }}
          onClick={handleAddNewSource}
        >
          <Icon as={AiOutlineFileAdd} mr={2} boxSize={5} />
          Upload
        </Button>
      </Flex>

      :
      <> */}
        <Flex justifyContent={'space-between'} alignItems={'center'} >

            <DataSearchInput
              submitSearchForm={submitSearchForm}
              clearSearch={clearSearch}
              searchVal={searchValue}
            />
            <Button
              maxW={"100%"}
              // h={"sm"}
              size="md"
              bg={'highlight.primary'}
              color={"neutral.100"}
              letterSpacing={".03rem"}
              fontSize={"16px"}
              borderRadius={"8px"}
              p={4}
              _hover={{ opacity: 0.9 }}
              _active={{ opacity: 0.9 }}
              onClick={handleAddNewSource}
            >
              <Icon as={AiOutlineFileAdd} mr={2} boxSize={5} />
              Add new source
            </Button>
        </Flex>

        <TableContainer height="full" style={{ position: 'relative' }} zIndex={sortHighlighted || filterHighlighted || rowHighlighted ? 1300 : 1}>
          <CustomScrollBar style={tableStyle} onScrollCapture={handleScroll} zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}>
            <Table variant="simple" size="sm" >
                <Thead
                  position={"sticky"}
                  zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}
                  top={0}
                  bg={"gray.100"}
                  boxShadow={'none'}
                >
                  {tableInstance.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id} position={'relative'}>
                      {headerGroup.headers.map((header, index) => {
                        const canSort = header.column.getCanSort();
                        const isSorted = header.column.getIsSorted();
                        const canFilter = header.column.getCanFilter();
                        const showFilter = (columnsFilters.showFilter === header.id) || !!columnsFilters.filters[header.id];

                        let SortInfo = !sortInfoDisplayed
                        let FilterInfo = !filterInfoDisplayed

                        if (canSort) {
                          sortInfoDisplayed = true
                        }
                        if (canFilter) {
                          filterInfoDisplayed = true
                        }

                        return (
                          <Th
                            key={header.id}
                            boxShadow={'none'}
                            py={2}
                            px={1}
                            textAlign={'left'}
                            border={'none'}
                            borderLeftRadius={index === 0 ? "md" : 'none'}
                            borderRightRadius={index === headerGroup.headers.length - 1 ? "md" : 'none'}
                            borderRight={index !== headerGroup.headers.length - 1 ? '1px solid' : 'none'}
                            borderRightColor={'gray.200'}
                            draggable
                            onDragStart={(e) => handleDragStart(e, header.id)}
                            onDrop={(e) => handleDrop(e, header.id, columnOrder, setColumnOrder)}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDragEnd={handleDragEnd}
                          >
                            {(sortHighlighted || filterHighlighted || rowHighlighted) && <CustomOverlay />}
                            <Flex alignItems={'center'} justifyContent={'space-between'} color={'gray.500'}>
                              {canSort ? (
                                <Box
                                  p={1}
                                  id={'prorietary_table-column-sort'}
                                  zIndex={sortHighlighted && SortInfo ? 1400 : 1}
                                  bg={sortHighlighted && SortInfo ? 'background' : 'transparent'}
                                  height={'100%'}
                                  onClick={header.column.getToggleSortingHandler()}
                                  borderRadius={'4px'}
                                >
                                  <Flex alignItems={'center'}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {isSorted === 'asc' ? (
                                      <FaSortUp style={{ marginLeft: '2px' }} />
                                    ) : isSorted === 'desc' ? (
                                      <FaSortDown style={{ marginLeft: '2px' }} />
                                    ) : (
                                      <FaSort style={{ marginLeft: '2px' }} />
                                    )}
                                  </Flex>
                                </Box>
                              ) : (
                                <Box>
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                </Box>
                              )}
                              {canFilter && (
                                <InputGroup
                                  width={'fit-content'}
                                  justifyContent={'flex-end'}
                                  id={'prorietary_table-column-filter'}
                                  zIndex={FilterInfo && filterHighlighted ? 1400 : 1}
                                  bg={FilterInfo && filterHighlighted ? 'background' : 'transparent'}
                                  borderRadius={'4px'}
                                >
                                  <Input
                                    ref={(el) => inputRefs.current.set(header.id, el)}
                                    disabled={!!filters[header.id]}
                                    value={columnsFilters.filters[header.id] || ''}
                                    transition="ease-in-out .4s"
                                    width={showFilter ? '100%' : '0'}
                                    minWidth={showFilter ? '70px' : '0'}
                                    maxWidth={'170px'}
                                    pl={showFilter ? 1 : 0}
                                    border={showFilter ? '1px solid' : 'none'}
                                    borderColor={showFilter ? 'inherit' : 'transparent'}
                                    backgroundColor={!!filters[header.id] ? 'green.100' : 'inherit'}
                                    pr={'20px'}
                                    py={0}
                                    h={6}
                                    borderRadius={4}
                                    size="xs"
                                    color={'gray.700'}
                                    outline={'none'}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleFilterInputKeyDown(e, header.id, clearSearch, setFilters)}
                                    onChange={(e) => handleFilterInputChange(e, header.id, setColumnsFilters)}
                                    placeholder={`filter ${header.column.columnDef.header}`}
                                    _focus={{
                                      boxShadow: 'none'
                                    }}
                                  />
                                  <InputRightElement height={'100%'} width={'12px'} right={'5px'}>
                                    {columnsFilters.filters[header.id] ? (
                                      <FaTimes
                                        fontSize="12px"
                                        onClick={() => handleFilterClear(header.id, setColumnsFilters, setFilters)}
                                        cursor={'pointer'}
                                      />
                                    ) : (
                                      <FaFilter
                                        fontSize="12px"
                                        onClick={() => handleFilterIconClick(header.id, setColumnsFilters, inputRefs)}
                                        cursor={'pointer'}
                                      />
                                    )}
                                  </InputRightElement>
                                </InputGroup>
                              )}
                            </Flex>
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
              <Tbody position={'relative'}>
                {loading ? (
                  <Tr>
                    <Td border={'none'} colSpan={columns.length} py={10}>
                      <CustomLoader sourceName={key} />
                    </Td>
                  </Tr>
                ) : error ? (
                  <Tr>
                    <Td colSpan={columns.length} py={10}>
                      <Flex direction={'column'} alignItems={'center'}>
                        <MainPanelError errorMessage={errorHandler(error).message} />
                      </Flex>
                    </Td>
                  </Tr>
                ) : !results?.length && !isFetching ? (
                  <Tr>
                  <Td colSpan={columns.length} py={10}>
                    <Flex direction={'column'} alignItems={'center'}>
                      <NoResultsFound sourceName={key.toLowerCase()} />
                    </Flex>
                  </Td>
                </Tr>
                ) :
                  <>
                    {(sortHighlighted || filterHighlighted) && <Tr><Td><CustomOverlay /></Td></Tr>}
                    {tableInstance.getRowModel().rows.map(row => (
                      <Tr
                        key={"table-row" + row.id}
                        id='prorietary_table-row'
                        zIndex={rowHighlighted ? 1350 : 1}
                        boxShadow={'none'}
                        bg={rowHighlighted ? 'background' : 'transparent'}
                        py={1}
                        transition="background 0.3s ease"
                        _hover={{ cursor: "pointer", bg: "gray.100" }}
                        onClick={() => handleRowClick(row.original, source, navigate, toast)}
                      >
                        {row.getVisibleCells().map(cell => {
                          const column = columns.find(col => col.accessor === cell.column.id);
                          if (column?.displayFormat === "IMAGE") {
                            return (
                              <Td borderColor={"gray.100"} w={"20%"} key={"table-cell" + cell.id} >
                                <Image
                                  src={getImageSrc(row.original, key)}
                                  boxSize="100%"
                                  maxHeight={"120px"}
                                  maxWidth={"120px"}
                                  objectFit={"cover"}
                                  fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
                                  alt={cell.column.id}
                                  boxShadow={"md"}
                                  borderRadius={"6px"}
                                  mx={"auto"}
                                />
                              </Td>
                            );
                          } else if (column?.displayFormat === "TAGS" ||
                            column?.displayFormat === "LIST") {
                            const isTag = column?.displayFormat === "TAGS";
                            const tags = returnValueAsAnArray(getValueByAccessor(row.original, cell.column.id)) || [];
                            return (
                              <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"25%"} p={1.5} isTruncated >
                                <Flex wrap={"wrap"} gap={1}>
                                  {tags.slice(0, 5).map((tag: any, index) => {
                                    const displayedText = cell.column.id === 'authors' ? `${tag.first_name} ${tag.last_name}` : tag
                                    return (
                                      <Tooltip
                                        key={"tag-" + index}
                                        label={displayedText}
                                        bg={"gray.900"}
                                        color={"gray.100"}
                                        hasArrow
                                        py={2}
                                        px={3}
                                        m={2}
                                        maxW={["300px", "360px", "440px"]}
                                        borderRadius={"6px"}
                                        fontSize={"12px"}
                                        boxShadow={"none"}
                                        arrowSize={8}
                                      >
                                        <Tag color={"gray.700"}
                                          bg={
                                            isTag
                                              ? indicationToColor(tag)
                                              : "gray.200"
                                          } fontSize={["10px", "12px", "12px"]}>
                                          {displayedText.length < 21 ? displayedText : `${displayedText.slice(0, 20)}..`}
                                        </Tag>
                                      </Tooltip>
                                    )
                                  })}
                                </Flex>
                                {tags.length >= 6 &&
                                  <Tag fontStyle={'italic'} bg={'transparent'} color={"gray.500"} fontSize={["10px", "12px", "12px"]}>{tags.length - 5} more {cell.column.id}..</Tag>
                                }
                              </Td>
                            );
                          } else if (column?.displayFormat === "BOOL") {
                            const value = getValueByAccessor(row.original, cell.column.id);
                            const NotDefined = value === null || value === undefined || '';
                            const bgColor = NotDefined ? 'gray.300' : value === false ? 'green.200' : cell.column.id === 'team_access' ? 'red.200' : 'orange.200';
                            const color = NotDefined ? 'gray.700' : value === false ? 'green.500' : cell.column.id === 'is_processing' ? 'red.600' : cell.column.id === 'is_processing' ? 'green.600' : 'orange.600';
                            const skeleton = NotDefined ? true : false
                            const displayText = cell.column.id === 'team_access'
                              ? NotDefined ? 'Not defined' : value === true ? 'Team access' : 'Private'
                              : NotDefined ? 'Not defined'
                                : cell.column.id === 'is_processing' && value === true ? 'In progress' : 'Embeded'

                            return (
                              <>
                                <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"25%"} p={1.5} isTruncated>
                                  <Tag fontSize={["10px", "12px", "12px"]} bg={bgColor} color={color}>
                                    {displayText}
                                  </Tag>
                                </Td>
                              </>

                            );
                          } else {
                            return (
                              <Td key={"table-cell" + cell.id} borderColor={"gray.100"} maxW={"350px"} px={1.5} isTruncated>
                                <Tooltip
                                  label={getValueByAccessor(row.original, cell.column.id)}
                                  bg={"gray.900"}
                                  color={"gray.100"}
                                  hasArrow
                                  py={2}
                                  px={3}
                                  m={2}
                                  maxW={["200px", "220px", "240px"]}
                                  borderRadius={"6px"}
                                  fontSize={"12px"}
                                  boxShadow={"none"}
                                  arrowSize={8}
                                >
                                  <Text
                                    textTransform={"capitalize"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    fontSize={["12px", "14px", "14px"]}
                                    fontWeight={'400'}
                                  >
                                    {getValueByAccessor(row.original, cell.column.id)}
                                  </Text>
                                </Tooltip>
                              </Td>
                            );
                          }
                        })}
                      </Tr>
                    )
                    )}
                    {isFetching && (
                      <Tr>
                        <Td colSpan={columns.length}>
                          <Loading />
                        </Td>
                      </Tr>
                    )}
                  </>
                }
              </Tbody>
            </Table>
          </CustomScrollBar>
        </TableContainer>
      
      {/* </>
      } */}
      <NewSourceModal isOpen={showNewSourceModal}
        onClose={closeModal} />
    </>
              
  );
};

export default AgnosticDataDisplay;