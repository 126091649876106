import { createContext, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, useToast } from "@chakra-ui/react";

import { useSubmit } from "hooks";
import TextField from "components/chat/TextField";
import { ConversationProps, SessionProps } from "models/chat/MessageProps";

// services
import { generateBotResponse } from "services/chatbot.service";
import { useChatBotAPI } from "api/useChatBotAPI";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { errorHandler } from "utils/helpers";

interface BotReplyProps {
  message?: [];
  generated_text?: string;
  data?: { generated_text: string };
  compounds?: string[] | [];
  sources?: { id: string; title: string }[] | [];
  followup_questions?: string[] | [];
}

const apologiesMessage =
  "Apologies, but I'm currently experiencing technical difficulties and I'm unable to assist you at the moment.\nPlease try again later.";

export const ChatbotContext = createContext({
  messages: [] as ConversationProps[],
  sessions: [] as SessionProps[],
  loadingSessions: undefined as boolean | undefined,
  loadingChat: false,
  chatError: undefined as Error | undefined,
  activeSession: "", // when the question being asked
  waitingOnBot: undefined as boolean | undefined,
});

export default function ChatbotView() {
  // hooks
  const toast = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  // extract session id from url
  const { id } = useParams();

  // States
  const [questionOnWait, setQuestionOnWait] = useState<string>("");
  const [chatState, setChatState] = useState<ConversationProps[]>([]);
  const [activeSession, setActiveSession] = useState("");
  const [, set_loadingSessions] = useState<Boolean>(false);

  // API
  const { fetchChatById, fetchSessions } = useChatBotAPI();

  // API - Fetch chat by id
  const {
    isLoading: loadingChat,
    data: chatData,
    error: chatError,
  } = useQuery({
    queryKey: ["chatbot-session", id],
    queryFn: fetchChatById,
    enabled: !!id,
  });

  useEffect(() => {
    if (chatData) {
      setChatState(chatData);
    }
  }, [chatData]);

  // API - Fetch all sessions
  const {
    isLoading: sessionsIsLoading,
    data: _sessions,
    error: sessionsError,
  } = useQuery({
    queryKey: ["chatbot-sessions"],
    queryFn: fetchSessions,
  });

  useEffect(() => {
    set_loadingSessions(sessionsIsLoading);
  }, [sessionsIsLoading]);

  // Display errors
  useEffect(() => {
    if (sessionsError) {
      toast({
        description: errorHandler(sessionsError).message,
        status: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsError]);

  // API
  const { onSubmit, loading: waitingOnBot } = useSubmit(
    useCallback(
      async (question: string) => {
        // if (!user) return;

        // display question in chat
        setQuestionOnWait(question);

        // request body containing user question and other user-related details
        const requestBody = {
          inputs: question,
          session_id: id ?? null,
          message_id: null,
        };

        // 1. id: if question being asked in a chat
        // 2. "new": if question being asked in new chat
        setActiveSession(id ?? "new");

        // to store llm bot reply
        let response: BotReplyProps = {};

        // LLM API call
        await generateBotResponse(requestBody)
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              response = error.response;
            }
          });

        // bot partial reply (paragraph only, without: sources, compounds, and followup questions)
        const reply =
          response?.generated_text ??
          response?.data?.generated_text ??
          apologiesMessage;

        // latest exchange containing question and complete bot reply
        const latestExchange: Partial<ConversationProps> = {
          messages: [{ human: question, ai: reply }],
          sources: response.sources ?? [],
          compounds: response.compounds ?? [],
          followup_questions: response.followup_questions ?? [],
        };

        // Update chat with response received from the server
        setChatState((prev: ConversationProps[]) => {
          const updatedChat = [...prev, latestExchange];
          id && queryClient.setQueryData(["chatbot-session", id], updatedChat);
          return updatedChat as ConversationProps[];
        });

        if (!id) {
          set_loadingSessions(true);
          await queryClient.invalidateQueries({
            queryKey: ["chatbot-sessions"],
          });
          set_loadingSessions(false);
        }

        setQuestionOnWait("");
        setActiveSession("");
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id]
    )
  );

  // handler
  function handleSendQuestion(qst: string) {
    // if valid question and no bot generation in progress, send question
    if (!waitingOnBot && qst.trim()) {
      onSubmit(qst);
    } else return;
  }

  useEffect(() => {
    if (_sessions) {
      if (_sessions?.length > 0) {
        location.pathname === "/chat" &&
          navigate(`/chat/${_sessions?.at(0)?.id}`);
      } else {
        navigate("/chat");
        setChatState([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sessions]);

  return (
    <Box w={"100%"}>
      <ChatbotContext.Provider
        value={{
          messages: chatState ?? [],
          sessions: _sessions ?? [],
          loadingSessions: sessionsIsLoading,
          loadingChat: loadingChat ?? false,
          chatError: chatError ?? undefined,
          waitingOnBot: waitingOnBot,
          activeSession: activeSession,
        }}
      >
        {/* route to new chat -> "chat/"
              route to existing chat -> "chat/<id>" */}
        <Outlet context={{ questionOnWait, handleSendQuestion }} />

        {/* send message */}
        {!loadingChat && !chatError && (
          <TextField onSendQuestion={handleSendQuestion} />
        )}
      </ChatbotContext.Provider>
    </Box>
  );
}
