import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

import {
  Flex,
  useTheme,
  useMediaQuery,
  Box,
  ChakraProvider,
  useColorMode,
} from "@chakra-ui/react";

// API
import Nav from "layouts/nav";
import Home from "views/home";
import Docs from "views/docs";

import LoginView from "views/auth/login";
import RegisterView from "views/auth/register";
import ChatbotView from "views/chatbot";

import DataLibrary from "views/library";
import Tools from "views/library/tools";

import PageNotFound from "views/PageNotFound";
import NewChatPanel from "components/chat/NewChatPanel";
import ChatMessages from "views/chatbot/ChatMessages";
import Feedback from "components/feedback/Feedback";

import PersistLogin from "components/authforms/PersistLogin";
import RequireAuth from "components/authforms/RequireAuth";

import Unauthorized from "views/Unauthorized";
import { ROLES } from "utils/roles";

import Team from "views/team";
import PostsPanel from "views/team/posts";
import MembersPanel from "views/team/members";

import UpdateChecker from "components/ui/UpdateChecker";
import EmbeddingLoading from "components/papers/suggestions/EmbeddingLoading";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";
import useFetchSavedElements from "hooks/research/useFetchSavedElements";
import MobileView from "views/MobileView";
import { GetGradients } from "utils/gradients";
import AuthLayout from "views/auth/AuthLayout";
import ResetPasswordView from "views/auth/resetPassword";
import generateTheme from "theme";
import TourGuide from "components/guide/TourGuide";
import SourceTable from "views/library/core/SourceTable";
import SourcesList from "views/library/core/SourcesList";
import CorePanel from "views/library/core";
import UserLibraryPanel from "views/library/research";
import FoldersList from "views/library/research/FoldersList";
import SourceItemDetails from "views/library/core/SourceItemDetails";
import HelpTourButton from "components/helpTour";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import useGuideTour from "hooks/guideTour/useGuideTour";
import PricingModal from "components/home/PricingModal";
import { ThemeProps } from "components/userMenu/Appearance";
import AcceptInvite from "views/team/acceptInvite";
import FolderContent from "components/research/folder/FolderContent";
import usePostWS, { TeamSocketContext } from "hooks/posts/usePostWS";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import ProprietaryPanel from "views/library/proprietary";
import ProprietarySourcesList from "views/library/proprietary/ProprietarySourcesList";
import ProprietarySourceDetails from "views/library/proprietary/ProprietarySourceDetails";
import ProprietarySourceTable from "views/library/proprietary/ProprietarySourceTable";

const loadRDKitScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};

function Layout({ onToggle, isDarkMode }: ThemeProps) {
  // Hooks
  const { checkForEmbeddingsInProgress } = useEmbeddingProcess();
  const { isGuideOpen, initialSeen: initialGuideSeen } = useSelector(
    selectCurrentGuideData
  );
  const { isHelpTourAvailable } = useGuideTour();

  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;
  const borderColor = isLight ? "transparent" : "dark.600";

  const state = window.localStorage.getItem("initialPricingSeen");
  const initialPricingSeen = state ? JSON.parse(state) : false;

  // State
  const [showPricingModal, setShowPricingModal] = useState(initialPricingSeen);

  useFetchSavedElements();

  // Team Chat Socket Connection Init
  const { user } = useSelector(selectCurrentAuthData);

  let { teamSocket, teamMessages, setTeamMessages, teamSocketConnected } =
    usePostWS({ teamId: user?.team_id });

  useEffect(() => {
    const initRDKit = async () => {
      try {
        // Load the RDKit script dynamically
        await loadRDKitScript(
          "https://unpkg.com/@rdkit/rdkit/dist/RDKit_minimal.js"
        );

        if (typeof window.initRDKitModule === "function") {
          const instance = await window.initRDKitModule();
          window.RDKit = instance;
        } else {
          throw new Error("initRDKitModule is not available on window");
        }
      } catch (error) {
        console.error("Failed to initialize RDKit:", error);
      }
    };

    initRDKit();
    checkForEmbeddingsInProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialGuideSeen && !initialPricingSeen) {
      setShowPricingModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGuideSeen]);

  return (
    <Flex
      position={"relative"}
      overflow={"hidden"}
      bgGradient={bgGradient}
      p={[null, null, null, 2, 3, 4]}
      h={"100vh"}
    >
      <Flex w={"100%"} gap={[null, null, null, 2, 3, 4]}>
        {/* navbar */}
        <Nav onToggle={onToggle} isDarkMode={isDarkMode} />

        {/* main panel */}
        <Flex
          position={"relative"}
          w={"100%"}
          borderRadius={"10px"}
          borderColor={borderColor}
          bg="background"
          borderWidth={1}
        >
          <TeamSocketContext.Provider
            value={{
              teamSocket: teamSocket,
              teamMessages: teamMessages,
              setTeamMessages: setTeamMessages,
              teamSocketConnected: teamSocketConnected,
            }}
          >
            <Outlet />
          </TeamSocketContext.Provider>
          {/* Embedding progress bar */}
          <EmbeddingLoading />
        </Flex>
      </Flex>

      <Feedback />

      {isHelpTourAvailable() && <HelpTourButton />}

      <UpdateChecker />

      {/* Tour guide */}
      {isGuideOpen && <TourGuide />}

      {/* display one-time pricing modal post-registration */}
      {!initialPricingSeen && (
        <PricingModal
          isOpen={showPricingModal}
          onClose={() => {
            window.localStorage.setItem(
              "initialPricingSeen",
              JSON.stringify(true)
            );
            setShowPricingModal(false);
          }}
        />
      )}
    </Flex>
  );
}

function AppWrapper() {
  // Hooks
  const location = useLocation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  const hiddenPathsOnSmallScreen = [
    "/chat",
    "/models",
    "/data",
    "/docs",
    "/login",
    "/reset-password",
  ];

  const isHiddenOnSmallScreen = hiddenPathsOnSmallScreen.some((path) =>
    location?.pathname?.startsWith(path)
  );

  if (isMobileView && isHiddenOnSmallScreen)
    return <MobileView isMobileView={isMobileView} />;

  const isVisible = !isMobileView || !isHiddenOnSmallScreen;

  return (
    <Box visibility={isVisible ? "visible" : "hidden"}>
      <Outlet />
    </Box>
  );
}

export default function AppRoutes() {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const { toggleColorMode } = useColorMode();
  const theme = generateTheme(colorMode);
  const { user } = useSelector(selectCurrentAuthData);

  // State
  const [isDarkEnabled, setIsDarkEnabled] = useState(colorMode === "dark");

  // Handlers
  function handleToggleTheme() {
    setIsDarkEnabled((c: boolean) => !c);
    toggleColorMode();
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<AppWrapper />}>
            {/* Public routes */}
            <Route element={<PersistLogin />}>
              <Route index element={<Home />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="docs" element={<Docs />} />

              {/* Auth */}
              <Route element={<AuthLayout />}>
                <Route path="login" element={<LoginView />} />
                <Route path="register" element={<RegisterView />} />
                <Route path="reset-password" element={<ResetPasswordView />} />
              </Route>

              {/* Protected routes */}
              <Route
                element={
                  <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
                }
              >
                <Route
                  element={
                    <Layout
                      // remove toggle here
                      onToggle={handleToggleTheme}
                      isDarkMode={isDarkEnabled}
                    />
                  }
                >
                  {/* data library routes */}
                  <Route path="data" element={<DataLibrary />}>
                    {/* Index */}
                    <Route path="" element={<CorePanel />}>
                      <Route index element={<SourcesList />} />
                    </Route>

                    {/* CORE */}
                    <Route path="core" element={<CorePanel />}>
                      <Route index element={<SourcesList />} />
                      <Route path=":source" element={<SourceTable />} />
                      <Route
                        path=":source/:sourceItemId"
                        element={<SourceItemDetails />}
                      />
                    </Route>

                    {/* MODELS */}
                    <Route path="tools" element={<Tools />} />

                   {/* PROPRIETARY */}
                   <Route path="proprietary" element={<ProprietaryPanel />}>
                      <Route index element={<ProprietarySourcesList />} />
                      <Route path=":source" element={<ProprietarySourceTable />} />
                      <Route path=":source/:sourceItemId" element={<ProprietarySourceDetails />} />
                    </Route>

                    {/* LIBRARY */}
                    <Route path="research" element={<UserLibraryPanel />}>
                      <Route index element={<FoldersList />} />
                      <Route path=":folderId" element={<FolderContent />} />
                    </Route>
                  </Route>

                  {/* chat routes */}
                  <Route path="chat/" element={<ChatbotView />}>
                    <Route index element={<NewChatPanel />} />
                    <Route path=":id" element={<ChatMessages />} />
                  </Route>

                  {/* Teams routes */}
                  <Route path="team" element={<Team />}>
                    {/* MEMBERS */}
                    <Route path="" element={<MembersPanel />} />

                    {/* LIBRARY */}
                    <Route path="folders" element={<UserLibraryPanel />}>
                      <Route index element={<FoldersList />} />
                      <Route path=":folderId" element={<FolderContent />} />
                    </Route>

                    {/* POSTS */}
                    <Route path="posts" element={<PostsPanel />} />

                    {/* TEAM INVITES */}
                    <Route path="accept-invite" element={<AcceptInvite />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
