import { Flex, useMediaQuery } from "@chakra-ui/react";
import NavItem from "./NavItem";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { TbLayoutGrid } from "react-icons/tb";

import { useEffect, useState } from "react";
import MobileNavItem from "./MobileNavItem";
import { GetGradients } from "utils/gradients";

function Menu() {
  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // theme
  const { lGradient2 } = GetGradients();

  // States
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeNav, setActiveNav] = useState("#home");

  // Handler
  function controlNavbar() {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  return (
    <>
      {isMobileView ? (
        // Mobile nav menu
        <Flex
          bg={"whiteAlpha.900"}
          bgGradient={lGradient2}
          boxShadow={{
            base: "unset",
            sm: "rgba(0, 0, 0, 0.2) 0px 3px 8px",
          }}
          justify={"center"}
          w={{ base: "100%", sm: "max-content" }}
          px={{ base: 1.5, sm: 7 }}
          pb={{ base: 1.5, sm: 3 }}
          pt={1.5}
          zIndex={101}
          position={"fixed"}
          left={"50%"}
          bottom={show ? { base: 0, sm: "0.5rem" } : "-5rem"}
          transform={"translateX(-50%)"}
          gap={2}
          borderRadius={{ base: 0, sm: "50px" }}
          backdropFilter={"blur(6px)"}
          transition={"bottom 1s ease"}
        >
          <MobileNavItem
            to="#home"
            title={"Home"}
            icon={AiOutlineHome}
            onClick={() => setActiveNav("#home")}
            active={activeNav}
          />
          <MobileNavItem
            to="#about"
            title={"About"}
            icon={AiOutlineUser}
            onClick={() => setActiveNav("#about")}
            active={activeNav}
          />
          <MobileNavItem
            to="#pipeline"
            title={"Pipeline"}
            icon={TbLayoutGrid}
            onClick={() => setActiveNav("#pipeline")}
            active={activeNav}
          />
          <MobileNavItem
            to="#faq"
            title={"FAQ"}
            icon={GrCircleQuestion}
            onClick={() => setActiveNav("#faq")}
            active={activeNav}
          />
          <MobileNavItem
            to="#contact"
            title={"Contact"}
            icon={BiMessageSquareDetail}
            onClick={() => setActiveNav("#contact")}
            active={activeNav}
          />
        </Flex>
      ) : (
        // large screen nav menu
        <Flex gap={6} justify="center" align="center">
          <NavItem to="#about" text="About" />
          <NavItem to="#pipeline" text="Pipeline" />
          <NavItem to="#faq" text="FAQ" />
          <NavItem to="#contact" text="Contact Us" />
        </Flex>
      )}
    </>
  );
}

export default Menu;
