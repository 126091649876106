import {
  AssayProps,
  DetectionConditionsProps,
  DrugIncubationConditionsProps,
} from "models/assays/AssayProps";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

function propertiesFormat(inputString: string): string {
  const words = inputString.split("_");
  const transformedWords = words.map((word, index) =>
    index > 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  );
  return transformedWords.join(" ");
}
interface DetailedProtocolProps {
  assay: AssayProps;
}
export function DetailedProtocol({ assay }: DetailedProtocolProps) {
  return (
    <>
      <Text mt={5}>{assay?.protocol.description}</Text>

      {Object.entries(assay?.protocol)?.map(
        (
          [key, value]: [
            string,
            DrugIncubationConditionsProps | DetectionConditionsProps | string
          ],
          index: number
        ) => {
          if (key === "description") {
            return null;
          }

          const protocolValue:
            | DrugIncubationConditionsProps
            | DetectionConditionsProps
            | undefined = typeof value === "string" ? undefined : value;

          return (
            <Box borderBottom={1} borderStyle="dashed" mt={7} key={index}>
              <Heading size="md" color="primary.400" mt={4} mb={4} pl={4}>
                {key === "drug_incubation_conditions"
                  ? "Drug incubation conditions"
                  : "Detection conditions"}
              </Heading>
              <Flex pl={4}>
                <Box w="70%" pr={4} pb={4}>
                  <Text>{protocolValue?.text}</Text>
                </Box>
                <Box flex="1" p={4} bg={"primary.200"} color={"primary.700"}>
                  {protocolValue?.overview &&
                    Object.entries(protocolValue?.overview).map(
                      ([subKey, subValue]: [string, string]) => (
                        <Text key={subKey}>
                          <b>{propertiesFormat(subKey)}: </b>
                          {subValue}
                        </Text>
                      )
                    )}
                </Box>
              </Flex>
            </Box>
          );
        }
      )}
    </>
  );
}
