export const colors = {
  background: "#0b2e3e", //"dark.700"
  lightBackground: "#193e4e",

  primary: {
    100: "#F5FEFF",
    200: "#B0DBDD",
    300: "#6DAFB3",
    400: "#367D80",
    500: "#156064",
    600: "#06575B",
    700: "#004044",
    800: "#003033",
    900: "#001618",
  },
  secondary: {
    100: "#041B2A",
    200: "#093654",
    300: "#0D4C76",
    400: "#166AA2",
    500: "#1A86CE",
    600: "#4196CE",
    700: "#66AAD8",
    800: "#A2CEEB",
    900: "#D5EEFF",
  },
  neutral: {
    900: "#ffffff",
    800: "#F5F5F5",
    700: "#EAEAEA",
    600: "#E0E0E0",
    500: "#D6D6D6",
    400: "#CACACA",
    300: "#BEBEBE",
    200: "#B1AFAF",
    100: "#696969",
  },
  dark: {
    900: "#1E1502",
    800: "#305768",
    700: "#193e4e",
    600: "#0b2e3e",
    500: "#042230",
    400: "#031d2a",
  },

  highlight: {
    primary: "#00C1B4",
    neutral: {
      100: "#F3F3F7",
      200: "#EBF0F9",
    },
  },

  gradient: {
    100: "#F3F3F7",
    200: "#EBF0F9",
  },

  none: "#D9594C",
  low: "#66AAD8",
  middle: "#367D80",
  high: "#198B7C",

  cosmiclatte: "#FFF6E1",
  gold: "#DDB967",
  bronze: "#DD8232",
  jasper: "#D9594C",

  gray: {
    50: "#171923",
    100: "#1a202c",
    200: "#2d3748",
    300: "#4a5568",
    400: "#718096",
    500: "#a0aec0",
    600: "#cbd5e0",
    700: "#e2e8f0",
    800: "#edf2f7",
    900: "#f7fafc",
  },
  red: {
    50: "#5a2424",
    100: "#742a2a",
    200: "#9b2c2c",
    300: "#c53030",
    400: "#e53e3e",
    500: "#f56565",
    600: "#fc8181",
    700: "#feb2b2",
    800: "#fed7d7",
    900: "#fff5f5",
  },
  orange: {
    50: "#652b19",
    100: "#7b341e",
    200: "#9c4221",
    300: "#c05621",
    400: "#dd6b20",
    500: "#ed8936",
    600: "#f6ad55",
    700: "#fbd38d",
    800: "#feebc8",
    900: "#fffaf0",
  },
  yellow: {
    50: "#5f370e",
    100: "#744210",
    200: "#975a16",
    300: "#b7791f",
    400: "#d69e2e",
    500: "#ecc94b",
    600: "#f6e05e",
    700: "#faf089",
    800: "#fefcbf",
    900: "#fffff0",
  },
  green: {
    50: "#1c4532",
    100: "#22543d",
    200: "#276749",
    300: "#2f855a",
    400: "#38a169",
    500: "#48bb78",
    600: "#68d391",
    700: "#9ae6b4",
    800: "#c6f6d5",
    900: "#f0fff4",
  },
  teal: {
    50: "#1d4044",
    100: "#234e52",
    200: "#285e61",
    300: "#2c7a7b",
    400: "#319795",
    500: "#38b2ac",
    600: "#4fd1c5",
    700: "#81e6d9",
    800: "#b2f5ea",
    900: "#e6fffa",
  },
  blue: {
    50: "#1A365D",
    100: "#2a4365",
    200: "#2c5282",
    300: "#2b6cb0",
    400: "#3182ce",
    500: "#4299e1",
    600: "#63b3ed",
    700: "#90cdf4",
    800: "#bee3f8",
    900: "#ebf8ff",
  },
  indigo: {
    50: "#2d2b55",
    100: "#3c366b",
    200: "#434190",
    300: "#4c51bf",
    400: "#5a67d8",
    500: "#667eea",
    600: "#7f9cf5",
    700: "#a3bffa",
    800: "#c3dafe",
    900: "#ebf4ff",
  },
  purple: {
    50: "#322659",
    100: "#44337a",
    200: "#553c9a",
    300: "#6b46c1",
    400: "#805ad5",
    500: "#9f7aea",
    600: "#b794f4",
    700: "#d6bcfa",
    800: "#e9d8fd",
    900: "#faf5ff",
  },
  pink: {
    50: "#521B41",
    100: "#702459",
    200: "#97266d",
    300: "#b83280",
    400: "#d53f8c",
    500: "#ed64a6",
    600: "#f687b3",
    700: "#fbb6ce",
    800: "#fed7e2",
    900: "#fff5f7",
  },
};
