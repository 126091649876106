import {
  Flex,
  Heading,
  Icon,
  Image,
  Tag,
  TagLabel,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { hexToRgba } from "utils/helpers";

interface PipeStepProps {
  step: number;
  icon: IconType;
  title: string;
  text: string;
}

interface StepIconProps {
  step: number;
  icon: IconType;
}

export default function PipeStep({ step, title, text, icon }: PipeStepProps) {
  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const color = colorMode === "light" ? "blue.100" : "blue.800";

  return (
    <Flex
      direction={"column"}
      gap={4}
      w={["100%", "80%", "80%", "100%", "100%", "100%"]}
      maxW={["100%", "360px", "100%", "100%", "100%", "100%"]}
      mx={["auto", "auto", "auto", "0", "0", "0"]}
    >
      {/* Heading illustration */}
      <Flex justify={"center"} position="relative">
        <Image
          src={`https://reacto.s3.eu-central-1.amazonaws.com/images/b${step}.png`}
          h={["60px", "70px", "80px", "100px", "130px", "150px"]}
          objectFit={"cover"}
        />

        <StepIcon step={step} icon={icon} />
        <StepTag step={step} />
      </Flex>

      {/* Heading */}

      <Heading
        fontSize={{ base: "14px", lg: "16px" }}
        fontFamily={"Poppins, sans-serif"}
        color={color}
        opacity={0.9}
        textTransform={"uppercase"}
        w={{ base: "95%", lg: "90%" }}
        mx={"auto"}
        py={"auto"}
        letterSpacing={"0.02rem"}
        minH={"60px"}
        verticalAlign={"middle"}
        textAlign={"center"}
      >
        {title}
      </Heading>

      {/* Text */}
      <Flex
        direction={"column"}
        textAlign={"center"}
        h={["340px", "340px", "340px", "340px", "360px", "370px"]}
        borderWidth={1}
        borderColor={hexToRgba(colors.highlight.neutral[200], 0.25)}
        borderRadius={"8px"}
        bg={hexToRgba(colors.secondary[500], 0.1)}
        px={2}
      >
        <Text
          color={color}
          w={"93%"}
          m={"auto"}
          textAlign={"center"}
          fontSize={{ base: "14px", lg: "15px", xl: "16px" }}
          lineHeight={"1.4"}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
}

function StepIcon({ step, icon }: StepIconProps) {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const orange = colorMode === "light" ? "orange.100" : "orange.800";
  const secondary = colorMode === "light" ? "secondary.100" : "secondary.900";

  return (
    <Icon
      position={"absolute"}
      top={[
        "calc(50% - 12px)",
        "calc(50% - 13px)",
        "calc(50% - 14px)",
        "calc(50% - 16px)",
        "calc(50% - 19px)",
        "calc(50% - 23px)",
      ]}
      left={[
        "calc(50% - 12px)",
        "calc(50% - 13px)",
        "calc(50% - 14px)",
        "calc(50% - 16px)",
        "calc(50% - 19px)",
        "calc(50% - 23px)",
      ]}
      as={icon}
      boxSize={["24px", "26px", "28px", "32px", "38px", "46px"]}
      color={step === 1 ? "primary.200" : step === 2 ? orange : secondary}
    />
  );
}

function StepTag({ step }: Omit<StepIconProps, "icon">) {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const teal100 = colorMode === "light" ? "teal.100" : "teal.800";
  const teal400 = colorMode === "light" ? "teal.400" : "teal.500";
  const orange = colorMode === "light" ? "orange.100" : "orange.800";
  const orange400 = colorMode === "light" ? "orange.400" : "orange.500";
  const blue = colorMode === "light" ? "blue.100" : "blue.800";

  return (
    <Tag
      size={{ base: "sm", xl: "md" }}
      borderRadius="full"
      variant="solid"
      bg={step === 1 ? teal100 : step === 2 ? orange : blue}
      position={"absolute"}
      top={{ base: 2, lg: 4 }}
      left={{
        base: "calc(50% + 13px)",
        sm: "calc(50% + 14px)",
        lg: "calc(50% + 19px)",
      }}
    >
      <TagLabel>
        <Text
          color={
            step === 1 ? teal400 : step === 2 ? orange400 : "secondary.400"
          }
        >
          STEP {step}
        </Text>
      </TagLabel>
    </Tag>
  );
}
