import React, { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Flex,
  useTheme,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { GetGradients } from "utils/gradients";
import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/auth/authSlice";
import { hexToRgba } from "utils/helpers";

import UpdateChecker from "components/ui/UpdateChecker";
import { verifyCode } from "services/user.service";

export default function ResetPasswordForm() {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { colors } = useTheme();

  // States
  const [stage, setStage] = useState(1); // 1: Forgot Password, 2: Verification Code, 3: Reset Password
  const [formData, setFormData] = useState({
    username: "",
    newPassword: "",
    confirmNewPassword: "",
    verificationCode: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  // Theme
  const { appTitleGradient } = GetGradients();

  // Handlers
  const handleInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: "PASSWORD" | "CONFIRM_PASSWORD") => {
    if (pass === "PASSWORD") {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password,
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setErrorMsg("");
    const { username, newPassword, confirmNewPassword, verificationCode } =
      formData;
    let response = null;
    let message = "";

    try {
      if (stage === 1) {
        if (!username) return;

        // Request to send verification code
        response = await axiosClient.post(
          "/api/forgot_password",
          JSON.stringify({ username }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        message =
          "Verification code sent successfully. Please check your email.";
        setStage(2);
      } else if (stage === 2) {
        setIsVerifying(true);
        // Verify the code
        if (!verificationCode || verificationCode.length !== 6) {
          setErrorMsg("Invalid verification code");
          setIsVerifying(false);
          return;
        }

        const payload = { verificationCode, username };

        await verifyCode(payload)
          .then((res) => {
            console.log(res);
            setIsVerifying(false);
            setStage(3);
          })
          .catch((error) => {
            console.log(error);
            toast({
              description: error.response.data.message
                ? error.response.data.message
                : "An error occured while verifying code",
              status: "error",
              position: "top-right",
            });
            setIsVerifying(false);
          });
      } else {
        //? Resetting new password
        if (!username || !newPassword) return;

        const passwordError = isPasswordValid(newPassword, confirmNewPassword);
        if (passwordError) {
          setErrorMsg(passwordError);
          setSubmitting(false);
          return;
        }

        response = await axiosClient.post(
          "/api/reset_password",
          JSON.stringify({
            username,
            newPassword,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        message = "Your password is reset successfully";

        const { accessToken, user, roles } = response?.data;

        dispatch(setCredentials({ user, accessToken, roles }));
      }
      message &&
        toast({
          position: "bottom-right",
          duration: 3000,
          render: () => (
            <Box
              color="white"
              p={3}
              bg={"highlight.primary"}
              borderRadius={"6px"}
            >
              {message}
            </Box>
          ),
        });
    } catch (error: any) {
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderInputs1 = (
    <FormControl id="username" w="100%">
      <FormLabel
        fontSize={"14px"}
        color={"black"}
        opacity={0.75}
        lineHeight={"1.3"}
      >
        Username
      </FormLabel>
      <Input
        type="text"
        name="username"
        value={formData.username}
        placeholder="Enter your username"
        onKeyDown={handleKeyDown}
        onChange={handleInputsChange}
        _placeholder={{
          fontSize: "14px",
          color: hexToRgba(colors.black, 0.55),
        }}
        borderColor={"neutral.700"}
        color={"black"}
        w={"100%"}
        _focus={{
          outline: "none",
          boxShadow: "none",
          borderColor: hexToRgba(colors.black, 0.25),
          bg: hexToRgba(colors.black, 0.03),
        }}
        _hover={{
          outline: "none",
          boxShadow: "none",
          borderColor: hexToRgba(colors.black, 0.25),
        }}
      />
    </FormControl>
  );

  const renderInputs2 = (
    <FormControl id="verificationCode">
      <FormLabel
        fontSize={"14px"}
        color={"black"}
        opacity={0.75}
        lineHeight={"1.3"}
      >
        Verification Code
      </FormLabel>
      <Input
        type="text"
        name="verificationCode"
        placeholder="Enter verification code"
        value={formData.verificationCode}
        onChange={handleInputsChange}
        onKeyDown={handleKeyDown}
        _placeholder={{
          fontSize: "14px",
          color: hexToRgba(colors.black, 0.55),
        }}
        borderColor={"neutral.700"}
        color={"black"}
        w={"100%"}
        _focus={{
          outline: "none",
          boxShadow: "none",
          borderColor: hexToRgba(colors.black, 0.25),
          bg: hexToRgba(colors.black, 0.03),
        }}
        _hover={{
          outline: "none",
          boxShadow: "none",
          borderColor: hexToRgba(colors.black, 0.25),
        }}
      />
    </FormControl>
  );

  const renderInputs3 = (
    <>
      <FormControl id="newPassword" w="100%">
        <FormLabel
          fontSize={"14px"}
          color={"black"}
          opacity={0.75}
          lineHeight={"1.3"}
        >
          New Password
        </FormLabel>
        <InputGroup>
          <Input
            type={showPassword.password ? "text" : "password"}
            name="newPassword"
            placeholder="Enter your new password"
            value={formData.newPassword}
            onChange={handleInputsChange}
            _placeholder={{
              fontSize: "14px",
              color: hexToRgba(colors.black, 0.55),
            }}
            borderColor={"neutral.700"}
            color={"black"}
            w={"100%"}
            _focus={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
              bg: hexToRgba(colors.black, 0.03),
            }}
            _hover={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
            }}
          />
          <InputRightElement>
            <Icon
              as={showPassword.password ? ViewOffIcon : ViewIcon}
              name="password"
              color="secondary.500"
              cursor="pointer"
              onClick={() => handlePasswordToggle("PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="confirmNewPassword" w="100%">
        <FormLabel
          fontSize={"14px"}
          color={"black"}
          opacity={0.75}
          lineHeight={"1.3"}
        >
          Confirm New Password
        </FormLabel>
        <InputGroup>
          <Input
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmNewPassword"
            placeholder="Confirm your new password"
            value={formData.confirmNewPassword}
            onKeyDown={handleKeyDown}
            onChange={handleInputsChange}
            _placeholder={{
              fontSize: "14px",
              color: hexToRgba(colors.black, 0.55),
            }}
            borderColor={"neutral.700"}
            color={"black"}
            w={"100%"}
            _focus={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
              bg: hexToRgba(colors.black, 0.03),
            }}
            _hover={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
            }}
          />
          <InputRightElement>
            <Icon
              as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
              name="confirmPassword"
              color="secondary.500"
              cursor="pointer"
              onClick={() => handlePasswordToggle("CONFIRM_PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </>
  );

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={4}
      p={10}
      borderRadius={"10px"}
      bg={hexToRgba(colors.white, 0.95)}
      boxShadow={"0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"}
      backdropFilter={"blur( 4.5px)"}
      borderWidth={1}
      borderColor={hexToRgba(colors.black, 0.18)}
    >
      <UpdateChecker />
      <Heading fontSize="26px" color={hexToRgba(colors.black, 0.7)} mb={"30px"}>
        Welcome to{" "}
        <Text
          as="span"
          fontWeight={"bold"}
          bgGradient={appTitleGradient}
          bgClip="text"
        >
          MoleculeLake
        </Text>
      </Heading>

      <Text fontSize="sm" color="black" opacity={0.85}>
        {stage === 2
          ? "Enter verification code"
          : stage === 3
          ? "Choose another password"
          : "Please enter your username or email"}
      </Text>

      {/* Inputs */}
      {stage === 2
        ? renderInputs2
        : stage === 3
        ? renderInputs3
        : renderInputs1}

      {/* Error message */}
      {errorMsg && (
        <Text fontSize="sm" color="jasper">
          {errorMsg}
        </Text>
      )}

      {/* Submit button */}
      <Button
        colorScheme="secondary"
        size="md"
        onClick={handleSubmit}
        w="100%"
        isLoading={submitting || isVerifying}
        loadingText="Processing"
      >
        {stage === 2
          ? "Continue"
          : stage === 3
          ? "Reset password"
          : "Get verification code"}
      </Button>
    </Flex>
  );
}
