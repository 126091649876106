import { useState } from "react";
import { Flex, Icon, Text, useTheme } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import Loading from "components/ui/Loading";
import { SavedFolderProps } from "models/research/SavedProps";

import { FaRegTrashAlt } from "react-icons/fa";
import { BsFolderCheck } from "react-icons/bs";

interface FoldersItemProps {
  folder: SavedFolderProps;
  deleting?: boolean;
  onDeleteFolder: () => void;
  onClick: () => void;
}

export default function FoldersItem({
  folder,
  deleting,
  onDeleteFolder,
  onClick,
}: FoldersItemProps) {
  // State
  const [show, setShow] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();

  const { name, literatures: literature, compounds, assays } = folder;

  const countElements =
    (assays?.length ?? 0) +
    (compounds?.length ?? 0) +
    (literature?.length ?? 0);

  return (
    <Flex
      position={"relative"}
      align={"center"}
      color={"gray.600"}
      _hover={{
        color: "blue.500",
        bg: hexToRgba(colors.blue[300], 0.15),
        cursor: "pointer",
      }}
      p={4}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      borderRadius={"8px"}
      bg={"background"}
      gap={2}
      border={`1px solid ${hexToRgba(colors.secondary[100], 0.5)}`}
      onClick={onClick}
      pointerEvents={deleting ? "none" : "auto"}
    >
      <Icon
        bg={hexToRgba(colors.blue[300], 0.15)}
        as={BsFolderCheck}
        color={"secondary.400"}
        borderRadius={"8px"}
        p={2}
        boxSize={[null, null, null, "40px", "42px", "44px"]}
      />

      {/* folder name */}
      <Flex
        direction={"column"}
        gap={1}
        w={"100%"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        <Text
          fontSize={"14px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          w={"95%"}
          lineHeight={"1.3"}
        >
          {name}
        </Text>
        <Text fontSize={"12px"} color={"neutral.800"} lineHeight={1.2}>
          {countElements} saved elements
        </Text>
      </Flex>

      {/* delete folder item */}
      {show && (
        <Flex
          position={"absolute"}
          right={3}
          top={"50%"}
          transform={"translateY(-50%)"}
        >
          {deleting ? (
            <Loading small />
          ) : (
            <Icon
              onClick={(e) => {
                e.stopPropagation(); // Prevent the Box from being triggered
                onDeleteFolder();
              }}
              cursor={"pointer"}
              as={FaRegTrashAlt}
              boxSize={"14px"}
              color={"gray.500"}
              _hover={{ color: "red.500" }}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}
