import { Flex, Text } from "@chakra-ui/react";
import { PaperProps } from "models/papers/PaperProps";
import PaperType from "./PaperType";

interface TypeAndYearProps {
  data: PaperProps;
}

export default function TypeAndYear({ data }: TypeAndYearProps) {
  return (
    <Flex
      justify={"space-between"}
      align={"center"}
      w={"full"}
      pb={2}
      mb={2}
      borderBottomColor={"gray.200"}
      borderBottomWidth={1}
    >
      {/* paper type */}
      <PaperType type={data?.paper_type ?? "Unclassified"} />

      {/* publication year */}
      <Text
        color={"gray.500"}
        fontSize={[null, null, null, "12px", "12px", "14px"]}
      >
        {data.year_published || null}
      </Text>
    </Flex>
  );
}
