import { Dispatch, SetStateAction, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Flex,
  Icon,
  SimpleGrid,
  Button,
  Divider,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { axiosClient } from "api/axios";

import {
  selectCurrentAuthData,
  setCredentials,
  updateUserAttributes,
} from "redux/features/auth/authSlice";
import { environment } from "environments";

import CurrentPlan from "./CurrentPlan";
import Appearance from "./Appearance";
import UserAvatar from "components/ui/UserAvatar";
import EmailInput from "components/authforms/userDetailsInputs/EmailInput";
import LastNameInput from "components/authforms/userDetailsInputs/LastNameInput";
import FirstNameInput from "components/authforms/userDetailsInputs/FirstNameInput";

import { IoMdSettings } from "react-icons/io";
import { RxUpdate } from "react-icons/rx";
import PasswordInput from "components/authforms/userDetailsInputs/PasswordInput";
import {
  containsNumber,
  containsSpecialCharacter,
  containsUppercase,
  hasLowercase,
  validateEmail,
} from "views/auth/helpers";

interface AccountSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  themeOnToggle: () => void;
  themeIsChecked: boolean;
}

interface UserDataProps {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export interface UserErrorsProps {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export default function AccountSettingsModal({
  isOpen,
  onClose,
  themeOnToggle,
  themeIsChecked,
}: AccountSettingsModalProps) {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  const u_firstname = user?.given_name ?? "";
  const u_lastname = user?.family_name ?? "";
  const u_email = user?.email ?? "";
  const u_password = "";

  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  // State
  const [isUpdating, setIsUpdating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [userData, setUserData] = useState<UserDataProps>({
    firstname: u_firstname || "",
    lastname: u_lastname || "",
    email: u_email || "",
    password: u_password || "",
  });

  const [errors, setErrors] = useState<UserErrorsProps>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  // Handlers
  function handleChangeFirstName(input: string) {
    setUserData((prev) => ({ ...prev, firstname: input }));
  }

  function handleChangeLastName(input: string) {
    setUserData((prev) => ({ ...prev, lastname: input }));
  }

  function handleChangeEmail(input: string) {
    setUserData((prev) => ({ ...prev, email: input }));
  }

  function handleChangePassword(input: string) {
    setUserData((prev) => ({ ...prev, password: input }));
  }

  function handlePasswordVisibility() {
    setShowPassword((s) => !s);
  }

  function checkUserDataValidation(
    userData: UserDataProps,
    setErrors: Dispatch<SetStateAction<UserErrorsProps>>
  ) {
    const { firstname, lastname, email, password } = userData;

    // set error if first name is unvalid
    const isUnvalidFisrtName = firstname?.trim().length < 3;
    setErrors((prev: UserErrorsProps) => ({
      ...prev,
      firstname: isUnvalidFisrtName
        ? "Needs to be 8 characters long at least"
        : "",
    }));

    // set error if last name is unvalid
    const isUnvalidLastName = lastname?.trim().length < 3;
    setErrors((prev: UserErrorsProps) => ({
      ...prev,
      lastname: isUnvalidLastName
        ? "Needs to be 8 characters long at least"
        : "",
    }));

    // set error if email is unvalid
    const isUnvalidEmail = !validateEmail(email);
    setErrors((prev: UserErrorsProps) => ({
      ...prev,
      email: isUnvalidEmail ? "Needs to be a valid email" : "",
    }));

    function validatePassword(s: string) {
      const lengthCond = s?.length >= 8;
      const upperCaseCond = containsUppercase(s);
      const lowerCaseCond = hasLowercase(s);
      const numberCond = containsNumber(s);
      const nonAlphaNumCond = containsSpecialCharacter(s);

      if (
        !!lengthCond &&
        !!upperCaseCond &&
        !!lowerCaseCond &&
        !!numberCond &&
        !!nonAlphaNumCond
      )
        return true;

      if (!lengthCond) {
        setErrors((prev: UserErrorsProps) => ({
          ...prev,
          password: "Needs to be 8 letters long at least",
        }));
        return false;
      } else if (!upperCaseCond) {
        setErrors((prev: UserErrorsProps) => ({
          ...prev,
          password: "Needs to contain at least 1 uppercase letter",
        }));
        return false;
      } else if (!lowerCaseCond) {
        setErrors((prev: UserErrorsProps) => ({
          ...prev,
          password: "Needs to contain at least 1 lowercase letter",
        }));
        return false;
      } else if (!numberCond) {
        setErrors((prev: UserErrorsProps) => ({
          ...prev,
          password: "Needs to contain at least 1 number",
        }));
        return false;
      } else if (!nonAlphaNumCond) {
        setErrors((prev: UserErrorsProps) => ({
          ...prev,
          password: "Needs to contain at least 1 special letter",
        }));
        return false;
      }
    }
    // set error if password is unvalid
    const isUnvalidPassword = !validatePassword(password);

    return (
      !isUnvalidFisrtName &&
      !isUnvalidLastName &&
      !isUnvalidEmail &&
      !isUnvalidPassword
    );
  }

  function isFormDataUnchanged(newUser: UserDataProps) {
    const isUnchanged =
      u_firstname === newUser?.firstname &&
      u_lastname === newUser?.lastname &&
      u_email === newUser?.email &&
      u_password === newUser?.password;

    return isUnchanged;
  }

  async function handleUpdateUserDetails() {
    // true if firstname, lastname, and email are valid
    const isValidFormData = checkUserDataValidation(userData, setErrors);

    // if form data is invalid, don't send request
    if (!isValidFormData) return;

    // if user doesn't make any changes
    const isUnchagedFormData = isFormDataUnchanged(userData);

    if (isUnchagedFormData) {
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box color="white" p={3} bg={"secondary.600"} borderRadius={"6px"}>
            No changes to update
          </Box>
        ),
      });
      return;
    }

    setIsUpdating(true);

    const attributes = {
      given_name: userData?.firstname,
      family_name: userData?.lastname,
      email: userData?.email,
    };

    try {
      await axiosPrivate.put(`${environment.BACKEND_API}/api/profile`, {
        attributes,
      });

      const resetPasswordResponse = await axiosClient.post(
        "/api/reset_password",
        JSON.stringify({
          username: userData?.email,
          newPassword: userData?.password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const { accessToken, user, roles } = resetPasswordResponse?.data;

      dispatch(updateUserAttributes(attributes));
      dispatch(setCredentials({ user, accessToken, roles }));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Profile updated successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box color={"white"} p={3} bg={"red"} borderRadius={"6px"}>
            Failed to update profile, try again
          </Box>
        ),
      });
    }

    setIsUpdating(false);
    setErrors({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    });
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleUpdateUserDetails();
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "640px" }}
        maxW={{ base: "90%", lg: "640px" }}
        h={"650px"}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            Account Settings
          </Text>

          {/* avatar & name */}
          <UserAvatar small />
        </ModalHeader>

        <ModalBody px={8} py={8} width={"100%"}>
          <Flex gap={10} direction={"column"} color={"secondary.700"}>
            {/* General section */}
            <Flex gap={2} direction={"column"}>
              <Flex gap={2} align={"center"}>
                <Icon as={IoMdSettings} boxSize={"18px"} opacity={0.8} />
                <Text fontWeight={"600"} lineHeight={1}>
                  General
                </Text>
              </Flex>

              <Divider my={1} />

              <SimpleGrid templateColumns={"repeat(2, 1fr)"} columnGap={6}>
                <FirstNameInput
                  error={errors?.firstname}
                  value={userData?.firstname}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeFirstName}
                />
                <LastNameInput
                  error={errors?.lastname}
                  value={userData?.lastname}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeLastName}
                />
                <EmailInput
                  error={errors?.email}
                  value={userData?.email}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeEmail}
                />
                <PasswordInput
                  error={errors?.password}
                  value={userData?.password}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangePassword}
                  showPassword={showPassword}
                  onChangeVisibility={handlePasswordVisibility}
                />
              </SimpleGrid>

              <Button
                type={"submit"}
                borderRadius={"30px"}
                bg={"highlight.primary"}
                color={"background"}
                h={"fit-content"}
                w={"fit-content"}
                py={3}
                px={6}
                fontSize={"14px"}
                fontWeight={"500"}
                isLoading={isUpdating}
                loadingText={"Updating..."}
                leftIcon={<RxUpdate size={"16px"} />}
                letterSpacing={".02rem"}
                _active={{ bg: "highlight.primary" }}
                _hover={{ bg: "highlight.primary" }}
                _focus={{ bg: "highlight.primary" }}
                _focusWithin={{ bg: "highlight.primary" }}
                onClick={handleUpdateUserDetails}
              >
                Update
              </Button>
            </Flex>

            {/* Subscription Plan section */}
            <CurrentPlan user={user} />

            {/* Appearance section - Theme */}
            <Appearance isDarkMode={themeIsChecked} onToggle={themeOnToggle} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
