import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { questions } from "./helpers";
import AccordionHeader from "components/ui/AccordionHeader";

export interface QuestionProps {
  title: string;
  description: string;
}

function Content() {
  return (
    <GridItem colSpan={2}>
      <Accordion w={"full"} allowToggle defaultIndex={0}>
        {questions &&
          questions?.map((q: QuestionProps, idx: number) => (
            <AccordionItem border={"none"} my={1} key={"faq-" + idx}>
              <AccordionHeader text={q?.title} isBold />
              <AccordionPanel pb={4} color={"black"} opacity={0.8}>
                <Flex direction={"column"} gap={4}>
                  <Text fontSize={{ base: "12px", lg: "14px" }}>
                    {q?.description}
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </GridItem>
  );
}

export default Content;
