import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { AssayHitsProps, ScreeningProps } from "models/assays/ScreeningProps";
import { indicationToColor } from "theme/helper";

interface AssayTableProps {
  assays: AssayHitsProps[] | ScreeningProps[];
  isHit?: boolean;
}

export default function AssaysTable({ assays, isHit }: AssayTableProps) {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th borderColor={"gray.100"} textAlign="left">
            Assay Type
          </Th>
          <Th borderColor={"gray.100"} textAlign="left">
            Indication
          </Th>
          {isHit ? (
            <>
              <Th borderColor={"gray.100"} textAlign="left">
                Activity Type
              </Th>
              <Th borderColor={"gray.100"} textAlign="left">
                Measurement
              </Th>
            </>
          ) : (
            <Th borderColor={"gray.100"} textAlign="left">
              Screening Int
            </Th>
          )}
          <Th borderColor={"gray.100"} textAlign="left">
            Assay Source
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {assays?.map((assay, index) => (
          <Tr key={"screening-collection" + index} fontSize={13}>
            <Td borderColor={"gray.100"} color={"secondary.600"}>
              {assay.name}
            </Td>
            <Td
              borderColor={"gray.100"}
              backgroundColor={indicationToColor(assay.indication)}
              p={2}
            >
              {assay.indication}
            </Td>
            {isHit && "activity_type" in assay ? (
              <>
                <Th borderColor={"gray.100"} textAlign="left">
                  {assay.activity_type}
                </Th>
                <Th borderColor={"gray.100"} textAlign="left">
                  {assay.measurement}
                </Th>
              </>
            ) : (
              "screening_hit" in assay && (
                <Td borderColor={"gray.100"} pr={1}>
                  <Text
                    {...(assay.screening_hit === "primary screening hit"
                      ? { as: "b" }
                      : {})}
                  >
                    {assay.screening_hit}
                  </Text>
                </Td>
              )
            )}
            <Td borderColor={"gray.100"} color={"secondary.600"}>
              {assay.assay_source}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
