import {
  Flex,
  Icon,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import { TbProgressCheck, TbRectangleVertical } from "react-icons/tb";

interface MobileViewProps {
  isMobileView: boolean;
}

export default function MobileView({ isMobileView }: MobileViewProps) {
  return (
    <Modal isOpen={isMobileView} onClose={() => {}}>
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        bg={"background"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"100vh"}
        minW={"100vw"}
        maxW={"100vw"}
        m={0}
        borderRadius={0}
      >
        <ModalBody
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={6}
          color={"gray.500"}
        >
          <Flex position={"relative"}>
            <Icon
              as={TbRectangleVertical}
              boxSize={["36px", "36px", "36px", null, null, null]}
            />
            <Icon
              position={"absolute"}
              bottom={"-8px"}
              right={"-8px"}
              as={TbProgressCheck}
              bg={"background"}
              boxSize={["22px", "22px", "22px", null, null, null]}
              borderRadius={"50%"}
              p={"2px"}
              color={"orange.400"}
            />
          </Flex>

          <Flex>
            <Text
              textAlign={"center"}
              fontSize={["12px", "14px", "14px", null, null, null]}
            >
              Application not available on smaller devices.
              <br />
              We're working to make it accessible.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
