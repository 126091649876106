import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import Header from "./Header";
import About from "./About";
import UseCase from "./UseCase";
import CTA from "./CTA";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Pipeline from "./Pipeline";
import Contact from "./Contact";

import UpdateChecker from "components/ui/UpdateChecker";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"white"}>
      <UpdateChecker />
      <Header id="home" />
      <About id="about" />
      <Pipeline id="pipeline" />
      <UseCase />
      <CTA />
      <FAQ id="faq" />
      <Contact id="contact" />
      <Footer id="footer" />
    </Flex>
  );
}
