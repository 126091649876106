import { Box, Card, Text } from "@chakra-ui/react";
import {formatDateTime} from "./Helper"

interface MessageProps {
  message: string,
  created_at: Date
}

export default function CurrentUserMessage({ message, created_at }: MessageProps) {    

  return (
    <Box>
      <Card
        p={4}
        borderRadius={"20px"}
        position="relative"
        boxShadow={"none"}
        bg={"highlight.primary"}
        color={"background"}
      >
        <Text
          fontSize={{ lg: "14px", "2xl": "15px" }}
          display={"flex"}
          gap={3}
          textAlign={"left"}
        >
          {message}
        </Text>
      </Card>
      <Text
        color={"gray.500"}
        fontSize={"10px"}
        gap={3}
        textAlign={"right"}
      >
        {formatDateTime(new Date(created_at))}
      </Text>
    </Box>
  );
}
