import { Card, Flex } from "@chakra-ui/react";
import BotAvatar from "./BotAvatar";
import LoadingDots from "components/ui/LoadingDots";
import FormattedText from "components/ui/FormattedText";

interface LoadingBubbleProps {
  partialReply: string[];
  notifications: string[];
}

export default function LoadingBubble({
  partialReply,
  notifications,
}: LoadingBubbleProps) {
  const processedPartialReply = partialReply
    ?.join("")
    ?.split("\n")
    .filter(Boolean);

  return (
    <Flex w="100%" mt={4} mb={8} minH={"50px"} overflow={"hidden"}>
      <BotAvatar />

      <Card p={3} bg={"transparent"} boxShadow={"none"}>
        {/* partial bot reply without molecules or sources */}
        {!!partialReply?.length && (
          <Flex
            h={"100%"}
            w={"100%"}
            direction={"column"}
            gap={1.5}
            mb={!!partialReply?.length ? 5 : "0"}
          >
            {processedPartialReply.map((messageItem, index) => (
              <FormattedText
                key={index}
                text={messageItem}
                streaming={index + 1 === processedPartialReply?.length} // NOTE: display typing character only in latest sentence
              />
            ))}
          </Flex>
        )}

        {/* notifications */}
        <Flex h={"100%"} w={"100%"} gap={3} align={"center"}>
          {/* dots indicators */}
          <LoadingDots />

          {/* notification message */}
          <FormattedText isNotification text={notifications?.at(-1) || ""} />
        </Flex>
      </Card>
    </Flex>
  );
}
