import { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  useTheme,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/auth/authSlice";
import { hexToRgba } from "utils/helpers";

export default function ChangePassword({
  username,
  resetPasswordSession,
  setResetPasswordSession,
}: {
  username: string;
  resetPasswordSession: string | null;
  setResetPasswordSession: (val: string | null) => void;
}) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordForm, setPasswordForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  // Theme
  const { colors } = useTheme();

  // Handlers
  const handleInputPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: "PASSWORD" | "CONFIRM_PASSWORD") => {
    if (pass === "PASSWORD") {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password,
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  // form submit
  const handleSubmit = async () => {
    try {
      const { newPassword, confirmNewPassword } = passwordForm;

      if (!username || !newPassword) return;

      const passwordError = isPasswordValid(newPassword, confirmNewPassword);

      if (passwordError.length > 0) {
        setErrorMsg(passwordError);
        setSubmitting(false);
        return;
      }

      if (!resetPasswordSession) {
        setErrorMsg("No session, try login again!");
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      const response = await axiosClient.post(
        "/api/new_password_required",
        JSON.stringify({ username, newPassword, resetPasswordSession }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setResetPasswordSession(null);
      const { user, roles } = response?.data;

      dispatch(setCredentials({ user, roles }));
      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Password was reset successfully
          </Box>
        ),
      });
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <FormControl id="newPassword" w="100%">
        <FormLabel color={"black"} opacity={0.75}>
          New Password
        </FormLabel>
        <InputGroup>
          <Input
            type={showPassword.password ? "text" : "password"}
            name="newPassword"
            placeholder="Enter your new password"
            _placeholder={{
              fontSize: "14px",
              color: hexToRgba(colors.black, 0.55),
            }}
            value={passwordForm.newPassword}
            onChange={handleInputPasswordChange}
            borderColor={"neutral.700"}
            color={"black"}
            _focus={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
              bg: hexToRgba(colors.black, 0.03),
            }}
            _hover={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
            }}
          />
          <InputRightElement>
            <Icon
              as={showPassword.password ? ViewOffIcon : ViewIcon}
              color={"secondary.500"}
              cursor={"pointer"}
              onClick={() => handlePasswordToggle("PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="confirmNewPassword" w="100%">
        <FormLabel color={"black"} opacity={0.75}>
          Confirm New Password
        </FormLabel>
        <InputGroup>
          <Input
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmNewPassword"
            value={passwordForm.confirmNewPassword}
            onChange={handleInputPasswordChange}
            placeholder="Confirm your new password"
            _placeholder={{
              fontSize: "14px",
              color: hexToRgba(colors.black, 0.55),
            }}
            borderColor={"neutral.700"}
            color={"black"}
            _focus={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
              bg: hexToRgba(colors.black, 0.03),
            }}
            _hover={{
              outline: "none",
              boxShadow: "none",
              borderColor: hexToRgba(colors.black, 0.25),
            }}
          />
          <InputRightElement>
            <Icon
              aria-label={
                showPassword.confirmPassword ? "Hide Password" : "Show Password"
              }
              as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
              color={"secondary.500"}
              cursor={"pointer"}
              onClick={() => handlePasswordToggle("CONFIRM_PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Text fontSize="sm" color="jasper">
        {errorMsg}
      </Text>
      <Button
        colorScheme="secondary"
        size="md"
        onClick={handleSubmit}
        w="100%"
        isLoading={submitting}
        loadingText="Processing"
      >
        Reset Password
      </Button>
    </>
  );
}
