import { Divider, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { CheckboxStyled } from "components/checkbox/Checkbox";

import { IoColorWand } from "react-icons/io5";
import DarkPlaceholder from "assets/theme/dark";
import LightPlaceholder from "assets/theme/light";

export interface ThemeProps {
  onToggle: () => void;
  isDarkMode: boolean;
}

function Appearance({ isDarkMode, onToggle }: ThemeProps) {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  const darkBg = isLight ? "gray.700" : "gray.200";
  return (
    <Flex gap={3} direction={"column"}>
      <Flex gap={2} align={"center"}>
        <Icon
          as={IoColorWand}
          transform={"rotate(90deg)"}
          boxSize={"18px"}
          opacity={0.8}
        />
        <Text fontWeight={"600"} lineHeight={"1.4"}>
          Appearance
        </Text>
      </Flex>

      <Divider />

      <Flex direction={"column"} gap={1}>
        <Text
          color={"gray.500"}
          fontSize={"13px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
        >
          Theme
        </Text>

        <Flex gap={4} align={"center"}>
          <Flex direction={"column"} gap={1} position={"relative"}>
            <Flex
              borderRadius={"12px"}
              borderColor={!isDarkMode ? "highlight.primary" : "transparent"}
              borderWidth={2}
              overflow={"hidden"}
              cursor={"pointer"}
              p={3}
              pb={0}
              bg={"white"}
              onClick={() => isDarkMode && onToggle()}
            >
              <Image
                src={LightPlaceholder}
                h={"120px"}
                objectFit={"cover"}
                alt={"Light"}
              />
            </Flex>
            <Text
              color={!isDarkMode ? "highlight.primary" : "gray.600"}
              fontSize={"12px"}
              fontWeight={"600"}
              fontFamily={"Poppins, sans-serif"}
            >
              Light
            </Text>
            <Flex position={"absolute"} bottom={"30px"} right={"10px"}>
              <CheckboxStyled
                roundedFull
                large
                isChecked={!isDarkMode}
                forTheme
                onChange={() => isDarkMode && onToggle()}
              />
            </Flex>{" "}
          </Flex>

          <Flex direction={"column"} gap={1} position={"relative"}>
            <Flex
              borderRadius={"12px"}
              borderColor={isDarkMode ? "highlight.primary" : "transparent"}
              borderWidth={2}
              overflow={"hidden"}
              cursor={"pointer"}
              p={3}
              pb={0}
              bg={darkBg}
              onClick={() => !isDarkMode && onToggle()}
            >
              <Image
                src={DarkPlaceholder}
                h={"120px"}
                objectFit={"cover"}
                alt={"Dark"}
              />
            </Flex>

            <Text
              color={isDarkMode ? "highlight.primary" : "gray.600"}
              fontSize={"12px"}
              fontWeight={"600"}
              fontFamily={"Poppins, sans-serif"}
            >
              Dark
            </Text>

            <Flex position={"absolute"} bottom={"30px"} right={"10px"}>
              <CheckboxStyled
                roundedFull
                large
                isChecked={isDarkMode}
                forTheme
                onChange={() => !isDarkMode && onToggle()}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Appearance;
