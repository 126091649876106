import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import savedReducer from "./features/research/savedSlice";
import { SavedStateProps } from "models/research/SavedProps";
import authReducer, { AuthState } from "./features/auth/authSlice";
import embeddingReducer, {
  EmbeddingState,
} from "./features/embedding/embeddingSlice";
import guideReducer from "./features/guide/guideSlice";
import { GuideStateProps } from "models/guide";
import postsReducer, { PostsState } from "./features/posts/postsSlice";


export interface StoreState {
  auth: AuthState;
  saved: SavedStateProps;
  embedding: EmbeddingState;
  guide: GuideStateProps;
  posts: PostsState
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    saved: savedReducer,
    embedding: embeddingReducer,
    guide: guideReducer,
    posts: postsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
