import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

import SuccessSendMessage from "../ui/SuccessSendMessage";
import SubmitFeedbackForm from "./SubmitFeedbackForm";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  sent?: boolean;
  setSent: (s: boolean) => void;
  setHasError: (s: boolean) => void;
  hasError: boolean;
}

function FeedbackModal({
  isOpen,
  onClose,
  sent,
  setSent,
  hasError,
  setHasError,
}: FeedbackModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        w={"fit-content"}
        minW={"540px"}
        minH={"200px"}
        borderRadius={"6px"}
        bg={"background"}
      >
        <ModalBody w={"full"} h={"full"} p={8}>
          {sent ? (
            <SuccessSendMessage text={"We received your feedback."} />
          ) : (
            <SubmitFeedbackForm
              setSent={setSent}
              setHasError={setHasError}
              hasError={hasError}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FeedbackModal;
