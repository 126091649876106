import { Dispatch, SetStateAction, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
  selectCurrentAuthData,
  clearCredentials,
} from "redux/features/auth/authSlice";

import UserAccess from "../UserAccess";
import DocsAPI from "../DocsAPI";
import { GoLinkExternal, GoSignIn, GoSignOut } from "react-icons/go";
import { FiUser } from "react-icons/fi";

interface ActionButtonsProps {
  setShowInfo: Dispatch<SetStateAction<boolean>>;
}

interface ProfileButtonProps {
  onClick: () => void;
}

function ActionButtons({ setShowInfo }: ActionButtonsProps) {
  // Navigation
  const navigate = useNavigate();
  const toast = useToast();

  // Custom hooks
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { user } = useSelector(selectCurrentAuthData);

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Handlers
  const handleLogout = useCallback(async () => {
    try {
      await axiosPrivate.get("/api/logout");
      dispatch(clearCredentials());

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged out successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        description: "Logout failed. Try again..",
        status: "error",
        position: "top-right",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, toast]);

  function handleInfo() {
    setShowInfo(true);
  }

  function navigateTo(route: string) {
    navigate(route);
  }

  return (
    <Flex align={"center"} gap={2}>
      {user ? (
        <>
          {/* API Documentation */}
          <DocsAPI setShowInfo={setShowInfo} />

          {/* Logout button */}
          <UserAccess
            icon={GoSignOut}
            state={"Logout"}
            onAccess={() => {
              isMobileView ? handleInfo() : handleLogout();
            }}
          />
        </>
      ) : (
        <>
          {/* Login button */}
          <UserAccess
            icon={GoSignIn}
            state={"Login"}
            onAccess={() => {
              isMobileView ? handleInfo() : navigateTo("/login");
            }}
          />

          {/* Register button */}
          <UserAccess
            icon={GoLinkExternal}
            state={"Sign Up"}
            onAccess={() => {
              isMobileView ? handleInfo() : navigateTo("/register");
            }}
          />
        </>
      )}
    </Flex>
  );
}

export default ActionButtons;

function ProfileButton({ onClick }: ProfileButtonProps) {
  return (
    <Button
      px={{ base: 2, sm: 3 }}
      py={{ base: 2, md: 2.5 }}
      w={"fit-content"}
      h={"fit-content"}
      letterSpacing={{ base: 0, sm: ".03rem" }}
      fontWeight={{ base: "400", md: "500" }}
      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
      bg={{ base: "transparent", md: "whiteAlpha.300" }}
      borderWidth={{ base: 1, md: 0 }}
      borderColor={{ base: "whiteAlpha.500", md: "transparent" }}
      color={"whiteAlpha.900"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      _active={{ backgroundColor: "whiteAlpha.500" }}
      onClick={onClick}
    >
      <Icon
        display={"inline-block"}
        fontSize={{ base: "sm", lg: "md" }}
        as={FiUser}
      />
    </Button>
  );
}
