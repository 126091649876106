import { PaperProps, Reference } from "models/papers/PaperProps";
import { PaperNetworkViewProps } from "views/papers/NetworkView";

export interface NodePosition {
    x: number;
    y: number;
    isOrigin: boolean
}


interface NodeOptionsProps {
    bg: string,
    category: number,
}

export interface NodeProps {
    data: PaperProps;
    category: number;
    id: string | undefined;
    isOrigin: boolean;
    highlighted: boolean,
    name: string | undefined;
    x: number;
    y: number;
    value: number | undefined;
    year: number | undefined;
    type: string | undefined;
    symbolSize: number;
    label: {
        bg: string;
        zIndex: number;
        show: boolean
    },
    itemStyle: {
        color: string,
        shadowBlur: number;
        borderType: string;
        borderWidth: number;
        borderColor: string;
    }
};

export interface LinkProps {
    source: string | undefined;
    target: string | undefined;
}

export const handleNodeOptions = (node: PaperProps, referencePapers: PaperProps[]): NodeOptionsProps => {
    if (referencePapers.find((p: PaperProps) => p.id === node.id)) {
        return {
            bg: "#00c1b4",
            category: 0,
        };
    }

    if (
        referencePapers.find((p: PaperProps) =>
            p.references?.find((lens: Reference) => lens.lens_id === node.lens_id)
        )
    ) {
        return {
            bg: "#FFA62B",
            category: 2,
        };
    }

    return {
        bg: "#63B3ED",
        category: 1,
    };
};

export const checkOrigin = (id: string | undefined, referencePapers: PaperProps[]) => {
    return referencePapers.some((p: PaperProps) => p.id === id)
}

export const generateNodes = (
    data: PaperProps[],
    referencePapers: PaperProps[],
    nodesPositions: NodePosition[],
    setNodesPositions: React.Dispatch<React.SetStateAction<NodePosition[]>>,
    nodesCount: number = 10
): NodeProps[] => {
    return data.map((item: PaperProps) => {
        const isOrigin = checkOrigin(item.id, referencePapers);
        let xBoundary = 700;
        let yBoundary = 510;

        let xPos = Math.floor(Math.random() * xBoundary + 1);
        let yPos = Math.floor(Math.random() * yBoundary);

        let tries = 0;
        const maxTries = nodesCount;

        while (nodesPositions.some(
            (pos) => Math.abs(pos.x - xPos) < 60 || Math.abs(pos.y - yPos) < 60
        ) &&
            tries < maxTries
        ) {
            xPos = Math.floor(Math.random() * xBoundary + 1);
            yPos = Math.floor(Math.random() * yBoundary);

            tries++;
        }
        xPos += 50;
        yPos += 50;

        setNodesPositions(prev => [...prev, { x: xPos, y: yPos, isOrigin }])

        return {
            data: item,
            category: handleNodeOptions(item, referencePapers).category,
            id: item.id,
            isOrigin,
            highlighted: false,
            name: item?.title,
            x: xPos,
            y: yPos,
            value: item.year_published,
            year: item.year_published,
            type: item.paper_type,
            symbolSize: isOrigin ? 35 : 20,
            label: {
                bg: "none",
                zIndex: -50,
                show: isOrigin ? true : false,
            },
            itemStyle: {
                color: handleNodeOptions(item, referencePapers).bg,
                shadowBlur: 0,
                borderType: "dashed",
                borderWidth: 2,
                borderColor: "transparent"
            },
        };
    });
}

export const generateLinks = (data: PaperNetworkViewProps[]) => {
    return data?.reduce((acc: LinkProps[], paper) => {
        const sourceId = paper.reference_paper.id;
        const connectedPapers = paper.similar_papers || [];
        connectedPapers.forEach((connectedPaper) =>
            acc.push({ source: sourceId, target: connectedPaper.id })
        );
        return acc;
    }, []);
}