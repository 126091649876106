import { Text, keyframes, usePrefersReducedMotion } from "@chakra-ui/react";

function TypingCharacter() {
  const blink = keyframes`50% { opacity: 0; }`;
  const hasMotion = usePrefersReducedMotion();

  const blinkAnimation = hasMotion ? undefined : `${blink} .8s ease infinite`;

  return (
    <Text animation={blinkAnimation} color={"gray.600"} as={"span"} ml={1}>
      |
    </Text>
  );
}

export default TypingCharacter;
