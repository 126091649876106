import { Box, Stack } from "@chakra-ui/react";

import Overlay from "components/home/Contact/Overlay";
import Header from "components/home/Contact/Header";
import { CurveSectionUp1 } from "components/ui/Curves";
import Form from "components/home/Contact/Form";

interface ContactProps {
  id: string;
}

export default function Contact({ id }: ContactProps) {
  return (
    <Box id={id} position={"relative"} pt={10}>
      <Box
        w={"100vw"}
        position={"absolute"}
        top={[0, 0, 0, -12, -20, -20]}
        left={0}
        zIndex={1}
      >
        <CurveSectionUp1 fill="white" />
      </Box>

      <Box
        zIndex={0}
        position={"relative"}
        backgroundImage={
          "url(https://reacto.s3.eu-central-1.amazonaws.com/images/f2.jpeg)"
        }
        bgSize={"cover"}
        bgPosition={"center"}
        bgRepeat={"no-repeat"}
        w={"100%"}
        minH={"720px"}
      >
        <Overlay>
          <Stack align={"center"} pt={[0, 0, 0, 12, 20, 20]}>
            <Header />
            <Form />
          </Stack>
        </Overlay>
      </Box>
    </Box>
  );
}
