import { Button, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface UserAccessProps {
  icon: IconType;
  state: string;
  onAccess: () => void;
}

function UserAccess({ icon, state, onAccess }: UserAccessProps) {
  return (
    <Button
      py={2}
      px={{ base: 2, sm: 3 }}
      w={"fit-content"}
      h={"fit-content"}
      letterSpacing={{ base: 0, sm: ".03rem" }}
      fontWeight={{ base: "400", md: "500" }}
      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
      bg={{ base: "transparent", md: "whiteAlpha.300" }}
      borderWidth={{ base: 1, md: 0 }}
      borderColor={{ base: "whiteAlpha.500", md: "transparent" }}
      color={"whiteAlpha.900"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      _active={{ backgroundColor: "whiteAlpha.500" }}
      onClick={onAccess}
    >
      <Icon
        display={{ base: "none", sm: "inline-block" }}
        fontSize={{ base: "sm", md: "sm", lg: "md" }}
        as={icon}
        mr={"2"}
      />
      {state}
    </Button>
  );
}

export default UserAccess;
