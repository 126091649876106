import {
  Button,
  keyframes,
  usePrefersReducedMotion,
  useTheme,
} from "@chakra-ui/react";
import { IoSparklesOutline } from "react-icons/io5";

interface MainButtonProps {
  text: string;
  inFooter?: boolean;
  onClick: () => void;
}

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `;

const gradient = keyframes`
 0%{background-position: 0% 92%}
  50%{background-position: 100% 9%}
  100%{background-position: 0% 92%}
`;

function MainButton({ text, inFooter, onClick }: MainButtonProps) {
  // Theme
  const { colors } = useTheme();

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const gradientAnimation = prefersReducedMotion
    ? undefined
    : `${gradient} 4s ease-in-out infinite`;

  const fadeInAnimation = prefersReducedMotion
    ? undefined
    : `${fadeIn} 1.5s ease-in-out`;

  // combine both animations
  const btnAnimation = prefersReducedMotion
    ? undefined
    : `${fadeInAnimation}, ${gradientAnimation}`;

  return (
    <Button
      m={0}
      mx={{ base: inFooter ? 0 : "auto", lg: 0 }}
      p={{ base: 5, lg: 6 }}
      px={{ base: 5, sm: 8, lg: 6 }}
      w={{ base: "100%", sm: "fit-content" }}
      fontSize={{ base: inFooter ? "13px" : "sm", sm: "sm", lg: "md" }}
      color={"whiteAlpha.900"}
      borderRadius={{ base: "10px", lg: "32px" }}
      letterSpacing={".05rem"}
      transition={"all .3s ease"}
      leftIcon={<IoSparklesOutline />}
      background={`-webkit-linear-gradient(225deg, ${colors.purple[500]}, ${colors.secondary[600]}, ${colors.highlight.primary}) 0% 0% / 300% 300%`}
      backgroundSize="200% 200%"
      animation={btnAnimation}
      onClick={onClick}
      _hover={{
        px: { base: 5, sm: inFooter ? 8 : 10, lg: inFooter ? 6 : 10 },
        background: `-webkit-linear-gradient(225deg, ${colors.purple[500]}, ${colors.secondary[600]}, ${colors.highlight.primary}) 0% 0% / 300% 300%`,
      }}
      _active={{
        px: { base: 5, sm: 10 },
        background: `-webkit-linear-gradient(225deg, ${colors.purple[500]}, ${colors.secondary[600]}, ${colors.highlight.primary}) 0% 0% / 300% 300%`,
      }}
    >
      {text}
    </Button>
  );
}

export default MainButton;
