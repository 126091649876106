import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { SuggestionProps } from "views/papers/SuggestionsView";

export const useLiteraturesAPI = () => {
  const axiosPrivate = useAxiosPrivate();
  const fetchSearchResults = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , query] = queryKey;
    if (!query) return [];

    const response = await axiosPrivate.get(
      `${environment.BACKEND_API}/api/search_papers_by_keyword/${query}`
    );
    const data = response.data;

    if (data?.length > 0) {
      return data.map((paper: SuggestionProps) => ({
        ...paper,
        paper_type:
          paper?.paper_type && paper?.paper_type.toLowerCase() !== "neither"
            ? paper?.paper_type
            : "Unclassified",
      }));
    }

    return [];
  };

  const fetchSimilarPapers = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , id] = queryKey;
    if (!id) return [];
    const url = `${environment.BACKEND_API}/api/get_similar_papers/${id}`;
    return await axiosPrivate.get(url).then((res) => res.data);
  };

  const fetchPapersById = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, id] = queryKey;
    const url = `${environment.BACKEND_API}/api/get_paper_by_id/${id}`;
    return await axiosPrivate.get(url).then((res) => res.data);
  };

  return { fetchSearchResults, fetchSimilarPapers, fetchPapersById };
};
