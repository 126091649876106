import { useState } from "react";
import { Heading, Text, Flex, useTheme, Link } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import UpdateChecker from "components/ui/UpdateChecker";
import ResendNewPassword from "./loginInputs/ResendNewPassword";
import ChangePassword from "./loginInputs/ChangePassword";
import Login from "./loginInputs/Login";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  // Theme
  const { colors } = useTheme();

  // Hook
  const navigate = useNavigate();

  // States
  const [changePassword, setChangePassword] = useState(false);
  const [expired, setExpired] = useState(false);
  const [resetPasswordSession, setResetPasswordSession] = useState<
    string | null
  >(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // Theme
  const { appTitleGradient } = GetGradients();

  return (
    <Flex
      direction="column"
      justify="center"
      align="left"
      gap={4}
      p={10}
      borderRadius={"10px"}
      bg={hexToRgba(colors.white, 0.95)}
      boxShadow={"0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"}
      backdropFilter={"blur( 4.5px)"}
      borderWidth={1}
      borderColor={hexToRgba(colors.black, 0.18)}
    >
      <UpdateChecker />
      <Heading fontSize="14px" color={hexToRgba(colors.black, 0.7)} mb={"0"}>
        Welcome to{" "}
        <Text
          as="span"
          fontWeight={"bold"}
          bgGradient={appTitleGradient}
          bgClip="text"
          cursor={"pointer"}
          onClick={() => navigate("/")}
        >
          MoleculeLake
        </Text>
      </Heading>

      <Heading fontSize="26px" mb={"12px"} color={hexToRgba(colors.black, 0.7)}>
        Sign in to your account
      </Heading>

      {changePassword ? (
        <ChangePassword
          username={formData.username}
          resetPasswordSession={resetPasswordSession}
          setResetPasswordSession={setResetPasswordSession}
        />
      ) : expired ? (
        <ResendNewPassword username={formData.username} />
      ) : (
        <Login
          setResetPasswordSession={setResetPasswordSession}
          setFormData={setFormData}
          formData={formData}
          setExpired={setExpired}
          setChangePassword={setChangePassword}
        />
      )}

      <Text mt={6} fontSize={"14px"}>
        Don't have an account?
        <Link
          pl={1}
          onClick={() => navigate("/register")}
          color={"primary.400"}
        >
          Sign up for free
        </Link>
      </Text>
    </Flex>
  );
}
