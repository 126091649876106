import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useCompoundsAPI = () => {
    const axiosPrivate = useAxiosPrivate();

    const fetchAllCompoundsNames = async () => {
        const url = `${environment.BACKEND_API}/api/get_all_compounds_names`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    const fetchCompoundsById = async ({ queryKey }: { queryKey: QueryKey }) => {
        const [, id] = queryKey
        const url = `${environment.BACKEND_API}/api/get_compound_by_id/${id}`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    const fetchCompoundsByName = async ({ queryKey }: { queryKey: QueryKey }) => {
        const [, name] = queryKey
        const url = `${environment.BACKEND_API}/api/get_compound_by_name/${name}`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    const fetchSearchResults = async ({ queryKey }: { queryKey: [string, { query: string | null; page: string | number | null; itemsPerPage: string | number | null; }] }) => {
        const [, params] = queryKey
        const { query, page, itemsPerPage } = params
        if (!query) return [];
        const url = `${environment.BACKEND_API}/api/find_compounds_by_query`;
        return await axiosPrivate.get(url, { params: { query, page, itemsPerPage } }).then((res) => res.data)
    };

    const fetchPapersReferencedIn = async ({ queryKey }: { queryKey: QueryKey }) => {
        const [, , cmp_name] = queryKey
        if (!cmp_name) return [];
        const url = `${environment.BACKEND_API}/api/find_papers_by_compound/${cmp_name}`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    return { fetchAllCompoundsNames, fetchCompoundsById, fetchSearchResults, fetchPapersReferencedIn, fetchCompoundsByName }
}