import { useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Image,
  Flex,
  Tooltip,
  Text,
  Box,
} from "@chakra-ui/react";

import { CompoundProps } from "models/compounds/CompoundProps";
import {
  adjustCompoundName,
  getAliases,
  getIndications,
  getSmilesImageSrc,
  stringToColor,
} from "views/compounds/helpers";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import MoleculeItem from "components/chat/molecules/MoleculeItem";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { useSelector } from "react-redux";

interface CompoundsTableProps {
  items: CompoundProps[];
  similarityScores?: string[];
  inChat?: boolean;
}

// Styles
const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
};

export default function CompoundsTable({
  items,
  similarityScores,
  inChat,
}: CompoundsTableProps) {
  const cols = useMemo(
    () => ["Molecule", "ID", "Name", "Aliases", "Assay Hits"],
    []
  );

  const cleanitems = useMemo(
    () => items.filter((item) => item !== undefined),
    [items]
  );

  // State
  const [show, setShow] = useState(false);
  const [selectedCompound, setSelectedCompound] = useState<
    CompoundProps | undefined
  >(undefined);

  // TODO: navigate to search results in data/core/molecules
  function handleClick(compound: CompoundProps) {
    if (inChat) {
      setShow(true);
      setSelectedCompound(compound);
    }
  }
  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  return (
    <>
      <TableContainer pt={inChat ? 6 : 0}>
        <CustomScrollBar style={tableStyle}>
          <Table variant="simple" size="sm" position={"relative"}>
            {/* Head */}

            <Thead>
              <Tr>
                {cols.map((col, index) => {
                  return (
                    <Th
                      key={"cpmds-table-header-" + index}
                      isTruncated={col === "Aliases" || col === "Assay Hits"}
                      borderColor={"gray.100"}
                    >
                      {applyIsGuideOpenStyles && <CustomOverlay />}
                      {col}
                    </Th>
                  );
                })}
                {similarityScores && (
                  <Th borderColor={"gray.100"} isTruncated>
                    Similarity Score
                  </Th>
                )}
              </Tr>
            </Thead>

            {/* Body */}
            <Tbody>
              {cleanitems.map((cmpd, index) => {
                return (
                  <Tr
                    key={"cmpd-row" + index}
                    _hover={inChat ? { cursor: "pointer", bg: "gray.100" } : {}}
                    transition="background 0.3s ease"
                    onClick={() => handleClick(cmpd)}
                  >
                    {/* Molecule */}
                    <Smiles cmpd={cmpd} />

                    {/* ID */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"15%"}
                      fontSize={"12px"}
                      p={1.5}
                    >
                      {cmpd?.compound_id}
                    </Td>

                    {/* Name */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"320px"}
                      p={1.5}
                      isTruncated
                    >
                      <Tooltip
                        label={adjustCompoundName(cmpd)}
                        bg={"gray.900"}
                        color={"gray.100"}
                        hasArrow
                        py={2}
                        px={3}
                        m={2}
                        maxW={[null, null, null, "200px", "220px", "240px"]}
                        borderRadius={"6px"}
                        fontSize={"12px"}
                        boxShadow={"none"}
                        arrowSize={8}
                      >
                        <Text
                          textTransform={"capitalize"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontSize={[null, null, null, "12px", "14px", "14px"]}
                        >
                          {adjustCompoundName(cmpd)}
                        </Text>
                      </Tooltip>
                    </Td>

                    {/* Aliases */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"10%"}
                      p={1.5}
                      isTruncated
                    >
                      <Flex w={"fit-content"} direction={"column"} gap={1}>
                        {getAliases(cmpd?.aliases).map((alias, index) => {
                          return (
                            <Tag
                              key={"alias-" + index}
                              color={"gray.700"}
                              bg={"gray.200"}
                              fontSize={[
                                null,
                                null,
                                null,
                                "12px",
                                "14px",
                                "14px",
                              ]}
                            >
                              {alias}
                            </Tag>
                          );
                        })}
                      </Flex>
                    </Td>

                    {/* Indication */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"20%"}
                      isTruncated
                      p={1.5}
                    >
                      <Flex wrap={"wrap"} rowGap={0.5} columnGap={0.5}>
                        {getIndications(cmpd).map((indication, index) => {
                          return (
                            <Tooltip
                              key={"ind-" + index}
                              label={indication}
                              bg={"gray.900"}
                              color={"gray.100"}
                              hasArrow
                              py={2}
                              px={3}
                              m={2}
                              maxW={[
                                null,
                                null,
                                null,
                                "300px",
                                "360px",
                                "440px",
                              ]}
                              borderRadius={"6px"}
                              fontSize={"12px"}
                              boxShadow={"none"}
                              arrowSize={8}
                            >
                              <Tag
                                key={"indication-" + index}
                                fontSize={[
                                  null,
                                  null,
                                  null,
                                  "10px",
                                  "12px",
                                  "12px",
                                ]}
                                bg={stringToColor(indication)}
                              >
                                {indication.length < 21
                                  ? indication
                                  : indication.slice(0, 20) + ".."}
                              </Tag>
                            </Tooltip>
                          );
                        })}
                      </Flex>
                    </Td>

                    {/* Similarity Score */}
                    {similarityScores && (
                      <Td borderColor={"gray.100"} isTruncated>
                        <Text as="b">{similarityScores[index]}</Text>
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CustomScrollBar>
      </TableContainer>

      <MoleculeItem
        isOpen={show}
        onClose={() => setShow(false)}
        details={selectedCompound}
      />
    </>
  );
}

interface SmilesProps {
  cmpd: CompoundProps;
}

function Smiles({ cmpd }: SmilesProps) {
  return (
    <Td borderColor={"gray.100"} w={"20%"}>
      <Image
        src={getSmilesImageSrc(cmpd)}
        boxSize="100%"
        maxHeight={"120px"}
        maxWidth={"120px"}
        objectFit={"cover"}
        fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
        alt={cmpd?.chemical_props?.formula}
        boxShadow={"md"}
        borderRadius={"6px"}
        mx={"auto"}
      />
    </Td>
  );
}

function CustomOverlay() {
  return (
    <Box
      borderRadius={"md"}
      bg={"rgba(0, 0, 0, 0.2)"}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      pointerEvents={"none"}
      zIndex={1300}
      border={"none"}
    />
  );
}
