import { Box, Flex } from "@chakra-ui/react";
import { RefObject } from "react";
import CurrentUserMessage from "./CurrentUserMessage";
import OtherUserMessage from "./OtherUserMessage";
import { User, selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import OtherUserAvatar from "./OtherUserAvatar";
import { TeamMessageProps } from "models/posts/PostProps";


interface BubbleProps {
  content: TeamMessageProps;
  isLastMessage: boolean;
  messagesRef: RefObject<HTMLDivElement>;
}

export default function MessageBubble({ content }: BubbleProps) {
  const { user } = useSelector(selectCurrentAuthData);

  return (
    <Flex direction={"column"}>
      {(user?.id == content.user_id) ?
        <>
          {/* Current user message */}
          <Flex w="100%" my={1} justifyContent={"flex-end"} textAlign={"end"}>
            <Flex w="80%" pl={"56px"} justifyContent={"flex-end"}>
              <CurrentUserMessage message={content.message} created_at={content.created_at} />
            </Flex>
          </Flex>
        </>
        :
        <>
          {/* Other user's message */}
          <Flex w="100%" my={1} pr={2} pl={2}>
            <Flex w="80%" pr={"30px"} justifyContent={"flex-start"}>
              <Box pt={2} mr={2}>
                <OtherUserAvatar memberName={content?.given_name + " " + content?.family_name} />
              </Box>
              <OtherUserMessage
                message={content.message}
                created_at={content.created_at}
                given_name={content?.given_name}
                family_name={content?.family_name}
              />
            </Flex>
          </Flex>
        </>
      }
    </Flex>
  );
}
