import {
    Box,
  } from "@chakra-ui/react";
  import { Navigate, useParams } from "react-router-dom";
  import { getProprietarySource } from "../helpers";
import AgnosticDataDisplay from "components/data/proprietary/AgnosticDataDisplay";
  
  export default function ProprietarySourcesList() {
    const { source } = useParams();
    const proprietarySources = getProprietarySource(source);

    if (!['LITERATURE'].includes(source?.toUpperCase() || '')) {
        return <Navigate to={'/not-found'} replace />
    }
    return (
        <>
        {!proprietarySources ?
            <Box>Source not found</Box> :
            <AgnosticDataDisplay source={proprietarySources} />
        }
    </>
    );
  }