import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useChatBotAPI = () => {
    const axiosPrivate = useAxiosPrivate();

    // Get the whole conversation for any session
    const fetchChatById = async ({ queryKey }: { queryKey: QueryKey }) => {
        const [, sessionId] = queryKey
        const url = `${environment.BACKEND_API}/llm/get_or_delete_chat/${sessionId}`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    // Get all session for the user
    const fetchSessions = async () => {
        const url = `${environment.BACKEND_API}/api/get_sessions`;
        return await axiosPrivate.get(url).then((res) => res.data)
    };

    return { fetchChatById, fetchSessions }
}