import HelperProps from "./HelperProps";


export const moaprops_helper: HelperProps[] = [
  {
    title: "MoA Category",
    description: "Broad classification of the Mechanism of Action (MoA), categorizing it into general roles or types of interactions."
  },
  {
    title: "MoA",
    description: "Specific Mechanism of Action detailing the precise biological or chemical activity of a substance."
  },
  {
    title: "Parent MoA",
    description: "Hierarchical classification that provides broader context or parent categories leading to the specific MoA, showing its place within a larger framework."
  },
  {
    title: "Data Source",
    description: "Indication of the source of the data, typically referring to a database or an identifier within a database."
  }
];
