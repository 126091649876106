import {
  Box,
  Flex,
  Heading,
  Icon,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { BsRobot } from "react-icons/bs";

const fadeDown = keyframes`
  0% {
    transform: translateY(-30px) scale(0.7);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

function NewChatHeading() {
  const prefersReducedMotion = usePrefersReducedMotion();

  const fadeDownAnimation = prefersReducedMotion
    ? undefined
    : `${fadeDown} 1s ease-in-out`;

  // Theme
  const { rGradient } = GetGradients();

  return (
    <Flex
      direction={"column"}
      align={"center"}
      gap={3}
      animation={fadeDownAnimation}
    >
      <Box
        bgGradient={rGradient}
        borderRadius={"50%"}
        p={{ lg: 3, xl: 4 }}
        w={{ lg: "50px", xl: "70px" }}
        h={{ lg: "50px", xl: "70px" }}
      >
        <Icon as={BsRobot} boxSize={"100%"} color={"black"} opacity={0.7} />
      </Box>
      <Heading
        fontSize={{ lg: "18px", xl: "22px" }}
        color={"gray.600"}
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"700"}
      >
        Hi! I'm your scientific research assistant!
      </Heading>
    </Flex>
  );
}

export default NewChatHeading;
