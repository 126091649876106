import {
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

interface PaymentSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentStatus: string;
}

function PaymentSuccessModal({
  isOpen,
  onClose,
  paymentStatus,
}: PaymentSuccessModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        bg={"background"}
        borderRadius={"6px"}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          {paymentStatus === "success" || paymentStatus === "cancel-plan" ? (
            <Icon
              as={FaCheckCircle}
              bg={"highlight.primary"}
              color={"gray.50"}
              boxSize={"28px"}
              borderRadius={"6px"}
              p={"3px"}
            />
          ) : (
            <Icon
              as={MdCancel}
              bg={"red.500"}
              color={"gray.50"}
              boxSize={"28px"}
              borderRadius={"6px"}
              p={"3px"}
            />
          )}
          {paymentStatus === "success" && (
            <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
              Thank you!
            </Text>
          )}
        </ModalHeader>

        <ModalBody mb={4}>
          <Text fontFamily={"Poppins, sans-serif"} fontSize={"14px"}>
            {paymentStatus === "success" && (
              <>Subscription plan changed successfully.</>
            )}
            {paymentStatus === "cancel" && (
              <>Subscription plan change request cancelled.</>
            )}
            {paymentStatus === "cancel-plan" && (
              <>
                Subscription plan successfully changed to <b>Basic</b>.
              </>
            )}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PaymentSuccessModal;
