import { useState } from "react";
import { Flex, Input, Text } from "@chakra-ui/react";

import emailjs from "@emailjs/browser";

import ErrorSendMessage from "../ui/ErrorSendMessage";
import FeedbackTypes from "./FeedbackTypes";
import { FeedbackParams } from "environments";
import FeedbackArea from "./FeedbackArea";
import SubmitButton from "./SubmitButton";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

interface SubmitFeedbackFormProps {
  setSent: (s: boolean) => void;
  setHasError: (s: boolean) => void;
  hasError: boolean;
}

function SubmitFeedbackForm({
  hasError,
  setSent,
  setHasError,
}: SubmitFeedbackFormProps) {
  // States
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // Auth
  const { user } = useSelector(selectCurrentAuthData);
  let fullName;
  if (user) {
    fullName = user?.email?.split("@")[0];
  }

  // Handler
  // NOTE: type not provided by emailJS
  async function sendEmail(e: any) {
    e.preventDefault();

    if (!message.trim()) return;

    const { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } = FeedbackParams;

    try {
      setSubmitting(true);

      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY);
      setSent(true);
    } catch (error) {
      setHasError(true);
    } finally {
      setSubmitting(false);
    }

    // Clear textarea and reset text-area state
    e.target.reset();
    setMessage("");
  }

  return (
    <>
      {hasError ? (
        <ErrorSendMessage text={"Feedback not delivered!"} />
      ) : (
        <Flex as="form" direction="column" onSubmit={sendEmail}>
          <Input value={fullName} type="hidden" name="fullname" />
          <Input value={user?.email} type="hidden" name="email" />
          <FeedbackTypes />
          <Flex direction="column" mt={8} gap={2}>
            <FeedbackArea
              message={message}
              onMessageChange={(e) => setMessage(e.target.value)}
            />

            {/* feedback info */}
            <Text color={"gray.400"} fontSize={"12px"}>
              By pressing Submit, your feedback will be used to improve this
              Vitafluence product.
            </Text>
          </Flex>

          <SubmitButton submitting={submitting} />
        </Flex>
      )}
    </>
  );
}

export default SubmitFeedbackForm;
