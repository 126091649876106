import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { selectCurrentSavedData } from "redux/features/research/savedSlice";

import ResearchBreadcrumb from "./Breadcrumb";
import NewFolderContent from "components/research/NewFolderContent";

import { getLibraryFolder } from "../helpers";

export default function UserLibraryPanel() {
  // Hooks
  const { folderId } = useParams();
  const { savedFolders } = useSelector(selectCurrentSavedData);

  const folder = getLibraryFolder(folderId, savedFolders);

  const hasSavedFolders = useMemo(
    () => savedFolders && savedFolders.length > 0,
    [savedFolders]
  );

  return (
    <Flex h={"100%"} direction={"column"} gap={4}>
      {/* Breadcrumb & Create Folder section */}
      <Flex align={"center"} gap={2} w={"100%"} justify={"space-around"}>
        {/* Breadcrumb */}
        <ResearchBreadcrumb folder={folder} />

        {/* Create Folder section */}
        {!folder && hasSavedFolders && (
          <Flex w={"50%"} maxW={"280px"} align={"center"} ml={"auto"}>
            <NewFolderContent hasFolders={hasSavedFolders} small />
          </Flex>
        )}
      </Flex>

      <Box w={"100%"} h={"100%"}>
        <Outlet />
      </Box>
    </Flex>
  );
}
