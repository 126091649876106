import React, { ReactNode } from "react";
import { Menu, MenuList, MenuItem, Divider, Text, Flex, Icon, Image, useClipboard, Tooltip, Spinner } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdContentCopy, MdCopyAll, MdSearch } from "react-icons/md";
import { BsBookmarkCheck } from "react-icons/bs";
import { IoMdCode } from "react-icons/io";
import { PiGraphFill } from "react-icons/pi";
import LensImg from "assets/chatbot/lens.png";
import { AuthorProps, ExternalId } from "models/papers/PaperProps";
import { CustomLock } from "components/ui/CustomIcons";
import { NodeProps } from "./helps";

interface CustomNodeContextMenuProps {
    node: NodeProps;
    x: number;
    y: number;
    isEmbeddingInProgress: boolean;
    isPremiumUser: boolean;
    onClose: () => void;
    handleSaveAction: () => void;
    handleSetAsOriginAction: () => void;
    handleEmbedAction: (paper: string) => void;
}

interface MenuItemType {
    hidden?: boolean;
    disabled?: boolean;
    isSectionHead?: boolean;
    locked?: boolean;
    label: string;
    info?: string;
    infoIcon?: ReactNode,
    icon: IconType,
    action: () => void;
}

const CustomNodeContextMenu: React.FC<CustomNodeContextMenuProps> = ({ x, y, onClose, node, handleSaveAction, isEmbeddingInProgress, isPremiumUser, handleSetAsOriginAction, handleEmbedAction }) => {
    // Theme
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLightTheme = colorMode === "light";
    const { id, lens_id, authors, external_ids, title, year_published, embedded } = node.data

    const DOI = external_ids?.find((id: ExternalId) => id?.type?.toLowerCase() === "doi")?.value

    const { onCopy: onCopyTitle } = useClipboard(title || '');
    const { onCopy: onCopyAll } = useClipboard(
        `${authors?.map((author: AuthorProps) => `${author.first_name} ${author.last_name}`).join(', ') || 'Unknown Authors'}. (${year_published}). ${title}. ${DOI || ''}`
    );

    const menuItems: MenuItemType[] = [
        {
            // hidden: !isPremiumUser || embedded, // Hide embed func if not PREMIUM or already embedded
            disabled: isEmbeddingInProgress || !isPremiumUser,
            hidden: embedded, // Hide embed func if already embedded
            locked: true,
            label: 'Embed Paper',
            info: !isPremiumUser ? 'Premium users only' : isEmbeddingInProgress ? 'An embedding process in progress' : '',
            infoIcon:
                !isPremiumUser
                    ? <CustomLock fill={isLightTheme ? "#718096" : "#a0aec0"} width="1.25rem" height="1.25rem" />
                    : isEmbeddingInProgress ? <Spinner size={'xs'} /> : null,
            icon: IoMdCode,
            action: () => id && handleEmbedAction(id),
        },
        {
            label: 'Save Paper',
            icon: BsBookmarkCheck,
            action: handleSaveAction,
        },
        {
            hidden: node.isOrigin,
            label: 'Set as Origin',
            icon: PiGraphFill,
            action: handleSetAsOriginAction
        },
        {
            label: 'Copy Name',
            icon: MdContentCopy,
            action: onCopyTitle,
            isSectionHead: true
        },
        {
            label: 'Copy All',
            icon: MdCopyAll,
            action: onCopyAll
        },
        {
            label: 'Search Paper Online',
            icon: MdSearch,
            action: () => window.open(`https://www.google.com/search?q=${encodeURIComponent(`doi: ${DOI}`)}`, '_blank'),
            isSectionHead: true
        },
        {
            label: 'Search Paper in Lens.org',
            icon: () => <Image
                src={LensImg}
                maxHeight={"14px"}
                maxWidth={"14px"}
                objectFit={"cover"}
                alt='Search Paper in Lens.org'
            />,
            action: () => window.open(`https://www.lens.org/lens/scholar/article/${lens_id}/main`),
        },
    ];

    const handleMenuItemClick = (action: () => void, onClose: () => void) => {
        action();
        onClose();
    };

    return (
        <Menu isOpen>
            <MenuList
                position={'fixed'}
                top={`${y}px`}
                left={`${x}px`}
                bg={'background'}
                boxShadow={'md'}
                borderRadius={'lg'}
                p={1}
                overflow={'hidden'}
            >
                {menuItems.map((item, index) => {
                    return !item.hidden && (<React.Fragment key={index}>
                        {item.isSectionHead && <Divider my={1} />}
                        <MenuItem
                            onClick={() => !item.disabled && handleMenuItemClick(item.action, onClose)}
                            minWidth={[null, null, "250px", "280px"]}
                            minHeight={[null, null, "28px", "32px"]}
                            py={0}
                            borderRadius={'lg'}
                            color={item.disabled ? 'gray.500' : 'gray.700'}
                            bg={'transparent'}
                            _hover={{
                                backgroundColor: item.disabled ? 'transparent' : 'gray.100'
                            }}
                            opacity={item.disabled ? 0.6 : 1}
                            cursor={item.disabled ? 'auto' : 'pointer'}
                        >
                            <Flex alignItems={'center'} gap={2} width={'100%'}>
                                <Icon
                                    // fontSize={[null, null, null, "md", "lg"]}
                                    aria-label={item.label}
                                    color={item.disabled ? 'gray.500' : 'gray.700'}
                                    h={[null, null, "14px", "16px"]}
                                    w={[null, null, "14px", "16px"]}
                                    as={item.icon}
                                />
                                <Text fontSize={[null, null, "xs", "sm"]}>{item.label}</Text>

                                {/* Info icon */}
                                {item.disabled && item.infoIcon &&
                                    <Tooltip
                                        label={item.info}
                                        placement={"right"}
                                        bg={"gray.900"}
                                        color={"gray.100"}
                                        hasArrow
                                        py={2}
                                        px={3}
                                        m={2}
                                        maxW={[null, null, null, "200px", "220px", "240px"]}
                                        borderRadius={"6px"}
                                        fontSize={"12px"}
                                        boxShadow={"none"}
                                        arrowSize={8}
                                    >
                                        <Flex ml={'auto'}>
                                            {item.infoIcon}
                                        </Flex>
                                    </Tooltip>}
                            </Flex>
                        </MenuItem>
                    </React.Fragment>)
                }
                )}
            </MenuList>
        </Menu>
    );
};

export default CustomNodeContextMenu