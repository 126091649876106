import { useContext, useEffect, useState } from "react";
import { Accordion, Flex, Icon, Text, useTheme } from "@chakra-ui/react";

import { PaperNetworkViewProps } from "views/papers/NetworkView";
import AccordionSourceItem from "./AccordionSourceItem";
import NetworkTabPanel from "./NetworkTabPanel";
import ReadMoreButton from "../ReadMoreButton";

import { BiSolidNetworkChart } from "react-icons/bi";
import { BsList } from "react-icons/bs";

import { hexToRgba } from "utils/helpers";
import { IconType } from "react-icons";
import NoDataAvailable from "../NoDataAvailable";
import { BotMessageContext } from "../BotMessage";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

interface SourcesProps {
  sources: PaperNetworkViewProps[];
}

export interface BaseButtonProps {
  id?: string;
  icon?: IconType;
  label: string;
  onClick: () => void;
}

interface SourceDisplayOptionProps extends BaseButtonProps {
  display: "list" | "network";
}

// limit nbr of sources to show to 3 initially
const MAX_SLICE = 3;

function SourcesTabPanel({ sources }: SourcesProps) {
  // States
  const [showAll, setShowAll] = useState(false);
  const [display, setDisplay] = useState<"list" | "network">("list");

  let sourcesToShow = showAll ? sources : sources?.slice(0, MAX_SLICE);

  const { openAccordionIndex } = useContext(BotMessageContext);

  useEffect(() => {
    if (openAccordionIndex && openAccordionIndex + 1 > MAX_SLICE) {
      setShowAll(true);
    }
    setDisplay("list");
  }, [openAccordionIndex]);

  return (
    <>
      {sourcesToShow && sourcesToShow?.length > 0 ? (
        <Flex direction={"column"} gap={1}>
          {/* sources display options: List or Network */}
          <Flex gap={0} py={1} pt={6} align={"center"}>
            <SourceDisplayOption
              id="chat_sources_list"
              label={"List"}
              display={display}
              icon={BsList}
              onClick={() => setDisplay("list")}
            />
            <SourceDisplayOption
              id="chat_sources_network"
              label={"network"}
              display={display}
              icon={BiSolidNetworkChart}
              onClick={() => setDisplay("network")}
            />
          </Flex>

          {/* display sources as list */}
          {display === "list" && (
            <Flex direction={"column"}>
              <Accordion
                defaultIndex={[0]}
                index={
                  openAccordionIndex !== null ? [openAccordionIndex] : undefined
                }
                allowToggle
              >
                {sourcesToShow?.map(
                  (source: PaperNetworkViewProps, index: number) => (
                    <AccordionSourceItem
                      key={"term" + index}
                      index={index}
                      data={source.reference_paper}
                    />
                  )
                )}
              </Accordion>

              {/* read more/less */}
              {sources.length > MAX_SLICE && (
                <ReadMoreButton
                  label={
                    showAll
                      ? "view less"
                      : `view more +${sources?.length - MAX_SLICE}`
                  }
                  onClick={() => setShowAll(!showAll)}
                />
              )}
            </Flex>
          )}

          {/* display sources as network */}
          {display === "network" && <NetworkTabPanel sources={sources} />}
        </Flex>
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

export default SourcesTabPanel;

function SourceDisplayOption({
  id = "",
  icon,
  label,
  display,
  onClick,
}: SourceDisplayOptionProps) {
  // Theme
  const { colors } = useTheme();

  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  const { isHighlighted } = useGuideTour();
  const highlighted = isHighlighted(id);

  return (
    <Flex
      id={id}
      borderRadius={"16px"}
      bg={
        highlighted
          ? "background"
          : isGuideOpen
          ? "transparent"
          : display === label?.toLowerCase()
          ? `linear-gradient(94deg, ${hexToRgba(
              colors.secondary[500],
              0.12
            )} 5%, ${hexToRgba(colors.primary[300], 0.12)} 50%, ${hexToRgba(
              colors.highlight.primary,
              0.12
            )} 80%)`
          : "transparent"
      }
      color={"gray.600"}
      borderWidth={1}
      borderColor={
        isGuideOpen
          ? "transparent"
          : display === label?.toLowerCase()
          ? "primary.300"
          : "transparent"
      }
      px={3}
      py={"5px"}
      h={"fit-content"}
      cursor={"pointer"}
      align={"center"}
      gap={1}
      onClick={onClick}
    >
      <Icon as={icon} boxSize={"14px"} />
      <Text fontSize={"12px"} lineHeight={1} fontFamily={"Poppins, sans-serif"}>
        {label}
      </Text>
    </Flex>
  );
}
