import { RefObject, createContext, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, Flex } from "@chakra-ui/react";

import FollowUpQuestions from "./FollowUpQuestions";
import FormattedText from "components/ui/FormattedText";
import { ConversationProps, SourceProps } from "models/chat/MessageProps";
import BotMessageActions from "./BotMessageActions";
import References from "./References";

interface BotMessageProps {
  botResponse: ConversationProps;
  isLastMessage: boolean;
  messagesRef: RefObject<HTMLDivElement>;
}

export interface BotMessageContextProps {
  openAccordionIndex: number | null;
  setOpenAccordionIndex: React.Dispatch<React.SetStateAction<number | null>>;
  resetOpenAccordionIndex: () => void;
  scrollToAccordion: () => void;
  messagesRef: RefObject<HTMLDivElement> | null;
  openAccordionRef: RefObject<HTMLDivElement> | null;
}

export const BotMessageContext = createContext<BotMessageContextProps>({
  openAccordionIndex: null,
  setOpenAccordionIndex: () => {},
  resetOpenAccordionIndex: () => {},
  scrollToAccordion: () => {},
  messagesRef: null,
  openAccordionRef: null,
});

export default function BotMessage({
  botResponse,
  isLastMessage,
  messagesRef,
}: BotMessageProps) {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  // States
  const [isHovered, setIsHovered] = useState(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null
  );

  // Hooks
  const openAccordionRef = useRef<HTMLDivElement | null>(null);

  // Handlers
  function resetOpenAccordionIndex() {
    setOpenAccordionIndex(null);
  }

  function scrollToAccordion() {
    if (openAccordionRef.current && messagesRef.current) {
      const acc = openAccordionRef.current;
      const messagesContainer = messagesRef.current;

      // Get the position of the child relative to the messages container
      const accRect = acc.getBoundingClientRect();
      const containerRect = messagesContainer.getBoundingClientRect();

      // Calculate the scroll position
      const scrollToY =
        accRect.top + messagesContainer.scrollTop - containerRect.top - 10;

      // Perform the scroll
      messagesContainer.scrollTo({
        top: scrollToY,
        behavior: "smooth",
      });
    }
  }

  const currentMessageIndex = botResponse.messages.length - 1;
  const hasError = botResponse.messages[currentMessageIndex]?.ai?.includes(
    "I'm currently experiencing technical difficulties"
  );

  const currentMessage = botResponse?.messages[currentMessageIndex];

  const followupQuestions: string[] | undefined =
    botResponse?.followup_questions;
  const compounds: string[] | undefined = botResponse?.compounds;
  const sources = useMemo(() => {
    return (
      botResponse?.sources.reduce((acc: SourceProps[], item) => {
        const existingSrc = acc.find((src: SourceProps) => src.id === item.id);
        if (!existingSrc) {
          acc.push(item);
        }
        return acc;
      }, []) || []
    );
  }, [botResponse]);

  const rawReply = currentMessage?.ai?.split("\n").filter(Boolean);

  return (
    <BotMessageContext.Provider
      value={{
        openAccordionIndex,
        setOpenAccordionIndex,
        resetOpenAccordionIndex,
        messagesRef,
        openAccordionRef,
        scrollToAccordion,
      }}
    >
      <Card
        position="relative"
        display={"flex"}
        flexDirection={"column"}
        gap={6}
        boxShadow={"none"}
        bg={"transparent"}
        p={3}
        color={hasError ? "orange.400" : ""}
      >
        <Flex
          h={"full"}
          w={"full"}
          direction={"column"}
          gap={2}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {rawReply?.map((item, index) => (
            <FormattedText
              key={index}
              text={item}
              citations={currentMessage?.citations}
              sources={sources}
            />
          ))}

          {/* Bot Answer footer */}
          {(isHovered || isLastMessage) && (
            <Box w={"100%"}>
              <BotMessageActions data={botResponse} />
            </Box>
          )}
        </Flex>
        {botResponse && (
          <>
            {(!!sources?.length || !!compounds?.length) && (
              <References sources={sources} compounds={compounds} />
            )}
            <FollowUpQuestions
              questions={followupQuestions}
              onSendFollowUpQuestion={handleSendQuestion}
            />
          </>
        )}
      </Card>
    </BotMessageContext.Provider>
  );
}
