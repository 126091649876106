import { environment } from "../environments";
import { axiosPrivate } from "api/axios";

// Verify Verification Code
export const verifyCode = async (payload: { verificationCode: string }) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/api/verify_verification_code`,
    payload
  );
  return response.data;
};

export const signUp = async (payload: {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
}) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/api/register`,
    payload
  );
  return response.data;
};

export const confirmSignUp = async (payload: {
  code: string;
  email: string;
  user_sub: string;
}) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/api/verify_sign_up`,
    payload
  );
  return response.data;
};
