import { KeyboardEvent } from "react";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";

interface FirstNameInputProps {
  value: string;
  onChange: (input: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  error?: string;
}

function FirstNameInput({
  value,
  onChange,
  error,
  onKeyDown,
}: FirstNameInputProps) {
  return (
    <FormControl w="100%">
      <FormLabel
        htmlFor="givenName"
        color={"gray.500"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        First name
      </FormLabel>
      <Input
        color={"gray.500"}
        fontSize={"14px"}
        type="text"
        name="givenName"
        id="givenName"
        placeholder="Your first name"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        width="100%"
      />
      <Text
        mt={0.5}
        minH={4}
        fontSize={"12px"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={"1.3"}
        flexWrap={"wrap"}
        color={"red.600"}
      >
        {error}
      </Text>
    </FormControl>
  );
}

export default FirstNameInput;
