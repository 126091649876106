import { Flex, Icon, Link, Text } from "@chakra-ui/react";
import { LuExternalLink } from "react-icons/lu";
import { Link as RouterLink } from "react-router-dom";

interface DoiProps {
  data: string;
}

function Doi({ data }: DoiProps) {
  const doiQuery = `doi: ${data}`;

  return (
    <>
      {data ? (
        <Flex gap={1} color={"gray.500"} align={"center"}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            lineHeight={"1.3"}
          >
            DOI:
          </Text>
          <Link
            as={RouterLink}
            to={`https://www.google.com/search?q=${encodeURIComponent(
              doiQuery
            )}`}
            isExternal
            fontWeight={"400"}
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            lineHeight={"1.3"}
            w={"fit-content"}
            transition="all ease .2s"
            display={"flex"}
            alignItems={"center"}
            gap={2}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            <Text
              fontWeight={"400"}
              fontSize={[null, null, null, "12px", "12px", "14px"]}
              lineHeight={"1.3"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              maxW={"92%"}
            >
              {data}
            </Text>
            <Icon as={LuExternalLink} boxSize={"12px"} />
          </Link>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}

export default Doi;
