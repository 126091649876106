import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Clock from "./Clock";

interface ComingSoonProps {
  text: string;
}

export default function ComingSoon({ text }: ComingSoonProps) {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box
        maxW="600px"
        p="40px"
        borderRadius="10px"
        bg="transparent"
        display="flex"
        alignItems="center"
        position="relative"
        overflow="hidden"
      >
        {/* Content */}
        <Flex flex="1" direction="column" align="flex-start" pr="40px">
          <Heading
            as="h1"
            fontSize="2xl"
            mb="20px"
            fontFamily={"Poppins, sans-serif"}
          >
            Coming Soon
          </Heading>
          <Box>
            <Heading as="h3" fontSize="sm" mb="10px" opacity={0.65}>
              Stay tuned for exciting updates!
            </Heading>
            <Text lineHeight={"1.35"} fontSize={"15px"} opacity={0.85}>
              {text}
            </Text>
          </Box>
        </Flex>

        {/* Clock */}
        <Box flex="1" display="flex" justifyContent="center">
          <Clock />
        </Box>
      </Box>
    </Flex>
  );
}
