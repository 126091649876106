import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, useTheme, useToast } from '@chakra-ui/react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getProprietarySource } from '../helpers';
import { createContext, useEffect, useRef, useState } from 'react';
import { SortingState } from '@tanstack/react-table';
import { getTeam } from 'services/teams.services';
import { selectCurrentAuthData } from 'redux/features/auth/authSlice';
import { useSelector } from 'react-redux';
import { TeamProps } from 'models/posts/PostProps';
import { useDataLibraryAPI } from 'api/useDataLibraryAPI';
import { useQueryClient } from '@tanstack/react-query';
import { errorHandler } from "utils/helpers";

export interface ColumnFiltersProp {
    [key: string]: string
}

export interface FiltersState {
    showFilter: string | null;
    filters: ColumnFiltersProp;
}

interface SearchStateProps {
    searchValue: string;
    filters: ColumnFiltersProp;
    columnsFilters: FiltersState;
    sorting: SortingState;
}
interface ProprietaryContextType {
    sourceItemName: string;
    setSourceItemName: React.Dispatch<React.SetStateAction<string>>;
    results: any[] | null;
    setResults: React.Dispatch<React.SetStateAction<any[] | null>>;
    searchState: SearchStateProps;
    setSearchState: React.Dispatch<React.SetStateAction<SearchStateProps>>;
    connectedTeams: TeamProps[];
    getTeamDetails: Function;
    isLoading: boolean
}

const initialContextState: ProprietaryContextType = {
    sourceItemName: '',
    setSourceItemName: () => { },
    results: null,
    setResults: () => { },
    searchState: {
        searchValue: '',
        columnsFilters: { showFilter: null, filters: {} },
        filters: {},
        sorting: [],
    },
    setSearchState: () => { },
    connectedTeams: [],
    getTeamDetails: () => { },
    isLoading: false,
};


export const ProprietaryContext = createContext<ProprietaryContextType>(initialContextState);
const ProprietaryPanel = () => {
    const navigate = useNavigate();
    const { colors } = useTheme();
    const queryClient = useQueryClient();
    const { source, sourceItemId } = useParams();
    const proprietarySource = getProprietarySource(source);
    const [sourceItemName, setSourceItemName] = useState('')
    const [results, setResults] = useState<any[] | null>(null);
    const [searchState, setSearchState] = useState<SearchStateProps>({
        searchValue: '',
        filters: {},
        columnsFilters: { showFilter: null, filters: {} },
        sorting: [],
    });
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const [connectedTeams, setConnectedTeams] = useState<TeamProps[]>([]);
    const { user } = useSelector(selectCurrentAuthData);
    const toast = useToast();

    useEffect(() => {
        !sourceItemId && setSourceItemName('')
    }, [navigate])

    useEffect(() => {
        setSearchState({
            searchValue: '',
            filters: {},
            columnsFilters: { showFilter: null, filters: {} },
            sorting: [],
        })
    }, [source])

    const getTeamDetails = async () => {
        setIsLoading(true);
        try {
            // Fetch team details
            const res = await getTeam();
            const teams: any[] = [res];
            setConnectedTeams(teams);
        } catch (error: any) {
            // Handle errors

            if(error.response.status === 404){
            return
            }
            toast({
                description: error.response?.data?.message,
                status: error.response?.status === 400 ? "error" : "info",
                position: "top-right",
            });
        } finally {
            isMounted.current = false;
        }
    };

    useEffect(() => {
        if (isMounted.current == false && user.user_type === 'teams') {
            getTeamDetails();
        }
        console.log(user)
    }, []);
    return (
        <Flex height={'100%'} direction={'column'} rowGap={'6%'}>
            <Breadcrumb py={1} height={'2%'} color={"gray.600"}>
                <BreadcrumbItem onClick={() => proprietarySource && navigate(`/data/proprietary`)}>
                    <BreadcrumbLink
                        transition={'all ease-in-out .3s'}
                        color={proprietarySource ? 'inherit' : colors.highlight.primary}
                        _hover={{
                            textDecoration: proprietarySource ? 'underline' : 'none',
                            cursor: proprietarySource ? 'pointer' : 'default'
                        }}>All sources</BreadcrumbLink>
                </BreadcrumbItem>
                {proprietarySource && (
                    <BreadcrumbItem onClick={() => sourceItemName && navigate(`/data/proprietary/${proprietarySource.path}`)}>
                        <BreadcrumbLink
                            transition={'all ease-in-out .3s'}
                            textTransform={"lowercase"}
                            color={sourceItemName ? 'inherit' : colors.highlight.primary}
                            _hover={{
                                textDecoration: sourceItemName ? 'underline' : 'none',
                                cursor: sourceItemName ? 'pointer' : 'default'
                            }}>
                            {proprietarySource.category}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
                {sourceItemName && (
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            transition={'all ease-in-out .3s'}
                            textTransform={"lowercase"}
                            maxWidth={[null, null, null, '300px', '600px']}
                            isTruncated
                            color={colors.highlight.primary}
                            _hover={{
                                textDecoration: 'none',
                                cursor: 'default'
                            }}>
                            {sourceItemName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
            </Breadcrumb>

            <ProprietaryContext.Provider value={{ sourceItemName, setSourceItemName, searchState, setSearchState, results, setResults, connectedTeams, getTeamDetails, isLoading }}>
                <Outlet />
            </ProprietaryContext.Provider>
        </Flex>
    )
}

export default ProprietaryPanel
