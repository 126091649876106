import { useEffect } from "react";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  setSuccess,
  setFail,
  setLoading,
  selectCurrentSavedData,
} from "redux/features/research/savedSlice";
import { useToast } from "@chakra-ui/react";

const useFetchSavedElements = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  useEffect(() => {
    const fetchSavedElements = async () => {
      dispatch(setLoading());

      try {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/saved_elements`
        );
        console.log(response.data)
        dispatch(setSuccess(response.data));
      } catch (error) {
        dispatch(setFail("An error occurred"));
        toast({
          title: "An error occurred",
          status: "error",
          duration: 1500,
        });
      }
    };

    fetchSavedElements();
  }, [axiosPrivate, dispatch, toast]);

  return { loading, error, savedFolders };
};

export default useFetchSavedElements;
