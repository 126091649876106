import { Flex, Text } from "@chakra-ui/react";

interface CitationProps {
  data: number | undefined;
}

export default function Citation({ data }: CitationProps) {
  return (
    <>
      {!!data ? (
        <Flex gap={1} color={"gray.500"} align={"center"}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            lineHeight={"1.3"}
          >
            Citation:
          </Text>
          <Text
            fontWeight={"400"}
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            lineHeight={"1.3"}
          >
            {data ?? 0}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}
