import { GridItem, Flex, Text, Heading, Tag, Icon } from "@chakra-ui/react";
import { BsPatchExclamation } from "react-icons/bs";
import { PiShootingStar } from "react-icons/pi";
import { IconType } from "react-icons";

interface DisclaimerTagProps {
  icon: IconType;
  text: string;
  isDisclaimer?: boolean;
}

function Content() {
  return (
    <GridItem
      colSpan={{ base: 3, md: 2 }}
      display={"flex"}
      flexDirection={"column"}
      gap={{ base: 12, md: 8 }}
    >
      {/* Header content */}
      <Flex direction={"column"} gap={{ base: 8, md: 4, lg: 2 }}>
        <Flex
          direction={"column"}
          gap={{ base: 2, md: 1 }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Text
            fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px" }}
            fontWeight={{ base: "400", lg: "500" }}
            color={"secondary.500"}
            letterSpacing={{ base: 1, lg: 3 }}
            lineHeight={1}
          >
            - About
          </Text>
          <Heading
            fontSize={{ base: "24px", md: "28px", lg: "32px" }}
            lineHeight={1}
            color={"primary.800"}
            fontFamily={"Poppins, sans-serif"}
          >
            What is MoleculeLake?
          </Heading>
        </Flex>

        <Text
          color={"black"}
          opacity={0.75}
          w={{ base: "85%", md: "90%" }}
          mx={{ base: "auto", md: 0 }}
          fontSize={{ base: "14px", md: "15px", lg: "16px" }}
          textAlign={{ base: "center", md: "left" }}
        >
          MoleculeLake leverages latest advancements in generative AI,
          empowering you to efficiently navigate privileged data and the total
          corpus of scientific literature.
          <br />
          <br />
          Drastically reduce the time for discovery and analysis with the
          assistance of our intelligent agent. Explore an enriched database of
          molecules and compounds, investigate connections to qualitative
          insights from millions of scientific publications, compounds, and
          assays. Thereby speeding up the development of new combinatorial
          therapies and drug repurposing.
        </Text>
      </Flex>

      {/* Disclaimers */}
      <Flex direction={"column"} gap={4}>
        {/* Upcoming */}
        <Flex
          direction={"column"}
          gap={1}
          borderRadius={{ base: "6px", md: 0 }}
          borderWidth={{ base: 1, md: 0 }}
          borderColor={"highlight.primary"}
          bg={{ base: "highlight.primary", md: "transparent" }}
          p={{ base: 3, md: 0 }}
        >
          <DisclaimerTag icon={PiShootingStar} text={"Upcoming"} />
          <Text
            fontSize={"small"}
            color={{ base: "white", md: "black" }}
            opacity={{ base: 1, md: 0.7 }}
            px={{ base: 2, md: 0 }}
            w={{ base: "100%", md: "90%" }}
          >
            As a continuously evolving platform, MoleculeLake holds the promise
            of aggregating research papers and proprietary data, providing
            access to the latest advancements in the ever-expanding realm of
            research.
          </Text>
        </Flex>

        {/* Disclaimer */}
        <Flex
          direction={"column"}
          gap={1}
          borderRadius={{ base: "6px", md: 0 }}
          borderWidth={{ base: 1, md: 0 }}
          borderColor={"secondary.500"}
          bg={{ base: "secondary.500", md: "transparent" }}
          p={{ base: 3, md: 0 }}
        >
          <DisclaimerTag
            icon={BsPatchExclamation}
            text={"Disclaimer"}
            isDisclaimer
          />
          <Text
            fontSize={"small"}
            color={{ base: "white", md: "black" }}
            opacity={{ base: 1, md: 0.7 }}
            px={{ base: 2, md: 0 }}
            w={{ base: "100%", md: "90%" }}
          >
            While we strive for accuracy and reliability, we cannot always
            guarantee the output of our models. We appreciate your kind feedback
            as we are still under early development. Our team is working very
            hard on improving the user experience.
          </Text>
        </Flex>
      </Flex>
    </GridItem>
  );
}

export default Content;

function DisclaimerTag({ icon, text, isDisclaimer }: DisclaimerTagProps) {
  return (
    <Tag
      display={"flex"}
      gap={2}
      alignItems={"center"}
      color={"whiteAlpha.900"}
      bg={isDisclaimer ? "secondary.500" : "highlight.primary"}
      w={"130px"}
      py={0}
    >
      <Icon as={icon} boxSize={"16px"} />
      <Heading
        fontSize={"13px"}
        opacity={0.9}
        textTransform={"uppercase"}
        lineHeight={1}
        letterSpacing={"1px"}
        fontFamily={"Poppins, sans-serif"}
        mt={0.5}
      >
        {text}
      </Heading>
    </Tag>
  );
}
