import { Text, Flex } from "@chakra-ui/react";

interface AbstractProps {
  data: string | null | undefined;
}

function Abstract({ data }: AbstractProps) {
  return (
    <>
      {data ? (
        <Flex direction={'column'} gap={1} color={"gray.500"}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            lineHeight={"1.3"}
          >
            Abstract:
          </Text>
          <Text
            fontWeight={"400"}
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            lineHeight={"1.3"}
          >
            {data}
          </Text>
        </Flex>
      ) : null}
    </>
  );
}

export default Abstract;
