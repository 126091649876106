import { Text } from "@chakra-ui/react";

interface ChatDateProps {
  date: string;
}

export default function ChatDate({ date }: ChatDateProps) {
  return (
    <Text
      fontSize={"10px"}
      fontWeight={"500"}
      lineHeight={"1"}
      fontFamily={"Poppins, sans-serif"}
      mb={1}
      pl={1.5}
      color={"secondary.400"}
    >
      {date.toUpperCase()}
    </Text>
  );
}
