import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/research/savedSlice";

import {
  Flex,
  Box,
  SimpleGrid,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";

import MainPanelError from "components/ui/MainPanelError";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";

import { SavedFolderProps } from "models/research/SavedProps";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useMemo, useState } from "react";
import { environment } from "environments";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import NoFolders from "components/research/NoFolders";
import FoldersItem from "components/research/FoldersItem";

export default function FoldersList() {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  // States
  const [deleting, setDeleting] = useState(false);

  const hasSavedFolders = useMemo(
    () => savedFolders && savedFolders.length > 0,
    [savedFolders]
  );

  // Handlers
  async function handleDeleteFolder(folderId: string) {
    if (!folderId) return;

    setDeleting(false);

    console.log(folderId);

    try {
      setDeleting(true);

      await axiosPrivate.delete(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${folderId}`
      );

      // update store
      let newFolders: SavedFolderProps[] = savedFolders?.filter(
        (folder: SavedFolderProps) => folder.id !== folderId
      );
      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder deleted successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setDeleting(false);
    }
  }

  function handleOpenFolder(id: string) {
    const currentSection = location.pathname.includes("team")
      ? "/team"
      : "/data/research";
    navigate(`${currentSection}/${id}`);
  }

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)", // 150 = (8x2) + 62 + 24 + 32 + 16 : py + nav + gap + breadcrumb
    xl: "calc(100vh - 162px)", // 158 = (12x2) + 62 + 24 + 32 + 16
    "2xl": "calc(100vh - 178px)", // 174 = (24x2) + 62 + 24 + 32 + 16
  });

  // Style
  const listStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 6px 0",
  };

  if (!!error) {
    return (
      <Flex h={listHeight} w={"100%"}>
        <MainPanelError errorMessage={errorHandler(error).message} />
      </Flex>
    );
  }

  if (!!loading) {
    return (
      <Flex h={listHeight} w={"100%"} border={"2px solid gray"}>
        <Loading message="Loading folders.." />
      </Flex>
    );
  }

  return (
    <>
      {hasSavedFolders ? (
        <CustomScrollBar style={listStyle}>
          <SimpleGrid columns={3} spacing={4}>
            {savedFolders?.map((folder: SavedFolderProps) => {
              return (
                <FoldersItem
                  key={"folder-" + folder.id}
                  folder={folder}
                  deleting={deleting}
                  onDeleteFolder={() => handleDeleteFolder(folder.id)}
                  onClick={() => handleOpenFolder(folder.id)}
                />
              );
            })}
          </SimpleGrid>
        </CustomScrollBar>
      ) : (
        <NoFolders />
      )}
    </>
  );
}
