import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useDataLibraryAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchTableData = async ({ queryKey, signal }: any) => {
    const [, params] = queryKey;
    console.log(signal)
    const { query, page, itemsPerPage, sort, order, source, filters } = params;
    const url = `${environment.BACKEND_API}/api/data_library_core`;
    const res = await axiosPrivate.get(url, {
      params: { query, page, itemsPerPage, source, sort, order, filters: JSON.stringify(filters) },
      signal,
    });
    return res.data;
  };

  const fetchProprietaryTableData = async ({ queryKey, signal }: any) => {
    const [, params] = queryKey;
    console.log(signal)
    const { query, page, itemsPerPage, sort, order, source, filters } = params;
    const url = `${environment.BACKEND_API}/api/proprietaries`;
    const res = await axiosPrivate.get(url, {
      params: { query, page, itemsPerPage, source, sort, order, filters: JSON.stringify(filters) },
      signal,
    });
    return res.data;
  };

  return { fetchTableData, fetchProprietaryTableData};
};
