export const minWidth = [
  null,
  null,
  null,
  "calc(100vw - 118px - max(20vw, 240px))",
  "calc(100vw - 146px - max(20vw, 240px))",
  "calc(100vw - 164px - max(20vw, 240px))",
];

export const maxWidth = [
  null,
  null,
  null,
  "calc(100vw - 118px)",
  "calc(100vw - 146px)",
  "calc(100vw - 164px)",
];

// Responsiveness: ~992px, ~1280px, ~1536px
export const maxHeight = {
  lg: "calc(100vh - 32px)",
  xl: "calc(100vh - 40px)",
  "2xl": "calc(100vh - 48px)",
};

export const savedNotificationPosition = {
  bottomPos: {
    lg: "197px",
    xl: "230px",
    "2xl": "263px",
  },
  leftPos: {
    lg: "70px",
    xl: "90px",
    "2xl": "100px",
  },
  arrowTop: {
    lg: "calc(50% - 6px)",
    xl: "calc(50% - 8px)",
    "2xl": "calc(50% - 10px)",
  },
  arrowLeft: {
    lg: "-12px",
    xl: "-16px",
    "2xl": "-18px",
  },
  arrowSize: {
    lg: "6px",
    xl: "8px",
    "2xl": "10px",
  },
};
