import { Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react";
import { IoSparklesSharp } from "react-icons/io5";

function NoChats() {
  const chatHistoryHeight = useBreakpointValue({
    lg: "calc(100vh - 306px)", // 300 = (8x2) + (16x2) + 32 + 132 + 32 + (3x16) + 8: (2.py) + (2.py) + logo + nav + avatar + gaps + mb
    xl: "calc(100vh - 324px)", // 320 = (12x2) + (16x2) + 34 + 136 + 32 + (3x16) + 12
    "2xl": "calc(100vh - 340px)", // 332 = (16x2) + (16x2) + 34 + 138 + 32 + (3x16) + 16
  });

  return (
    <Flex
      direction={"column"}
      gap={6}
      h={chatHistoryHeight}
      w={"full"}
      justify={"center"}
      align={"center"}
      borderRadius={3}
      color={"highlight.primary"}
      fontFamily={"Poppins"}
      fontWeight={"500"}
      fontSize={"13px"}
      textAlign={"center"}
    >
      <Text display={"flex"} gap={1.5} justifyContent={"center"}>
        <Icon as={IoSparklesSharp} boxSize={4} />
        Initiate new chat
      </Text>
    </Flex>
  );
}

export default NoChats;
