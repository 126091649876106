import { memo, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import SourcesTabPanel from "./sources/SourcesTabPanel";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";
import { useQueries } from "@tanstack/react-query";
import { PaperNetworkViewProps } from "views/papers/NetworkView";
import MainPanelError from "components/ui/MainPanelError";
import Loading from "components/ui/Loading";
import { SourceProps } from "models/chat/MessageProps";

interface SourcesComProps {
  sources: SourceProps[];
}

function Sources({ sources }: SourcesComProps) {

  const { fetchSimilarPapers } = useLiteraturesAPI();

  // API
  // * Fetch similar papers
  const similarPapersQueries = useQueries({
    queries: sources.map((source: { id: string; title: string }) => ({
      queryKey: ["literatures", "network", source.id],
      queryFn: fetchSimilarPapers,
      staleTime: 40 * 1000 * 60, // After 40 mins, a refetch will be triggered the next time the query is accessed
      gcTime: 30 * 1000 * 60, // After 30 mins, remove unused cached data
      retry: 1, // the query function will be retried once more in case of failure
    })),
  });

  const allFinished = similarPapersQueries.every((query) => !query.isLoading);
  const noErrorFound = similarPapersQueries.every((query) => !query.error);

  const data = useMemo(() => {
    return similarPapersQueries
      .filter((query) => query.data !== undefined)
      .map((query) => query.data as PaperNetworkViewProps);
  }, [similarPapersQueries]);

  // loading
  if (!allFinished)
    return (
      <Box my={6}>
        <Loading message={"Loading sources..."} />
      </Box>
    );

  if (!noErrorFound) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading sources"} />
      </Box>
    );
  }

  return <SourcesTabPanel sources={data} />;
}

export default memo(Sources);
