import { ReactNode } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";

interface ContainerProps {
  children: ReactNode;
  id: string;
}

function Container({ children, id }: ContainerProps) {
  // Theme
  const { colors } = useTheme();
  const { homeGradient, homeGradientDark } = GetGradients();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const bgGradient = colorMode === "light" ? homeGradient : homeGradientDark;
  const bgColor =
    colorMode === "light" ? colors.secondary[900] : colors.secondary[100];

  return (
    <Box
      backgroundImage={
        "url(https://reacto.s3.eu-central-1.amazonaws.com/H-background.png)"
      }
      bgRepeat="no-repeat"
      bgPosition={{ base: "left", lg: "center" }}
      position="relative"
      bgSize="cover"
      h="100vh"
      id={id}
    >
      {/* Color overlay */}
      <Box
        bg={{ base: hexToRgba(bgColor, 0.8), lg: "transparent" }}
        bgGradient={{ base: "unset", lg: bgGradient }}
        p={["20px", "30px", "40px", "40px", "40px", "60px"]}
        px={["20px", "30px", "40px", "40px", "60px", "80px"]}
        pb={0}
        position={"absolute"}
        top={"0"}
        left={"0"}
        right={"0"}
        bottom={"0"}
        color={"whiteAlpha.900"}
        textAlign={"center"}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Container;
