import { Box, Flex, Text, useTheme, useToast } from "@chakra-ui/react";
import Loading from "components/ui/Loading";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  selectCurrentPostsData,
  setTeamData,
} from "redux/features/posts/postsSlice";
import { getTeam } from "services/teams.services";
import { hexToRgba } from "utils/helpers";

interface CreatePostContextProps {
  getTeamDetails: Function;
}

export const PostContext = createContext<CreatePostContextProps>({
  getTeamDetails: () => {},
});

export default function Team() {
  const { user } = useSelector(selectCurrentAuthData);

  const titles = ["Members", "Folders", "Posts"];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { colors } = useTheme();
  const toast = useToast();
  const isMounted = useRef(false);

  let { unReadMessages, teamData } = useSelector(selectCurrentPostsData);

  const [isLoading, setIsLoading] = useState(false);

  const getTeamDetails = async () => {
    setIsLoading(true);
    await getTeam()
      .then((res: any) => {
        dispatch(setTeamData(res));
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast({
            description: error.response.data.message
              ? error.response.data.message
              : "Error fetching team details",
            status: "error",
            position: "top-right",
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user?.can_access_teams === false) {
      navigate("/data");
    } else if (
      isMounted.current === false &&
      location.pathname === "/team" &&
      teamData == null
    ) {
      isMounted.current = true;
      getTeamDetails();
    }
  }, []);

  if (isLoading && location.pathname === "/team") {
    return (
      <Flex
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
      >
        <Loading message="Loading team data ..." />
      </Flex>
    );
  }

  return (
    <Flex
      height="100%"
      width={["90%", "80%"]}
      maxW="98%"
      direction="column"
      mx="auto"
      py={4}
      gap="16px"
      overflow={"hidden"}
    >
      {/* Tabs */}
      <Flex
        w={"100%"}
        justify={"center"}
        align={"center"}
        p={3}
        borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
        borderBottomWidth={1}
      >
        <Flex w={"fit-content"} h={"fit-content"}>
          {location.pathname.includes("accept-invite") === false && (
            <>
              {titles.map((title, index) => {
                const isSelected = location.pathname.includes(
                  title.toLowerCase()
                );
                const isRoot = location.pathname === "/team" && index === 0;
                const isActive = isSelected || isRoot;
                return (
                  <Box
                    key={index}
                    px={5}
                    py={2}
                    cursor="pointer"
                    w={"fit-content"}
                    textAlign={"center"}
                    borderRadius={"99px"}
                    bg={
                      isActive
                        ? hexToRgba(colors.blue[300], 0.15)
                        : "transparent"
                    }
                    color={isActive ? "blue.500" : "gray.500"}
                    transition={"all .3s ease"}
                    onClick={() =>
                      navigate(
                        `/team${
                          title === "Members" ? "" : "/" + title.toLowerCase()
                        }`
                      )
                    }
                  >
                    <Text fontSize="md">{title}</Text>
                    {title === "Posts" && unReadMessages > 0 && (
                      <Box bg={"red.500"} borderRadius={"full"} width={2.5} height={2.5}></Box>
                    )}
                  </Box>
                );
              })}
            </>
          )}
        </Flex>
      </Flex>

      {/* Content */}
      <Box pt={2} height={"calc(100% - 58px)"} width="100%">
        <PostContext.Provider
          value={{            
            getTeamDetails: getTeamDetails
          }}
        >
          <Outlet />
        </PostContext.Provider>
      </Box>
    </Flex>
  );
}
