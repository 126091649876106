import { Flex, Image, Text } from "@chakra-ui/react";

function VitafluenceContent() {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const purple = colorMode === "light" ? "purple.800" : "purple.100";

  return (
    <Flex
      direction={{ base: "row", md: "column" }}
      align={{ base: "center", md: "flex-start" }}
      justify={"flex-start"}
      gap={1}
      flexWrap={"wrap"}
    >
      <Image
        width={["120px", "150px", "60%", "60%", "65%", "70%"]}
        objectFit={"cover"}
        src="https://vf-website-assets.s3.eu-central-1.amazonaws.com/logos/vitafluence.svg"
        mr={{ base: 3, md: 0 }}
      />
      <Text
        color={purple}
        fontWeight={"500"}
        fontSize={["11px", "12px", "13px", "14px", "15px", "16px"]}
        fontFamily={"Poppins, sans-serif"}
        w={{ base: "fit-content", md: "95%" }}
        pt={{ base: "6px", md: "0" }}
      >
        Accelerating scientific discovery
      </Text>
    </Flex>
  );
}

export default VitafluenceContent;
