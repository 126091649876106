import { useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { Box, Flex, Icon, Link, Text, useTheme } from "@chakra-ui/react";

import { IconType } from "react-icons";
import { useMemo } from "react";
import { hexToRgba } from "utils/helpers";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

export interface ItemProps {
  id: string;
  name: string;
  link: string;
  icon: IconType;
  activeIcon: IconType;
  tooltip: string;
  rightIcon?: IconType;
  counter?: boolean
}

export interface NavItemProps {
  item: ItemProps;
}

export default function NavItem({ item }: NavItemProps) {
  const { isHighlighted } = useGuideTour()
  let highlighted = isHighlighted(item.id)

  const { name, link, icon, activeIcon, id } = item;
  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  let { unReadMessages } = useSelector(selectCurrentPostsData);  

  // Theme
  const { colors } = useTheme();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // active route
  const isActive = useMemo(
    () => location?.pathname?.includes(link),
    [location, link]
  );

  const applyIsActiveStyles = isActive && !isGuideOpen;
  
  return (
    <Link
      zIndex={highlighted ? 2000 : "inherit"}
      as={RouterLink}
      to={highlighted ? "#" : link}
      id={id}
      p={2.5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      borderRadius={"6px"}
      letterSpacing={applyIsActiveStyles || highlighted ? "-0.012rem" : 0}
      borderWidth={1}
      borderColor={
        applyIsActiveStyles
          ? hexToRgba(colors.highlight.primary, 0.16)
          : "transparent"
      }
      color={
        applyIsActiveStyles || highlighted ? "highlight.primary" : "gray.500"
      }
      bg={
        highlighted
          ? "background"
          : applyIsActiveStyles
            ? hexToRgba(colors.highlight.primary, 0.12)
            : "transparent"
      }
      fontWeight={applyIsActiveStyles || highlighted ? "500" : "400"}
      _hover={{
        textDecoration: "none",
        bg: highlighted
          ? "background"
          : applyIsActiveStyles
            ? hexToRgba(colors.highlight.primary, 0.12)
            : "gray.100",
      }}
    >
      <Flex align={"center"} gap={2}>
        <Icon
          as={applyIsActiveStyles || highlighted ? activeIcon : icon}
          boxSize={{ lg: 3.5, xl: 4 }}
        />
        <Text
          fontSize={{ lg: "13px", xl: "14px" }}
          textTransform={"capitalize"}
        >
          {name}
        </Text>
      </Flex>

      {item.rightIcon && (
        <Icon
          pointerEvents={highlighted ? "none" : "auto"}
          zIndex={10}
          as={item.rightIcon}
          boxSize={{ lg: 3, xl: 4 }}
          _hover={{
            textDecoration: "none",
            color: "secondary.400",
          }}
          onClick={() => navigate("/chat")}
        />
      )}

      {item.counter && unReadMessages>0 && (
        <Box
          bg={"red.500"}
          borderRadius={"full"}
          display={"flex"}          
          minWidth={5}
          height={5}
          py={0.5}
          px={1}
          fontSize={"10px"}
          textAlign={"center"}
          justifyContent={"center"}
        >
          <Text margin={"auto"} color={"white"}>{unReadMessages}</Text>
        </Box>
      )}
    </Link>
  );
}
