import { Flex, Icon, Text } from "@chakra-ui/react";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { TbMessage2 } from "react-icons/tb";

interface FeedbackButtonProps {
  handleClick: () => void;
}

function FeedbackButton({ handleClick }: FeedbackButtonProps) {
  const { isHighlighted } = useGuideTour();
  let highlighted = isHighlighted("feedback");

  return (
    <Flex
      id="feedback"
      zIndex={highlighted ? 1350 : 999}
      bg={"highlight.primary"}
      overflow={"hidden"}
      align={"center"}
      position={"absolute"}
      top={"50%"}
      w={"fit-content"}
      h={"36px"}
      right={"-40.5px"}
      color={"background"}
      borderRadius={"8px 8px 0 0"}
      transform={"translateY(-50%) rotate(-90deg)"}
      transition={"all 0.3s ease"}
      _hover={{ opacity: 0.9 }}
      cursor={"pointer"}
      onClick={handleClick}
      m={0}
      px={3}
      gap={2}
    >
      <Icon as={TbMessage2} boxSize={"18px"} />

      <Text
        h={"fit-content"}
        alignSelf={"center"}
        fontWeight={"500"}
        fontSize={"14px"}
        letterSpacing={".05rem"}
      >
        Feedback
      </Text>
    </Flex>
  );
}

export default FeedbackButton;
