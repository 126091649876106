export function stringToColor(str: string) {
  if (!str) return;

  const colors = [
    "#555E50",
    "#008A0D",
    "#161FA8",
    "#281F5D",
    "#FD2400",
    "#E49FF6",
    "#EB7013",
    "#930081",
    "#F7BBC7",
    "#00F60B",
  ];
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}
