import { AccordionButton, AccordionIcon, Box } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";

interface AccordionHeaderProps {
  text: string;
  isBold?: boolean;
}

function AccordionHeader({ text, isBold }: AccordionHeaderProps) {
  // Theme
  const { lGradient2 } = GetGradients();

  return (
    <AccordionButton
      py={[1, 2]}
      px={[3, 4]}
      _expanded={{ bgGradient: lGradient2 }}
      _hover={{ bgGradient: lGradient2 }}
      fontWeight={isBold ? "500" : "400"}
      color={"secondary.500"}
      borderColor={"gray.200"}
      borderWidth={1}
      borderRadius={"6px"}
      overflow={"hidden"}
      fontSize={["sm", "md"]}
    >
      <Box
        as="span"
        flex="1"
        textAlign="left"
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
      >
        {text}
      </Box>
      <AccordionIcon />
    </AccordionButton>
  );
}

export default AccordionHeader;
