import { Flex, Heading, Text } from "@chakra-ui/react";

function Headers() {
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const color = colorMode === "light" ? "secondary.100" : "secondary.900";

  return (
    <Flex
      direction={"column"}
      gap={2}
      textAlign={{ base: "center", md: "left" }}
    >
      <Heading size={{ base: "sm", lg: "md", xl: "lg" }} color={color}>
        Be AI empowered!
      </Heading>
      <Text fontSize={{ base: "sm", xl: "md" }} color={color} opacity={0.7}>
        Scientific discovery awaits you. Request access today!
      </Text>
    </Flex>
  );
}

export default Headers;
