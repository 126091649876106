import { Card, Text, useTheme } from "@chakra-ui/react";
import { ConversationProps } from "models/chat/MessageProps";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { hexToRgba } from "utils/helpers";

interface HumanMessageProps {
  botResponse?: ConversationProps | undefined;
  questionOnWait?: string | undefined;
}

export default function HumanMessage({
  botResponse,
  questionOnWait,
}: HumanMessageProps) {
  let question: string = "";

  if (botResponse) {
    const currentMessageIndex = botResponse.messages?.length - 1;
    question = botResponse?.messages[currentMessageIndex]?.human;
  }

  // Theme
  const { colors } = useTheme();

  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  return (
    <Card
      p={4}
      borderRadius={"20px"}
      position="relative"
      boxShadow={"none"}
      bg={isGuideOpen ? 'transparent' : hexToRgba(colors.secondary[100], 0.8)}
    >
      <Text
        color={"gray.700"}
        lineHeight={"1.3"}
        fontSize={{ lg: "14px", "2xl": "15px" }}
        display={"flex"}
        gap={3}
        textAlign={"left"}
      >
        {!!questionOnWait ? questionOnWait : question}
      </Text>
    </Card>
  );
}
