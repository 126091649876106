import { Flex, useMediaQuery } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import MainButton from "../MainButton";

interface AboutUsProps {
  setShowInfo: Dispatch<SetStateAction<boolean>>;
  setShowPrivacy: Dispatch<SetStateAction<boolean>>;
  setShowDisclaimers: Dispatch<SetStateAction<boolean>>;
}

function AboutUs({
  setShowInfo,
  setShowPrivacy,
  setShowDisclaimers,
}: AboutUsProps) {
  // Redux
  const { user } = useSelector(selectCurrentAuthData);
  const isUser = !!(user && user?.id);

  // Navigation
  const navigate = useNavigate();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Handler
  function handleClick() {
    if (isUser) {
      if (isMobileView) {
        setShowInfo(true);
      } else {
        navigate("/chat");
      }
    } else {
      navigate("/login");
    }
  }

  return (
    <Flex
      direction={"column"}
      gap={{ base: 1, lg: 3 }}
      mt={{ base: "auto", lg: 6 }}
      justify={"flex-end"}
      minW={{ base: "fit-content", md: "200px" }}
    >
      <Flex
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        fontWeight={"500"}
        cursor={"pointer"}
        bg={"transparent"}
        color={"secondary.600"}
        w={"fit-content"}
        height={"fit-content"}
        onClick={() => setShowPrivacy(true)}
      >
        Privacy Policy
      </Flex>

      <Flex
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        fontWeight={"500"}
        cursor={"pointer"}
        bg={"transparent"}
        color={"secondary.600"}
        w={"fit-content"}
        height={"fit-content"}
        onClick={() => setShowDisclaimers(true)}
      >
        Disclaimers
      </Flex>

      <MainButton
        inFooter
        text={"Get Started"}
        onClick={handleClick}
      />
    </Flex>
  );
}

export default AboutUs;
