import { Flex } from "@chakra-ui/react";
import ComingSoon from "components/ui/ComingSoonImage";

export default function Tools() {
  return (
    <Flex
      direction={"column"}
      w={"100%"}
      justifyContent={"center"}
      mx={"auto"}
    >
      <ComingSoon
        text={
          "Great number of models and tools ready to use to accelerate your drug discovery."
        }
      />
    </Flex>
  );
}
