import { useState } from "react";
import { Heading, Text, Flex, useTheme, Link } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import UpdateChecker from "components/ui/UpdateChecker";
import Register from "./registerInputs/Register";
import { useNavigate } from "react-router-dom";

export default function RegisterForm() {
  // Theme
  const { colors } = useTheme();

  // Hook
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    fullname: "",
    password: "",
  });

  // Theme
  const { appTitleGradient } = GetGradients();

  return (
    <Flex
      direction="column"
      justify="center"
      align="left"
      gap={4}
      p={10}
      borderRadius={"10px"}
      bg={hexToRgba(colors.white, 0.95)}
      boxShadow={"0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"}
      backdropFilter={"blur( 4.5px)"}
      borderWidth={1}
      borderColor={hexToRgba(colors.black, 0.18)}
    >
      <UpdateChecker />
      <Heading fontSize="14px" color={hexToRgba(colors.black, 0.7)} mb={"0"}>
        Welcome to{" "}
        <Text
          as="span"
          fontWeight={"bold"}
          bgGradient={appTitleGradient}
          bgClip="text"
          cursor={"pointer"}
          onClick={() => navigate("/")}
        >
          MoleculeLake
        </Text>
      </Heading>

      <Heading fontSize="26px" mb={"12px"}>
        Create Your Account
      </Heading>

      <Register setFormData={setFormData} formData={formData} />

      <Text mt={6} fontSize={"14px"}>
        Already have an account?
        <Link pl={1} onClick={() => navigate("/login")} color={"primary.400"}>
          Login
        </Link>
      </Text>
    </Flex>
  );
}
