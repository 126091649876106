import { Box, Card } from "@chakra-ui/react";
import { ReactNode } from "react";

interface PropsCardProps {
  children: ReactNode;
}

function PropsCard({ children }: PropsCardProps) {
  return (
    <Box as={Card} mb={2} p={4} bg={"background"} overflow={"hidden"}>
      {children}
    </Box>
  );
}

export default PropsCard;
