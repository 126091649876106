import { Box, Button, Divider, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { format } from "date-fns";
import PricingModal from "components/home/PricingModal";

import { MdOutlinePayments } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { User } from "redux/features/auth/authSlice";
import { titleCase } from "utils/helpers";
import { FaArrowRight } from "react-icons/fa6";
import InvoicesModal from "components/home/InvoicesModal";

export interface UserProps {
  user: User | null;
}

function CurrentPlan({ user }: UserProps) {
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);

  const planName =
    user?.user_type === "teams" ? "Enterprise" || "" : user?.user_type || "";

  const getNextPayment = () => {
    let next = null;
    if (user && user.user_wallet.wallet > 0) {
      if (user.user_wallet.is_yearly) {
        const updated_on = new Date(user.user_wallet.updated_on);
        const year = updated_on.getFullYear();
        const month = updated_on.getMonth();
        const day = updated_on.getDate();
        const next = new Date(year + 1, month, day);
        return format(next, "MMMM d, yyyy");
      } else {
        const updated_on = new Date(user.user_wallet.updated_on);
        next = new Date(updated_on.setMonth(updated_on.getMonth() + 1));
        return format(next, "MMMM d, yyyy");
      }
    }
    return next;
  };

  return (
    <Flex gap={3} direction={"column"}>
      <Flex gap={2} align={"center"}>
        <Icon
          as={MdOutlinePayments}
          transform={"rotate(90deg)"}
          boxSize={"18px"}
          opacity={0.8}
        />
        <Text fontWeight={"600"} lineHeight={"1.4"}>
          Subscription Plan
        </Text>
      </Flex>

      <Divider />

      <Flex direction={"column"} gap={1}>
        <Flex justify="space-between">
          <Flex direction={"column"}>
            <Text
              color={"gray.500"}
              fontFamily={"Poppins, sans-serif"}
              fontSize={"18px"}
              fontWeight={"700"}
            >
              {titleCase(planName)} Plan
            </Text>
            {getNextPayment() && (
              <Flex align={"center"}>
                <Flex direction={"row"} gap={1}>
                  <Text>Next Payment: </Text>
                  <Text color={"gray.500"} fontWeight={"500"}>
                    {getNextPayment()}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Button
              mt={3}
              py={3}
              px={6}
              type={"submit"}
              borderRadius={"30px"}
              bg={"highlight.primary"}
              color={"background"}
              h={"fit-content"}
              w={"fit-content"}
              fontSize={"14px"}
              fontWeight={"500"}
              leftIcon={<RiExchangeDollarLine size={"18px"} />}
              letterSpacing={".02rem"}
              _active={{ bg: "highlight.primary" }}
              _hover={{ bg: "highlight.primary" }}
              _focus={{ bg: "highlight.primary" }}
              _focusWithin={{ bg: "highlight.primary" }}
              onClick={() => setShowPricingModal(true)}
            >
              Change Plan
            </Button>
          </Flex>
          <Flex gap={1}>
            <Box>
              <Flex align={"center"}>
                <Flex
                  direction={"row"}
                  align={"center"}
                  color="highlight.primary"
                  gap={1}
                >
                  <Link onClick={() => setShowInvoicesModal(true)}>
                    Invoices
                  </Link>
                  <FaArrowRight />
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <PricingModal
        isOpen={showPricingModal}
        onClose={() => setShowPricingModal(false)}
      />
      <InvoicesModal
        isOpen={showInvoicesModal}
        onClose={() => setShowInvoicesModal(false)}
      />
    </Flex>
  );
}

export default CurrentPlan;
