import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import useGuideTour from 'hooks/guideTour/useGuideTour';
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'

interface DataSearchInputProps {
  searchVal: string;
  // requestOngoing: boolean;
  submitSearchForm: (inputStr: string) => void;
  clearSearch: () => void;
}

export default function DataSearchInput({
  searchVal,
  submitSearchForm,
  clearSearch,
  // requestOngoing,
}: DataSearchInputProps) {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const { isHighlighted } = useGuideTour()
  let highlighted = isHighlighted('proprietary_table-search')

  useEffect(() => setInputSearchValue(searchVal), [searchVal]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim();
    setInputSearchValue(e.target.value);

    if (!val) {
      clearSearch();
    }
  };

  const handleClearInput = () => {
    setInputSearchValue("");
    clearSearch();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submitSearchForm(inputSearchValue.trim());
    }
  };

  return (
    <InputGroup
      p="1px"
      maxW="300px"
      transition={'all ease-in-out .1s'}
      id='proprietary_table-search'
      zIndex={highlighted ? 2000 : "inherit"}
      bg={highlighted ? 'background' : 'transparent'}
    >
      <Input
        // isDisabled={requestOngoing}
        type="text"
        id="search"
        placeholder="Search..."
        value={inputSearchValue}
        onChange={onChangeInput}
        onKeyDown={handleKeyDown}
        size="md"
        bg="transparent"
      />
      {/* {inputSearchValue && !requestOngoing && ( */}
      {inputSearchValue && (
        <InputRightElement>
          <IoClose onClick={handleClearInput} cursor={"pointer"} />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
