import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentSavedData } from "redux/features/research/savedSlice";
import {
  SavedAssayProps,
  SavedCompoundProps,
  SavedLiteratureProps,
} from "models/research/SavedProps";
import { Flex, useBreakpointValue } from "@chakra-ui/react";

import Assays from "./Assays";
import Molecules from "./Molecules";
import Literature from "./Literature";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import Loading from "components/ui/Loading";
import { getLibraryFolder } from "views/library/helpers";
import NoResultsFound from "components/ui/NoResultsFound";
import EmptyFolder from "./EmptyFolder";

export default function FolderContent() {
  // Hooks
  const { folderId } = useParams();
  const { loading, savedFolders } = useSelector(selectCurrentSavedData);

  const folder = getLibraryFolder(folderId, savedFolders);
  let molecules: SavedCompoundProps[] = [];
  let assays: SavedAssayProps[] = [];
  let literature: SavedLiteratureProps[] = [];

  if (folder) {
    molecules = folder?.compounds ?? [];
    assays = folder?.assays ?? [];
    literature = folder?.literatures ?? [];
  }

  const isEmpty = !assays?.length && !molecules?.length && !literature?.length;

  // Responsiveness: ~992px, ~1280px, ~1536px
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  // Style
  const contentStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 40px 0",
  };

  if (loading) {
    return <Loading message="Loading research folder content..." />;
  }

  if (!folder) {
    return <NoResultsFound sourceName={"folder"} />;
  }
  if (isEmpty) {
    return <EmptyFolder />;
  }

  return (
    <CustomScrollBar style={contentStyle}>
      <Flex direction={"column"} gap={10}>
        {molecules?.length && <Molecules content={molecules} />}
        {assays?.length && <Assays content={assays} />}
        {literature?.length && <Literature content={literature} />}
      </Flex>
    </CustomScrollBar>
  );
}
