import { environment } from "../environments";
import { axiosPrivate } from "api/axios";

export const createCheckoutSession = async (payload: {
  username?: string;
  email?: string;
  plan: string;
  is_yearly: boolean;
  quantity?: number;
  payment_mode: string;
}) => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/payments/create_checkout_session`,
    payload
  );
  return response.data;
};

export const cancelSubscription = async () => {
  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/payments/cancel_subscription`);
  return response.data;
};

export const getUserInvoices = async () => {
  const response = await axiosPrivate.get(
    `${environment.BACKEND_API}/payments/invoices`
  );
  return response.data;
};
