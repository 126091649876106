import { Button } from "@chakra-ui/react";

interface SubmitButtonProps {
  submitting: boolean;
  text?: string;
}

export default function SubmitButton({ submitting, text }: SubmitButtonProps) {
  return (
    <Button
      bg={"highlight.primary"}
      color={"gray.50"}
      border={"none"}
      _hover={{ opacity: 0.9 }}
      borderRadius={"6px"}
      fontSize={"14px"}
      type="submit"
      mt={"30px"}
      w={"fit-content"}
      ml={"auto"}
      isLoading={submitting}
      loadingText="submitting..."
      _loading={{ color: "white" }}
    >
      {text ?? "Submit"}
    </Button>
  );
}
