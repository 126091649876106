import { Grid } from "@chakra-ui/react";
import Content from "components/home/About/Content";
import Logo from "components/home/About/Logo";

interface AboutProps {
  id: string;
}

export default function About({ id }: AboutProps) {
  return (
    <Grid
      mx={"auto"}
      maxW={"1200px"}
      w={["100%", "100%", "100%", "90%", "80%", "70%"]}
      px={["20px", "30px", "40px", null, null, null]}
      py={["80px", "90px", "128px", 32, 32, 32]}
      id={id}
      templateColumns="repeat(3, 1fr)"
      gap={[0, 0, "30px", "40px", "50px", "60px"]}
    >
      <Logo />
      <Content />
    </Grid>
  );
}
