import {
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import TableData from "components/team/members/TableData";
import NewTeamModal from "components/team/members/NewTeamModal";
import Loading from "components/ui/Loading";
import { BsPersonPlus } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { PostContext } from "..";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";
import { RepeatIcon } from "@chakra-ui/icons";

export default function MembersPanel() {
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);  

  // State
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [isInvite, setIsInvite] = useState(true); // If true, open new member invite modal

  const { getTeamDetails } = useContext(PostContext);

  // Theme
  const { colors } = useTheme();

  const createTeam = (is_invite: boolean) => {
    if (!is_invite) {
      setIsInvite(false);
    }
    setShowNewTeamModal(true);
  };

  useEffect(() => {
    if(isLoading===true){
      setIsLoading(false);
      if (user?.user_type === "teams" && user?.user_wallet?.wallet >= 100) {
        setIsOwner(true);
      }
      else {
        setIsOwner(false);
      }
    }
  }, [user, isLoading]);

  return (
    <Box>
      {isLoading ? (
        <Flex
          h={"100%"}
          w={"100%"}
          align={"center"}
          justify={"center"}
          color={"gray.500"}
          direction={"column"}
        >
          <Loading message="Loading members.." />
        </Flex>
      ) : (
        <>
          {teamData ? (
            <>
              <Flex marginBottom={5}>
                <Text color={colors.highlight.primary}>Members</Text>
                <Spacer />
                <Text fontSize={14} color={colors.gray["500"]} fontWeight={"bold"}>{teamData.name || ""}</Text>
                <Spacer />                
                <IconButton 
                  border={"none"} 
                  borderRadius={"100px"}
                  aria-label='Refresh Table' 
                  color={"gray.500"}                  
                  onClick={() => getTeamDetails()}
                  icon={<RepeatIcon />} />
                {isOwner ?
                  <Button
                    marginLeft={2}
                    bg={"highlight.primary"}
                    color={"gray.50"}
                    border={"none"}
                    _hover={{ opacity: 0.9 }}
                    borderRadius={"100px"}
                    onClick={() => createTeam(true)}
                  >
                    <Flex
                      display={"flex"}
                      justify={"center"}
                      alignItems={"center"}
                    >
                      <BsPersonPlus style={{ marginRight: 5 }} />
                      Add Member
                    </Flex>
                  </Button>
                  :
                  <></>
                }
              </Flex>

              <TableData members={teamData.members} isOwner={isOwner} getTeamDetails={getTeamDetails} teamData={teamData} />
            </>
          ) : (
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              gap={10}
              w={"full"}
              h={"full"}
            >
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                gap={2}
                mt={6}
              >
                <FaUsers color="#6c757d" fontSize="48px" />
              </Flex>

              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                gap={3}
              >
                <Text color={"gray.500"} fontSize={"14px"}>
                  You do not have a team yet.{" "}
                  {isOwner && <>Start by creating a team:</>}
                </Text>
                {isOwner && (
                  <Flex align={"center"} justify={"center"} gap={2}>
                    <Button
                      bg={"highlight.primary"}
                      color={"gray.50"}
                      border={"none"}
                      _hover={{ opacity: 0.9 }}
                      borderRadius={"100px"}
                      onClick={() => createTeam(false)}
                    >
                      <Flex
                        display={"flex"}
                        justify={"center"}
                        alignItems={"center"}
                      >
                        Create a Team
                      </Flex>
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </>
      )}

      <NewTeamModal
        isInvite={isInvite}
        isOpen={showNewTeamModal}
        onClose={() => setShowNewTeamModal(false)}
      />
    </Box>
  );
}
