import { GridItem, Image } from "@chakra-ui/react";

function Logo() {
  return (
    <GridItem
      colSpan={1}
      display={{ base: "none", md: "flex" }}
      justifySelf={"center"}
      alignItems={{ base: "flex-start", lg: "center" }}
      justifyContent={"center"}
      h={"full"}
      w={"full"}
    >
      <Image
        src="https://reacto.s3.eu-central-1.amazonaws.com/logo/green.svg"
        w={[null, null, "180px", "220px", "260px", "300px"]}
        mt={[null, null, "24px", 0, 0, 0]}
      />
    </GridItem>
  );
}

export default Logo;
