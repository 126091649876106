import { Flex, Grid, GridItem, Link, Tag, Text } from "@chakra-ui/react";
import ExternalResourceProps from "models/compounds/ExternalResource";
import { Link as RouterLink } from "react-router-dom";

interface ExternalSourcesListProps {
  resources: ExternalResourceProps[];
}

export default function ExternalSourcesList({
  resources,
}: ExternalSourcesListProps) {
  if (!!resources) {
    return (
      <Grid templateColumns={`repeat(3, 1fr)`} gap={2}>
        {resources.map((resource, index) => {
          return (
            <GridItem key={"resource" + index}>
              <Flex gap={2} align={"center"}>
                <Text fontSize={"xs"}>{resource.name}:</Text>
                {resource?.links?.length === 0 && <Tag></Tag>}
                <Flex align={"center"} gap={2} wrap={"wrap"}>
                  {resource?.links?.map((link, index2) => {
                    return (
                      <Link
                        as={RouterLink}
                        to={link.url}
                        key={"link-" + index2}
                      >
                        <Tag
                          color={"gray.700"}
                          bg={"gray.200"}
                          cursor={"pointer"}
                          _hover={{ textDecoration: "underline" }}
                        >
                          {link.key}
                        </Tag>
                      </Link>
                    );
                  })}
                </Flex>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    );
  }

  return <Text>No external resources provided</Text>;
}
