import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PositionProps,
  Text,
  Textarea,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import Confetti, {
  ConfettiProps,
  PositionsProps,
} from "components/ui/Confetti";

import { IconType } from "react-icons";
import { LuClipboard } from "react-icons/lu";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import {
  TbThumbDown,
  TbThumbDownFilled,
  TbThumbUp,
  TbThumbUpFilled,
} from "react-icons/tb";

import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { ConversationProps, FeedbackType } from "models/chat/MessageProps";

import { environment } from "environments";
import { errorHandler } from "utils/helpers";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

interface FeedbackProps {
  data: ConversationProps;
}

const initPos = { x: 0, y: 0 };

function BotMessageActions({ data }: FeedbackProps) {
  // Hooks
  const axiosPrivate = useAxiosPrivate();
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(data.messages[0].ai);

  // States
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);
  const [thumbPosition, setThumbPosition] = useState<PositionsProps>(initPos);

  // Confetti
  const [confetti, setConfetti] = useState<ConfettiProps | null>(null);

  useEffect(() => {
    setFeedback(data.feedback);
  }, [data]);

  const handleCloseModal = () => {
    onClose();
    setThumbPosition({ x: 0, y: 0 });
    setFeedback(null);
  };

  const handleSubmit = async (
    positive: boolean = false,
    x: number = thumbPosition.x,
    y: number = thumbPosition.y
  ) => {
    let content = positive ? "" : feedback?.content;
    if (!positive && !content) return;
    !positive && onClose();
    try {
      let newFeedback = {
        message_id: data.id,
        content: !positive ? content : "",
        is_positive: positive,
      };
      const res = await axiosPrivate.post(
        `${environment.BACKEND_API}/llm/answer_feedback`,
        newFeedback
      );
      setFeedback(newFeedback);
      let confettiInstance = {
        active: true,
        colors: positive ? ["#68d391"] : ["#f56565"],
        position: { x, y },
      };
      setConfetti(confettiInstance);
      setTimeout(() => {
        setConfetti(null);
      }, 2000);
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "warning",
      });
      setFeedback(null);
    }
  };

  const actionButtons = [
    {
      id: 'copy',
      label: "Copy",
      icon: LuClipboard,
      actionFc: onCopy,
      isCopyIcon: true,
      hasCopied,
    },
    {
      id: 'positive_feedback',
      label: "Good response",
      icon: feedback?.is_positive === true ? TbThumbUpFilled : TbThumbUp,
      actionFc: (e: any) => {
        const { pageX, pageY } = e;
        if (feedback?.is_positive) return;
        setThumbPosition({ x: pageX, y: pageY });
        handleSubmit(true, pageX, pageY);
      },
      color: feedback?.is_positive ? "green.500" : undefined,
      bgColor: feedback?.is_positive ? "green.100" : undefined,
      disabled: feedback?.is_positive === true,
    },
    {
      id: 'negative_feedback',
      label: "Bad response",
      icon: feedback?.is_positive === false ? TbThumbDownFilled : TbThumbDown,
      actionFc: (e: any) => {
        if (feedback?.is_positive === false) return;
        const { pageX, pageY } = e;
        setThumbPosition({ x: pageX, y: pageY });
        onOpen();
      },
      color: feedback?.is_positive === false ? "red.500" : undefined,
      bgColor: feedback?.is_positive === false ? "red.100" : undefined,
      disabled: feedback?.is_positive === false,
    },
  ];

  return (
    <Flex justifyContent={"flex-end"} pr={3} gap={2}>
      {actionButtons.map((action, index) => (
        <CustomActionBtn key={index} data={action} />
      ))}

      {confetti?.active && <Confetti data={confetti} />}

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent
          alignSelf={"center"}
          overflowY={"auto"}
          h={"fit-content"}
          bg={"background"}
        >
          <ModalHeader>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Flex gap={3} alignItems={"center"}>
                <Flex
                  bg={"red.100"}
                  borderRadius={"50%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  p={2}
                >
                  <Icon
                    aria-label={"Bad response"}
                    color={"red.500"}
                    h={"24px"}
                    w={"24px"}
                    strokeWidth={1.5}
                    as={TbThumbDown}
                  />
                </Flex>
                <Text fontSize={"lg"}>Provide additional feedback</Text>
              </Flex>

              {/* Close modal */}
              <Icon
                onClick={handleCloseModal}
                as={IoCloseOutline}
                color={"gray.500"}
                p={1}
                boxSize={7}
                borderRadius={"50%"}
                cursor={"pointer"}
                transition={"all ease-in-out .2s"}
                _hover={{ bg: "gray.50" }}
              />
            </Flex>
          </ModalHeader>

          <ModalBody>
            <Textarea
              value={feedback?.content}
              onChange={(e) =>
                setFeedback({ ...feedback, content: e.target.value })
              }
              _placeholder={{ fontSize: "14px", color: "gray.400" }}
              placeholder="What would the most complete answer consist of?"
              resize="none"
              _focus={{
                outline: "none",
                borderColor: "red.500",
                borderWidth: 1,
              }}
              _focusVisible={{ outline: "none" }}
            />
          </ModalBody>
          <ModalFooter pt={1}>
            <Button
              bg={"highlight.primary"}
              color={"gray.50"}
              border={"none"}
              _hover={{ opacity: feedback?.content && 0.9 }}
              borderRadius={"6px"}
              fontSize={"14px"}
              type="submit"
              mt={"30px"}
              w={"fit-content"}
              ml={"auto"}
              onClick={() => handleSubmit(false)}
              isDisabled={!feedback?.content}
            >
              Submit feedback
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

interface CustomActionBtnProps {
  data: {
    icon: IconType;
    id: string;
    label: string;
    actionFc: (e: any) => void;
    color?: string | undefined;
    bgColor?: any;
    hasCopied?: boolean;
    isCopyIcon?: boolean;
    disabled?: boolean;
    isConfettiActive?: boolean;
  };
}

const CustomActionBtn = ({ data }: CustomActionBtnProps) => {
  const {
    id,
    icon,
    label,
    actionFc,
    color,
    bgColor,
    isCopyIcon,
    hasCopied,
    disabled,
  } = data;
  const IconComponent = isCopyIcon && hasCopied ? IoCheckmarkOutline : icon;
  const labelText = isCopyIcon && hasCopied ? "Copied" : label;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isGuideOpen } = useSelector(selectCurrentGuideData);

  const { isHighlighted } = useGuideTour()
  let highlighted = isHighlighted(id)

  const handleClick = (e: any) => {
    actionFc(e);
    buttonRef.current?.blur(); // Remove focus from the button to hide the tooltip
  };

  return (
    <Tooltip
      label={labelText}
      placement={"top"}
      hasArrow
      bg={"gray.900"}
      color={"gray.100"}
      py={2}
      px={3}
      maxW={[null, null, null, "200px", "220px", "240px"]}
      borderRadius={"6px"}
      fontSize={"12px"}
      boxShadow={"none"}
      arrowSize={8}
    >
      <Flex
        id={id}
        zIndex={highlighted ? 1400 : 1}
        bg={highlighted ? 'background' : isGuideOpen ? 'inherit' : disabled ? bgColor || "gray.100" : 'transparent'}
        as="button"
        ref={buttonRef}
        onClick={handleClick}
        aria-label={labelText}
        borderRadius={4}
        alignItems={"center"}
        justifyContent={"center"}
        p={0.5}
        transition={"all ease-in-out .2s"}
        disabled={!!disabled}
        // bg={disabled ? bgColor || "gray.100" : ""}
        // _hover={{ bg: disabled ? bgColor || "gray.100" : "" }}
        _hover={{ bg: bgColor || "gray.100" }}
        cursor={disabled ? "auto" : "pointer"}
      >
        <Icon
          aria-label={labelText}
          color={isGuideOpen ? 'gray.500' : color || "gray.500"}
          h={"16px"}
          w={"18px"}
          strokeWidth={1.5}
          as={IconComponent}
        />
      </Flex>
    </Tooltip>
  );
};
export default BotMessageActions;
