import { useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import emailjs from "@emailjs/browser";

import Headers from "components/home/CTA/Headers";
import EmailInput from "components/home/CTA/EmailInput";
import SubmitEmail from "components/home/CTA/SubmitEmail";
import SuccessMessage from "components/home/CTA/SuccessMessage";

import { SubscriptionParams } from "environments";
import { HashLink as Link } from "react-router-hash-link";

export default function CTA() {
  return (
    <Flex
      w={["100%", "100%", "95%", "90%", "95%", "100%"]}
      maxW={[null, null, null, "1100px", "1360px", "1360px"]}
      mx={"auto"}
      my={["40px", "40px", "60px", "60px", "100px", "100px"]}
      borderRadius={{ base: "unset", md: "24px" }}
      px={{ base: 2, lg: 0 }}
      backgroundImage={require("assets/cta-bg.png")}
      backgroundPosition={"center center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <Flex
        direction={["column", "column", "row"]}
        align={"center"}
        gap={["30px", "30px", "60px"]}
        py={["50px", "50px", "80px"]}
        px={2}
        w={"fit-content"}
        mx={"auto"}
      >
        <Headers />

        <Flex direction={"column"} gap={2}>
          <SubmitSubscription />
          <Text fontSize={{ base: "12px", lg: "14px" }} color={"primary.200"}>
            We care about your data. Check our{" "}
            <Link to="#footer" style={{ textDecoration: "underline" }}>
              privacy policy
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

function SubmitSubscription() {
  // Hooks
  const toast = useToast();

  // State
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);

  // Handler
  // NOTE: type not provided by emailJS
  async function sendEmail(e: any) {
    e.preventDefault();

    if (!email.trim()) return;

    const { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } = SubscriptionParams;

    try {
      setSending(true);
      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY);

      toast({
        duration: 4000,
        render: () => <SuccessMessage type="subscription" />,
      });
    } catch (error) {
      toast({
        description: "Failed to send subscription request. Please try again.",
        status: "warning",
      });
    } finally {
      setSending(false);
    }

    // Clear textarea and reset text-area state
    e.target.reset();
    setEmail("");
  }

  return (
    <Flex
      w={"100%"}
      direction={["column", "column", "row"]}
      gap={2}
      as="form"
      onSubmit={sendEmail}
    >
      <EmailInput email={email} setEmail={setEmail} />
      <SubmitEmail sending={sending} />
    </Flex>
  );
}
