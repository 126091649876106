import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Box, Card, Text, CardBody, Circle, Flex, Heading, Icon, SimpleGrid, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { proprietarySources } from "../helpers";


const ProprietarySourceTable = () => {
    const { colors } = useTheme();
    const navigate = useNavigate();
  
    function openSource(path: string) {
      navigate(`/data/proprietary/${path.toLowerCase()}`);
    }
  
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";
    const bgCard = isLight
      ? "background"
      : `lightBackground`;
  
    return (
        <>
        <SimpleGrid columns={2} spacing={6} p={"1px"}>
        {proprietarySources.map((source, index) => (
            <Box key={"proprietary-source-" + index}>
            <Card
                bg={bgCard}
                h={"120px"}
                _hover={{
                bg: hexToRgba(colors.secondary[100], 0.15),
                cursor: "pointer",
                }}
                onClick={() => openSource(source.path)}
            >
                <CardBody>
                <Flex gap={6} align={"center"}>
                    <Circle
                    size="50px"
                    bg={source.color}
                    color="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Icon as={source.icon} />
                    </Circle>
                    <Box>
                    <Heading size="sm" textTransform={"capitalize"}>
                        {source.category}
                    </Heading>
                    <Text
                        fontSize={"sm"}
                        maxH="3em"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {source.description}
                    </Text>
                    {/* {source.source && (
                        <Text fontSize={"xs"} color={"neutral.800"} mt={2}>
                        By {source.source}
                        </Text>
                    )} */}
                    </Box>
                </Flex>
                </CardBody>
            </Card>
            </Box>
        ))}
        </SimpleGrid>
        </>
    );
};

export default ProprietarySourceTable;