import { createSlice } from "@reduxjs/toolkit";
import { tourGuideSteps } from "data/helpers/tourGuideSteps";
import { GuideStateProps, StepProps } from "models/guide";
import { createSelector } from "reselect";

const state = window.localStorage.getItem("initialGuideSeen");
const INITIAL_SEEN_STATE = state ? JSON.parse(state) : false;

const initialState: GuideStateProps = {
  initialSeen: INITIAL_SEEN_STATE,
  isGuideOpen: !INITIAL_SEEN_STATE,
  currentSteps: tourGuideSteps["WELCOMING"] as StepProps[],
  currentMode: "WELCOMING",
  currentStep: 1,
  currentModalPosition: null
};

const checkElementsInDOM = (steps: StepProps[]): StepProps[] => {
  return steps.filter((step) => {
    const element = document.getElementById(step.id);
    return element !== null && element?.offsetParent !== null;
  });
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    setGuideOpen: (state, action) => {
      const { route }: { route: string } = action.payload
      state.currentMode = "PAGE_INSTRUCTIONS";

      const initSteps = (tourGuideSteps["PAGE_INSTRUCTIONS"] as { [key: string]: StepProps[] })[route] || [];
      const filteredSteps = checkElementsInDOM(initSteps);

      state.currentSteps = filteredSteps;
      state.currentStep = 1;

      if (!!state.currentSteps?.length) {
        state.isGuideOpen = true;
      }
    },
    onGuideClose: (state) => {
      state.isGuideOpen = false;
      state.currentModalPosition = null;
      if (!state.initialSeen) {
        state.initialSeen = true;
        window.localStorage.setItem("initialGuideSeen", JSON.stringify(true));
      }
    },
    handleNextStep: (state) => {
      const { currentStep, currentSteps } = state;
      if (currentStep < currentSteps?.length) {
        state.currentStep = currentStep + 1;
      } else {
        guideSlice.caseReducers.onGuideClose(state);
      }
    },
    handlePrevStep: (state) => {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      }
    },
    updateCurrentModalPosition: (state, action) => {
      state.currentModalPosition = action.payload
    },
  },
});

export const { setGuideOpen, onGuideClose, handleNextStep, handlePrevStep, updateCurrentModalPosition } =
  guideSlice.actions;

export default guideSlice.reducer;

const selectGuideState = (state: { guide: GuideStateProps }) => state.guide;

export const selectCurrentGuideData = createSelector(
  [selectGuideState],
  (guide) => ({
    initialSeen: guide.initialSeen,
    isGuideOpen: guide.isGuideOpen,
    currentSteps: guide.currentSteps,
    currentMode: guide.currentMode,
    currentStep: guide.currentStep,
    currentModalPosition: guide.currentModalPosition,
  })
);
