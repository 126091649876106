import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import {
  Flex,
  Image,
  Heading,
  useBreakpointValue,
  Icon,
  useTheme,
} from "@chakra-ui/react";

import useNavigationTabs from "hooks/useNavigationTabs";
import ChatHistory from "./ChatHistory";
import AvatarMenu from "./UserMenu";
import NavItem from "./Item";

import BetaTag from "components/ui/Beta";
import { hexToRgba } from "utils/helpers";

import { BsReverseLayoutSidebarReverse } from "react-icons/bs";
import { ThemeProps } from "components/userMenu/Appearance";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

export default function Nav({ onToggle, isDarkMode }: ThemeProps) {
  const { user } = useSelector(selectCurrentAuthData);
  
  // State
  const [isNavOpen, setIsNavOpen] = useState(true);

  // Hooks
  const { navItems } = useNavigationTabs();
  const navigate = useNavigate();

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const borderColor = isLight ? "transparent" : "dark.600";

  // Responsiveness
  const sidebarWidth = useBreakpointValue({
    lg: "260px",
    xl: "280px",
    "2xl": "300px",
  });

  // motion variants
  const sidebarVariants = {
    open: {
      width: sidebarWidth,
      opacity: 1,
      display: "block",
      transition: { duration: 0.4 },
    },
    closed: {
      width: "0px",
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
      transition: { duration: 0.4 },
    },
  };

  const buttonVariants = {
    open: {
      width: "40px",
      opacity: 1,
      display: "block",
      transition: { duration: 0.2, delay: 0.4 },
    },
    closed: {
      width: "0px",
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
      transition: { duration: 0.2 },
    },
  };

  const NavabarToggleButton = (
    <Icon
      as={BsReverseLayoutSidebarReverse}
      boxSize={8}
      borderRadius={"6px"}
      p={2}
      bg={hexToRgba(colors.primary[500], 0.06)}
      color={"primary.500"}
      cursor={"pointer"}
      transition={"all .3s ease"}
      _hover={{ bg: hexToRgba(colors.primary[500], 0.12) }}
      onClick={() => setIsNavOpen(!isNavOpen)}
    />
  );

  return (
    <>
      <motion.div
        initial={isNavOpen ? "closed" : "open"}
        animate={isNavOpen ? "closed" : "open"}
        variants={buttonVariants}
      >
        <Flex mt={[null, null, null, "8px", "20px", "16px"]}>
          {NavabarToggleButton}
        </Flex>
      </motion.div>

      <motion.div
        initial={isNavOpen ? "open" : "closed"}
        animate={isNavOpen ? "open" : "closed"}
        variants={sidebarVariants}
      >
        <Flex
          direction={"column"}
          h={"100%"}
          w={sidebarWidth}
          p={4}
          borderRadius={"10px"}
          borderColor={borderColor}
          bg={"background"}
          borderWidth={1}
          justify={"center"}
          gap={4}
        >
          {/* Logo and home */}
          <Flex
            align={"center"}
            justify={"space-between"}
            mb={{ lg: 2, xl: 3, "2xl": 4 }}
          >
            <Flex gap={2} align={"center"}>
              <Image
                h={[null, null, null, "26px", "30px", "34px"]}
                src={
                  isLight
                    ? "https://reacto.s3.eu-central-1.amazonaws.com/logo/green.svg"
                    : "https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
                }
                opacity={isLight ? 0.8 : 0.4}
                onClick={() => navigate("/")}
                _hover={{ cursor: "pointer" }}
              />

              {/* Company name + version tag */}
              <Flex direction={"column"}>
                <Heading
                  size={{ lg: "sm", xl: "md" }}
                  color={"highlight.primary"}
                  opacity={0.6}
                >
                  MoleculeLake
                </Heading>

                <BetaTag />
              </Flex>
            </Flex>

            {/* close sidebar */}
            {NavabarToggleButton}
          </Flex>

          {/* Main navs */}

          <Flex direction={"column"} gap={1}>
            {navItems.map((item, index) => {              
              if (item.name === "Team" && user?.can_access_teams === false){
                // eslint-disable-next-line array-callback-return
                return
              }
              return(
                <NavItem key={index} item={item} />
              )
            })}
          </Flex>

          {/* chats */}
          <ChatHistory />

          {/* User Avatar & Menu */}
          <AvatarMenu onToggle={onToggle} isDarkMode={isDarkMode} />
        </Flex>
      </motion.div>
    </>
  );
}
