import { useState } from "react";
import {
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useNumberInput,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useStripe } from "@stripe/react-stripe-js";
import {
  cancelSubscription,
  createCheckoutSession,
} from "services/stripe.service";
import { formatCurrency } from "utils/helpers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import { FaUsers } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
import { IoMdStar } from "react-icons/io";
import { MdMoveUp, MdWorkspacePremium } from "react-icons/md";

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  newPlan: string;
  newPlanColor: string;
  newPlanPrice: number;
  isAnnual: boolean;
}

interface MembersNumberProps {
  members: number;
  changeNumber: (value: number) => void;
}

function ChangePlanModal({
  isOpen,
  onClose,
  newPlan,
  newPlanPrice,
  newPlanColor,
  isAnnual,
}: ChangePlanModalProps) {
  const { user } = useSelector(selectCurrentAuthData);
  // Stripe
  const stripe = useStripe();

  // State
  const [isProcessing, setIsProcessing] = useState(false);
  const [members, setMembers] = useState(2);

  // Hooks
  const toast = useToast();
  const navigate = useNavigate();

  // Handlers
  const changePlan = async () => {
    if (toBasic) cancelPlan();
    if (toEnterprise) changetoEnterprise();
  };

  const handleInputChange = (value: number) => {
    setMembers(value);
  };

  const cancelPlan = async () => {
    setIsProcessing(true);

    await cancelSubscription()
      .then(async (res) => {
        console.log(res);
        toastMessage();
        setIsProcessing(false);
        setTimeout(() => {
          navigate("/data?p=cancel-plan");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "An error occurred while cancelling the subscription",
          status: "error",
          position: "top-right",
        });
        setIsProcessing(false);
      });
  };

  const changetoEnterprise = async () => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // TODO: Handle errors
      return;
    }

    setIsProcessing(true);

    let session: any;
    const payload = {
      email: user?.email,
      username: `${user?.given_name}${user?.family_name}`,
      plan: "Teams",
      quantity: members,
      is_yearly: isAnnual,
      payment_mode: "subscription", // subscription => recurring, payment => one off payment
    };

    await createCheckoutSession(payload)
      .then(async (res) => {
        console.log(res);
        session = res;
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "Error initializing checkout",
          status: "error",
          position: "top-right",
        });
        setIsProcessing(false);
      });

    if (!session) {
      // TODO: Handle errors
      return;
    }

    // Redirect to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (result.error) {
      // Handle error here.
      console.error(result.error.message);
      toast({
        description: result.error.message
          ? result.error.message
          : "Error initializing checkout",
        status: "error",
        position: "top-right",
        duration: 6000,
      });
    }
  };

  const toastMessage = () => {
    toast({
      position: "bottom",
      duration: 3000,
      render: () => (
        <Flex
          borderRadius={"6px"}
          borderWidth={1}
          borderColor={newPlanColor}
          bg={newPlanColor}
          color={"white"}
          p={3}
          align={"center"}
          gap={2}
        >
          <Icon
            as={toBasic ? IoMdStar : toEnterprise ? FaUsers : MdWorkspacePremium}
            boxSize={"18px"}
          />
          <Text
            fontFamily={"Poppins, sans-serif"}
            fontSize={{ lg: "13px", xl: "14px" }}
            lineHeight={"1.3"}
          >
            <>
              {toBasic ? (
                <>Subscription successfully changed to Basic</>
              ) : (
                <>Subscription successfully changed to Enterprise</>
              )}
            </>
          </Text>
        </Flex>
      ),
    });
  };

  const toBasic = newPlan?.toLowerCase() === "basic";
  const toEnterprise = newPlan?.toLowerCase() === "enterprise";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        bg={"background"}
        borderRadius={"6px"}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={toBasic ? MdMoveUp : FaUsers}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            <>
              {!!toBasic && !toEnterprise ? (
                <>Change Subscription Plan</>
              ) : (
                <>Change to Enterprise Plan</>
              )}
            </>
          </Text>
        </ModalHeader>

        <ModalBody mb={4}>
          <>
            {!!toBasic && !toEnterprise ? (
              <Text fontFamily={"Poppins, sans-serif"} fontSize={"14px"}>
                Are you sure you want to change to{" "}
                <b>{`${toBasic ? "Basic" : "Enterprise"}`} plan</b>?
              </Text>
            ) : (
              <>
                <Text
                  fontSize={"16px"}
                  color={"gray.600"}
                  fontWeight={"500"}
                  mb={4}
                >
                  Choose how many members in the team
                </Text>
                <MembersNumberInput
                  members={members}
                  changeNumber={handleInputChange}
                />
                <Text fontSize={"12px"} color={"gray.500"} mt={4}>
                  A minimum of 2 members
                </Text>

                <Flex mt={2} flexDirection="row" align={"center"}>
                  <Text fontSize={"12px"} color={"#1a1a1a80"}>
                    US{formatCurrency(newPlanPrice * members)}
                  </Text>
                  <Text fontSize={"4px"} px={3}>
                    <FaCircle color={"#1a1a1a80"} />
                  </Text>
                  <Text fontSize={"12px"} color={"#1a1a1a80"}>
                    Billed <>{isAnnual ? "yearly" : "monthly"}</>
                  </Text>
                </Flex>
              </>
            )}
          </>
        </ModalBody>

        <ModalFooter gap={1}>
          <Button
            size={"md"}
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            pointerEvents={isProcessing ? "none" : "auto"}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size={"md"}
            bg={"highlight.primary"}
            color={"gray.50"}
            _hover={{ opacity: 0.9 }}
            isLoading={isProcessing ?? false}
            pointerEvents={isProcessing ? "none" : "auto"}
            loadingText={"Processing"}
            borderRadius={"100px"}
            onClick={() => changePlan()}
          >
            Change
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangePlanModal;

function MembersNumberInput({ members, changeNumber }: MembersNumberProps) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: members,
      min: 2,
      onChange: (valueAsString, valueAsNumber) => changeNumber(valueAsNumber),
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="320px">
      <Button {...dec}>-</Button>
      <Input {...input} />
      <Button {...inc}>+</Button>
    </HStack>
  );
}
