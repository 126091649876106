import { Button, Icon, useMediaQuery } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { HiCodeBracket } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface Props {
  setShowInfo: Dispatch<SetStateAction<boolean>>;
}

function DocsAPI({ setShowInfo }: Props) {
  // Navigation
  const navigate = useNavigate();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  function handleClick() {
    if (isMobileView) {
      setShowInfo(true);
    } else {
      navigate("/docs");
    }
  }

  return (
    <Button
      py={2}
      px={{ base: 2, sm: 3 }}
      w={"fit-content"}
      h={"fit-content"}
      letterSpacing={{ base: 0, sm: ".03rem" }}
      fontWeight={{ base: "400", md: "500" }}
      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
      bg={{ base: "transparent", md: "whiteAlpha.300" }}
      borderWidth={{ base: 1, md: 0 }}
      borderColor={{ base: "whiteAlpha.500", md: "transparent" }}
      color={"whiteAlpha.900"}
      _hover={{ backgroundColor: "whiteAlpha.500" }}
      _active={{ backgroundColor: "whiteAlpha.500" }}
      onClick={handleClick}
    >
      <Icon
        display={{ base: "none", sm: "inline-block" }}
        fontSize={{ base: "sm", md: "sm", lg: "md" }}
        as={HiCodeBracket}
        mr={"2"}
      />
      Docs
    </Button>
  );
}

export default DocsAPI;
