import { Flex, GridItem, Heading, Text, useTheme } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

function Header() {
  // Theme
  const { colors } = useTheme();

  return (
    <GridItem
      colSpan={1}
      display={"flex"}
      flexDirection={"column"}
      gap={4}
      textAlign={["center", "center", "start"]}
    >
      <Flex
        direction={"column"}
        gap={{ base: 2, md: 1 }}
        textAlign={{ base: "center", md: "left" }}
      >
        <Text
          fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px" }}
          fontWeight={{ base: "400", lg: "500" }}
          color={"secondary.500"}
          letterSpacing={{ base: 1, lg: 3 }}
          lineHeight={1}
        >
          - Service
        </Text>
        <Heading
          fontSize={{ base: "24px", md: "28px", lg: "32px" }}
          lineHeight={1}
          color={"primary.800"}
          fontFamily={"Poppins, sans-serif"}
        >
          FAQ
        </Heading>
      </Flex>

      <Text
        color={"black"}
        opacity={0.75}
        w={"95%"}
        mx={{ base: "auto", md: 0 }}
        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
        textAlign={{ base: "center", md: "left" }}
      >
        Can't find what you are looking for?
        <br />
        Just{" "}
        <Link
          to="#contact"
          style={{ color: colors.secondary[500], fontWeight: "500" }}
          smooth
        >
          contact
        </Link>{" "}
        our friendly team, and we will be more than happy to assist you.
      </Text>
    </GridItem>
  );
}

export default Header;
