import {
    Button,
    Flex,
    Icon,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Text,
    useTheme,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
    handleNextStep,
    handlePrevStep,
    onGuideClose,
    selectCurrentGuideData,
} from "redux/features/guide/guideSlice";
import { useEffect, useRef, useState } from "react";
import { hexToRgba } from "utils/helpers";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { GuideModalProps } from "models/guide";

function GuideModal({ position, content }: GuideModalProps) {
    // Theme
    const { colors } = useTheme();
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";
    const arrowBgColor = isLight ? "white" : "#0b2e3e";

    // Hooks
    const dispatch = useDispatch();
    const { getIcon } = useGuideTour()
    const { currentSteps, currentStep, currentMode } = useSelector(selectCurrentGuideData);

    const modalRef = useRef<HTMLDivElement>(null);

    // States
    const [modalTop, setModalTop] = useState<number | null>(position.top);
    const [modalLeft, setModalLeft] = useState<number | null>(position.left);
    const [arrowTop, setArrowTop] = useState<string | null>(null);
    const [arrowLeft, setArrowLeft] = useState<string | null>(null);

    const totalSteps = currentSteps.length;
    const isLastStep = currentStep === totalSteps;

    const getButtonText = () => {
        if (currentStep === 1 && currentMode === 'WELCOMING') return "Show Me Around";
        if (isLastStep) return "Done";
        return "Next";
    };

    const updateModalPosition = () => {
        if (modalRef.current) {
            const modalHeight = modalRef.current.offsetHeight;
            const modalWidth = modalRef.current.offsetWidth;

            let newModalTop = position.top + (position.placement === 'BOTTOM'
                ? 0
                : position.placement === 'TOP'
                    ? -modalHeight - position.elHeight
                    : -modalHeight / 2) + (position.elHeight / 2);


            let newModalLeft = position.left - ((position.placement === 'TOP' || position.placement === 'BOTTOM')
                ? modalWidth / 2
                : position.placement === 'RIGHT' ? -20 : (modalWidth + 20));

            let newArrowTop = (position.placement === 'LEFT' || position.placement === 'RIGHT')
                ? '50%'
                : (position.placement === 'TOP'
                    ? `calc(100% + 10px)`
                    : `-10px`);

            let newArrowLeft = (position.placement === 'TOP' || position.placement === 'BOTTOM')
                ? '50%'
                : (position.placement === 'LEFT'
                    ? 'calc(100% + 10px)'
                    : '-10px');

            const availableSpaceBelowIcon = window.innerHeight - position.top;
            if (availableSpaceBelowIcon < modalHeight) {
                newModalTop = position.top - 15 - (modalHeight - availableSpaceBelowIcon);
                newArrowTop = `${position.top - newModalTop + (position.elHeight / 2)}px`
            }

            setModalTop(newModalTop);
            setModalLeft(newModalLeft);
            setArrowTop(newArrowTop);
            setArrowLeft(newArrowLeft);
        }
    };

    useEffect(() => {
        updateModalPosition();
    }, [position.top, position.left, position.placement, modalRef]);


    return (
        <ModalContent
            transition={'top ease-in-out .3s, left ease-in-out .3s'}
            ref={modalRef}
            alignSelf={"center"}
            minW={[null, null, null, "250px", "400px"]}
            maxW={[null, null, null, "300px", "150px"]}
            h={"fit-content"}
            my={"auto"}
            p={[null, null, null, 3, 4]}
            bg={"background"}
            position={"absolute"}
            top={modalTop !== null ? `${modalTop}px` : 'auto'}
            left={modalLeft !== null ? `${modalLeft}px` : 'auto'}
            _before={{
                content: '""',
                position: "absolute",
                left: arrowLeft ? arrowLeft : 'auto',
                top: arrowTop ? arrowTop : 'auto',
                transform: `translate(-50%, -50%)`,
                borderWidth: [null, null, null, "10px", "12px", "14px"],
                borderStyle: "solid",
                borderColor: getArrowBorderColor(position.placement, arrowBgColor),
                zIndex: 5000
            }}
        >
            <ModalHeader p={0}>
                <Flex
                    align={"center"}
                    justify={"space-between"}
                    mb={[null, null, null, 3, 4]}
                    pb={2}
                    w={"100%"}
                    borderBottomColor={"gray.200"}
                    borderBottomWidth={1}
                    position={"relative"}
                >
                    <Flex alignItems={"center"} gap={2} color={"highlight.primary"}>
                        {content.icon && (
                            <Icon
                                as={getIcon(content.icon)}
                                boxSize={{ lg: 3.5, xl: 4 }}
                            />
                        )}
                        <Text
                            fontSize={{ lg: "14px", xl: "15px" }}
                            textTransform={"capitalize"}
                        >
                            {content.title}
                        </Text>
                    </Flex>

                    <ModalCloseButton
                        fontSize={"10px"}
                        w={6}
                        h={6}
                        position={"absolute"}
                        right={0}
                        top={0}
                    />
                </Flex>
            </ModalHeader>
            <ModalBody p={0} >
                <Text
                    mb={[null, null, null, 5, 6]}
                    fontSize={{ lg: "12px", xl: "13px" }}
                    color={"gray.500"}
                    fontFamily={"Poppins, sans-serif"}
                >
                    {content.content}
                </Text>
            </ModalBody>
            <ModalFooter p={0}>
                <Flex justify="space-between" align="center" w={"100%"}>
                    <Text
                        fontSize={{ lg: "12px", xl: "13px" }}
                        color={"gray.600"}
                    >{`${currentStep} of ${totalSteps}`}</Text>

                    <Flex gap={2} align={"center"}>
                        {currentStep === 1 && currentMode === 'WELCOMING' && (
                            <Button
                                fontSize={{ lg: "12px", xl: "13px" }}
                                h={"fit-content"}
                                w={"fit-content"}
                                py={{ lg: 2, xl: 3 }}
                                px={{ lg: 3, xl: 4 }}
                                color={"gray.600"}
                                onClick={() => dispatch(onGuideClose())}
                                border={"none"}
                                bg={"transparent"}
                                _hover={{ bg: "transparent" }}
                                _active={{ bg: "transparent" }}
                                transition={"all ease-in-out .3s"}
                            >
                                Maybe Later
                            </Button>
                        )}
                        {currentStep > 1 && (
                            <Button
                                fontSize={{ lg: "12px", xl: "13px" }}
                                h={"fit-content"}
                                w={"fit-content"}
                                py={{ lg: 2, xl: 3 }}
                                px={{ lg: 3, xl: 4 }}
                                color={"gray.600"}
                                onClick={() => dispatch(handlePrevStep())}
                                border={"none"}
                                bg={"transparent"}
                                _hover={{ bg: "transparent" }}
                                _active={{ bg: "transparent" }}
                                transition={"all ease-in-out .3s"}
                            >
                                Back
                            </Button>
                        )}
                        <Button
                            py={{ lg: 2, xl: 3 }}
                            px={{ lg: 3, xl: 4 }}
                            fontSize={{ lg: "12px", xl: "13px" }}
                            h={"fit-content"}
                            w={"fit-content"}
                            color={"white"}
                            onClick={() => dispatch(handleNextStep())}
                            bg={hexToRgba(colors.highlight.primary, 0.8)}
                            _hover={{ bg: "highlight.primary" }}
                            _active={{ bg: "highlight.primary" }}
                            transition={"all ease-in-out .3s"}
                        >
                            {getButtonText()}
                        </Button>
                    </Flex>
                </Flex>
            </ModalFooter>
        </ModalContent>
    );
}

const getArrowBorderColor = (placement: string, arrowBgColor: string) => {
    switch (placement) {
        case 'TOP':
            return `${arrowBgColor} transparent transparent transparent`;
        case 'BOTTOM':
            return `transparent transparent ${arrowBgColor} transparent`;
        case 'LEFT':
            return `transparent transparent transparent ${arrowBgColor}`;
        case 'RIGHT':
            return `transparent ${arrowBgColor} transparent transparent`;
        default:
            return `transparent transparent ${arrowBgColor} transparent`;
    }
};


export default GuideModal;
