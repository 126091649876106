import { Flex, Icon, Text } from "@chakra-ui/react";
import { TbMailX } from "react-icons/tb";

interface ErrorSendMessageProps {
  text: string;
}

export default function ErrorSendkMessage({ text }: ErrorSendMessageProps) {
  return (
    <Flex
      direction={"column"}
      gap={6}
      justify={"center"}
      align={"center"}
      w={"full"}
      maxW={"80%"}
      alignSelf={"center"}
      justifySelf={"center"}
      mt={"30px"}
      mx={"auto"}
      textAlign={"center"}
    >
      <Flex direction={"column"} gap={2} justify={"center"} align={"center"}>
        <Icon as={TbMailX} boxSize={10} color={"red.500"} />
        <Text color={"gray.700"} fontWeight={"500"}>
          {text}
        </Text>
      </Flex>

      <Text fontSize={"14px"} color={"gray.500"}>
        Something went wrong. Please try again later.
      </Text>
    </Flex>
  );
}
