import { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { Flex, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";

import useWS from "hooks/chat/useWS";
import { ChatbotContext } from "views/chatbot";

import HumanMessage from "./HumanMessage";
import Loading from "components/ui/Loading";
import LoadingBubble from "./LoadingBubble";
import NewChatHeading from "./NewChatHeading";
import { suggestedQuestions } from "./helpers";
import SuggestedQuestion, { SuggestedQuestionProps } from "./SuggestedQuestion";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

function NewChatPanel() {
  // Contexts
  const { waitingOnBot, activeSession, loadingSessions } =
    useContext(ChatbotContext);
  const { questionOnWait }: { questionOnWait: string | undefined } =
    useOutletContext();

  // Hooks
  const { notifications, partialReply } = useWS({ waitingOnBot });

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const conversationHeight = useBreakpointValue({
    lg: "calc(100vh - 148px)", // 148 = (8x2) + 16 + 8 + 60 + (24x2): py + boxSize + gap + textfield + py
    xl: "calc(100vh - 156px)", // 156 = (12x2) + 16 + 8 + 60 + (24x2)
    "2xl": "calc(100vh - 164px)", // 164 = (16x2) + 16 + 8 + 60 + (24x2)
  });

  const listStyle = {
    height: conversationHeight,
    width: "100%",
    padding: "0 6px 0 0",
  };

  // true if user asks question in new chat
  const isActiveSession = useMemo(
    () => activeSession === "new",
    [activeSession]
  );

  return (
    <>
      <Flex
        justify={"flex-end"}
        align={"flex-end"}
        h={"calc(100% - 84px)"}
        w={{ lg: "700px", xl: "900px" }}
        maxW={"90%"}
        mx={"auto"}
        py={"24px"}
      >
        {/* welcoming content */}
        {!isActiveSession && !loadingSessions && <Welcome />}

        {/* transition phase - loading */}
        {!isActiveSession && loadingSessions && (
          <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
            <Loading />
          </Flex>
        )}

        {/* submit qst in new chat */}
        {!!waitingOnBot && isActiveSession && (
          <CustomScrollBar style={listStyle}>
            {/* temporary conversation thread: human(qst) + bot(notifications, partial reply) */}
            <Flex direction={"column"} px={2} pr={6} w={"100%"}>
              <Flex w="100%" mt={4} pl={"48px"}>
                <Flex
                  alignContent={"flex-start"}
                  width={"fit-content"}
                  display={"inline-block"}
                  ml={"auto"}
                >
                  {!!questionOnWait && (
                    <HumanMessage questionOnWait={questionOnWait} />
                  )}
                </Flex>
              </Flex>
              <LoadingBubble
                partialReply={partialReply}
                notifications={notifications}
              />
            </Flex>
          </CustomScrollBar>
        )}
      </Flex>
    </>
  );
}

export default NewChatPanel;

function Welcome() {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"flex-end"}
      gap={{ lg: "60px", xl: "100px" }}
      w={"900px"}
      maxW={[null, null, null, "90%", "85%", "100%"]}
      mx={"auto"}
      py={{ lg: 6, xl: 8 }}
      px={{ lg: 2, xl: 3 }}
      h={"calc(100% - 84px)"}
    >
      {/* Heading with icon */}
      <NewChatHeading />

      {/* suggested questions */}
      <SimpleGrid
        w={"100%"}
        mx={"auto"}
        templateColumns={[
          null,
          null,
          null,
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        templateRows={[
          null,
          null,
          null,
          "repeat(4, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        spacing={[null, null, null, "10px", "16px", "18px"]}
      >
        {suggestedQuestions.map(
          (
            item: Omit<SuggestedQuestionProps, "onSendSuggestedQuestion">,
            index: number
          ) => (
            <SuggestedQuestion
              key={index}
              question={item.question}
              header={item.header}
              onSendSuggestedQuestion={handleSendQuestion}
            />
          )
        )}
      </SimpleGrid>
    </Flex>
  );
}
