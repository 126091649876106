import React, { useState, ChangeEvent, FocusEvent, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  useTheme,
  Text,
  FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import {
  getName,
  hexToRgba,
  validateEmail,
  validateFullName,
  validatePass,
} from "utils/helpers";
import { confirmSignUp, signUp } from "services/user.service";

import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/auth/authSlice";

export default function Register({
  setFormData,
  formData,
}: {
  setFormData: (data: {
    email: string;
    fullname: string;
    password: string;
  }) => void;
  formData: {
    email: string;
    fullname: string;
    password: string;
  };
}) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const fullnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [userSub, setUserSub] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  // Theme
  const { colors } = useTheme();

  // Handlers
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    isInputValid(name, value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const isInputValid = (name: string, value: string) => {
    switch (name) {
      case "fullname":
        if (!validateFullName(value)) {
          setErrorMsg("Please provide firstname and lastname");
        } else {
          setErrorMsg("");
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          setErrorMsg("Please provide a valid email address");
        } else {
          setErrorMsg("");
        }
        break;
      case "password":
        if (validatePass(value).length > 0) {
          setErrorMsg(validatePass(value));
        } else {
          setErrorMsg("");
        }
        break;
      default:
    }
  };

  const isFormValid = () => {
    if (!validateFullName(formData.fullname)) {
      setErrorMsg("Please provide firstname and lastname");
      if (fullnameRef.current !== null) fullnameRef.current.focus();
      return false;
    }

    if (!validateEmail(formData.email)) {
      setErrorMsg("Please provide a valid email address");
      if (emailRef.current !== null) emailRef.current.focus();
      return false;
    }

    if (validatePass(formData.password).length > 0) {
      setErrorMsg(validatePass(formData.password));
      if (passwordRef.current !== null) passwordRef.current.focus();
      return false;
    }

    setErrorMsg("");
    return true;
  };

  // form submit
  const handleSubmit = async () => {
    if (!isFormValid()) return;

    const payload = {
      email: formData.email.trim(),
      firstname: getName("first", formData.fullname.trim()),
      lastname: getName("last", formData.fullname.trim()),
      password: formData.password,
    };

    setSubmitting(true);
    await signUp(payload)
      .then(async (res) => {
        setUserSub(res.user_sub);
        setSubmitting(false);
        setStep(2);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "Something went wrong while signing up. Please try again",
          status: "error",
          position: "top-right",
        });
        setSubmitting(false);
      });
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleCodeKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleVerify();
    }
  };

  const handleVerify = async () => {
    if (code.length < 6) {
      toast({
        description: "Please enter a valid code",
        status: "error",
        position: "bottom",
      });
      return;
    }

    const payload = {
      code: code,
      user_sub: userSub,
      email: formData.email.trim(),
    };

    setIsVerifying(true);

    await confirmSignUp(payload)
      .then(async (res) => {
        console.log(res);
        toast({
          description: "Registration successfully",
          status: "success",
          position: "top-right",
        });
        setIsVerifying(false);
        loginUser(payload.email, formData.password);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "Something went wrong while confirming sign up.",
          status: "error",
          position: "top-right",
        });
        setIsVerifying(false);
      });
  };

  const loginUser = async (username: string, password: string) => {
    try {
      setIsSigningIn(true);

      const response = await axiosClient.post(
        "/api/auth",
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { user, roles } = response?.data;

      dispatch(setCredentials({ user, roles }));
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        setIsSigningIn(false);
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
      setIsSigningIn(false);
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <>
      {step === 1 ? (
        <>
          <FormControl id="fullname" isRequired>
            <FormLabel
              fontSize={"14px"}
              color={"black"}
              opacity={0.75}
              lineHeight={"1.3"}
            >
              Fullname
            </FormLabel>
            <Input
              type="text"
              name="fullname"
              ref={fullnameRef}
              placeholder="Enter your fullname"
              _placeholder={{
                fontSize: "14px",
                color: hexToRgba(colors.black, 0.55),
              }}
              value={formData.fullname}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              borderColor={"neutral.700"}
              color={"black"}
              w={"100%"}
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
                bg: hexToRgba(colors.black, 0.03),
              }}
              _hover={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
              }}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel
              fontSize={"14px"}
              color={"black"}
              opacity={0.75}
              lineHeight={"1.3"}
            >
              Email Address
            </FormLabel>
            <Input
              type="email"
              name="email"
              ref={emailRef}
              placeholder="Enter your email address"
              _placeholder={{
                fontSize: "14px",
                color: hexToRgba(colors.black, 0.55),
              }}
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              borderColor={"neutral.700"}
              color={"black"}
              w={"100%"}
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
                bg: hexToRgba(colors.black, 0.03),
              }}
              _hover={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
              }}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel
              fontSize={"14px"}
              color={"black"}
              opacity={0.75}
              lineHeight={"1.3"}
            >
              Password
            </FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                ref={passwordRef}
                placeholder="Enter your password"
                _placeholder={{
                  fontSize: "14px",
                  color: hexToRgba(colors.black, 0.55),
                }}
                value={formData.password}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                borderColor={"neutral.700"}
                color={"black"}
                w={"100%"}
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: hexToRgba(colors.black, 0.25),
                  bg: hexToRgba(colors.black, 0.03),
                }}
                _hover={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: hexToRgba(colors.black, 0.25),
                }}
              />
              <InputRightElement>
                <Icon
                  as={showPassword ? ViewOffIcon : ViewIcon}
                  color={"primary.400"}
                  cursor={"pointer"}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputRightElement>
            </InputGroup>
            <FormHelperText color={errorMsg ? "jasper" : "gray.500"}>
              {errorMsg ? (
                <>{errorMsg}</>
              ) : (
                <>
                  {formData.password.length < 8 && (
                    <>Must be at least 8 characters</>
                  )}
                </>
              )}
            </FormHelperText>
          </FormControl>
          <Button
            bg={"primary.400"}
            color={"primary.100"}
            size="md"
            w="100%"
            type="submit"
            isLoading={submitting}
            loadingText="Processing"
            onClick={handleSubmit}
            mt={6}
            _hover={{ bg: "primary.500" }}
          >
            Register
          </Button>
        </>
      ) : (
        <>
          <FormControl>
            <Text color="gray.500">
              Verification code has been send to your <br />
              email address.
            </Text>
          </FormControl>
          <FormControl id="code" isRequired>
            <FormLabel
              fontSize={"14px"}
              color={"black"}
              opacity={0.75}
              lineHeight={"1.3"}
            >
              Verification Code
            </FormLabel>
            <Input
              type="text"
              name="code"
              placeholder="Enter verification code"
              _placeholder={{
                fontSize: "14px",
                color: hexToRgba(colors.black, 0.55),
              }}
              value={code}
              onChange={handleCodeChange}
              onKeyDown={handleCodeKeyDown}
              borderColor={"neutral.700"}
              color={"black"}
              w={"100%"}
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
                bg: hexToRgba(colors.black, 0.03),
              }}
              _hover={{
                outline: "none",
                boxShadow: "none",
                borderColor: hexToRgba(colors.black, 0.25),
              }}
            />
          </FormControl>

          <Button
            bg={"primary.400"}
            color={"primary.100"}
            size="md"
            w="100%"
            type="submit"
            isLoading={isVerifying || isSigningIn}
            loadingText={isVerifying ? "Verifying" : "Signing In"}
            onClick={handleVerify}
            mt={6}
            _hover={{ bg: "primary.500" }}
          >
            Verify
          </Button>
        </>
      )}
    </>
  );
}
