import { Box, useRadio, useTheme, UseRadioProps } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface FeedbackTypeProps extends UseRadioProps {
  children: React.ReactNode;
}

export default function FeedbackType(props: FeedbackTypeProps | undefined) {
  // Theme
  const { colors } = useTheme();

  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        color={"gray.600"}
        bg={hexToRgba(colors.gray[100], 0.4)}
        borderWidth={1}
        borderColor={"gray.200"}
        borderRadius="6px"
        fontSize="14px"
        height={"fit-content"}
        _checked={{
          bg: "highlight.primary",
          color: "gray.100",
          borderColor: "highlight.primary",
          fontWeight: "500",
          letterSpacing: "-0.012rem",
          transition: "border 0.2s ease",
        }}
        transition="background 0.2s ease"
        px={5}
        py={2}
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        {props?.children}
      </Box>
    </Box>
  );
}
