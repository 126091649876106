import { createSlice } from "@reduxjs/toolkit";
import { TeamProps } from "models/posts/PostProps";
import { createSelector } from "reselect";


export interface PostsState {
  unReadMessages: number; 
  teamData: TeamProps 
}

const postsSlice = createSlice({
  name: "posts",
  initialState: { 
    unReadMessages: 0,
    teamData: null
  },
  reducers: {
    incrementUnreadMessages: (state, action) => {
      state.unReadMessages += action.payload;      
    },
    setUnreadMessages: (state, action) => {
      state.unReadMessages = action.payload;      
    },
    setTeamData: (state, action) => {
      state.teamData = action.payload;      
    },
  },
});

export const { setUnreadMessages, incrementUnreadMessages, setTeamData } = postsSlice.actions;

export default postsSlice.reducer;

const selectPostsState = (state: { posts: PostsState }) => state.posts;

export const selectCurrentPostsData = createSelector(
  [selectPostsState],
  (posts) => ({
    unReadMessages: posts.unReadMessages,
    teamData: posts.teamData
  })
);
