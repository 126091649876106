import { Box, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

function BackgroundShadowEffect() {
  // Theme
  const { colors } = useTheme();

  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      height="24px"
      bgGradient={`linear(to-t, ${colors.background}, ${hexToRgba(
        colors.background,
        0.25
      )})`}
    />
  );
}

export default BackgroundShadowEffect;
