import { Flex, useMediaQuery } from "@chakra-ui/react";

import Logo from "components/home/Header/Logo";
import Menu from "components/home/Header/Menu";
import Container from "components/home/Header/Container";
import ActionButtons from "components/home/Header/ActionButtons";
import Content from "components/home/Header/Content";
import ResponsivenessInfo from "components/home/ResponsivenessInfo";
import { useState } from "react";

interface HeaderProps {
  id: string;
}

export default function Header({ id }: HeaderProps) {
  // State
  const [showInfo, setShowInfo] = useState(false);

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Container id={id}>
      <ResponsivenessInfo
        isOpen={showInfo && isMobileView}
        onClose={() => setShowInfo(false)}
      />

      <Flex
        justify={"space-between"}
        align={"center"}
        w={"100%"}
        flexWrap={"wrap"}
        gap={1}
      >
        <Logo />

        <Flex align="center" gap={6} h={{ base: "fit-content", lg: "100px" }}>
          <Menu />
          <ActionButtons setShowInfo={setShowInfo} />
        </Flex>
      </Flex>

      <Content setShowInfo={setShowInfo} />
    </Container>
  );
}
