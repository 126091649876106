import { useMemo } from "react";
import { Flex, Icon, Text, useTheme } from "@chakra-ui/react";

import { SavedFolderProps } from "models/research/SavedProps";
import { FaRegTrashAlt } from "react-icons/fa";
import { hexToRgba } from "utils/helpers";
import Loading from "components/ui/Loading";

interface FoldersListItemProps {
  folder: SavedFolderProps;
  selectedFolder: string;
  deleting: boolean;
  onClick: () => void;
  onDelete: () => void;
}

function FoldersListItem({
  folder,
  selectedFolder,
  deleting,
  onClick,
  onDelete,
}: FoldersListItemProps) {
  // Theme
  const { colors } = useTheme();

  const isSelected = useMemo(
    () => selectedFolder === folder?.id,
    [selectedFolder, folder]
  );

  return (
    <Flex
      cursor={isSelected ? "auto" : "pointer"}
      color={isSelected ? "gray.100" : "gray.600"}
      bg={isSelected ? "highlight.primary" : hexToRgba(colors.gray[100], 0.4)}
      borderWidth={1}
      borderColor={isSelected ? "highlight.primary" : "gray.200"}
      borderRadius={"6px"}
      height={"fit-content"}
      transition="all 0.2s ease"
      px={5}
      py={2}
      display={"flex"}
      alignItems={"center"}
      gap={2}
      onClick={onClick}
    >
      <Text
        fontSize={"14px"}
        fontWeight={isSelected ? "500" : "400"}
        letterSpacing={isSelected ? "-0.012rem" : 0}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        {folder.name}
      </Text>

      {isSelected && (
        <Flex position={"absolute"} right={5}>
          {deleting ? (
            <Loading small />
          ) : (
            <Icon
              cursor={isSelected ? "pointer" : "none"}
              as={FaRegTrashAlt}
              boxSize={"14px"}
              color={"background"}
              _hover={{ color: "red.500" }}
              onClick={onDelete}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default FoldersListItem;
