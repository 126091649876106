import {
  FormControl,
  FormErrorMessage,
  Input,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface EmailFormControlProps {
  email: string;
  isInvalid: boolean;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function EmailFormControl({
  email,
  isInvalid,
  onEmailChange,
}: EmailFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Input
        type="email"
        name="email"
        value={email}
        onChange={onEmailChange}
        placeholder="Email"
        _placeholder={{
          color: "black",
          opacity: 0.75,
          fontSize: { base: "13px", md: "15px" },
        }}
        fontSize={{ base: "14px", md: "16px" }}
        borderRadius={"8px"}
        color={"black"}
        bg={hexToRgba(colors.white, 0.5)}
        border={"none"}
        _hover={{ bg: hexToRgba(colors.white, 0.7) }}
        _focusVisible={{ bg: hexToRgba(colors.white, 0.7) }}
      />
      <FormErrorMessage color={"whiteAlpha.700"}>
        Email is required. Sould be valid email address.
      </FormErrorMessage>
    </FormControl>
  );
}

export default EmailFormControl;
