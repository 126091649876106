import { Box } from "@chakra-ui/react";
import React from "react";

const Clock: React.FC = () => {
  return (
    <Box
      aria-label="clock-frame"
      position="relative"
      height="200px"
      width="200px"
      background="white"
      borderRadius="100%"
      border="15px solid #67D2C8"
      margin="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      sx={{ "@keyframes time": { to: { transform: "rotate(360deg)" } } }}
      overflow="hidden"
    >
      <Box
        aria-label="clock-top-line"
        position="absolute"
        width="3px"
        height="8px"
        background="#262626"
        left="50%"
        transform="translateX(-50%)"
        top="0"
        borderRadius="2px"
      />
      <Box
        aria-label="clock-right-line"
        position="absolute"
        width="8px"
        height="3px"
        background="#262626"
        top="50%"
        transform="translateY(-50%)"
        right="0"
        borderRadius="2px"
      />
      <Box
        aria-label="clock-bottom-line"
        position="absolute"
        width="3px"
        height="8px"
        background="#262626"
        left="50%"
        transform="translateX(-50%)"
        bottom="0"
        borderRadius="2px"
      />
      <Box
        aria-label="clock-left-line"
        position="absolute"
        width="8px"
        height="3px"
        background="#262626"
        top="50%"
        transform="translateY(-50%)"
        left="0"
        borderRadius="2px"
      />

      <Box
        aria-label="clock-gradient"
        height="200px"
        width="200px"
        position="absolute"
        left="56px"
        top="56px"
        transform="rotate(135deg)"
        background="linear-gradient(transparent, rgba(0, 0, 0, 0.1))"
      />
      <Box
        aria-label="clock-hour"
        width="3px"
        height="58px"
        position="absolute"
        left="49.5%"
        top="16%"
        transformOrigin="bottom"
        background="#262626"
        borderRadius="2px"
        sx={{
          animation: "time 144s infinite linear",
          "@keyframes time": { to: { transform: "rotate(360deg)" } },
        }}
      />
      <Box
        aria-label="clock-minute"
        width="2px"
        height="70px"
        position="absolute"
        left="50%"
        top="8%"
        transformOrigin="bottom"
        background="#262626"
        borderRadius="2px"
        sx={{
          animation: "time 12s infinite linear",
          "@keyframes time": { to: { transform: "rotate(360deg)" } },
        }}
      />
      <Box
        aria-label="clock-second"
        width="1px"
        height="75px"
        position="absolute"
        left="50%"
        top="5%"
        transformOrigin="bottom"
        background="#fd1111"
        borderRadius="2px"
        sx={{
          animation: "time 1s infinite linear",
          "@keyframes time": { to: { transform: "rotate(360deg)" } },
        }}
      />
      <Box
        aria-label="clock-middle"
        height="6px"
        width="6px"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        background="#262626"
        borderRadius="100%"
      />
    </Box>
  );
};

export default Clock;
