import { Box, useTheme } from "@chakra-ui/react";
import { RedocStandalone } from "redoc";
import { environment } from "environments";

function Docs() {
  // Theme
  const { colors } = useTheme();

  return (
    <Box h={"100vh"} overflowY={"auto"}>
      <RedocStandalone
        options={{
          hideDownloadButton: true,
          theme: {
            rightPanel: {
              backgroundColor: colors.background,
              servers: {
                overlay: {
                  backgroundColor: "white",
                  textColor: colors.gray[500],
                },
              },
            },
            codeBlock: {
              backgroundColor: colors.secondary[600],
            },
            colors: {
              gray: { 100: colors.gray[100] },
              text: { primary: colors.gray[500] },
              primary: { main: colors.blue[500] },
            },
            schema: {
              linesColor: colors.blue[500],
              typeNameColor: colors.blue[500],
              nestedBackground: colors.blue[100],
            },
            sidebar: {
              backgroundColor: colors.background,
              textColor: colors.gray[700],
              groupItems: {
                activeBackgroundColor: colors.gray[50],
                activeTextColor: colors.green[500],
              },
            },
          },
          hideFab: true,
        }}
        specUrl={`${environment.BACKEND_API}/documentation2/?format=openapi`}
      />
    </Box>
  );
}

export default Docs;
