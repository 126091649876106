import { useToast } from "@chakra-ui/react";
import { number } from "echarts";
import { TeamMessageProps, TeamSocketContextProps } from "models/posts/PostProps";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { incrementUnreadMessages, setUnreadMessages } from "redux/features/posts/postsSlice";

export const TeamSocketContext = createContext<TeamSocketContextProps>({
  teamSocket: { current: null },
  teamMessages: [],
  setTeamMessages: () => { },
  teamSocketConnected: false
});

interface UseWSProps {
  teamId: string | undefined;
}

const usePostWS = ({ teamId }: UseWSProps) => {
  const toast = useToast();  
  const dispatch = useDispatch();
  const reconnectInterval = useRef<any>(number);
  let teamSocket = useRef<WebSocket | null>(null);

  // States  
  const [teamMessages, setTeamMessages] = useState<TeamMessageProps[]>([]);
  const [teamSocketConnected, setTeamSocketConnected] = useState(false);

  const { user } = useSelector(selectCurrentAuthData);

  const HOST_URL = window.location.hostname;

  const WS_URL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `ws://127.0.0.1:8000/ws/team_chat/${teamId}/${user?.id}/`
      : `wss://${HOST_URL}/ws/team_chat/${teamId}/${user?.id}/`;

  // websocket connection handler
  const handleConnection = () => {
    if (teamId == undefined || teamId == null) {
      return
    }
    teamSocket.current = new WebSocket(WS_URL);

    teamSocket.current.onopen = () => {
      setTeamSocketConnected(true);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    teamSocket.current.onmessage = (event: any) => {
      const eventData = JSON.parse(event.data);
      if (eventData.type === "chat_message" && eventData.message.user_id !== user?.id) {
        setTeamMessages((prevMessages) => [...prevMessages, eventData.message]);

        if (window.location.pathname.includes("/team/posts") == true) {
          teamSocket.current?.send(JSON.stringify({
            type: "read_one",
            message_id: eventData.message.id,
            user_id: user?.id
          }));
        }
        else {          
          dispatch(incrementUnreadMessages(1));
        }
      }
      else if (eventData.type === "read_receipt" && eventData.data.user_id !== user?.id) {
        setTeamMessages((prevMessages) => {
          for (let msg of prevMessages) {
            if (msg.id == eventData.data.message_id) {
              msg.read_by?.push(eventData.data.user_id)
            }
          }
          return prevMessages
        });
      }
      else if (eventData.type === "un_read_messages" && eventData.data.user_id === user?.id) {
        dispatch(setUnreadMessages(eventData.data.count));
      }
    };

    teamSocket.current.onerror = () => {
      teamSocket.current?.close();
      toast({
        description: "Seems like there is an issue with connecting to the server!",
        status: "error",
        position: "top-right"
      });
    }

    teamSocket.current.onclose = () => {
      setTeamSocketConnected(false);
      attemptReconnect();
    };
  }

  const attemptReconnect = () => {
    if (!reconnectInterval.current && teamId != undefined && teamId != null) {
      reconnectInterval.current = setInterval(() => {
        handleConnection();
      }, 5000); // Attempt to reconnect every 5 seconds
    }
  };

  useEffect(() => {
    if (teamSocketConnected == false && teamId != undefined && teamId != null) {
      handleConnection();
    }

    // cleanup: close socket connection when all requested data received
    return () => {
      !!teamSocket.current && teamSocket.current.close();
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { teamSocket, teamMessages, setTeamMessages, teamSocketConnected };
};

export default usePostWS;
