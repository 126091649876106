import { Flex, Text, Link, Icon } from "@chakra-ui/react";
import { LuExternalLink } from "react-icons/lu";
import { Link as RouterLink } from "react-router-dom";

interface LensIdProps {
  data: string;
}

export default function LensId({ data }: LensIdProps) {
  return (
    <>
      {data ? (
        <Flex gap={1} color={"gray.500"} align={"center"}>
          <Text
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            fontWeight={"500"}
            lineHeight={"1.3"}
          >
            Lens ID:
          </Text>
          <Link
            as={RouterLink}
            to={`https://www.lens.org/lens/scholar/article/${data}/main`}
            isExternal
            fontWeight={"400"}
            fontSize={[null, null, null, "12px", "12px", "14px"]}
            lineHeight={"1.3"}
            w={"fit-content"}
            transition="all ease .2s"
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <Text
              fontWeight={"400"}
              fontSize={[null, null, null, "12px", "12px", "14px"]}
              lineHeight={"1.3"}
            >
              {data}
            </Text>
            <Icon as={LuExternalLink} boxSize={"12px"} />
          </Link>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}
