import { Flex, Image, Text } from "@chakra-ui/react";
import LinkToView from "components/buttons/LinkToView";

import emptyFolderImg from "assets/empty-folder.svg";
import { PiGraph, PiTestTube } from "react-icons/pi";
import { BsPaperclip } from "react-icons/bs";

function EmptyFolder() {
  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={8}
      w={"100%"}
      h={"100%"}
    >
      <Flex direction={"column"} align={"center"} justify={"center"} gap={2}>
        <Image
          src={emptyFolderImg}
          w={"100px"}
          alt={"empty folder"}
          loading={"lazy"}
        />
        <Text color={"gray.600"} fontSize={"14px"} fontWeight={500}>
          No saved items found
        </Text>
      </Flex>

      <Flex direction={"column"} gap={3} align={"center"} justify={"center"}>
        <Text color={"gray.500"} fontSize={"14px"}>
          Save your first item from the sources below:
        </Text>
        <Flex align={"center"} justify={"center"} gap={2}>
          <LinkToView
            name="Molecules"
            icon={PiGraph}
            link="/data/core/molecules"
          />
          <LinkToView
            name="Assays"
            icon={PiTestTube}
            link="/data/core/assays"
          />
          <LinkToView
            name="Literature"
            icon={BsPaperclip}
            link="/data/core/literature"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default EmptyFolder;
