import { useTheme } from "@chakra-ui/react";
import { hexToRgba } from "./helpers";

export function GetGradients() {
  // Theme
  const { colors } = useTheme();

  const rGradient = `radial-gradient(61.04% 89.69% at 100% 100%, ${hexToRgba(
    colors.highlight.primary,
    0.1
  )} 0%,  40.63%, ${hexToRgba(
    colors.blue[300],
    0
  )} 100%), radial-gradient(43.78% 64.34% at 60.31% 100%,  ${hexToRgba(
    colors.highlight.primary,
    0.1
  )} 0%, ${hexToRgba(
    colors.blue[600],
    0
  )} 100%), linear-gradient(180deg, ${hexToRgba(
    colors.blue[600],
    0
  )} 29.44%, ${hexToRgba(
    colors.blue[600],
    0.02
  )} 100%), linear-gradient(90deg, ${colors.highlight.neutral[100]} 0%, ${
    colors.highlight.neutral[200]
  } 100%)`;

  const lGradient =
    "linear-gradient(90deg, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0.02) 100%)";
  const lGradient2 =
    "linear-gradient(90deg, rgba(23, 74, 228, 0.02) 0%,  rgba(23, 74, 228, 0.08) 100%)";
  const lGradient3 =
    "linear-gradient(180deg, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0.02) 100%)";

  const navGradient = `linear(to-b, ${hexToRgba(
    colors.neutral[100],
    0.4
  )}, ${hexToRgba(colors.neutral[200], 0.4)})`;
  const navGradientDark = `linear(to-b, ${colors.dark[600]}, ${colors.dark[500]})`;

  // NOTE: to keep this static color.
  // because picked from a bg gradient palette
  const bgShadowEffect = `linear-gradient(to-b, ${hexToRgba(
    "#F8F8FB",
    0.5
  )} 80%, #F8F8FB)`;
  const bgShadowEffectDark = `linear-gradient(to-b, ${hexToRgba(
    colors.dark[500],
    0.1
  )}, ${colors.dark[500]})`;
  const profileGradient = `radial-gradient(circle at bottom center, ${colors.secondary[500]} 15px, ${colors.secondary[300]})`;
  const profileGradientDark = `radial-gradient(circle at bottom center, ${colors.secondary[600]} 15px, ${colors.secondary[400]})`;

  const appTitleGradient = `linear(94.23deg, ${colors.primary[300]} 5%, ${colors.highlight.primary} 50%, ${colors.secondary[400]} 80%)`;

  const emGradient = `linear-gradient(94deg, ${colors.secondary[500]} 5%, ${colors.primary[300]} 50%, ${colors.highlight.primary} 80%)`;
  const maskGradient = `linear-gradient(${colors.neutral[100]} 0 0) content-box, linear-gradient(${colors.neutral[100]} 0 0)`;

  const homeGradient = `linear(to-r, ${hexToRgba(
    colors.secondary[900],
    0.85
  )}, ${hexToRgba(colors.secondary[900], 0.7)}, ${hexToRgba(
    colors.secondary[900],
    0.55
  )})`;

  const homeGradientDark = `linear(to-r, ${hexToRgba(
    colors.secondary[100],
    0.85
  )}, ${hexToRgba(colors.secondary[100], 0.7)}, ${hexToRgba(
    colors.secondary[100],
    0.55
  )})`;

  return {
    rGradient,
    lGradient,
    lGradient2,
    lGradient3,
    navGradient,
    navGradientDark,
    bgShadowEffect,
    bgShadowEffectDark,
    profileGradient,
    profileGradientDark,
    appTitleGradient,
    emGradient,
    maskGradient,
    homeGradient,
    homeGradientDark,
  };
}
