import { Navigate, useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { getCoreSource } from "../helpers";
import AgnosticDataDisplay from "components/data/core/AgnosticDataDisplay";

const SourceTable = () => {
    const { source } = useParams();
    const coreSource = getCoreSource(source);

    if (!['MOLECULES', 'LITERATURE', 'ASSAYS'].includes(source?.toUpperCase() || '')) {
        return <Navigate to={'/not-found'} replace />
    }

    return (
        <>
            {!coreSource ?
                <Box>Source not found</Box> :
                <AgnosticDataDisplay source={coreSource} />
            }
        </>
    );
};

export default SourceTable;
