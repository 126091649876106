import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, useTheme } from '@chakra-ui/react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { getCoreSource } from '../helpers';
import { createContext, useEffect, useState } from 'react';
import { SortingState } from '@tanstack/react-table';

export interface ColumnFiltersProp {
    [key: string]: string
}

export interface FiltersState {
    showFilter: string | null;
    filters: ColumnFiltersProp;
}

interface SearchStateProps {
    searchValue: string;
    filters: ColumnFiltersProp;
    columnsFilters: FiltersState;
    sorting: SortingState;
}
interface CoreContextType {
    sourceItemName: string;
    setSourceItemName: React.Dispatch<React.SetStateAction<string>>;
    results: any[] | null;
    setResults: React.Dispatch<React.SetStateAction<any[] | null>>;
    searchState: SearchStateProps;
    setSearchState: React.Dispatch<React.SetStateAction<SearchStateProps>>;
}

const initialContextState: CoreContextType = {
    sourceItemName: '',
    setSourceItemName: () => { },
    results: null,
    setResults: () => { },
    searchState: {
        searchValue: '',
        columnsFilters: { showFilter: null, filters: {} },
        filters: {},
        sorting: [],
    },
    setSearchState: () => { },
};

export const CoreContext = createContext<CoreContextType>(initialContextState);


const CorePanel = () => {
    const navigate = useNavigate();
    const { colors } = useTheme();
    const { source, sourceItemId } = useParams();

    const coreSource = getCoreSource(source);

    const [sourceItemName, setSourceItemName] = useState('')

    const [results, setResults] = useState<any[] | null>(null);
    const [searchState, setSearchState] = useState<SearchStateProps>({
        searchValue: '',
        filters: {},
        columnsFilters: { showFilter: null, filters: {} },
        sorting: [],
    });

    useEffect(() => {
        !sourceItemId && setSourceItemName('')
    }, [navigate])

    useEffect(() => {
        setSearchState({
            searchValue: '',
            filters: {},
            columnsFilters: { showFilter: null, filters: {} },
            sorting: [],
        })
        setResults(null)
    }, [source])

    return (
        <Flex height={'100%'} direction={'column'} rowGap={'5%'}>
            <Breadcrumb py={1} height={'2%'} color={"gray.600"}>
                <BreadcrumbItem onClick={() => coreSource && navigate(`/data/core`)}>
                    <BreadcrumbLink
                        transition={'all ease-in-out .3s'}
                        color={coreSource ? 'inherit' : colors.highlight.primary}
                        _hover={{
                            textDecoration: coreSource ? 'underline' : 'none',
                            cursor: coreSource ? 'pointer' : 'default'
                        }}
                    >
                        All sources
                    </BreadcrumbLink>
                </BreadcrumbItem>
                {coreSource && (
                    <BreadcrumbItem onClick={() => sourceItemName && navigate(`/data/core/${coreSource.path}`)}>
                        <BreadcrumbLink
                            transition={'all ease-in-out .3s'}
                            textTransform={"lowercase"}
                            color={sourceItemName ? 'inherit' : colors.highlight.primary}
                            _hover={{
                                textDecoration: sourceItemName ? 'underline' : 'none',
                                cursor: sourceItemName ? 'pointer' : 'default'
                            }}
                        >
                            {coreSource.key}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
                {sourceItemName && (
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            transition={'all ease-in-out .3s'}
                            textTransform={"lowercase"}
                            maxWidth={[null, null, null, '300px', '600px']}
                            isTruncated
                            color={colors.highlight.primary}
                            _hover={{
                                textDecoration: 'none',
                                cursor: 'default'
                            }}
                        >
                            {sourceItemName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
            </Breadcrumb>

            <CoreContext.Provider value={{ sourceItemName, setSourceItemName, searchState, setSearchState, results, setResults }}>
                <Outlet />
            </CoreContext.Provider>
        </Flex>
    )
}

export default CorePanel