import { Button, Icon, Text } from "@chakra-ui/react";
import FormattedText from "components/ui/FormattedText";
import { BsDatabaseCheck } from "react-icons/bs";
import { GetGradients } from "utils/gradients";

interface LlmRefreshEnrichmentProps {
  answer: string;
}

export default function LlmRefreshEnrichment({
  answer,
}: LlmRefreshEnrichmentProps) {
  return (
    <>
      {answer
        ?.split("\n")
        .filter(Boolean)
        .map((messageItem, index) => (
          <FormattedText key={index} text={messageItem} />
        ))}
    </>
  );
}

interface LlmEnrichmentButtonProps {
  loading: boolean;
  text: string;
  onSendQuestion: () => void;
}

export function LlmEnrichmentButton({
  loading,
  text,
  onSendQuestion,
}: LlmEnrichmentButtonProps) {
  // Theme
  const { emGradient, maskGradient } = GetGradients();

  return (
    <Button
      ml={3}
      borderRadius={"40px"}
      bg={"transparent"}
      _hover={{
        textDecoration: "none",
        bg: "primary.100",
      }}
      display={"flex"}
      gap={1}
      alignItems={"center"}
      w={"fit-content"}
      h={"fit-content"}
      px={4}
      py={2}
      position={"relative"}
      _before={{
        content: "''",
        position: "absolute",
        inset: "0",
        borderRadius: "24px",
        padding: ".1rem",
        background: emGradient,
        WebkitMask: maskGradient,
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        pointerEvents: "none",
      }}
      onClick={onSendQuestion}
      isLoading={loading}
      loadingText="Enriching..."
      _loading={{
        color: "secondary.700",
      }}
    >
      <Icon as={BsDatabaseCheck} boxSize={4} color={"secondary.700"} />
      <Text
        fontWeight={"500"}
        color={"secondary.700"}
        textTransform={"capitalize"}
      >
        {text}
      </Text>
    </Button>
  );
}
