import { Box, Flex, usePrefersReducedMotion } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

export default function LoadingDots() {
  const prefersReducedMotion = usePrefersReducedMotion();

  const keyframe_dot = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

  const animations = prefersReducedMotion
    ? undefined
    : [
        `${keyframe_dot} infinite 1.5s linear`,
        `${keyframe_dot} infinite 1.5s linear 0.2s`,
        `${keyframe_dot} infinite 1.5s linear 0.4s`,
      ];
  const style = {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <Flex gap={1} w={"fit-content"} align={"center"}>
      {Array.from({ length: 3 }, (_, i) => (
        <Box
          key={i}
          bg={"gray.400"}
          style={style}
          animation={animations && animations[i]}
        />
      ))}
    </Flex>
  );
}
