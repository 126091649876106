import { Button } from "@chakra-ui/react";

interface SubmitEmailProps {
  sending: boolean;
}

function SubmitEmail({ sending }: SubmitEmailProps) {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const bg = colorMode === "light" ? "blue.900" : "blue.50";

  return (
    <Button
      type="submit"
      fontSize={["12px", "12px", "14px", "14px", "15px", "16px"]}
      w={["100%", "100%", "40%", "40%", "40%", "40%"]}
      bg={bg}
      color={"white"}
      borderRadius={["12px", "12px", "12px", "12px", "24px", "24px"]}
      transition={"all .3s ease"}
      _hover={{ bg: "black" }}
      _active={{ bg: "black" }}
      isLoading={sending}
      loadingText="sending..."
      _loading={{ color: "white" }}
    >
      Request
    </Button>
  );
}

export default SubmitEmail;
