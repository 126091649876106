import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { IconType } from "react-icons";
import { BsChatRightQuoteFill, BsDatabaseFill, BsGridFill } from "react-icons/bs";
import { IoSearchSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import { TbSortAscendingLetters, TbThumbDownFilled, TbThumbUpFilled } from "react-icons/tb";
import { PiListFill, PiMouseFill } from "react-icons/pi";
import { useLocation, useParams } from "react-router-dom";
import { MdHelp } from "react-icons/md";
import { BiSolidMessageAltDetail, BiSolidNetworkChart } from "react-icons/bi";
import { HiClipboard } from "react-icons/hi";

const ICONS: { [key: string]: IconType } = {
    'BsDatabaseFill': BsDatabaseFill,
    'BsGridFill': BsGridFill,
    'BsChatRightQuoteFill': BsChatRightQuoteFill,
    'IoSearchSharp': IoSearchSharp,
    'FaFilter': FaFilter,
    'TbSortAscendingLetters': TbSortAscendingLetters,
    'PiMouseFill': PiMouseFill,
    'BiSolidMessageAltDetail': BiSolidMessageAltDetail,
    'MdHelp': MdHelp,
    'HiClipboard': HiClipboard,
    'TbThumbUpFilled': TbThumbUpFilled,
    'TbThumbDownFilled': TbThumbDownFilled,
    'BiSolidNetworkChart': BiSolidNetworkChart,
    'PiListFill': PiListFill,
}

const useGuideTour = () => {
    const { isGuideOpen, currentSteps, currentStep, currentMode } = useSelector(selectCurrentGuideData);
    const params = useParams()
    const { pathname } = useLocation()

    // Cases
    const IS_WELCOMING = currentMode === 'WELCOMING' && isGuideOpen
    const IS_SOURCE = pathname.includes('/data/core') && 'source' in params && Object.entries(params).length === 1
    const IS_SOURCE_PROPRIETARY = pathname.includes('/data/proprietary') && 'source' in params && Object.entries(params).length === 1
    const IS_CONVERSATION = pathname.includes('/chat') && 'id' in params && Object.entries(params).length === 1


    const isHelpTourAvailable = () => IS_WELCOMING || IS_SOURCE || IS_CONVERSATION || IS_SOURCE_PROPRIETARY

    const isHighlighted = (id: string) => (currentSteps[currentStep - 1]?.id === id) && isGuideOpen

    const getIcon = (icon: string) => ICONS[icon]

    const getRoute = () => {
        if (IS_SOURCE) return "core_table"
        if (IS_SOURCE_PROPRIETARY) return "propprietary_table"
        if (IS_CONVERSATION) return "chat_conversation"
        return ''
    }


    return { isHelpTourAvailable, isHighlighted, getIcon, getRoute }
}

export default useGuideTour