import { Flex, Icon, Text } from "@chakra-ui/react";
import { BsFileEarmarkX } from "react-icons/bs";

interface NoResultsFoundProps {
    sourceName?: string
}

export default function NoResultsFound({ sourceName = 'items' }: NoResultsFoundProps) {
    return (
        <Flex
            h={"100%"}
            w={"100%"}
            align={"center"}
            justify={"center"}
            color={"gray.500"}
            direction={"column"}
            gap={6}
        >
            <Icon as={BsFileEarmarkX} boxSize={"40px"} color={"orange.300"} />
            <Text fontSize={"14px"} lineHeight={"1.28"} fontWeight={"500"}>
                No {sourceName} found
            </Text>
        </Flex>
    );
}