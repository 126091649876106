import { ChangeEvent, KeyboardEvent, useState } from "react";
import { Flex, Icon, Text, Textarea } from "@chakra-ui/react";
import { BsSendFill } from "react-icons/bs";

interface TextFieldProps {
  onSendQuestion: (s: string) => void;
}

export default function TextField({ onSendQuestion }: TextFieldProps) {
  // State
  const [question, setQuestion] = useState("");

  // Handler
  function handleInputChange(e: ChangeEvent<HTMLTextAreaElement>) {
    // remove line break characters when pasting question
    setQuestion(e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
  }

  // Handler
  function handleSend(event: KeyboardEvent<HTMLTextAreaElement>) {
    const trimmed = question.trim();

    // Check if the trimmed string is not empty
    if (trimmed !== "") {
      if (event.key === "Enter") {
        // don't break line when 'Enter' key pressed
        event.preventDefault();

        onSendQuestion(trimmed);
        setQuestion("");
      }
    }
  }

  // Handler
  function handleSendIconClicked() {
    const trimmed = question.trim();

    if (trimmed !== "") {
      onSendQuestion(question);
      setQuestion("");
    }
  }

  return (
    <Flex
      direction={"column"}
      gap={1}
      w={{ lg: "700px", xl: "900px" }}
      maxW={"90%"}
      mx={"auto"}
      align={"center"}
    >
      <Flex position={"relative"} h={"100%"} w={"100%"}>
        <Textarea
          name="message-box"
          value={question}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleInputChange(e)
          }
          onKeyDown={handleSend}
          placeholder="Ask MoleculeLake..."
          _placeholder={{ color: "gray.500" }}
          fontSize={"14px"}
          resize="none"
          minHeight="60px"
          maxHeight="60px"
          bg={"background"}
          borderRadius={"16px"}
          _focus={{
            outline: "none",
            borderWidth: 1,
            boxShadow: "md",
            overflow: "auto",
          }}
          _focusVisible={{ outline: "none" }}
        />

        <Icon
          position={"absolute"}
          top={"50%"}
          right={4}
          transform={"translateY(-50%)"}
          as={BsSendFill}
          cursor={"pointer"}
          boxSize={4}
          color={"primary.300"}
          onClick={handleSendIconClicked}
          _hover={{ color: "highlight.primary" }}
        />
      </Flex>

      <Text
        fontSize={"12px"}
        color={"gray.500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
      >
        GenAI can make mistakes. Double check important information.
      </Text>
    </Flex>
  );
}
