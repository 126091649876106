import { Box, Flex, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import Container from "components/home/Pipeline/Container";
import Content from "components/home/Pipeline/Content";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import PipeStep from "components/home/Pipeline/PipeStep";
import { processSteps } from "./helpers";
import { ProcessStep } from "components/home/Pipeline/Steps";
import Header from "components/home/Pipeline/Header";

interface PipelineProps {
  id: string;
}

export default function Pipeline({ id }: PipelineProps) {
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Flex direction={"column"} id={id} position="relative">
      <Container>{isMobileView ? <ContentForMobile /> : <Content />}</Container>
    </Flex>
  );
}

function ContentForMobile() {
  // Refs
  const sliderRef = useRef<Slider>(null);

  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const bg = colorMode === "light" ? "secondary.700" : "secondary.300";

  // State
  const [slideIndex, setSlideIndex] = useState(0);

  // Hooks
  const slidesToShow = useBreakpointValue({ base: 1, md: 2 });

  return (
    <Flex
      direction={"column"}
      px={{ base: "20px", lg: "auto" }}
      py={{ base: "120px", lg: "220px" }}
      bg={bg}
    >
      <Header />

      <Slider
        ref={sliderRef}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        swipeToSlide={true}
        focusOnSelect={true}
        dots={true}
        arrows={false}
        lazyLoad={"progressive"}
        className={"slider-container"}
        centerMode={true}
        centerPadding={"0"}
        afterChange={setSlideIndex}
        customPaging={function (i: number) {
          return (
            <Box
              w={"12px"}
              h={"12px"}
              borderRadius={"50%"}
              background={
                i === slideIndex ? "highlight.primary" : "whiteAlpha.800"
              }
              transition={"all .2s ease"}
              outline={"none"}
              border={"none"}
              mt={4}
            />
          );
        }}
      >
        {processSteps.map((step: ProcessStep, index: number) => {
          return (
            <PipeStep
              key={"step-" + index}
              step={index + 1}
              title={step.title}
              text={step.text}
              icon={step.icon}
            />
          );
        })}
      </Slider>
    </Flex>
  );
}
