import { AugmentationDensity } from "models/compounds/AugmentationDensity";
import {
  CompoundProps,
  ReducedCompoundProps,
} from "models/compounds/CompoundProps";
import { PaperProps } from "models/papers/PaperProps";
import { SavedElementProps } from "models/research/SavedProps";
import _ from "lodash";

// general
export const parseParam = (param: string | null): string => {
  return (param?.split(",") ?? []).join(",");
};

// ----------------------------- Saturation Dentity ---------------------------------------
const hasPharmacokinetic = (cmpd: CompoundProps) => {
  return cmpd.citeline_data && cmpd.citeline_data.pk && true;
  // would still be missing drug interactions, clinical outcomes, Population PK Analysis, Dose-Response Relationship
};

const hasAdministrationRoutes = (cmpd: CompoundProps) => {
  return cmpd.gvk_data && cmpd.gvk_data.administration_route && true;
};

const hasMechanismOfAction = (cmpd: CompoundProps) => {
  return (cmpd.gvk_data && cmpd.gvk_data.action_mechanism) ||
    (cmpd.integrity_data && cmpd.integrity_data.action_mechanism) ||
    (cmpd.citeline_data && cmpd.citeline_data.action_mechanism)
    ? true
    : false;
};

const hasPharmacodynamics = (cmpd: CompoundProps) => {
  return hasAdministrationRoutes(cmpd) || hasMechanismOfAction(cmpd)
    ? true
    : false;

  // missing Resistance Mechanisms
};

const hasSmiles = (cmpd: CompoundProps) => {
  return (cmpd.chemical_props && cmpd.chemical_props.canonical_smiles) ||
    (cmpd.chemical_props && cmpd.chemical_props.isomeric_smiles) ||
    (cmpd.integrity_data && cmpd.integrity_data.smiles) ||
    (cmpd.citeline_data && cmpd.citeline_data.smiles) ||
    (cmpd.gvk_data && cmpd.gvk_data.smiles)
    ? true
    : false;
};

const hasFormula = (cmpd: CompoundProps) => {
  return cmpd.chemical_props && cmpd.chemical_props?.formula && true;
};
const hasInchi = (cmpd: CompoundProps) => {
  return cmpd.chemical_props && cmpd.chemical_props?.inchi && true;
};

const hasChirality = (cmpd: CompoundProps) => {
  return cmpd.chemical_props && cmpd.chemical_props?.chirality && true;
};

const hasChemicalProps = (cmpd: CompoundProps) => {
  return hasFormula(cmpd) ||
    hasInchi(cmpd) ||
    hasChirality(cmpd) ||
    hasSmiles(cmpd)
    ? true
    : false;
};

const hasSafety = (cmpd: CompoundProps) => {
  return cmpd.wiki_data && cmpd.wiki_data.pregnancy_category ? true : false;
};

const hasCmpdCmpdSimilarity = (cmpd: CompoundProps) => {
  return cmpd.similar_compounds;
};

const hasIndication = (cmpd: CompoundProps) => {
  return cmpd.wiki_data && cmpd.wiki_data.medical_condition_treated
    ? true
    : false;
};

export const scoreDataAvailability = (
  label: string,
  data: boolean,
  enriched_data: boolean | object | undefined | string
) => {
  // 1 -> no information
  // 2 -> information from Reframe
  // 3 -> enriched information
  // 4 -> enriched reframe

  if (enriched_data === "No data available") enriched_data = false;

  if (data && enriched_data) return 4;
  if (enriched_data) return 3;
  if (data) return 2;
  return 1;
};

export const getInfoTypesHeatmap = (cmpd: CompoundProps) => {
  var informationTypeAvailability: AugmentationDensity = {
    pharmacokinetics: scoreDataAvailability(
      "pk",
      hasPharmacokinetic(cmpd),
      cmpd.enrichment?.pharmacokinetics
    ),
    pharmacodynamics: scoreDataAvailability(
      "pd",
      hasPharmacodynamics(cmpd),
      cmpd.enrichment?.pd
    ),
    chemical: hasChemicalProps(cmpd) ? 2 : 1,
    safety: scoreDataAvailability(
      "safety",
      hasSafety(cmpd),
      cmpd.enrichment?.safety
    ),
    synthesis: 1,
    similarity: hasCmpdCmpdSimilarity(cmpd) ? 2 : 1,
    indication: scoreDataAvailability(
      "indication",
      hasIndication(cmpd),
      cmpd.enrichment?.indication
    ),
  };

  return informationTypeAvailability;
};

// --------------------- Auxiliar Functions for Compound -----------------------
export const getAliases = (aliases: string[]) => {
  if (aliases) {
    const filteredAliases = aliases.filter((str: string) => str.length <= 10);
    const sortedAliases = filteredAliases.sort(
      (a: string, b: string) => a.length - b.length
    );
    return sortedAliases.slice(0, 3);
  }

  return [];
};

type getSmilesImageSrcProps = CompoundProps | SavedElementProps;

const isValidMol = (mol: any) => {
  return !!mol;
};
const MOL_DETAILS = {
  bondLineWidth: 2,
  addStereoAnnotation: true,
  explicitMethyl: true,
};
const getMolDetails = (mol: any, qmol: any) => {
  if (isValidMol(mol) && isValidMol(qmol)) {
    const subStructHighlightDetails = JSON.parse(
      mol.get_substruct_matches(qmol)
    );
    const subStructHighlightDetailsMerged = !_.isEmpty(
      subStructHighlightDetails
    )
      ? subStructHighlightDetails.reduce(
          (acc: any, { atoms, bonds }: { atoms: any; bonds: any }) => ({
            atoms: [...acc.atoms, ...atoms],
            bonds: [...acc.bonds, ...bonds],
          }),
          { bonds: [], atoms: [] }
        )
      : subStructHighlightDetails;
    return JSON.stringify({
      ...MOL_DETAILS,
      ...subStructHighlightDetailsMerged,
    });
  } else {
    return JSON.stringify({
      ...MOL_DETAILS,
    });
  }
};

export const getSmilesImageSrc = (
  cmpd: getSmilesImageSrcProps,
  forResearch?: boolean
) => {
  const chemProps = forResearch
    ? (cmpd as SavedElementProps).chemical_props
    : (cmpd as CompoundProps).chemical_props;

  const smiles = forResearch
    ? chemProps?.canonical_smiles
    : chemProps?.isomeric_smiles;
  const smilesStructure = forResearch
    ? chemProps?.canonical_smiles_structure
    : chemProps?.isomeric_smiles_structure;

  if (!!smiles && cmpd?.substructure_match && window.RDKit) {
    const mol = window.RDKit.get_mol(smiles || "invalid");
    const qmol = window.RDKit.get_qmol(cmpd?.substructure_match || "invalid");

    const svg = btoa(
      mol?.get_svg_with_highlights(getMolDetails(mol, qmol)) ?? ""
    );
    mol?.delete();
    qmol?.delete();

    return `data:image/svg+xml;base64,${svg}`;
  }
  if (smilesStructure) {
    return `data:image/png+svg;base64,${smilesStructure}`;
  }

  return "ex.png";
};

export const getIndications = (cmpd: CompoundProps) => {
  const items = cmpd.assays_hits?.map((hit) => {
    return hit.indication;
  });
  const unique = new Set(items);
  return [...unique];
};

// --------------------- Auxiliars -------------------------

export const stringToColor = (str: string) => {
  const colors = [
    "#df8e98",
    "#FFC0CB",
    "#C3E6CB",
    "#ADD8E6",
    "#52CEBE",
    "#B7A8F1",
    "#CBF2FF",
  ];
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};

export const capitalise = (s: string) => {
  if (!s) return "";
  return s?.charAt(0)?.toUpperCase() + s?.slice(1);
};

// --------------------- Auxiliar Functions for Compounds List -------------------------
export const getCompoundsByCapital = (
  data: CompoundProps[] | null,
  query: string
) => {
  let results = data?.filter((compound: CompoundProps) => {
    const nameMatch = compound.name?.toLowerCase()[0] === query.toLowerCase();

    const aliasesMatch = compound.aliases?.some(
      (alias: string) =>
        alias.toLowerCase()[0] === query.toLowerCase() && alias.length <= 14
    );

    const hasOriginalName = compound.name !== compound.compound_id;

    return hasOriginalName && (nameMatch || aliasesMatch);
  });

  if (results) {
    // sort alphabetically and capitalise
    results = results.sort((a: CompoundProps, b: CompoundProps) => {
      return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    });

    return results;
  }

  return [];
};

export const findCompound = (compounds: CompoundProps[], id: string) => {
  return compounds.find((cmpd: CompoundProps) => {
    return cmpd.compound_id === id;
  });
};

export const findAliasWithLetter = (letter: string, aliases: string[]) => {
  const match = aliases.find((alias: string) => {
    return alias.toLowerCase()[0] === letter.toLowerCase() && alias.length < 14;
  });

  return match;
};

export const buildCmpdTitle = (letter: string, cmpd: CompoundProps) => {
  // const title = cmpd.name.toLowerCase()[0] === letter.toLowerCase()? cmpd.name : findAliasWithLetter(letter, cmpd.aliases)

  if (cmpd.name.toLowerCase()[0] === letter.toLowerCase()) {
    return capitalise(cmpd.name);
  }
  // else
  let title = findAliasWithLetter(letter, cmpd.aliases) || "";
  if (cmpd.name !== cmpd.compound_id) {
    title += " (" + capitalise(cmpd.name) + ") ";
  }
  return capitalise(title);
};

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export function getChartYears(data: PaperProps[]) {
  const papersWithValidYear: PaperProps[] = (data || []).filter(
    (paper: PaperProps) =>
      typeof paper.year_published === "number" && paper.year_published > 0
  );

  const unique: number[] = papersWithValidYear.map(
    (paper: PaperProps) => paper.year_published as number
  );

  const valid: number[] = unique?.sort((a: number, b: number) => a - b);

  const sorted: number[] = Array.from(new Set(valid)).sort(
    (a: number, b: number) => a - b
  );

  return sorted;
}

const PapersTypesColors = [
  "221 107 102",
  "99 179 237",
  "255 166 43",
  "79 209 197",
  "224 98 174",
  "131 120 234",
  "203 213 224",
];
// const PapersTypesColors = [
//   "#dd6b66",
//   "#63B3ED",
//   "#FFA62B",
//   "#4FD1C5",
//   "#E062AE",
//   "#8378EA",
//   "#CBD5E0",
// ];

export function getPaperColor(
  paperType: string | undefined,
  percent: number = 100
): string {
  switch (paperType?.toLowerCase()) {
    case "journal_article":
      return `rgba(${PapersTypesColors[0]}/${percent}%)`;
    case "observational study":
      return `rgba(${PapersTypesColors[1]}/${percent}%)`;
    case "review":
      return `rgba(${PapersTypesColors[2]}/${percent}%)`;
    case "interventional study":
      return `rgba(${PapersTypesColors[3]}/${percent}%)`;
    case "case report":
      return `rgba(${PapersTypesColors[4]}/${percent}%)`;
    case "experimental study":
      return `rgba(${PapersTypesColors[5]}/${percent}%)`;
    case "other":
      return `rgba(${PapersTypesColors[6]}/${percent}%)`;

    default:
      return `rgba(${PapersTypesColors[6]}/${percent}%)`;
  }
}

// export const getReferencedNodeBg = (reference_lens_id: string | undefined, item: PaperProps,) => {
//   // Origin
//   if (reference_lens_id === item.lens_id) return "#E062AE"

//   // referenced in
//   if (item.references?.find((ref: any) => ref.lens_id === reference_lens_id)) return "#63B3ED"

//   // References
//   return "#FFA62B"
// }

export const getNodeOptions = (
  reference_lens_id: string | undefined,
  item: PaperProps
) => {
  // Origin
  if (reference_lens_id === item.lens_id) {
    return {
      bg: "#E062AE",
      category: 0,
    };
  }

  // referenced in
  if (item.references?.find((ref: any) => ref.lens_id === reference_lens_id)) {
    return {
      bg: "#63B3ED",
      category: 1,
    };
  }

  // References
  return {
    bg: "#FFA62B",
    category: 2,
  };
};

export const getReferencedNodeName = (
  reference_lens_id: string | undefined,
  item: PaperProps
) => {
  // Origin
  if (reference_lens_id === item.lens_id) return "Origin";

  // referenced in
  if (item.references?.find((ref: any) => ref.lens_id === reference_lens_id))
    return "Referenced in";

  // References
  return "References";
};

export function adjustCompoundName(cmpd: CompoundProps | ReducedCompoundProps) {
  if (cmpd?.name && cmpd?.name !== "(no name)") {
    return cmpd?.name;
  }

  return cmpd?.compound_id;
}
