import {
  Box,
  Heading,
  Highlight,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { InfoIcon, QuestionIcon } from "@chakra-ui/icons";
import HelperProps from "data/helpers/HelperProps";

interface HelperModalProps {
  title: string;
  data: HelperProps[];
  isQuestion?: boolean;
}

export default function HelperModal({
  title,
  data,
  isQuestion,
}: HelperModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip
        label={"more info"}
        placement={"right"}
        bg={"gray.900"}
        color={"gray.100"}
        hasArrow
        p={2}
        m={2}
        maxW={[null, null, null, "200px", "220px", "240px"]}
        borderRadius={"4px"}
        fontSize={"12px"}
        boxShadow={"none"}
        arrowSize={8}
        
      >
        <IconButton
          aria-label=""
          variant="ghost"
          color={"secondary.500"}
          icon={isQuestion ? <QuestionIcon /> : <InfoIcon />}
          onClick={onOpen}
          _hover={{ bg: "transparent" }}
        >
          Open Modal
        </IconButton>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent bg={"background"} p={8}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <VStack gap={6} align={"flex-start"}>
              {data.map((prop: HelperProps, index: number) => {
                return (
                  <Box key={index}>
                    <Heading fontSize={"md"}>{prop.title}</Heading>
                    {prop.description.split("\n").map((descript, i) => {
                      return (
                        <Text key={i} fontSize="sm">
                          <Highlight
                            query={prop.highlight ?? ""}
                            styles={{
                              px: "2",
                              py: "1",
                              bg: "lightBackground",
                              color: "gray.700",
                            }}
                          >
                            {descript}
                          </Highlight>
                        </Text>
                      );
                    })}

                    {prop.image && <Image src={prop.image} />}
                  </Box>
                );
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
