import { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react";

import { ChatbotContext } from ".";
import useWS from "hooks/chat/useWS";

import Loading from "components/ui/Loading";
import HumanMessage from "components/chat/HumanMessage";
import LoadingBubble from "components/chat/LoadingBubble";
import MessageBubble from "components/chat/MessageBubble";

import { ConversationProps } from "models/chat/MessageProps";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import ScrollDownButton from "components/ui/ScrollDownButton";

import { errorHandler } from "utils/helpers";
import { MdOutlineHideSource } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGuideData,
  updateCurrentModalPosition,
} from "redux/features/guide/guideSlice";

export default function ChatMessages() {
  // Contexts
  const { messages, waitingOnBot, loadingChat, chatError, activeSession } =
    useContext(ChatbotContext);
  const { questionOnWait }: { questionOnWait: string | undefined } =
    useOutletContext();

  // States
  const [scrolledUp, setScrolledUp] = useState(false);

  // Refs
  const messagesRef = useRef<HTMLDivElement>(null);
  const previousScrollTop = useRef(0); // Track previous scrollTop

  // extract session id from url
  const { id } = useParams();

  // Hooks
  const dispatch = useDispatch();
  const { notifications, partialReply } = useWS({ waitingOnBot });
  const { isGuideOpen, currentModalPosition, currentMode } = useSelector(
    selectCurrentGuideData
  );

  const isActiveSession = activeSession === id;

  const scrollToElement = (element: any) => {
    const rect = element.getBoundingClientRect();
    const modalHeight = 300; // Adjust based on your modal's height

    let scrollTo = element.offsetTop - modalHeight / 2 + rect.height / 2;
    const currentRef = messagesRef.current;

    if (currentRef) {
      const maxScroll = currentRef.scrollHeight - currentRef.clientHeight;
      scrollTo = Math.min(Math.max(scrollTo, 0), maxScroll);

      currentRef.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (
      isGuideOpen &&
      currentModalPosition &&
      currentMode === "PAGE_INSTRUCTIONS"
    ) {
      const element = document.querySelector(`#${currentModalPosition.id}`);
      if (element && messagesRef.current) {
        const newTop = scrollToElement(element);
        dispatch(
          updateCurrentModalPosition({ ...currentModalPosition, top: newTop })
        );
      }
    }
  }, [isGuideOpen]);

  // Handlers
  function scrollToBottomWithBehavior(smoothScroll = true) {
    // DO NOT SCROLL DOWN IF HELp TOUR IS OPEN
    if (isGuideOpen) return;
    if (messagesRef.current) {
      const { scrollHeight } = messagesRef.current;

      smoothScroll
        ? messagesRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          })
        : (messagesRef.current.scrollTop = scrollHeight);
    }
  }

  function handleScrollUp() {
    if (waitingOnBot) {
      const currentScrollTop = messagesRef.current!.scrollTop;

      if (currentScrollTop < previousScrollTop.current) {
        // Remove event listener after first scroll up
        messagesRef.current!.removeEventListener("scroll", handleScrollUp);

        setScrolledUp(true);
      }

      // Update previous scrollTop value for next check
      if (previousScrollTop.current !== currentScrollTop) {
        previousScrollTop.current = currentScrollTop;
      }
    }
  }

  // scroll event listener
  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef && !scrolledUp && !!waitingOnBot) {
      currentRef.addEventListener("scroll", handleScrollUp);
    } else return;

    return () => {
      if (currentRef && !scrolledUp && !!waitingOnBot) {
        currentRef.removeEventListener("scroll", handleScrollUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef, waitingOnBot]);

  // smooth scroll on streaming reply data
  useEffect(() => {
    const autoScrollEnabled = !scrolledUp && partialReply.length;

    if (autoScrollEnabled) {
      scrollToBottomWithBehavior(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialReply]);

  // instant scroll to bottom on select different session
  useEffect(() => {
    scrollToBottomWithBehavior(false);
  }, [messages]);

  // instant scroll to bottom on initial render
  useEffect(() => {
    messagesRef.current && scrollToBottomWithBehavior(false);
  }, []);

  // smooth scroll on question submitted
  useEffect(() => {
    waitingOnBot ? scrollToBottomWithBehavior(true) : setScrolledUp(false);
  }, [waitingOnBot]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const conversationHeight = useBreakpointValue({
    lg: "calc(100vh - 110px)", // 148 = (8x2) + 16 + 8 + 60 + 10: py + boxSize + gap + textfield
    xl: "calc(100vh - 120px)", // 156 = (12x2) + 16 + 8 + 60 + 10
    "2xl": "calc(100vh - 126px)", // 164 = (16x2) + 16 + 8 + 60 + 10
  });

  const listStyle = {
    height: conversationHeight,
    width: "100%",
    padding: "0 6px 0 0",
  };

  /* waiting for session content */
  if (loadingChat) {
    return (
      <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
        <Loading />
      </Flex>
    );
  }

  // has error
  if (!loadingChat && !!chatError) {
    return (
      <Flex
        w={"100%"}
        h={"100%"}
        direction={"column"}
        gap={3}
        justify={"center"}
        align={"center"}
        color={"yellow.500"}
      >
        <Icon as={MdOutlineHideSource} boxSize={8} />
        <Text fontSize={"14px"} fontWeight={"500"}>
          {errorHandler(chatError)?.message}
        </Text>
      </Flex>
    );
  }

  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  return (
    <CustomScrollBar
      scrollableNodeProps={{ ref: messagesRef }}
      style={listStyle}
      maxW={"98%"}
      mx={"auto"}
      zIndex={applyIsGuideOpenStyles ? 1300 : 1}
    >
      <Flex
        direction={"column"}
        justify={"flex-end"}
        align={"flex-end"}
        h={"calc(100% - 90px)"}
        w={"900px"}
        maxW={"90%"}
        mx={"auto"}
        py={"24px"}
        position={"relative"}
        _focusVisible={{ border: "none", outline: "none" }}
      >
        {/* waiting for session content */}
        {loadingChat && (
          <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
            <Loading />
          </Flex>
        )}

        {/* messages content */}
        {!loadingChat &&
          !chatError &&
          (messages.length > 0 || waitingOnBot) && (
            <>
              <Flex
                direction={"column"}
                pr={4}
                w={"100%"}
                _focusVisible={{ border: "none", outline: "none" }}
              >
                {messages.map(
                  (conversation: ConversationProps, index: number) => (
                    <MessageBubble
                      key={"message" + index}
                      content={conversation ?? undefined}
                      isLastMessage={index === messages.length - 1}
                      messagesRef={messagesRef}
                    />
                  )
                )}
              </Flex>

              {/* streaming - partial reply */}
              {waitingOnBot && isActiveSession && (
                <Flex direction={"column"} px={2} pr={6} w={"100%"}>
                  <Flex w="100%" mt={4} pl={"48px"}>
                    <Flex
                      alignContent={"flex-start"}
                      width={"fit-content"}
                      display={"inline-block"}
                      ml={"auto"}
                    >
                      {!!questionOnWait && (
                        <HumanMessage questionOnWait={questionOnWait} />
                      )}
                    </Flex>
                  </Flex>
                  <LoadingBubble
                    partialReply={partialReply}
                    notifications={notifications}
                  />
                </Flex>
              )}
            </>
          )}
      </Flex>

      {!applyIsGuideOpenStyles && (
        <ScrollDownButton
          messagesRef={messagesRef}
          onClick={() => scrollToBottomWithBehavior()}
        />
      )}
    </CustomScrollBar>
  );
}
