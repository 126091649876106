import {
  Flex,
  Heading,
  Text,
  useMediaQuery,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import MainButton from "../MainButton";

interface Props {
  setShowInfo: Dispatch<SetStateAction<boolean>>;
}

const fadeDown = keyframes`
  0% {
    transform: translateY(-30px) scale(0.85);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

const fadeUp = keyframes`
  0% {
    transform: translateY(30px) scale(0.85);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

export default function Content({ setShowInfo }: Props) {
  // Redux
  const { user } = useSelector(selectCurrentAuthData);
  const isUser = !!(user && user?.id);

  // Navigation
  const navigate = useNavigate();

  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const color = colorMode === "light" ? "secondary.200" : "secondary.800";

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Handler
  function handleClick() {
    if (isUser) {
      if (isMobileView) {
        setShowInfo(true);
      } else {
        navigate("/chat");
      }
    } else {
      navigate("/login");
    }
  }

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const fadeDownAnimation = prefersReducedMotion
    ? undefined
    : `${fadeDown} 1s ease-in-out`;

  const fadeUpAnimation = prefersReducedMotion
    ? undefined
    : `${fadeUp} 1.1s ease-in-out`;

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      textAlign={"left"}
      h={[
        "calc(100vh - 122px)", // 20 + 32 + 70
        "calc(100vh - 140px)", // 30 + 32 + 70 + 8: pt + logo height + nav height + bottom pos.
        "calc(100vh - 150px)", // 30 + 32 + 70 + 8
        "calc(100vh - 140px)",
        "calc(100vh - 140px)",
        "calc(100vh - 160px)",
      ]}
      gap={{ base: "90px", sm: "60px" }}
    >
      {/* Heading content */}
      <Flex direction={"column"} w={"full"} gap={{ base: 12, lg: 10 }}>
        {/* welcome heading */}
        <Flex
          direction={"column"}
          gap={{ base: 1, lg: 2 }}
          textAlign={{ base: "center", lg: "left" }}
          animation={{ base: fadeDownAnimation, lg: "none" }}
        >
          <Text
            fontSize={["14px", "16px", "18px", "20px", "22px", "24px"]}
            color={color}
            letterSpacing={{ base: 1, lg: 3 }}
            lineHeight={1}
          >
            - Welcome to
          </Text>
          <Heading
            fontSize={["40px", "44px", "50px", "78px", "86px", "94px"]}
            lineHeight={1}
            letterSpacing={-1}
          >
            MoleculeLake
          </Heading>
        </Flex>

        <Flex
          direction={"column"}
          gap={3}
          textAlign={{ base: "center", lg: "left" }}
          animation={{ base: fadeUpAnimation, lg: "none" }}
        >
          {/* secondary heading */}
          <Heading
            color={"whiteAlpha.900"}
            size={{ base: "sm", md: "md", lg: "lg" }}
            letterSpacing={{ base: 0.5, lg: 4 }}
            fontWeight={"500"}
          >
            {isMobileView ? (
              <>
                Unlock Generative AI capabilities
                <br />
                in your scientific journey
              </>
            ) : (
              "Accelerating scientific discovery"
            )}
          </Heading>

          {/* paragraph */}
          <Text
            display={{ base: "none", lg: "inline-block" }}
            w={{ base: "100%", lg: "70%" }}
            maxW={{ base: "auto", lg: "600px" }}
            color={"WhiteAlpha.400"}
            letterSpacing={0.5}
            lineHeight={"1.5"}
            fontSize={{ base: "13px", lg: "16px" }}
          >
            Taking advantage of the latest advancements in generative AI,
            MoleculeLake enables you to intelligently explore privileged life
            science data and the entire body of scientific literature.
          </Text>
        </Flex>
      </Flex>

      <MainButton
        text={"Get Started"}
        onClick={handleClick}
      />
    </Flex>
  );
}
