import { Navigate,useParams } from 'react-router-dom'
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import LiteratureItem from 'components/data/proprietary/literature';

const CustomScrollBarStyle = {
    height: '100%',
    width: "100%",
    padding: '10px'
};


const ProprietaryPanel = () => {
    const { source } = useParams();
    const category = source?.toUpperCase() || ''

    if (!['LITERATURE'].includes(category)) {
        return <Navigate to={'/not-found'} replace />
    }
    return (
        <CustomScrollBar style={CustomScrollBarStyle} overflowX={"hidden"}>
            {category === 'LITERATURE' ? <LiteratureItem  /> : null}
        </CustomScrollBar>
    
    )
}

export default ProprietaryPanel