import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";

import BreadcrumbHome from "components/ui/BreadcrumbHome";
import { SavedFolderProps } from "models/research/SavedProps";

import { ChevronRightIcon } from "@chakra-ui/icons";

interface ResearchBreadcrumbProps {
  folder: SavedFolderProps | undefined;
}

function ResearchBreadcrumb({ folder }: ResearchBreadcrumbProps) {
  // Navigation
  const navigate = useNavigate();

  // Handler
  function handleGoToIndex() {
    navigate(`/data/research`);
  }

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      mr={"auto"}
      w={"400px"}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleGoToIndex} lineHeight={1} p={1.5}>
          <BreadcrumbHome />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {/* Id */}
      {folder && (
        <BreadcrumbItem
          isCurrentPage
          color={"gray.500"}
          fontWeight={"500"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <BreadcrumbLink w={"100%"}>
            <Text
              fontSize={"small"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {folder?.name}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default ResearchBreadcrumb;
