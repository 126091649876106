import { ListItem, List } from "@chakra-ui/react";
import { ExtraPapersProps } from "./PapersReferencesChart";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

interface AdditionalPapersListProps {
  titles: (ExtraPapersProps | undefined)[];
}

// Styles
const listStyle = {
  height: "340px",
  paddingBottom: "10px",
  margin: "auto 0",
};

function AdditionalPapersList({ titles }: AdditionalPapersListProps) {
  return (
    <CustomScrollBar style={listStyle}>
      <List color={"gray.600"} spacing={2} styleType="none">
        {titles?.map((item: ExtraPapersProps | undefined) => (
          <ListItem
            fontSize={"12px"}
            width={"90%"}
            key={item?.id}
            lineHeight={1.3}
          >
            ✦ {item?.title}
          </ListItem>
        ))}
      </List>
    </CustomScrollBar>
  );
}

export default AdditionalPapersList;
