import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import PaymentSuccessModal from "./PaymentSuccessModal";
import { hexToRgba } from "utils/helpers";
import { LibFeatures } from "./helpers";

export default function DataLibrary() {
  const [showModal, setShowModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { colors } = useTheme();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let params = searchParams.get("p");
    if (params) {
      setPaymentStatus(params);
      setShowModal(true);
    }
  }, [location.search]);

  return (
    <>
      <Flex
        h={"100%"}
        direction="column"
        mx="auto"
        gap={6}
        overflow={"hidden"}
        maxW={"1200px"}
        w={"95%"}
      >
        {/* Tabs */}
        <Flex
          w={"100%"}
          justify={"center"}
          align={"center"}
          p={3}
          borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
          borderBottomWidth={1}
        >
          <Flex w={"fit-content"} h={"fit-content"}>
            {LibFeatures.map((title, index) => {
              const isSelected = location.pathname.includes(
                title.toLowerCase()
              );
              const isRoot = location.pathname === "/data" && index === 0;
              const isActive = isSelected || isRoot;

              return (
                <Box
                  key={index}
                  py={2}
                  px={5}
                  cursor="pointer"
                  w={"fit-content"}
                  textAlign={"center"}
                  borderRadius={"99px"}
                  bg={
                    isActive ? hexToRgba(colors.blue[300], 0.15) : "transparent"
                  }
                  color={isActive ? "blue.500" : "gray.500"}
                  onClick={() => navigate(`/data/${title.toLowerCase()}`)}
                  transition={"all .3s ease"}
                >
                  <Text
                    fontSize={"14px"}
                    fontFamily={"Poppins, sans-serif"}
                    fontWeight={"500"}
                    letterSpacing={"-0.01rem"}
                    textTransform={"capitalize"}
                  >
                    {title}
                  </Text>
                </Box>
              );
            })}
          </Flex>
        </Flex>

        {/* Content */}
        <Box w={"100%"} h={"100%"}>
          <Outlet />
        </Box>
      </Flex>

      {/* Payment Success or cancel modal */}
      <PaymentSuccessModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          navigate("/data");
        }}
        paymentStatus={paymentStatus}
      />
    </>
  );
}
