import { useMemo, useState } from "react";
import { AxiosError } from "axios";
import { Box, Flex } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";

import { useCompoundsAPI } from "api/useCompoundsAPI";

import Loading from "components/ui/Loading";
import ReadMoreButton from "./ReadMoreButton";
import NoDataAvailable from "./NoDataAvailable";
import MainPanelError from "components/ui/MainPanelError";
import CompoundsTable from "components/compounds/CompoundsTable";

import { CompoundProps } from "models/compounds/CompoundProps";

interface MoleculesProps {
  compounds: string[] | undefined;
}

// limit nbr of molecules to show to 3 initially
const MAX_SLICE = 3;

function Molecules({ compounds }: MoleculesProps) {
  // Hooks
  const { fetchCompoundsByName } = useCompoundsAPI();

  // States
  const [showAll, setShowAll] = useState(false);

  //   * Fetch referenced compounds
  const moleculesQueries = useQueries({
    queries: (compounds || []).map((cmpd: string) => {
      return {
        queryKey: ["compounds-by-name", cmpd],
        queryFn: fetchCompoundsByName,
        staleTime: 40 * 1000 * 60,
        gcTime: 30 * 1000 * 60,
        // NOTE: retry and enabled don't fix the actual issue.
        // reminder: the issue was whenever user selects Molecules Tab, a re-fetch occurs for failed queries.
        // The fix takes place in parent component References: muting isLazy property in Tabs component
        // this prop. was causing the re-fetch for failed queries
        // -------
        // retry: (failureCount: any, error: any) => {
        //   const failedUrl = error?.config?.url;
        //   const newFailedQuery: string =
        //     failedUrl?.split("get_compound_by_name/")[1]?.toLowerCase() ?? "";

        //   setFailedQueries((p) => [...p, newFailedQuery]);
        //   return false;
        // },
        // enabled: !failedQueries?.includes(cmpd?.toLowerCase()), // Prevent refetching for failed compounds
      };
    }),
  });

  const allFinished = moleculesQueries.every((query) => !query.isLoading);
  const allErrorsFound = moleculesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const molecules = useMemo(() => {
    return moleculesQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as CompoundProps);
  }, [moleculesQueries]);

  const moleculesToShow = showAll ? molecules : molecules?.slice(0, MAX_SLICE);

  // loading
  if (!allFinished)
    return (
      <Box my={6}>
        <Loading message={"Loading molecules..."} />
      </Box>
    );

  // Some molecules are not found; we ignore them and display the rest
  if (allErrorsFound) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading molecules"} />
      </Box>
    );
  }

  return (
    <>
      {moleculesToShow && moleculesToShow?.length > 0 ? (
        <Flex direction={"column"}>
          <CompoundsTable items={moleculesToShow} inChat />

          {/* read more/less */}
          {molecules.length > MAX_SLICE && (
            <ReadMoreButton
              label={
                showAll
                  ? "view less"
                  : `view more (+${molecules?.length - MAX_SLICE})`
              }
              onClick={() => setShowAll(!showAll)}
            />
          )}
        </Flex>
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

export default Molecules;
