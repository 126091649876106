import { Navigate, useParams } from 'react-router-dom';
import MoleculeItem from '../../../components/data/core/molecule';
import AssayItem from 'components/data/core/assay';
import LiteratureItem from 'components/data/core/literature';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';

const CustomScrollBarStyle = {
    height: '100%',
    width: "100%",
    padding: '10px'
};


const SourceItemDetails = () => {
    const { source } = useParams();
    const key = source?.toUpperCase() || ''

    if (!['MOLECULES', 'LITERATURE', 'ASSAYS'].includes(key)) {
        return <Navigate to={'/not-found'} replace />
    }
    return (
        <CustomScrollBar style={CustomScrollBarStyle} overflowX={"hidden"}>
            {key === 'MOLECULES' ? <MoleculeItem /> : key === 'ASSAYS' ? <AssayItem /> : <LiteratureItem />}
        </CustomScrollBar>
    )
}

export default SourceItemDetails