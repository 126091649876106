import parse, { domToReact } from "html-react-parser";
import { Box, useBreakpointValue, useTheme } from "@chakra-ui/react";

import CitationNode from "components/chat/CitationNode";
import TypingCharacter from "components/chat/TypingCharacter";
import { SourceProps, CiteProps } from "models/chat/MessageProps";
import {
  emboldenText,
  mergeStrings,
  removeAsterisks,
  replaceCitesWithSources,
} from "components/chat/helpers";

interface FormattedTextProps {
  text: string;
  citations?: CiteProps[];
  sources?: SourceProps[];
  streaming?: boolean;
  isNotification?: boolean;
}

const FormattedText = ({
  text,
  streaming,
  isNotification,
  citations = [],
  sources = [],
}: FormattedTextProps) => {
  // Theme
  const { colors } = useTheme();

  // Responsiveness
  const fontSize = useBreakpointValue({ lg: "14px", xl: "15px" });

  // Custom parsing options
  const parsingOptions = {
    // replace function looks for citation-node elements
    // and replaces them with <CitationNode />
    replace: (domNode: any) => {
      if (domNode.name === "citation-node") {
        const index = domNode.attribs.index;
        return (
          <CitationNode
            index={parseInt(index)}
            citeID={sources?.at(index)?.id}
            sources={sources}
          />
        );
      }

      if (domNode.name === "strong" && domNode.attribs.class === "bold") {
        return (
          <Box
            as="strong"
            fontSize={fontSize}
            lineHeight={"1.7"}
            color={colors.gray[600]}
          >
            {domToReact(domNode.children, parsingOptions)}
          </Box>
        );
      }
    },
  };

  // Handler
  function addCitations(text: string, citations: CiteProps[]) {
    let outputText = "";
    const elements: React.ReactNode[] = [];

    // for older conversations, before adding citations field
    if (!citations || citations?.length === 0) {
      outputText = text;

      const boldText = emboldenText(outputText);
      const parsedText = parse(boldText, parsingOptions);

      elements.push(parsedText);

      return elements;
    }

    // NOTE: 1st step: get cleaned text by removing asterisks
    const textNoAsterisks = removeAsterisks(text);
    const asterisksInText = text?.length - textNoAsterisks?.length;

    citations.forEach((citation) => {
      const { response, cite } = citation;

      // remove asterisks from citation response for subsequent comparison with bot reply text
      const responseNoAsterisks = removeAsterisks(response);

      // is true if citation response is part of the bot reply text
      const isResponseInText = textNoAsterisks?.includes(responseNoAsterisks);

      if (isResponseInText) {
        if (!!cite && cite?.length > 0) {
          // NOTE: 2nd step: extract response position in cleaned text
          const citationPosition =
            textNoAsterisks.indexOf(responseNoAsterisks) +
            responseNoAsterisks.length;

          // NOTE: 3rd step: insert cites just after the end of the response
          const textWithAddedCitesAtTheEnd =
            text.slice(0, citationPosition + asterisksInText) +
            `${cite?.join(",")}` +
            text.slice(citationPosition + asterisksInText);

          let restoredText;
          let textWithAddedCitesAtTheMiddle;

          if (outputText) {
            // insert cites regardless of its place in the reply. ex: cites in the middle of the reply
            textWithAddedCitesAtTheMiddle =
              mergeStrings(outputText, textWithAddedCitesAtTheEnd) ?? "";
          }

          // if output is not empty it means that the current reply sentence
          // is a concatenation of citations responses joined by citation(s) id(s)
          restoredText = !!outputText
            ? textWithAddedCitesAtTheMiddle
            : textWithAddedCitesAtTheEnd;

          outputText = restoredText ?? "";
        } else {
          if (!outputText?.includes(response)) {
            // append non-cited introductory sentences into one string
            // if response has no cite, no edit is needed
            outputText = `${outputText} ${response}`?.trim();
          }
        }
      }
    });

    // replace each cite id with the correspondent source index
    outputText = replaceCitesWithSources(citations, sources, outputText);

    const boldText = emboldenText(outputText);
    const parsedText = parse(boldText, parsingOptions);

    elements.push(parsedText);

    return elements;
  }

  if (!text.length) {
    return <div style={{ fontSize: fontSize, lineHeight: "1.7" }}>{text}</div>;
  }

  // add cite(s) to raw reply in the right position(s)
  const citedText = addCitations(text, citations);

  // notification message
  if (isNotification) {
    return (
      <Box
        fontSize={"13px"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={"1.7"}
        color={colors.gray[500]}
      >
        {text}
      </Box>
    );
  }

  return (
    <Box fontSize={fontSize} lineHeight={"1.7"} color={colors.gray[600]}>
      {citedText}
      {!!streaming && <TypingCharacter />}
    </Box>
  );
};

export default FormattedText;
