import { Flex, Image, Text } from "@chakra-ui/react";

import NewFolderContent from "./NewFolderContent";
import noFolders from "assets/no-folders.png";

function NoFolders() {
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      h={"100%"}
      w={"100%"}
      mx={"auto"}
      gap={10}
    >
      <Flex direction={"column"} align={"center"} justify={"center"} gap={2}>
        <Image src={noFolders} w={"80px"} alt="no folders" loading="lazy" />
        <Text color={"blue.700"}>No folders found</Text>
      </Flex>

      <Flex w={"240px"}>
        <NewFolderContent inView />
      </Flex>
    </Flex>
  );
}

export default NoFolders;
