import {
  Text,
  List,
  ListItem,
  ListIcon,
  Heading,
  Flex,
  Icon,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hexToRgba } from "utils/helpers";
import ChangePlanModal from "./ChangePlanModal";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useStripe } from "@stripe/react-stripe-js";
import { createCheckoutSession } from "services/stripe.service";
import { FaUsers } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdStar } from "react-icons/io";
import { MdWorkspacePremium } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { FiMinusCircle } from "react-icons/fi";

export interface PlansProps {
  data: any;
  isLoading: boolean;
  isAnnual: boolean;
}

export default function PlanOffers({ data, isLoading, isAnnual }: PlansProps) {
  // Hooks
  const { user } = useSelector(selectCurrentAuthData);

  // Stripe
  const stripe = useStripe();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Hooks
  const toast = useToast();
  const navigate = useNavigate();

  // Handler
  const handleCheckout = async (plan: string) => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // TODO: Handle errors
      return;
    }
    setIsProcessing(true);

    // if (plan === "Basic" || plan === "Enterprise") {
    if (plan === "Enterprise") {
      setShowModal(true);
      setIsProcessing(false);
      return;
    }

    if (plan === "Premium" || plan === "Basic") {
      let session: any;
      const payload = {
        email: user?.email,
        username: `${user?.given_name}${user?.family_name}`,
        // plan: "Premium",
        plan: plan === "Premium" ? "Premium" : "Basic",
        is_yearly: isAnnual,
        payment_mode: "subscription", // subscription => recurring, payment => one off payment
      };

      await createCheckoutSession(payload)
        .then(async (res) => {
          console.log(res);
          session = res;
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          toast({
            description: error.response.data.message
              ? error.response.data.message
              : "Error initializing checkout",
            status: "error",
            position: "top-right",
          });
          setIsProcessing(false);
        });

      if (!session) {
        // TODO: Handle errors
        return;
      }

      // Redirect to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        // Handle error here.
        console.error(result.error.message);
        toast({
          description: result.error.message
            ? result.error.message
            : "Error initializing checkout",
          status: "error",
          position: "top-right",
          duration: 6000,
        });
      }
      return;
    }
  };

  const forBasic = useMemo(() => data.plan_name === "Basic", [data]);
  const forPremium = useMemo(() => data.plan_name === "Premium", [data]);
  const forEnterprise = useMemo(() => data.plan_name === "Enterprise", [data]);

  const isSelected = useMemo(() => {
    const planName = data?.plan_name?.toLowerCase();

    const userType = user?.user_type;
    let targetPlan = userType;

    if (userType === "teams") {
      targetPlan = "enterprise";
    }

    return planName === targetPlan;
  }, [data, user]);

  const billPeriod = isAnnual ? "yr" : "mo";
  const priceSufix = forEnterprise ? `pp/${billPeriod}` : billPeriod;

  // Use the names from initial code: plan_price for real price, and promotion_price for promo price
  const realPrice = isAnnual
    ? parseFloat(data.plan_price) * 12
    : parseFloat(data.plan_price);
  const promoPrice = isAnnual
    ? parseFloat(data.promotion_price) * 12
    : parseFloat(data.promotion_price);
  return (
    <>
      <Flex
        minH={"500px"}
        direction={"column"}
        p={3}
        borderRadius={"6px"}
        borderWidth={isSelected ? 2 : 1}
        borderColor={
          isSelected ? data.color_theme[0] : hexToRgba(data.color_theme[0], 0.3)
        }
        bg={isSelected ? hexToRgba(data.color_theme[0], 0.06) : "transparent"}
      >
        {/* Real price scratched out */}
        <Flex
          align="center"
          fontFamily={"Poppins, sans-serif"}
          color={"gray.700"}
          gap={1}
          direction="column"
          alignItems="flex-end"
        >
          <Flex align="center" direction="row" mr={1}>
            <Text
              fontSize={{ lg: "14px", xl: "16px" }}
              fontWeight="500"
              color={"gray.500"}
              textDecoration="line-through"
            >
              ${realPrice.toFixed(2)}
            </Text>
            <Text
              fontSize={{ lg: "14px", xl: "16px" }}
              fontWeight="500"
              opacity={0.6}
              textDecoration="line-through"
            >
              {priceSufix}
            </Text>
          </Flex>
        </Flex>

        {/* type and price */}
        <Flex justify="space-between" align="center" mb={2}>
          {/* heading with icon */}
          <Flex align={"center"} gap={2}>
            <Icon
              as={
                forBasic ? IoMdStar : forPremium ? MdWorkspacePremium : FaUsers
              }
              boxSize={"22px"}
              color={data.color_theme[0]}
            />
            <Heading
              as="h4"
              size="md"
              fontWeight={700}
              color={data.color_theme[0]}
            >
              {data.plan_name}
            </Heading>
          </Flex>

          {/* price */}
          <Flex
            align="center"
            fontFamily={"Poppins, sans-serif"}
            color={"gray.700"}
            gap={1}
            direction="column"
            alignItems="flex-end"
          >
            {/* Promotional price */}
            <Flex align="center" direction="row">
              <Text fontSize={{ lg: "18px", xl: "22px" }} fontWeight="700">
                ${promoPrice.toFixed(2)}
              </Text>
              <Text
                pl={1}
                fontSize={{ lg: "16px", xl: "18px" }}
                fontWeight="500"
                minW={{ lg: "27px", xl: "31px" }}
                opacity={0.6}
              >
                {priceSufix}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* offer description */}
        <Text my={1} mb={5} fontSize={"13px"} lineHeight={"1.3"}>
          {data.description}
        </Text>

        <Flex direction={"column"} gap={4}>
          {data.offers?.map((offer: any, indx: number) => (
            <Flex
              direction={"column"}
              gap={2}
              key={`${data.plan_name}-${indx}`}
            >
              <Text
                fontSize={{ lg: "13px", xl: "14px" }}
                fontWeight={500}
                lineHeight={"1.2"}
                fontFamily={"Poppins, sans-serif"}
              >
                {offer.offer_name}
              </Text>
              <List spacing={1.5}>
                {offer.offers?.map((offr: any, idex: number) => (
                  <ListItem
                    key={idex}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontSize={{ lg: "13px", xl: "14px" }}
                  >
                    <ListIcon
                      as={
                        offr.type === "unlimited"
                          ? FaCheck
                          : offr.type === "limited"
                          ? FiMinusCircle
                          : LiaTimesSolid
                      }
                      color={
                        offr.type === "unlimited"
                          ? "highlight.primary"
                          : "gray.500"
                      }
                      boxSize={{ lg: "13px", xl: "14px" }}
                    />
                    <Text lineHeight={"1.3"}>{offr.offer}</Text>
                  </ListItem>
                ))}
              </List>
            </Flex>
          ))}
        </Flex>

        {/* select offer button */}
        <Button
          mt={"auto"}
          size="md"
          fontSize={"14px"}
          w={"100%"}
          onClick={() => handleCheckout(data.plan_name)}
          isLoading={isProcessing}
          loadingText="Processing"
          opacity={isSelected ? 0.7 : 1}
          colorScheme={isSelected ? "gray" : data.color_theme[1]}
          pointerEvents={isSelected ? "none" : "auto"}
          borderRadius={"20px"}
        >
          {isSelected ? `Your Current Plan` : data.plan_name}
        </Button>
      </Flex>

      {/* Confirm Cancel Plan modal */}
      <ChangePlanModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          navigate("/data");
        }}
        newPlan={data.plan_name}
        newPlanPrice={promoPrice}
        isAnnual={isAnnual}
        newPlanColor={data.color_theme[0]}
      />
    </>
  );
}
