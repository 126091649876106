import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Bullet from "components/home/Bullet";
import { useState } from "react";

export default function UseCase() {
  return (
    <Box
      bgImage="https://reacto.s3.eu-central-1.amazonaws.com/images/bg-poly1.png"
      bgSize="contain"
      bgRepeat={"no-repeat"}
      bgPos={"center"}
    >
      <Flex
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        gap={10}
        w={["85%", "85%", "85%", "85%", "95%", "100%"]}
        maxW={["1100px", "1100px", "1100px", "1100px", "1360px", "1360px"]}
        mx={"auto"}
        my={"100px"}
      >
        <DemoVideo />
        <StorySummary />
      </Flex>
    </Box>
  );
}

function DemoVideo() {
  // State
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      height={"fit-content"}
      w={["100%", "90%", "635px", "80%", "60%", "60%"]}
      overflow={"hidden"}
      align={"flex-start"}
      boxShadow={"lg"}
      borderWidth={2}
      borderColor={"neutral.500"}
      borderRadius={"8px"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        as="video"
        controls={isHovered}
        src="https://reacto.s3.eu-central-1.amazonaws.com/moleculelake_demo_video.mp4"
        w={"full"}
      />
    </Flex>
  );
}

function StorySummary() {
  return (
    <Flex
      direction={"column"}
      gap={{ base: 8, md: 4, lg: 2 }}
      w={{ base: "100%", xl: "40%" }}
    >
      <Flex direction={"column"} gap={{ base: 2, md: 1 }}>
        <Text
          fontSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px" }}
          fontWeight={{ base: "400", lg: "500" }}
          color={"secondary.500"}
          letterSpacing={{ base: 1, lg: 3 }}
          lineHeight={1}
        >
          - Use Case
        </Text>
        <Heading
          fontSize={{ base: "24px", md: "28px", lg: "32px" }}
          lineHeight={1}
          color={"primary.800"}
          fontFamily={"Poppins, sans-serif"}
        >
          Psychoneuroimmunology Research
        </Heading>
      </Flex>

      <Flex
        direction={"column"}
        gap={3}
        maxW={"800px"}
        color={"black"}
        opacity={0.75}
        w={"90%"}
        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
      >
        <Text>
          <Bullet />
          Anna is a neuroscientific researcher at a biopharmaceutical company.
          She is currently working on a project focused on the relationship
          between infectious diseases and neuropsychiatric symptoms. Her company
          gives her access to MoleculeLake.
        </Text>
        <Text>
          <Bullet />
          She begins by navigating the compound view and gains new insight into
          the indications of a relevant compound used in the treatment of
          neuropsychiatric conditions. She investigates experimental studies by
          exploring the references and similar scientific papers in the
          literature network.
        </Text>
        <Text>
          <Bullet />
          With the help of our conversational agent, she discovers promising
          potential uses for compounds in the neuropsychiatric conditions
          associated with infectious diseases. With her new discoveries she
          feels empowered to continue her research and uncover life-changing
          medicine.
        </Text>
      </Flex>
    </Flex>
  );
}
