import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import DeleteModal from "components/chat/DeleteModal";
import Loading from "components/ui/Loading";
import { removeTeamMember } from "services/teams.services";
import { MemberProps, TeamProps } from "models/posts/PostProps";


interface DataProps {
  members: MemberProps[];
  isOwner: boolean,
  getTeamDetails: Function,
  teamData: TeamProps
}

const tableStyle = {
  height: "94%",
  width: "100%",
  padding: "0",
};

const TableData = ({ members, isOwner, getTeamDetails, teamData }: DataProps) => {
  const toast = useToast();

  const headers = ["First Name", "Last Name", "Email", "Role"];
  if (isOwner) {
    headers.push("")
  }

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<string>("");


  const openRemoveMemberModal = (memberId: string) => {
    setMemberToRemove(memberId);
    setShowModal(true);
  }

  const closeRemoveMemberModal = () => {
    setMemberToRemove("");
    setShowModal(false);
  }

  const handleConfirmRemove = async () => {
    setLoading(true);
    await removeTeamMember("member", memberToRemove).then(async () => {
      await getTeamDetails();

      toast({
        position: "top-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Team member removed successfully
          </Box>
        )
      });
    }).catch(error => {
      toast({
        description: error.response.data.message
          ? error.response.data.message
          : "Error removing team member",
        status: "error",
        position: "top-right",
      });
    }).finally(() => {
      setShowModal(false);
      setLoading(false);
    });
  }

  const renderHeader = (text: string, index: number) => {
    return (
      <Th
        key={"table-header" + index}
        py={2}
        px={2}
        textAlign={"left"}
        position="sticky"
        top={0}
        zIndex={1}
        bg="gray.100"
        borderLeftRadius={index === 0 ? "md" : "none"}
        borderRightRadius={index === headers.length - 1 ? "md" : "none"}
        borderRight={index !== headers.length - 1 ? "1px solid" : "none"}
        borderRightColor={"gray.200"}
      >
        {text}
      </Th>
    );
  };

  const renderRow = (data: MemberProps, index: number) => {
    return (
      <Tr
        key={"table-row" + index}
        py={5}
        transition="background 0.3s ease"
        _hover={{ cursor: "pointer", bg: "gray.100" }}
      >
        <Td
          key={'name' + data.given_name + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          {data.given_name}
        </Td>
        <Td
          key={'name' + data.family_name + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          {data.family_name}
        </Td>
        <Td
          key={'email' + data.email + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          {data.email}
        </Td>
        <Td
          key={'date' + data.first_login + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          <Badge>{(data.id !== teamData.owner) ? "Member" : "Admin"}</Badge>
        </Td>
        {isOwner ?
          <>
            <Td
              key={'date' + data.first_login + index * 2}
              borderColor={"gray.100"}
              p={1.5}
              isTruncated
            >
              {data.id !== teamData.owner &&
                <Button
                  size="sm"
                  borderWidth={1}
                  color={"red.500"}
                  bg={"transparent"}
                  borderRadius={"100px"}
                  borderColor={"transparent"}
                  _hover={{ borderColor: "red.500" }}
                  onClick={() => openRemoveMemberModal(data?.id)}
                >
                  Remove
                </Button>
              }
            </Td>
          </>
          :
          <></>
        }
      </Tr>
    );
  };

  return (
    <TableContainer height="88%" style={{ position: "relative" }}>
      <CustomScrollBar style={tableStyle}>
        <Table variant="simple" size="sm">
          {
            members?.length > 0 ?
              <>
                <Thead>
                  <Tr>{headers.map((text, index) => renderHeader(text, index))}</Tr>
                </Thead>
                <Tbody>{members?.map((row: any, index: number) => renderRow(row, index))}</Tbody>
              </>
              :
              <Text color={"gray.500"} marginTop={20} textAlign={"center"}>You do not have any team member yet. Start by inviting a team member to collaborate.</Text>
          }
        </Table>
      </CustomScrollBar>
      <DeleteModal
        isOpen={showModal}
        onClose={closeRemoveMemberModal}
        onConfirm={handleConfirmRemove}
        header={"Remove team member"}
      >
        {loading ? (
          <Loading message={"Removing team member ..."} />
        ) : (
          <Text fontSize={"14px"} mt={4}>
            {"Are you sure you want to remove this team member?"}
          </Text>
        )}
      </DeleteModal>
    </TableContainer>
  );
};

export default TableData;
