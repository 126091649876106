import { Grid } from "@chakra-ui/react";
import Header from "components/home/FAQ/Header";
import Content from "components/home/FAQ/Content";

interface FAQProps {
  id: string;
}

export default function FAQ({ id }: FAQProps) {
  return (
    <Grid
      px={["12px", null]}
      id={id}
      w={["100%", "100%", "95%", "90%", "95%", "100%"]}
      maxW={[null, null, null, "1100px", "1360px", "1360px"]}
      mt={["60px", "60px", "60px", "60px", "100px", "100px"]}
      mb={["60px", "60px", "60px", "60px", "160px", "100px"]}
      mx={"auto"}
      templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(3, 1fr)"]}
      rowGap={["40px", "40px", null]}
      columnGap={[null, null, "40px", "40px", "50px", "60px"]}
    >
      <Header />
      <Content />
    </Grid>
  );
}
