import { IconButton } from "@chakra-ui/react";
import { BsRobot } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

function BotAvatar() {
  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  return (
    <IconButton
      transition={'none'}
      icon={<BsRobot />}
      pointerEvents={"none"}
      cursor={"default"}
      mr={2}
      isRound={true}
      boxShadow={"md"}
      aria-label=""
      disabled
      color={"neutral.900"}
      bg={isGuideOpen ? 'transparent' : "background"}
    />
  );
}

export default BotAvatar;
