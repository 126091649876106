import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useTheme,
  useToast,
  Text,
} from "@chakra-ui/react";
import { ProfileUserProps } from "components/authforms/Profile";

// icons
import {
  MdLogout,
  MdOutlinePrivacyTip,
  MdOutlinePayments,
} from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { axiosPrivate } from "api/axios";
import {
  clearCredentials,
  selectCurrentAuthData,
} from "redux/features/auth/authSlice";

import { hexToRgba } from "utils/helpers";
import PrivacyPolicyModal from "components/home/PrivacyPolicyModal";
import PricingModal from "components/home/PricingModal";
import { RiUserSettingsLine } from "react-icons/ri";
import { FaCode } from "react-icons/fa";
import AccountSettingsModal from "components/userMenu/AccountSettingsModal";
import { LuMoreVertical } from "react-icons/lu";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { ThemeProps } from "components/userMenu/Appearance";
import UserAvatar from "components/ui/UserAvatar";

interface UserProps {
  user: ProfileUserProps;
}

export default function AvatarMenu({ onToggle, isDarkMode }: ThemeProps) {
  const { isHighlighted } = useGuideTour();
  let highlighted = isHighlighted("user_menu");

  // State
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        name: "Account Settings",
        icon: RiUserSettingsLine,
        handler: () => setShowAccountModal(true),
        tooltip: "Account Settings",
      },
      {
        name: "Change Your Plan",
        icon: MdOutlinePayments,
        handler: () => setShowPricingModal(true),
        tooltip: "Upgrade Plan",
      },
      {
        name: "API Documentation",
        icon: FaCode,
        handler: () => window.open(`${window.location.origin}/docs`),
        tooltip: "API Documentation",
      },
      {
        name: "Privacy Policy",
        icon: MdOutlinePrivacyTip,
        handler: () => setShowPrivacyModal(true),
        tooltip: "Privacy Policy",
      },
    ],
    []
  );

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();

  return (
    <Flex
      id="user_menu"
      zIndex={highlighted ? 2000 : "initial"}
      borderRadius={"6"}
      fontWeight={highlighted ? 600 : "initial"}
      bg={highlighted ? "background" : "transparent"}
      _hover={{ bg: highlighted ? "background" : "transparent" }}
      pointerEvents={highlighted ? "none" : "auto"}
    >
      {/* Avatar & Menu Icon */}
      <Flex align={"center"} justify={"space-between"} w={"100%"}>
        {/* Avatar */}
        <UserAvatar />

        {/* Menu */}
        <Menu>
          <MenuButton
            h={"fit-content"}
            w={"fit-content"}
            borderRadius={"6px"}
            color={"primary.500"}
            bg={hexToRgba(colors.primary[500], 0.06)}
            transition={"all .3s ease"}
            _hover={{ bg: hexToRgba(colors.primary[500], 0.12) }}
          >
            <Icon
              as={LuMoreVertical}
              boxSize={8}
              p={2}
              cursor={"pointer"}
              display={"block"}
            />
          </MenuButton>

          <MenuList
            bg={"background"}
            borderColor={hexToRgba(colors.secondary[700], 0.2)}
            borderRadius={16}
            p={2}
            zIndex={999}
          >
            <UserDetails user={user ?? {}} />

            {menuItems.map((item, index) => {
              return (
                <MenuItem
                  key={"item-" + index}
                  bg={"transparent"}
                  color={"gray.500"}
                  _hover={{ bg: hexToRgba(colors.blue[500], 0.12) }}
                  onClick={item.handler}
                  display={"flex"}
                  alignItems={"center"}
                  gap={4}
                  py={3}
                  borderRadius={8}
                >
                  <Icon as={item.icon} boxSize="16px" />
                  <Text fontSize={"14px"} fontWeight={"500"}>
                    {item.name}
                  </Text>
                </MenuItem>
              );
            })}

            <MenuDivider />
            <Logout />
          </MenuList>
        </Menu>
      </Flex>

      {/* Modals */}
      <PrivacyPolicyModal
        isOpen={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
      />

      <AccountSettingsModal
        isOpen={showAccountModal}
        onClose={() => setShowAccountModal(false)}
        themeOnToggle={onToggle}
        themeIsChecked={isDarkMode}
      />

      <PricingModal
        isOpen={showPricingModal}
        onClose={() => setShowPricingModal(false)}
      />
    </Flex>
  );
}

function UserDetails({ user }: UserProps) {
  const { given_name, family_name, email } = user;

  return (
    <Flex direction={"column"} gap={1} mt={1} mb={4} px={3}>
      {family_name && given_name ? (
        <Text
          textTransform={"capitalize"}
          fontWeight={"bold"}
          fontSize={"14px"}
          color={"blue.700"}
        >
          {`${given_name} ${family_name}`}
        </Text>
      ) : null}
      {email ? (
        <Text color={"blue.600"} fontSize={"12px"}>
          {email}
        </Text>
      ) : null}
    </Flex>
  );
}

function Logout() {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  // Theme
  const { colors } = useTheme();

  // Handler
  const handleLogout = useCallback(async () => {
    try {
      await axiosPrivate.get("/api/logout");
      dispatch(clearCredentials());

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged out successfully
          </Box>
        ),
      });
    } catch (error: any) {
      toast({
        description: "Logout failed. Try again..",
        status: "error",
        position: "top-right",
      });
    }
  }, [toast, dispatch]);

  return (
    <MenuItem
      bg={"transparent"}
      color={"gray.500"}
      _hover={{ bg: hexToRgba(colors.blue[500], 0.12) }}
      onClick={handleLogout}
      gap={2}
      borderRadius={"6px"}
    >
      <Icon as={MdLogout} boxSize="16px" />
      <Text fontSize={"14px"} fontWeight={"500"}>
        Log out
      </Text>
    </MenuItem>
  );
}
