import { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Tag,
  TagLabel,
  Tooltip,
  TagLeftIcon,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";

interface SmilesWithTagProps {
  canonic_url: string | undefined;
  isometric_url: string | undefined;
}

export default function SmilesWithTag({
  canonic_url,
  isometric_url,
}: SmilesWithTagProps) {
  // State
  const baseUrl = "data:image/png;base64,";
  const [imageMode, setImageMode] = useState<"canonical" | "isomeric">(
    "canonical"
  );

  const toCanonical = "See canonical smile";
  const toIsometric = "See isometric smile";

  // Handlers
  const toggleImageMode = () => {
    setImageMode((prevMode) =>
      prevMode === "canonical" ? "isomeric" : "canonical"
    );
  };

  if (!!canonic_url && !!isometric_url) {
    return (
      <Flex
        position="relative"
        w="fit-content"
        justify={"center"}
        align={"center"}
        direction={"column"}
        gap={2}
      >
        <Image
          src={
            imageMode === "canonical"
              ? baseUrl + canonic_url
              : baseUrl + isometric_url
          }
          maxW={"240px"}
          fallbackSrc="https://via.placeholder.com/240x240.png/FFFFFF"
          objectFit={"cover"}
        />

        <Tooltip
          label={imageMode === "canonical" ? toIsometric : toCanonical}
          bg={"gray.900"}
          color={"gray.100"}
          hasArrow
          py={2}
          px={3}
          m={2}
          maxW={[null, null, null, "200px", "220px", "240px"]}
          borderRadius={"6px"}
          fontSize={"12px"}
          boxShadow={"none"}
          arrowSize={8}
        >
          <Tag
            display={"flex"}
            size={"sm"}
            minW={"90px"}
            justifyContent={"center"}
            alignItems={"center"}
            variant="solid"
            bg="primary.100"
            color="primary.400"
            onClick={toggleImageMode}
            style={{ cursor: "pointer" }}
          >
            <TagLeftIcon as={RepeatIcon} />
            <TagLabel lineHeight={1}>{imageMode}</TagLabel>
          </Tag>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Box>
      {!!canonic_url && <Image src={baseUrl + canonic_url} />}
      {!!isometric_url && <Image src={baseUrl + isometric_url} />}
    </Box>
  );
}
