import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { PkProps } from "models/compounds/CompoundProps";

interface PkTableProps {
  rows: PkProps[];
}

export default function PkTable({ rows }: PkTableProps) {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th textAlign="left">Parameter</Th>
          <Th textAlign="left">Value</Th>
          <Th textAlign="left">Unit</Th>
          <Th textAlign="left">Model</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows?.map((row: PkProps, index: number) => (
          <Tr key={"pharmacokinetics" + index} fontSize={13}>
            <Td color={"secondary.600"} borderBottomColor={"gray.100"}>
              {row?.parameter}
            </Td>
            <Td borderBottomColor={"gray.100"}>{row?.value}</Td>
            <Td borderBottomColor={"gray.100"}>{row?.unit}</Td>
            <Td borderBottomColor={"gray.100"}>{row?.model}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
